import { isEmpty, isNil } from 'lodash';
import i18n from 'i18next';

export const isUrl = (as, value) => {
  if (isNil(value) || isEmpty(value))
    return Promise.reject(
      new Error(i18n.t('integrationsSettingsPage.nameAndType.errors.emptyURL'))
    );

  const validation = new RegExp(
    '^(?!mailto:)(?:(?:http|https|ftp)://|//)(?:\\S+(?::\\S*)?@)?(?:(?:(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}(?:\\.(?:[0-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))|(?:(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)(?:\\.(?:[a-z\\u00a1-\\uffff0-9]+-*)*[a-z\\u00a1-\\uffff0-9]+)*(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))|localhost)(?::\\d{2,5})?(?:(/|\\?|#)[^\\s]*)?$',
    'i'
  );

  if (validation.test(value)) {
    return Promise.resolve();
  }
  return Promise.reject(
    new Error(i18n.t('integrationsSettingsPage.nameAndType.errors.invalidURL'))
  );
};
