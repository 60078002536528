import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  isIntegrationModalVisible: false
};

export const openIntegrationModal = createAction('uiIntegrationModal/openIntegrationModal');
export const closeIntegrationModal = createAction('uiIntegrationModal/closeIntegrationModal');
export const toggleIntegrationModal = createAction('uiIntegrationModal/toggleIntegrationModal');

export const integrationModalReducer = createReducer(defaultState, {
  [openIntegrationModal]: state => ({ ...state, isIntegrationModalVisible: true }),
  [closeIntegrationModal]: state => ({ ...state, isIntegrationModalVisible: false }),
  [toggleIntegrationModal]: state => ({
    ...state,
    isIntegrationModalVisible: !state.isIntegrationModalVisible
  })
});
