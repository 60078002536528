import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: false,
  showUsersModal: false,
  showBJModal: false,
  interactions: [],
  selectedUsersIds: [],
  users: {},
  currentStep: 'users',
  syncFrom: '1'
};

export const interactionsLoadStarted = createAction('uiBitrixIntegration/interactionsLoadStarted');
export const interactionsLoadFailed = createAction('uiBitrixIntegration/interactionsLoadFailed');
export const interactionsLoadSucceed = createAction('uiBitrixIntegration/interactionsLoadSucceed');

export const bitrixIntegrationPageReducer = createReducer(defaultState, {
  [interactionsLoadStarted]: state => ({ ...state, loading: true }),
  [interactionsLoadFailed]: state => ({ ...state, loading: false }),
  [interactionsLoadSucceed]: (state, { payload }) => ({
    ...state,
    loading: false,
    interactions: payload.interactions
  })
});
