import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  idToAnalytics: {},
  idToHistoryAnalytics: {},
  loading: false
};

export const loadChecklistDefinitionAnalytics = createAction(
  'checklistDefinitions/loadChecklistDefinitionAnalytics'
);

export const checklistDefinitionAnalyticsLoaded = createAction(
  'checklistDefinitions/checklistDefinitionAnalyticsLoaded'
);

export const checklistDefinitionAnalyticsLoadFailed = createAction(
  'checklistDefinitions/checklistDefinitionAnalyticsLoadFailed'
);

export const loadChecklistDefinitionAnalyticsHistory = createAction(
  'checklistDefinitions/loadChecklistDefinitionAnalyticsHistory'
);

export const checklistDefinitionAnalyticsHistoryLoaded = createAction(
  'checklistDefinitions/checklistDefinitionAnalyticsHistoryLoaded'
);

export const checklistDefinitionAnalyticsHistoryLoadFailed = createAction(
  'checklistDefinitions/checklistDefinitionAnalyticsHistoryLoadFailed'
);

export const checklistDefinitionsAnalyticsReducer = createReducer(defaultState, {
  [loadChecklistDefinitionAnalytics]: state => ({ ...state, loading: true }),
  [checklistDefinitionAnalyticsLoaded]: (state, { payload }) => ({
    ...state,
    loading: false,
    idToAnalytics: { ...state.idToAnalytics, [payload.checklistDefinitionId]: payload }
  }),
  [checklistDefinitionAnalyticsLoadFailed]: state => ({ ...state, loading: false }),
  [checklistDefinitionAnalyticsHistoryLoaded]: (state, { payload }) => ({
    ...state,
    idToHistoryAnalytics: {
      ...state.idToHistoryAnalytics,
      [payload.checklistDefinitionId]: payload
    }
  })
});

export default checklistDefinitionsAnalyticsReducer;
