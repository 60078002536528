import React from 'react';

const Low = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6587 8.98803L8.33337 2.68803C8.14604 2.52603 7.8547 2.52603 7.6667 2.68803L0.341369 8.98803C0.242702 9.0727 0.186035 9.19203 0.186035 9.3167V12.3167C0.186035 12.4954 0.301368 12.658 0.479368 12.7294C0.658035 12.8007 0.864702 12.7687 1.0067 12.6467L8.00004 6.2847L14.9934 12.6467C15.0854 12.7254 15.2047 12.7667 15.3254 12.7667C15.3914 12.7667 15.458 12.7547 15.5207 12.7294C15.6987 12.658 15.814 12.4954 15.814 12.3167V9.3167C15.814 9.19203 15.7574 9.0727 15.6587 8.98803V8.98803Z"
        fill="#597EF7"
      />
      <path
        d="M15.5 13.3334C15.376 13.3334 15.254 13.2874 15.16 13.2001L8 6.55209L0.84 13.2001C0.694 13.3354 0.482667 13.3714 0.3 13.2921C0.118 13.2121 0 13.0321 0 12.8334V9.50009C0 9.36142 0.0573333 9.22942 0.158667 9.13476L7.65867 2.13476C7.85067 1.95476 8.14867 1.95476 8.34067 2.13476L15.8407 9.13476C15.9427 9.22942 16 9.36142 16 9.50009V12.8334C16 13.0321 15.882 13.2121 15.7 13.2921C15.636 13.3194 15.568 13.3334 15.5 13.3334V13.3334ZM8 5.37009C8.122 5.37009 8.244 5.41409 8.34 5.50342L15 11.6868V9.71742L8 3.18409L1 9.71742V11.6868L7.66 5.50342C7.756 5.41409 7.878 5.37009 8 5.37009V5.37009Z"
        fill="white"
      />
    </svg>
  );
};

export default Low;
