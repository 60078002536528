import React from 'react';

const Medium = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0)">
        <path
          d="M15.6587 6.45847L8.33337 0.636093C8.14603 0.486374 7.8547 0.486374 7.6667 0.636093L0.341368 6.45847C0.242702 6.53672 0.186035 6.64701 0.186035 6.76222V9.53479C0.186035 9.69991 0.301368 9.85024 0.479368 9.91617C0.658035 9.98209 0.864702 9.95252 1.0067 9.83977L8.00003 3.96009L14.9934 9.83977C15.0854 9.91247 15.2047 9.95067 15.3254 9.95067C15.3914 9.95067 15.458 9.93958 15.5207 9.91617C15.6987 9.85024 15.814 9.69991 15.814 9.53479V6.76222C15.814 6.64701 15.7574 6.53672 15.6587 6.45847V6.45847Z"
          fill="#597EF7"
        />
        <path
          d="M15.5 10.4744C15.376 10.4744 15.254 10.4319 15.16 10.3512L8 4.20721L0.84 10.3512C0.694 10.4763 0.482667 10.5096 0.3 10.4362C0.118 10.3623 0 10.1959 0 10.0123V6.93172C0 6.80356 0.0573333 6.68157 0.158667 6.59408L7.65867 0.124765C7.85067 -0.0415884 8.14867 -0.0415884 8.34067 0.124765L15.8407 6.59408C15.9427 6.68157 16 6.80356 16 6.93172V10.0123C16 10.1959 15.882 10.3623 15.7 10.4362C15.636 10.4615 15.568 10.4744 15.5 10.4744V10.4744ZM8 3.11482C8.122 3.11482 8.244 3.15549 8.34 3.23805L15 8.95261V7.13257L8 1.09455L1 7.13257V8.95261L7.66 3.23805C7.756 3.15549 7.878 3.11482 8 3.11482V3.11482Z"
          fill="white"
        />
        <path
          d="M15.6587 11.7152L8.33337 5.89281C8.14603 5.74309 7.8547 5.74309 7.6667 5.89281L0.341368 11.7152C0.242702 11.7934 0.186035 11.9037 0.186035 12.0189V14.7915C0.186035 14.9566 0.301368 15.107 0.479368 15.1729C0.658035 15.2388 0.864702 15.2092 1.0067 15.0965L8.00003 9.2168L14.9934 15.0965C15.0854 15.1692 15.2047 15.2074 15.3254 15.2074C15.3914 15.2074 15.458 15.1963 15.5207 15.1729C15.6987 15.107 15.814 14.9566 15.814 14.7915V12.0189C15.814 11.9037 15.7574 11.7934 15.6587 11.7152V11.7152Z"
          fill="#597EF7"
        />
        <path
          d="M15.5 15.731C15.376 15.731 15.254 15.6885 15.16 15.6078L8 9.4638L0.84 15.6078C0.694 15.7329 0.482667 15.7661 0.3 15.6928C0.118 15.6189 0 15.4525 0 15.2689V12.1883C0 12.0602 0.0573333 11.9382 0.158667 11.8507L7.65867 5.38136C7.85067 5.215 8.14867 5.215 8.34067 5.38136L15.8407 11.8507C15.9427 11.9382 16 12.0602 16 12.1883V15.2689C16 15.4525 15.882 15.6189 15.7 15.6928C15.636 15.7181 15.568 15.731 15.5 15.731V15.731ZM8 8.37141C8.122 8.37141 8.244 8.41208 8.34 8.49464L15 14.2092V12.3892L8 6.35114L1 12.3892V14.2092L7.66 8.49464C7.756 8.41208 7.878 8.37141 8 8.37141V8.37141Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Medium;
