import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { operations } from 'redux/lists/appellationsListReducer';
import { Helmet } from 'react-helmet';
import { usersResource } from 'redux/resources/users';
import AppealsList from 'pages/Appeals/Components/AppealsList';
import { useParams } from 'react-router-dom';
import Filters from './Filters';

const UserAppeals = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { id: viewedUserId } = useParams();

  const { page, sort, filters } = useSelector(state => state.appellationsList);

  useEffect(() => {
    dispatch(
      usersResource.operations.load({
        include: 'unit',
        pagination: 'false'
      })
    );
    if (viewedUserId)
      dispatch(
        operations.load({
          page,
          filters: { ...filters, withUserIdAsMember: viewedUserId },
          sort,
          include: 'review.reviewer,last_action,actions,objections.question,appellant,assigned_to'
        })
      );
  }, [page, sort, filters]);

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.userAnalyticsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SRow gutter={[0, 16]}>
          <SCol span={24}>
            <Filters />
          </SCol>
          <SCol span={24} padding="0px 16px 16px 16px">
            <AppealsList userPageView />
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default UserAppeals;
