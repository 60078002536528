import { createSelector } from '@reduxjs/toolkit';
import { pickBy } from 'lodash';

export const getAppellationsByIds = state => state.appellationsResource.byIds;
export const getAppellationActionsByIds = state => state.appellationActionsResource.byIds;

export const getAppealActionsByIds = createSelector(
  [getAppellationsByIds, getAppellationActionsByIds, (_, appealId) => appealId],
  (appealsByIds, actionsByIds, appealId) => {
    const appeal = appealsByIds[appealId];

    if (!appeal) return {};

    return pickBy(actionsByIds, { appellationId: appealId });
  }
);

export const getAppellationsSettingRules = createSelector(
  state => state.assignerByAppellantRulesResource.byIds,
  (_, settings) => settings,
  (rulesByIds, settings) => {
    return pickBy(rulesByIds, rule => settings.assignerByAppellantRulesIds.includes(rule.id));
  }
);
