export const ACTION_TARGET_TYPES = {
  COLLECTION: 'collection',
  MEMBER: 'member'
};

export const API_METHODS = {
  GET: 'get',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete'
};

export const ACTIONS = {
  load: {
    on: ACTION_TARGET_TYPES.COLLECTION,
    apiMethod: API_METHODS.GET
  },
  create: {
    on: ACTION_TARGET_TYPES.MEMBER,
    apiMethod: API_METHODS.CREATE
  },
  loadById: {
    on: ACTION_TARGET_TYPES.MEMBER,
    apiMethod: API_METHODS.GET
  },
  updateById: {
    on: ACTION_TARGET_TYPES.MEMBER,
    apiMethod: API_METHODS.UPDATE
  },
  deleteById: {
    on: ACTION_TARGET_TYPES.MEMBER,
    apiMethod: API_METHODS.DELETE
  }
};
