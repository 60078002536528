import React from 'react';
import { withRouter } from 'react-router-dom';
import { Button, Result } from 'antd';
import { useTranslation } from 'react-i18next';
import SCard from 'components/Standard/SCard';

const Forbidden = ({ history }) => {
  const { t } = useTranslation();
  return (
    <SCard
      data-testid="forbidden"
      height="90vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
      bordered={false}
    >
      <Result
        status="403"
        title="403"
        subTitle={t('errorPages.forbidden.title')}
        extra={
          <Button type="primary" onClick={() => history.goBack()}>
            {t('errorPages.buttons.goBack')}
          </Button>
        }
      />
    </SCard>
  );
};

export default withRouter(Forbidden);
