import CommentsPreview from 'components/Comments/CommentsPreview';
import { Row, Col } from 'antd';
import { SCALE_TYPES, ZONE_TO_COLOR_RELATIONS } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { findKey, isEmpty, orderBy } from 'lodash';
import React from 'react';
import styled from 'styled-components';

const Value = styled.div`
  border-radius: 4px;
  color: white;
  font-weight: 500;
  background-color: ${props => props.colorZone};
  width: ${props => (props.isFixedColumns ? '160px' : undefined)};
  margin-left: ${props => (props.isFixedColumns ? undefined : '25px')};
`;

const AnswerValueCell = ({ value, question = {}, comments = [], isFixedColumns }) => {
  const { colorZones, ratingValues, scaleType } = question;

  if (!ratingValues) return null;

  let valueColorZone = ZONE_TO_COLOR_RELATIONS.empty;

  if (scaleType === SCALE_TYPES.max_2 || ratingValues.length === 2) {
    const orderedValues = orderBy(ratingValues);
    valueColorZone = !orderedValues.includes(value)
      ? ZONE_TO_COLOR_RELATIONS.empty
      : ZONE_TO_COLOR_RELATIONS[orderedValues[0] === value ? 'low' : 'high'];
  } else {
    // ! could be better algo
    // * get zones values
    const low = ratingValues.filter(value => value < colorZones.low.stop);
    const medium = ratingValues.filter(
      value => value < colorZones.medium?.stop && value >= colorZones.low.stop
    );
    const high = ratingValues.filter(value => value >= colorZones.medium?.stop);
    // * find zone that contains given value
    valueColorZone =
      ZONE_TO_COLOR_RELATIONS[
        findKey({ low, medium, high }, values => values.includes(value)) || 'empty'
      ];
  }

  const showComments = !isEmpty(comments);

  return (
    <Row type="flex" align="middle" justify="start">
      <Col span={isFixedColumns ? undefined : 22}>
        <Value
          showComments={showComments}
          colorZone={valueColorZone}
          isFixedColumns={isFixedColumns}
        >
          {beatifyFloat(value)}
        </Value>
      </Col>
      {showComments && (
        <Col>
          <CommentsPreview comments={comments} singleIcon />
        </Col>
      )}
    </Row>
  );
};

export default AnswerValueCell;
