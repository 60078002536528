import { Tooltip } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Icon from 'components/Icon';
import { Info } from 'react-feather';
import SText from 'components/Standard/SText';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import ShareSettings from './ShareSettings';
import Tables from './Tables';

const TableConfigurations = ({ selectedWorkPlanTaskConfigurationId, hasConflicts }) => {
  const { t } = useTranslation();

  const { isVisibleShareSettings } = useSelector(state => state.uiClientInteractions);

  return (
    <SRow gutter={[0, 8]} style={{ width: '100%' }}>
      <SCol span={24}>
        <SText fontWeight={600}>
          {t('clientInteractionsPage.tableFilters.tableConfigurations.title')}
        </SText>
        {selectedWorkPlanTaskConfigurationId && (
          <Tooltip title='Работа с фильтрами заблокирована, так была установлена задача. Для продолжения работы с фильтрами списка коммуникаций, удалите выбранную задачу, и нажмите "Применить фильтры"'>
            <SText>
              <Icon icon={Info} color="var(--primary)" style={{ marginLeft: '2px' }} />
            </SText>
          </Tooltip>
        )}
      </SCol>

      <SCol span={24}>
        <SRow>
          <SCol span={24}>
            <Tables
              hasConflicts={hasConflicts}
              selectedWorkPlanTaskConfigurationId={selectedWorkPlanTaskConfigurationId}
            />
          </SCol>
          {isVisibleShareSettings && (
            <SCol span={24}>
              <ShareSettings />
            </SCol>
          )}
        </SRow>
      </SCol>
    </SRow>
  );
};

export default TableConfigurations;
