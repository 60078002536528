import React, { useEffect, useRef, useState } from 'react';
import ApexCharts from 'apexcharts';
import i18next from 'i18next';
import { isEqual } from 'lodash';
import {
  prepareChartData,
  getSeriesData
} from 'pages/DashboardPages/AutofailScoresPage/Components/Widgets/AutoFailCountsBy/utils';

const INIT_CHART_OPTIONS = {
  series: [],
  chart: {
    height: 350,
    type: 'line'
  },
  stroke: {
    width: [0, 4]
  },
  dataLabels: {
    enabled: true,
    offsetY: -8
  },
  xaxis: {
    type: 'datetime'
  },
  colors: ['#5780E7', '#52C41A', '#F5222D'],
  markers: {
    size: [0, 3, 3]
  },
  plotOptions: {
    bar: {
      borderRadius: 0,
      columnWidth: '50%'
    }
  },
  yaxis: [
    {
      seriesName: 'ChecklistDefinitionAverageScoreHistory',
      title: {
        text: i18next.t(
          'dashboardPages.autoFailScores.columns.checklistDefinitionAverageScoreHistory'
        )
      },
      labels: {
        show: true,
        formatter: value => `${Math.ceil(value)}%`
      },
      min: 0,
      max: 100,
      tickAmount: 5
    },
    {
      seriesName: 'PercentOfClientInteractions',
      opposite: true,
      title: {
        text: i18next.t('dashboardPages.autoFailScores.columns.percentOfClientInteractions')
      },
      labels: {
        show: true,
        formatter: value => `${value}%`
      },
      min: 0,
      max: 100,
      tickAmount: 5
    },
    {
      seriesName: 'PercentOfReviewsWithAnyTypeAutofailCount',
      opposite: true,
      title: {
        text: i18next.t(
          'dashboardPages.autoFailScores.columns.percentOfReviewsWithAnyTypeAutofailCount'
        )
      },
      labels: {
        show: true,
        formatter: value => `${value}%`
      },
      min: 0,
      max: 100,
      tickAmount: 5
    }
  ]
};

const NO_DATA_FOR_CHART = {
  text: `${i18next.t('general.loading')}...`,
  align: 'center',
  verticalAlign: 'middle',
  offsetX: 0,
  offsetY: 0,
  style: {
    color: undefined,
    fontSize: '14px',
    fontFamily: undefined
  }
};

const ChartLineColumnAnalytics = ({ dataSource, datesArray, loading }) => {
  const autoFailScoresChartRef = useRef();
  const apexChartsRef = useRef();
  const [prevDataSource, setPrevDataSource] = useState();
  const [prevDatesArray, setPrevDatesArray] = useState();
  const { chartData, categories } = prepareChartData(dataSource, datesArray);

  useEffect(() => {
    if (autoFailScoresChartRef?.current) {
      if (!apexChartsRef?.current) {
        apexChartsRef.current = new ApexCharts(autoFailScoresChartRef.current, {
          ...INIT_CHART_OPTIONS,
          ...{
            xaxis: {
              categories
            }
          }
        });
        apexChartsRef.current.render().then(r => r);
      }

      if (loading) {
        apexChartsRef.current.updateOptions({ noData: NO_DATA_FOR_CHART });
        apexChartsRef.current.resetSeries();
      }

      if (!isEqual(prevDataSource, dataSource) || !isEqual(prevDatesArray, datesArray)) {
        setPrevDataSource(dataSource);
        setPrevDatesArray(datesArray);

        const series = getSeriesData({ dataSource: chartData });

        apexChartsRef.current.updateSeries(series, true);
        apexChartsRef.current.resetSeries();
      }
    }
  }, [autoFailScoresChartRef, loading, dataSource, datesArray]);

  return <div ref={autoFailScoresChartRef} />;
};

export default ChartLineColumnAnalytics;
