import { get } from 'redux-bees';

const entitiesEndpoints = apiPath => ({
  getEntity: { method: get, path: `${apiPath}/entities/:id` },
  getClientInteractionsForEntity: {
    method: get,
    path: `${apiPath}/entities/:id/client_interactions`
  }
});

export default entitiesEndpoints;
