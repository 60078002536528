import { Row, Tooltip, Typography } from 'antd';
import ClientInteractionLink from 'components/ClientInteractionLink';
import CommentsPreview from 'components/Comments/CommentsPreview';
import SCard from 'components/Standard/SCard';
import STable from 'components/Standard/STable';
import { TagsDisplay } from 'components/Tags/TagsDisplay';
import UserPreview from 'components/UserPreview';
import { withRouter, useLocation, useHistory } from 'react-router-dom';
import { beatifyFloat } from 'core/utils/convertFloat';
import { get, isEmpty, pick } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { actions, operations } from 'redux/lists/userReviewsListReducer';
import { omitAllQuestionsFilterAndFlagsFilter } from 'redux/ui/userAnalyticsPage/operations';
import SCol from 'components/Standard/SCol';
import { stringify } from 'core/utils/queryString';
import { setFiltersByUserId } from 'redux/ui/userAnalyticsPage/reducer';
import { SText } from 'components/Standard';
import MainControlsPanel from '../MainControlsPanel';
import ColorZonesFilter from './ColorZonesFilter';
import EmptyForUserPage from '../EmptyForUserPage';

const { Text } = Typography;

const UserReviewsTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const { ids, totalCount, page, sorting } = useSelector(state => state.userReviewsList);

  const loading = useSelector(
    state => !state.uiUserAnalyticsPage.currentUser || state.userReviewsList.loading
  );
  const reviews = useSelector(state => ids.map(id => state.reviewsResource.byIds[id] || {}));

  const userId = useSelector(state => get(state.uiUserAnalyticsPage, 'currentUser.id', undefined));

  const filters = useSelector(state => get(state.uiUserAnalyticsPage.filters, userId, {}));

  const tableRows = useSelector(state =>
    reviews.reduce((acc, review) => {
      const clientInteraction =
        state.clientInteractionsResource.byIds[review.clientInteractionId] || {};

      const operator = pick(state.usersResource.byIds, clientInteraction?.operatorId);
      const comments = Object.values(pick(state.commentsResource.byIds, review.commentsIds));
      const checklist = get(state.checklistsResource.byIds, review.checklistId, {});
      const checklistDefinition = get(
        state.checklistDefinitionsResource.byIds,
        checklist.checklistDefinitionId
      );
      const reviewer = state.usersResource.byIds[review?.reviewerId];
      const tasks = get(review, 'tasksIds', []).reduce((acc, id) => {
        const task = state.tasksResource.byIds[id];
        const taskDefinition = state.taskDefinitionsResource.byIds[task?.taskDefinitionId];

        if (!task || !taskDefinition) {
          return acc;
        }

        return [...acc, { ...task, taskDefinition }];
      }, []);

      if (isEmpty(clientInteraction)) return acc;

      return [
        ...acc,
        {
          ...review,
          clientInteraction,
          operator,
          comments,
          reviewer,
          checklist,
          checklistDefinition,
          tasks
        }
      ];
    }, [])
  );

  useEffect(() => {
    if (!isEmpty(userId)) dispatch(actions.updateFilters({ operatorsIds: userId }));
  }, [userId]);

  useEffect(() => {
    if (filters.checklistDefinitionsIds)
      dispatch(
        operations.load({
          page,
          filters: omitAllQuestionsFilterAndFlagsFilter(filters),
          sort: sorting,
          include:
            'client_interaction,reviewer.unit,comments.author,checklist.answers,checklist.checklist-definition,tasks.task-definition'
        })
      );
  }, [page, sorting, filters]);

  const { t } = useTranslation();

  const columns = [
    {
      key: 'clientInteractionLink',
      dataIndex: 'id',
      width: 30,
      render: (id, { clientInteraction }) => (
        <ClientInteractionLink
          id={id}
          isReview
          communication={clientInteraction}
          style={{ display: 'flex' }}
        />
      )
    },

    {
      key: 'clientInteractionStartedAt',
      title: t('general.dateAndTime'),
      dataIndex: 'clientInteraction',
      width: 150,

      render: call => (
        <SText ellipsis>
          {call ? moment(call.startedAt || call.createdAt).format('DD/MM/YYYY, HH:mm') : ''}
        </SText>
      )
    },

    {
      key: 'clientPhoneNumber',
      title: t('general.phoneNumber'),
      dataIndex: ['clientInteraction', 'clientPhoneNumber'],
      width: 150
    },

    {
      key: 'checklistScore',
      title: t('general.score'),
      dataIndex: ['checklist', 'score'],
      width: 100,
      render: checklist => (
        <span>
          {checklist % 1 === 0 || checklist === undefined ? checklist : beatifyFloat(checklist)}
        </span>
      )
    },

    {
      key: 'comments',
      title: t('general.flagsAndComments'),
      dataIndex: 'comments',
      width: 210,
      render: (comments, checklist) => (
        <CommentsPreview comments={comments} checklistData={checklist} />
      )
    },

    {
      key: 'checklistDefinition',
      title: t('general.checklistDefinition'),
      dataIndex: ['checklistDefinition', 'name'],
      width: 200,
      render: text => (
        <Tooltip title={text}>
          <SText ellipsis display="block">
            {text}
          </SText>
        </Tooltip>
      )
    },

    {
      key: 'tasks',
      title: t('general.tags'),
      dataIndex: 'tasks',
      width: 200,
      render: (tasks = []) => <TagsDisplay tasks={tasks} truncate={16} />
    },
    {
      key: 'createdAt',
      title: t('general.reviewDate'),
      dataIndex: 'createdAt',
      width: 200,

      render: text => <SText ellipsis>{text ? moment(text).format('DD/MM/YYYY, HH:mm') : ''}</SText>
    },
    {
      key: 'reviewerLastName',
      title: t('userAnalyticsPage.userReviewsTable.columns.reviewerLastName'),
      dataIndex: 'reviewer',
      width: 250,
      render: reviewer => (
        <UserPreview
          user={reviewer}
          disabled
          showAvatar
          showUnit
          margin="0px"
          truncateSize={25}
          hidden
        />
      )
    }
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    dispatch(
      actions.updatePage({
        size: pagination.pageSize.toString(),
        number: pagination.current.toString()
      })
    );

    dispatch(actions.setSorting(sorter));
  };

  const setFiltersWithQuery = ({ userId, filters }) => {
    dispatch(
      setFiltersByUserId({
        userId,
        operatorsIds: userId,
        ...filters
      })
    );
    history.replace({
      pathname: location.pathname,
      search: stringify({
        filters
      })
    });
  };

  return (
    <Row>
      <Helmet>
        <title>{t('pagesMeta.userAnalyticsPage.title')}</title>
      </Helmet>

      <SCol span={24}>
        <MainControlsPanel
          additionalFilterComponent={
            <ColorZonesFilter
              loading={loading}
              filters={filters}
              onChangeFilters={setFiltersWithQuery}
            />
          }
          additionalInfoComponent={
            <Text type="secondary">{`${t('general.found')}: ${totalCount || '0'}`}</Text>
          }
        />
      </SCol>

      <SCol span={24} padding="16px">
        {isEmpty(tableRows) && !loading && <EmptyForUserPage />}
        {!isEmpty(tableRows) && (
          <SCard bordered shadowed bodyPadding="24px">
            <STable
              size="small"
              rowKey="id"
              columns={columns}
              dataSource={tableRows}
              tableLayout="fixed"
              loading={loading}
              border
              pagination={{
                pageSize: parseInt(page.size),
                current: parseInt(page.number),
                total: totalCount
              }}
              scroll={{ y: 'calc(100vh - 266px)', x: 'max-content' }}
              onChange={handleTableChange}
            />
          </SCard>
        )}
      </SCol>
    </Row>
  );
};

export default withRouter(UserReviewsTable);
