import api from 'core/api';
import { createReduxResource } from 'core/redux/resources';
import { dashboardsResource } from 'redux/resources/dashboards';

export const dashboardRowsResource = createReduxResource({
  name: 'dashboardRows',
  // reactors: {
  //   onDeleteByIdSucceed: ({ id, dispatch, getState }) => {
  //     const state = getState();
  //     const row = state.dashboardRowsResource.byIds[id];
  //     const widgetsToDelete = row.widgetsIds;
  //     widgetsToDelete.forEach(id =>
  //       dispatch({ type: 'dashboardWidgets/deleteByIdSucceed', payload: { id } })
  //     );
  //   }
  // },
  customOperations: {
    moveUpRowById: {
      callEndpoint: async requestOptions => {
        const { id } = requestOptions;
        return api.moveUp({ id }, {});
      },
      onSuccess: async ({ dispatch, getState }) => {
        const state = getState();
        const id = state.uiDashboard.activeDashboardId;
        await dispatch(dashboardsResource.operations.loadById({ id }));
      }
    },
    moveDownRowById: {
      callEndpoint: async requestOptions => {
        const { id } = requestOptions;
        return api.moveDown({ id }, {});
      },
      onSuccess: async ({ dispatch, getState }) => {
        const state = getState();
        const id = state.uiDashboard.activeDashboardId;
        await dispatch(dashboardsResource.operations.loadById({ id }));
      }
    },
    createNewRow: {
      callEndpoint: async requestOptions => {
        return api.createDashboardRow({ ...requestOptions }, {});
      },
      onSuccess: async ({ dispatch, getState }) => {
        const state = getState();
        const id = state.uiDashboard.activeDashboardId;
        await dispatch(dashboardsResource.operations.loadById({ id }));
      }
    }
  }
});
