import { MinusCircle } from 'react-feather';
import { Col, Input, Row } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';

export const MediaUrlInput = ({ remove, id, allowRemove, value, onChange }) => {
  const { t } = useTranslation();
  return (
    <Col span={24}>
      <Row gutter={[8, 8]} style={{ margin: '-8px' }} align="middle">
        <Col flex="auto">
          <Input
            value={value}
            onChange={onChange}
            placeholder={t('customCommunicationPage.form.fields.mediaUrl')}
          />
        </Col>

        {allowRemove ? (
          <Col span={2}>
            <Icon icon={MinusCircle} className="dynamic-delete-button" onClick={() => remove(id)} />
          </Col>
        ) : null}
      </Row>
    </Col>
  );
};
