export default {
  recoverPasswordPage: {
    title: 'Recover password',
    description: 'Enter the email address which you use for qolio account.',
    buttons: { login: 'Login' },
    form: {
      fields: { emailPlaceholder: 'Enter email' },
      messages: {
        enterEmail: 'Enter email',
        wrongEmail: 'Wrong email format'
      },
      buttons: { continue: 'Continue' }
    },
    success: {
      title: 'Thanks!',
      description:
        'We have sent instructions on how to recover your password to your specified email address. If the email with the instructions will not arrive within 10 minutes, please check if the email address you entered is correct.',
      buttons: {
        login: 'Login',
        register: 'Sign in'
      }
    },
    setNewPassword: {
      title: {
        newUser: '👋 Welcome!',
        setPass: '🔐 Choose secure password'
      },
      form: {
        newPassMessage: 'Choose secure password for',
        fields: { passwordPlaceholder: 'Enter password' },
        messages: {
          enterPass: 'Enter password',
          minLengthPassword: 'Short password'
        },
        buttons: { continue: 'Continue' }
      }
    }
  }
};
