import { round, sum } from 'lodash';
import { valueToColorZoneColor } from 'pages/DashboardPage/utils';
import i18next from 'i18next';
import { getValues } from 'pages/DashboardPages/AutofailScoresPage/Components/Widgets/AutoFailCountsBy/utils';

export const calcPercent = (a, b, fn) => {
  if (!!a && !!b && parseFloat(b) > 0) {
    const value = Math.ceil((a / b) * 1000) / 10;
    return fn ? fn(value) : value;
  }

  return null;
};

export const prepareFooterData = ({ data, checklistDefinition }) => {
  const totalReviewsCountHistory = sum([...getValues(data, 'reviewsCount')]);
  const totalClientInteractionsCountHistory = sum([...getValues(data, 'clientInteractionsCount')]);
  const totalAnyTypeAutofailsCountHistory = sum([...getValues(data, 'anyTypeAutofailsCount')]);
  const totalReviewsWithAnyTypeAutofailCount = sum([
    ...getValues(data, 'reviewsWithAnyTypeAutofailCount')
  ]);

  const percentOfClientInteractions = calcPercent(
    totalReviewsCountHistory,
    totalClientInteractionsCountHistory,
    a => round(a, 1)
  );

  const percentOfReviewsWithAnyTypeAutofailCount = calcPercent(
    totalReviewsWithAnyTypeAutofailCount,
    totalReviewsCountHistory,
    a => round(a, 1)
  );

  const colorPercentOfReviewsWithAnyTypeAutofailCount = valueToColorZoneColor({
    value: percentOfReviewsWithAnyTypeAutofailCount,
    colorZones: checklistDefinition.colorZones
  });

  const checklistDefinitionAverageScore = round(data.checklistDefinitionAverageScore, 1);
  const colorChecklistDefinitionAverageScore = valueToColorZoneColor({
    value: checklistDefinitionAverageScore,
    colorZones: checklistDefinition.colorZones
  });

  const colorPercentOfClientInteractions = valueToColorZoneColor({
    value: percentOfClientInteractions,
    colorZones: checklistDefinition.colorZones
  });

  return {
    id: 'footer',
    date: i18next.t('dashboardPages.autoFailScores.footer.total'),
    reviewsCountHistory: {
      value: totalReviewsCountHistory,
      color: null
    },
    clientInteractionsCountHistory: {
      value: totalClientInteractionsCountHistory,
      color: null
    },
    anyTypeAutofailsCountHistory: {
      value: totalAnyTypeAutofailsCountHistory,
      color: null
    },
    reviewsWithAnyTypeAutofailCount: {
      value: totalReviewsWithAnyTypeAutofailCount,
      color: null
    },
    percentOfClientInteractions: {
      value: percentOfClientInteractions,
      color:
        colorPercentOfClientInteractions === 'white'
          ? 'var(--gray_4)'
          : colorPercentOfClientInteractions
    },
    percentOfReviewsWithAnyTypeAutofailCount: {
      value: percentOfReviewsWithAnyTypeAutofailCount,
      color:
        colorPercentOfReviewsWithAnyTypeAutofailCount === 'white'
          ? 'var(--gray_4)'
          : colorPercentOfReviewsWithAnyTypeAutofailCount
    },
    checklistDefinitionAverageScoreHistory: {
      value: checklistDefinitionAverageScore,
      color:
        colorChecklistDefinitionAverageScore === 'white'
          ? 'var(--gray_4)'
          : colorChecklistDefinitionAverageScore
    }
  };
};

export const PERCENT_FIELDS = [
  'checklistDefinitionAverageScoreHistory',
  'percentOfClientInteractions',
  'percentOfReviewsWithAnyTypeAutofailCount',
  'checklistDefinitionAverageScore'
];

export const formatTableCellValue = (value, field) => {
  if (PERCENT_FIELDS.includes(field) && !!value) return `${value}%`;

  return value;
};
