import createListReducer from 'core/redux/lists/listReducer';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';

export const listParams = {
  name: 'bitrixClientInteractions',
  rebuildQuery: true,
  loader: clientInteractionsResource.operations.load,
  keepResults: true
};

const { actions, reducer, operations } = createListReducer(listParams);

const bitrixClientInteractionsListReducer = reducer;

export { actions, bitrixClientInteractionsListReducer, operations };
