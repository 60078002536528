import React, { useState } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import SText from 'components/Standard/SText';
import { useDispatch, useSelector } from 'react-redux';
import RedesignWidgetTable from 'components/Dashboard/RedesignWidgetTable';
import { IconButton } from 'components/Buttons';
import { get, isEqual } from 'lodash';
import { getDatesArray, hasConflictFilters, widgetPeriodSetter } from 'pages/DashboardPage/utils';
import { useTranslation } from 'react-i18next';
import { Empty, Tooltip } from 'antd';
import { TimePeriod } from 'components/TimePeriod';
import { WidgetTitle } from 'components/WidgetTitle';
import { LoadingOutlined, SettingOutlined } from '@ant-design/icons';
import ExcelIcon from 'images/ExcelIcon';
import Icon from 'components/Icon';
import { onTableChange, startExport } from './utils';
import { prepareFooterRow, prepareTableColumns, prepareTableData } from './prepareTableData';
import { StyledEmpty, StyledErrorMessage } from '../../../../DashboardPage/styled';
import WarningIcon from '../../../../../assets/images/warning-icon.svg';
import { getUnitsByIds } from '../../../../../redux/selectors/units';

const UnitsAnalytics = ({ widgetData = {}, setVisibleWidgetId }) => {
  const { t } = useTranslation();
  const [loadingExport, setLoadingExport] = useState();
  const dispatch = useDispatch();

  const unitDashboard = useSelector(
    state => state.dashboardPages.dashboardsByTypes?.scores_by_units,
    isEqual
  );

  const { id, name, page, type, filters } = widgetData;

  const loading = useSelector(state => state.dashboardPages.loading);
  const loadingWidgetId = useSelector(state => state.dashboardPages.loadingWidgetId);
  const loadingTable = loadingWidgetId === id || loadingWidgetId === 'filters' || loadingExport;

  const [initialState, setInitialState] = useState(widgetData?.page);
  const widgetAnalytics = useSelector(
    state => get(state.dashboardAnalytics.byIds, id, {}),
    isEqual
  );
  const dashboardPageFilters = useSelector(
    state => state.dashboardPages.dashboardsByTypes.scores_by_units?.filters,
    isEqual
  );
  const { meta, metadata, data, total } = widgetAnalytics;
  const checklistDefinitionsByIds = useSelector(state => state.checklistDefinitionsResource.byIds);
  const checklistDefinition = get(
    checklistDefinitionsByIds,
    dashboardPageFilters?.checklistDefinitionsIds,
    {}
  );
  const units = useSelector(state => getUnitsByIds(state));
  const arrayOfUnits = Object.keys(units) || [];

  const datesArray = getDatesArray({
    timeFrom: metadata?.time_from,
    timeTo: metadata?.time_to,
    isWeekendView: true,
    historyGroupingPeriod: dashboardPageFilters?.historyGroupingPeriod,
    clientInteractionSymbolicTimeRange: dashboardPageFilters?.clientInteractionSymbolicTimeRange
  });

  const hasConflicts = hasConflictFilters({
    currentFilters: get(filters, 'unitsIds', []),
    availableReduxFilters: arrayOfUnits
  });

  const tableColumns = prepareTableColumns({ datesArray, t });
  const tableData = [
    ...prepareTableData({
      datesArray,
      unitsByIds: data,
      checklistDefinition
    }),
    prepareFooterRow({ t, datesArray, total, checklistDefinition })
  ];

  const renderDescription = () => {
    if (hasConflicts) {
      return (
        <>
          <SText color="#333333" fontSize="21px" fontWeight="600" style={{ lineHeight: '25px' }}>
            {t('dashboardPages.conflicts.unitsConflict.title')}
          </SText>
          <SRow align="center" marginTop="10px">
            <SText maxWidth="396px" type="secondary" style={{ lineHeight: '20px' }}>
              {t('dashboardPages.conflicts.unitsConflict.description1')}
            </SText>
          </SRow>
          <SRow align="center" marginTop="10px">
            <SText maxWidth="396px" type="secondary" style={{ lineHeight: '20px' }}>
              {t('dashboardPages.conflicts.unitsConflict.description2')}
            </SText>
          </SRow>
        </>
      );
    }
    return (
      <>
        <StyledErrorMessage level={3} type="secondary">
          {t('dashboardPage.widget.checkFilters')}
        </StyledErrorMessage>
        <SText type="secondary">{t('dashboardPage.widget.emptyFiltersData')}</SText>
      </>
    );
  };

  const isEmptyWidget = widgetAnalytics?.data?.length === 0;
  const title = !name || hasConflicts ? t('dashboardPages.unitsAnalyticsWidgetTitle') : name;

  return (
    <SCol marginBottom={15} span={24}>
      <SCard bordered>
        <SRow type="flex" wrap={false} alignItems="center">
          <SCol flex="auto">
            <Tooltip title={title}>
              <WidgetTitle>{title}</WidgetTitle>
            </Tooltip>
          </SCol>
          <SCol display="flex" justifyContent="flex-end" flex="none" alignItems="center">
            {!hasConflicts && (
              <IconButton
                tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
                button={{
                  icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                  marginTop: '2px',
                  size: 'icon',
                  onClick: () => {
                    startExport({
                      t,
                      datesArray,
                      checklistDefinition,
                      id,
                      meta,
                      type,
                      total,
                      filters,
                      dispatch,
                      initialState,
                      setLoadingExport,
                      columns: tableColumns
                    });
                  }
                }}
              />
            )}
            <IconButton
              tooltip={{ title: t('dashboardPage.widget.modal.tooltip') }}
              button={{
                onClick: () => setVisibleWidgetId(id),
                size: 'icon',
                icon: <Icon icon={SettingOutlined} />
              }}
            />
          </SCol>
        </SRow>
        <SRow>
          <SCol>
            <TimePeriod type="secondary">
              {widgetPeriodSetter({
                widgetsFilters: get(unitDashboard, 'filters', {}),
                widgetType: 'units_analytics'
              })}
            </TimePeriod>
          </SCol>
        </SRow>

        <SRow>
          <SCol marginTop="10px" span={24}>
            {hasConflicts && (
              <SCol marginBottom="40px">
                <StyledEmpty
                  image={<img src={WarningIcon} alt="warning" width="70px" />}
                  description={renderDescription()}
                />
              </SCol>
            )}
            {!hasConflicts && isEmptyWidget && (
              <SCol marginBottom="40px">
                <StyledEmpty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={renderDescription()}
                />
              </SCol>
            )}
            {!isEmptyWidget && !hasConflicts && (
              <RedesignWidgetTable
                dataSource={tableData}
                columns={loading ? [] : tableColumns}
                loading={loadingTable}
                size="small"
                showSorterTooltip={false}
                scroll={{ x: 1500, y: 575 }}
                pagination={{
                  position: 'bottomLeft',
                  showSizeChanger: true,
                  total: parseInt(meta?.total_count) + 1,
                  current: parseInt(meta?.page),
                  pageSize: parseInt(page?.size) + 1,
                  pageSizeOptions: ['10', '25', '50']
                }}
                onChange={(pagination, _, sorter) =>
                  onTableChange({
                    pagination,
                    sorter,
                    widgetId: id,
                    filters,
                    dispatch,
                    setInitialState,
                    tableColumns
                  })
                }
              />
            )}
          </SCol>
        </SRow>
      </SCard>
    </SCol>
  );
};

export default UnitsAnalytics;
