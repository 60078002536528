import { destroy, get, put } from 'redux-bees';

const phoneCallChainsEndpoints = apiPath => ({
  getPhoneCallChains: { method: get, path: `${apiPath}/phone_call_chains` },
  getPhoneCallChain: { method: get, path: `${apiPath}/phone_call_chains/:id` },
  deletePhoneCallChain: { method: destroy, path: `${apiPath}/phone_call_chains/:id` },
  updatePhoneCallChain: { method: put, path: `${apiPath}/phone_call_chains/:id` }
});

export default phoneCallChainsEndpoints;
