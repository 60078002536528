import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';

export const assignmentsResource = createReduxResource({
  name: 'assignments',
  reactors: {
    onCreateSucceed: ({ resource, dispatch, getState }) => {
      const state = getState();
      const user = state.usersResource.byIds[resource.userId];
      if (user)
        updateResource({
          dispatch,
          type: 'users',
          resource: { ...user, assignmentsIds: [resource.id] }
        });
    }
  }
});
