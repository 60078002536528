export default {
  libraryPage: {
    buttons: {
      createFolder: 'Создать папку'
    },
    libraryItem: {
      emptyName: 'Имя не задано',
      added: 'Добавлено:',
      dropdown: {
        edit: 'Редактировать',
        delete: 'Удалить'
      },
      deleteFolderModal: {
        title: 'Вы уверены, что хотите удалить папку?',
        description: 'Это действие необратимо',
        buttons: {
          cancel: 'Отмена',
          delete: 'Удалить'
        }
      },
      deleteItemModal: {
        title: 'Звонок будет удален из библиотеки. Продолжить?',
        buttons: {
          cancel: 'Отмена',
          delete: 'Удалить'
        }
      },
      empty: {
        folders: 'Пока не добавлено ни одной папки',
        calls: 'В этой папке пока нет звонков'
      }
    },
    modal: {
      title: 'Введите название для новой папки',
      form: {
        name: 'Название'
      },
      buttons: {
        cancel: 'Отмена',
        create: 'Создать'
      }
    },
    messages: {
      folderSuccessfullyCreated: 'Папка успешно создана',
      folderCreateFailed: 'Не удалось создать папку'
    }
  }
};
