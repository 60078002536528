import styled from 'styled-components';
import { Input, InputNumber } from 'antd';
import {
  space,
  layout,
  flexbox,
  background,
  shadow,
  position,
  variant,
  border
} from 'styled-system';

export default styled(Input)`
  &.ant-input {
    ${variant({
      prop: 'size',
      variants: {
        large: {
          width: '304px',
          height: '40px',
          fontSize: '16px'
        },
        middle: {
          width: '180px',
          height: '32px'
        }
      }
    })}

    ${space}
    ${layout} 
    ${flexbox} 
    ${background} 
    ${shadow} 
    ${position}
    ${border}
  }
`;

export const SInputPassword = styled(Input.Password)`
  &.ant-input-password  {
    ${variant({
      prop: 'size',
      variants: {
        large: {
          width: '304px',
          height: '40px',
          fontSize: '16px'
        },
        middle: {
          width: '180px',
          height: '32px'
        }
      }
    })}

    ${space}
    ${layout} 
    ${flexbox} 
    ${background} 
    ${shadow} 
    ${position}
  }
`;

export const SInputNumber = styled(InputNumber)`
  &.ant-input-number  {
    ${variant({
      prop: 'size',
      variants: {
        large: {
          width: '304px',
          height: '40px',
          fontSize: '16px'
        },
        middle: {
          width: '180px',
          height: '32px'
        }
      }
    })}

    ${space}
    ${layout} 
    ${flexbox} 
    ${background} 
    ${shadow} 
    ${position}
  }
`;
