import { Col, Row } from 'antd';
import React from 'react';
import CombinedChart from './Components/CombinedChart';
import UsersTable from './Components/UsersTable';
import WidgetCardDivider from '../../../Components/WidgetCardDivider';

const ChartView = ({
  colorZones,
  dataSource,
  checklistDefinitionMax,
  checklistDefinition,
  barData,
  lineData,
  filters,
  widgetId
}) => {
  return (
    <Row>
      <Col span={13}>
        <CombinedChart
          checklistDefinitionMax={checklistDefinitionMax}
          checklistDefinition={checklistDefinition}
          barData={barData}
          lineData={lineData}
          filters={filters}
          widgetId={widgetId}
        />
      </Col>
      <Col span={1}>
        <WidgetCardDivider />
      </Col>
      <Col span={10}>
        <UsersTable
          dataSource={dataSource.table}
          checklistDefinition={checklistDefinition}
          colorZones={colorZones}
          checklistDefinitionMax={checklistDefinitionMax}
          filters={filters}
          widgetId={widgetId}
        />
      </Col>
    </Row>
  );
};

export default ChartView;
