import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  checklistIdToEdit: null,
  checklistIdToSettings: null,
  checklistIdToDelete: null,
  newChecklistMode: false,
  editingChecklist: {}
};

export const setChecklistIdToEdit = createAction('uiChecklistsPage/setChecklistIdToEdit');
export const setChecklistIdToSettings = createAction('uiChecklistsPage/setChecklistIdToSettings');
export const setChecklistIdToDelete = createAction('uiChecklistsPage/setChecklistIdToDelete');
export const setNewChecklistMode = createAction('uiChecklistsPage/setNewChecklistMode');
export const checklistDeleted = createAction('uiChecklistsPage/checklistDeleted');
export const setEditingChecklist = createAction('uiChecklistsPage/setEditingChecklist');
export const updateEditingChecklist = createAction('uiChecklistsPage/updateEditingChecklist');
export const checklistsPageReducer = createReducer(defaultState, {
  [setEditingChecklist]: (state, { payload }) => ({ ...state, editingChecklist: payload }),
  [updateEditingChecklist]: (state, { payload }) => ({
    ...state,
    editingChecklist: { ...(state.editingChecklist || {}), ...payload }
  }),
  [setChecklistIdToEdit]: (state, { payload }) => ({
    ...state,
    checklistIdToEdit: payload,
    newChecklistMode: false,
    checklistIdToDelete: null,
    checklistIdToSettings: null
  }),
  [setChecklistIdToSettings]: (state, { payload }) => ({
    ...state,
    checklistIdToEdit: null,
    checklistIdToDelete: null,
    newChecklistMode: false,
    checklistIdToSettings: payload
  }),
  [setChecklistIdToDelete]: (state, { payload }) => ({
    ...state,
    checklistIdToDelete: payload
  }),
  [setNewChecklistMode]: (state, { payload }) => ({
    ...state,
    checklistIdToEdit: null,
    checklistIdToDelete: null,
    newChecklistMode: payload,
    checklistIdToSettings: null
  }),
  [checklistDeleted]: state => ({
    ...state,
    checklistIdToEdit: null,
    checklistIdToDelete: null,
    newChecklistMode: false,
    checklistIdToSettings: null
  })
});
