import styled from 'styled-components';
import { Card } from 'antd';

export const StyledCard = styled(Card)`
  &.ant-card {
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}
  }
`;

export const SpinContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
`;

export const StyledDiv = styled.div`
  background-color: #b1c0cb;
  height: 100vh;
  padding-top: 16px;
  text-align: center;
`;
