import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';

export const levelsResource = createReduxResource({
  name: 'levels',
  reactors: {
    onCreateSucceed: ({ resource, dispatch, getState }) => {
      const state = getState();
      const role = state.rolesResource.byIds[resource.roleId];
      if (role) {
        updateResource({
          dispatch,
          type: 'roles',
          resource: { ...role, levelsIds: [...role.levelsIds, resource.id] }
        });
      }
    },
    onDeleteByIdSucceed: ({ id, dispatch, getState }) => {
      const state = getState();
      const level = state.levelsResource.byIds[id];

      const role = state.rolesResource.byIds[level.roleId];
      if (role) {
        updateResource({
          dispatch,
          type: 'reviews',
          resource: {
            ...role,
            levelsIds: [...role.levelsIds.filter(relationId => relationId !== id)]
          }
        });
      }
    }
  }
});
