import { createAction, createReducer } from '@reduxjs/toolkit';
import { omit } from 'lodash';

const defaultState = {
  form: {
    communicationType: {
      value: ''
    },
    metadata: {
      mediaUrls: {},
      additional: {}
    }
  }
};

export const updateCustomCommunicationForm = createAction(
  'uiCustomCommunicationReviewReducer/updateCustomCommunicationForm'
);
export const clearCustomCommunicationForm = createAction(
  'uiCustomCommunicationReviewReducer/clearCustomCommunicationForm'
);
export const deleteMediaUrl = createAction('uiCustomCommunicationReviewReducer/deleteMediaUrl');

export const customCommunicationReviewReducer = createReducer(defaultState, {
  [updateCustomCommunicationForm]: (state, { payload }) => ({
    ...state,
    form: {
      ...state.form,
      ...payload
    }
  }),
  [clearCustomCommunicationForm]: state => ({
    ...state,
    ...defaultState
  }),
  [deleteMediaUrl]: (state, { payload }) => {
    const newFormState = omit({ ...state.form }, payload.name);
    return {
      ...state,
      form: newFormState
    };
  }
});
