import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  form: { title: '', description: '', status: '', asigneeId: '', finishes: '' },
  showModal: false
};

export const updateFormValues = createAction('uiCreateChecklistModal/updateFormValues');
export const openModal = createAction('uiCreateChecklistModal/openModal');
export const closeModal = createAction('uiCreateChecklistModal/closeModal');

export const createTaskModal = createReducer(defaultState, {
  [updateFormValues]: (state, { payload }) => ({ ...state, form: { ...state.form, ...payload } }),
  [openModal]: state => ({ ...state, showModal: true }),
  [closeModal]: state => ({ ...state, showModal: false })
});

export default createTaskModal;
