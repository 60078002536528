import { get, put, post, destroy } from 'redux-bees';

const assignmentsEndpoints = apiPath => ({
  getAssignments: { method: get, path: `${apiPath}/organization/assignments` },
  updateAssignment: { method: put, path: `${apiPath}/organization/assignments/:id` },
  createAssignment: { method: post, path: `${apiPath}/organization/assignments` },
  deleteAssignment: { method: destroy, path: `${apiPath}/organization/assignments/:id` }
});

export default assignmentsEndpoints;
