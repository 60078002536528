export default {
  registerPage: {
    form: {
      title: 'Регистрация',
      description: 'Вы можете начать пользоваться Qolio с помощью интеграции с вашей CRM',
      or: 'или',
      buttons: {
        login: 'Войти',
        bitrix: 'Продолжить через Битрикс24',
        amo: 'Продолжить через AmoCRM',
        email: 'Получить доступ через e-mail',
        bitrixPopover: {
          title: 'Укажите адрес вашего портала',
          wrongUrl: 'Неверно указан адрес портала',
          continue: 'Продолжить'
        }
      }
    },
    layout: {
      slides: {
        efficiency: {
          title: 'Эффективность',
          description:
            'Все инструменты для контроля качества и оценки коммуникаций собраны на одной платформе'
        },
        motivation: {
          title: 'Мотивация',
          description: 'Вовлекайте, развивайте и мотивируйте сотрудников'
        },
        simplicity: {
          title: 'Простота',
          description:
            'Откажитесь от электронных таблиц и устаревших инструментов для оценки и контроля качества'
        },
        analytics: {
          title: 'Аналитика',
          description: 'Анализируйте показатели качества и находите новые возможности для роста'
        }
      }
    }
  }
};
