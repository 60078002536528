import { Col, Row, Skeleton } from 'antd';
import SCard from 'components/Standard/SCard';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { rolesResource } from 'redux/resources/roles';
import { unitsResource } from 'redux/resources/units';
import UnitEmployeesList from './UnitEmployeesList';
import UnitsTree from './UnitsTree';
import SCol from 'components/Standard/SCol';

const StructurePage = () => {
  const [loading, setLoading] = useState(false);
  const selectedUnitId = useSelector(state => state.uiOrganizationPage?.selectedUnit?.id);
  const dispatch = useDispatch();

  const initialLoad = async () => {
    setLoading(true);
    await Promise.all([
      dispatch(unitsResource.operations.load()),
      dispatch(rolesResource.operations.load({ include: 'levels' }))
    ]);
    setLoading(false);
  };

  useEffect(() => {
    initialLoad().then(r => r);
  }, []);

  const { t } = useTranslation();

  return (
    <Row gutter={[16, 16]}>
      <Helmet>
        <title>{t('pagesMeta.organizationStructurePage.tabs.organizationStructure.title')}</title>
      </Helmet>
      <SCol lg={24} xl={12} width="100%">
        {!loading ? (
          <UnitsTree />
        ) : (
          <SCard width="100%">
            <Skeleton />
          </SCard>
        )}
      </SCol>
      {selectedUnitId ? (
        <Col lg={24} xl={12}>
          <UnitEmployeesList />
        </Col>
      ) : null}
    </Row>
  );
};

export default StructurePage;
