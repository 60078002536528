export default {
  dashboardPages: {
    unitsAnalyticsWidgetTitle: 'Аналитика по отделам',
    reportSettings: 'Настройки отчёта',
    reportName: 'Название отчета',
    changeUnit: 'Выберите отделы',
    changeUser: 'Выберите сотрудников',
    addReport: 'Добавить отчёт',
    selectAll: 'Выбрать все',
    deleteReport: 'Удалить отчёт',
    deleteWidgetConfirmModalTitle: 'Удалить виджет?',
    deleteWidgetConfirmModalContent: 'Отменить данное действие будет невозможно',
    noCreatedReports: 'Не создано ни одного отчета',
    clickAddReportButton: 'Нажмите на кнопку "Добавить отчет" для отображения данных',
    conflicts: {
      unitsAnalyticsPage: {
        checklistsConflict: {
          title: 'Отсутствует доступ к форме оценки',
          description1:
            'В связи с изменением настроек профиля вы потеряли доступ к ранее выбранной форме оценки.',
          description2:
            'Чтобы добавить или просмотреть отчет, выберите другую форму оценки. Для восстановления доступа к ранее заданной форме оценки обратитесь к Админу.'
        }
      },
      appealsAnalyticsPage: {
        checklistsConflict: {
          title: 'Отсутствует доступ к формам оценки',
          description1:
            'В связи с изменением настроек профиля вы потеряли доступ к одной или нескольким ранее выбранным формам оценки.',
          description2:
            'Чтобы просмотреть отчет, выберите другие формы оценки. Для восстановления доступа к ранее заданным формам оценки обратитесь к Админу.'
        }
      },
      unitsConflict: {
        title: 'Установленные настройки недоступны',
        description1:
          'В связи с изменением настроек профиля вы потеряли доступ к данным одного или нескольких отделов, заданных в настройках отчета.',
        description2:
          'Для изменения фильтров выберите новые отделы в настройках отчета. Для восстановления доступа к ранее установленным настройкам обратитесь к Админу.'
      },
      usersConflict: {
        title: 'Установленные настройки недоступны',
        description1:
          'В связи с изменением настроек профиля вы потеряли доступ к данным одного или нескольких сотрудников, заданных в настройках отчета.',
        description2:
          'Для изменения фильтров выберите других сотрудников в настройках отчета. Для восстановления доступа к ранее установленным настройкам обратитесь к Админу.'
      }
    },

    autoFailScores: {
      autoFailScoresAnalyticsWidgetTitle: 'Динамика критичных ошибок',
      criticalErrorsCriteriaWidgetTitle: 'Критерии с критичной ошибкой',
      columns: {
        date: 'Период',
        reviewsCountHistory: 'Кол-во проверок',
        percentOfClientInteractions: '% проверок',
        anyTypeAutofailsCountHistory: 'Всего критичных ошибок',
        clientInteractionsCountHistory: 'Всего коммуникаций',
        reviewsWithAnyTypeAutofailCount: 'Проверок с критичными ошибками',
        checklistDefinitionAverageScoreHistory: 'Средняя оценка',
        percentOfReviewsWithAnyTypeAutofailCount: '% проверок с критичными ошибками'
      },
      footer: {
        total: 'Итого'
      }
    }
  }
};
