import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { SButton } from 'components/Standard';
import ConditionSetting from './ConditionSetting';

const Conditions = styled.div`
  margin: 12px 0px;
`;

const defaultSettings = [
  { min: 0, max: 33, kpi: 0 },
  { min: 34, max: 66, kpi: 50 },
  { min: 67, max: 100, kpi: 100 }
];

const KpiSettings = ({ updateUiWidgetFilters, kpi }) => {
  const [settings, setSettings] = useState(kpi || defaultSettings);

  const { t } = useTranslation();

  useEffect(() => {
    updateUiWidgetFilters({ settings });
  }, []);

  const handleUpdateCondition = i => updatedCondition => {
    setSettings(prevSettings => {
      const newSettings = [...prevSettings];
      newSettings[i] = { ...updatedCondition };
      updateUiWidgetFilters({ settings: newSettings });
      return newSettings;
    });
  };

  const handleAddCondition = () => {
    setSettings(prevSettings => {
      const newSettings = [
        ...prevSettings,
        {
          min: 0, // *  тут можно регулировать минимальное значение в зависимости от существующих условий
          max: 100,
          kpi: 0
        }
      ];
      updateUiWidgetFilters({ settings: newSettings });
      return newSettings;
    });
  };

  const handleRemoveCondition = i => {
    setSettings(prevSettings => {
      const newSettings = [...prevSettings].filter((item, itemIndex) => itemIndex !== i);
      updateUiWidgetFilters({ settings: newSettings });
      return newSettings;
    });
  };

  return (
    <>
      <Conditions>
        {settings.map((condition, i) => (
          <ConditionSetting
            key={i}
            index={i}
            condition={condition}
            onUpdateCondition={handleUpdateCondition(i)}
            onRemoveCondition={handleRemoveCondition}
          />
        ))}
      </Conditions>
      <SButton onClick={handleAddCondition} style={{ width: '100%' }}>
        {t('kpi.condition.add')}
      </SButton>
    </>
  );
};

export default KpiSettings;
