import styled from 'styled-components';
import { Col } from 'antd';
import {
  space,
  border,
  layout,
  flexbox,
  background,
  shadow,
  position,
  typography
} from 'styled-system';

export default styled(Col)`
&.ant-col {
    ${space}
    ${layout} 
    ${flexbox} 
    ${background} 
    ${shadow} 
    ${position}
    ${typography}
    ${border}
    &:empty {
      display: none;
    }
  }
`;
