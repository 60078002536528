import styled from 'styled-components';
import { Row } from 'antd';
import { space, layout, flexbox, background, shadow, position, color, border } from 'styled-system';

export default styled(Row)`
  &.ant-row {
    ${space}
    ${layout} 
    ${flexbox} 
    ${background} 
    ${shadow} 
    ${position}
    ${color}
    ${border}
    &:empty {
        display: none;
    }
  }
`;
