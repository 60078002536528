import { createAction, createReducer } from '@reduxjs/toolkit';
import { SYNC_DATA_PERIODS } from 'core/utils/constants';

const defaultState = {
  loading: false,
  showUsersModal: false,
  showBJModal: false,
  selectedUsersIds: [],
  selectedUnitsIds: [],
  users: {},
  currentStep: 'users',
  syncFrom: SYNC_DATA_PERIODS.THREE_DAYS.key,
  integrationId: null
};

export const setUsers = createAction('uiRegisterFromIntegration/updateSelectedUsers');
export const setSelectedUsersIds = createAction('uiRegisterFromIntegration/setSelectedUsersIds');
export const updateUser = createAction('uiRegisterFromIntegration/updateUser');

export const setShowUsersModal = createAction('uiRegisterFromIntegration/setShowUsersModal');
export const setShowBJModal = createAction('uiRegisterFromIntegration/setShowBJModal');

export const setCurrentStep = createAction('uiRegisterFromIntegration/setCurrentStep');
export const setSyncFrom = createAction('uiRegisterFromIntegration/setSyncFrom');
export const setSelectedUnitsIds = createAction('uiRegisterFromIntegration/setSelectedUnitsIds');
export const setIntegrationId = createAction('uiRegisterFromIntegration/setIntegrationId');

export const uiRegisterFromIntegrationReducer = createReducer(defaultState, {
  [setUsers]: (state, { payload }) => ({
    ...state,
    users: payload,
    selectedUsersIds: Object.values(payload)
      .filter(({ active }) => active)
      .map(({ id }) => id)
  }),
  [setSelectedUsersIds]: (state, { payload }) => ({ ...state, selectedUsersIds: payload }),
  [updateUser]: (state, { payload }) => {
    const oldUser = state.users[payload.id];
    return {
      ...state,
      users: { ...state.users, [payload.id]: { ...oldUser, ...payload } }
    };
  },
  [setShowUsersModal]: (state, { payload }) => ({ ...state, showUsersModal: payload }),
  [setShowBJModal]: (state, { payload }) => ({ ...state, showBJModal: payload }),
  [setCurrentStep]: (state, { payload }) => ({ ...state, currentStep: payload }),
  [setSyncFrom]: (state, { payload }) => ({ ...state, syncFrom: payload }),
  [setSelectedUnitsIds]: (state, { payload }) => ({ ...state, selectedUnitsIds: payload }),
  [setIntegrationId]: (state, { payload }) => ({ ...state, integrationId: payload })
});
