import React from 'react';
import { orderBy, get } from 'lodash';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import ChecklistDefinitionStatusTag from 'components/Checklist/ChecklistDefinitionStatusTag';

const ChecklistDefinitionsSelect = props => {
  const { t } = useTranslation();

  const allChecklistDefinitions = {
    key: 'all',
    value: 'all',
    id: 'all',
    name: t('components.checklistsDefinitionsSelect.allChecklists')
  };

  const { Option } = Select;
  const { checklistDefinitions = [], allowAll = false, ...selectProps } = props;
  const orderedChecklistDefinitions = orderBy(
    checklistDefinitions,
    ['status', checklistDefinition => get(checklistDefinition, 'name', '').toLowerCase()],
    ['desc', 'asc']
  );

  const checklistDefinitionsToRender = allowAll
    ? [allChecklistDefinitions, ...orderedChecklistDefinitions]
    : orderedChecklistDefinitions;

  return (
    <Select {...selectProps}>
      {checklistDefinitionsToRender.map(checklistDefinition => (
        <Option
          key={checklistDefinition.id}
          value={checklistDefinition.id}
          searchValue={checklistDefinition.name}
        >
          <span style={{ marginRight: 8 }}>{checklistDefinition.name}</span>
          {checklistDefinition.status && (
            <ChecklistDefinitionStatusTag status={checklistDefinition.status} />
          )}
        </Option>
      ))}
    </Select>
  );
};

export default ChecklistDefinitionsSelect;
