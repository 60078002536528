import { LogOut, MoreVertical } from 'react-feather';
import { Col, Dropdown, Menu, Modal, Row, Tag } from 'antd';
import SettingsButton from 'components/Buttons/SettingsButton';
import ClientInteractionLink, { typeToRoute } from 'components/ClientInteractionLink';
import UserPreview from 'components/UserPreview';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { ListItemContainer } from 'pages/BitrixIntegration/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { utils } from 'react-media-player';
import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';

const ClientInteractionPreview = ({ interaction }) => {
  const {
    operatorId,
    direction,
    id,
    startedAt,
    duration,
    reviewsIds,
    clientInteractionType
  } = interaction;
  const { t } = useTranslation();
  const warning = () => {
    Modal.warning({
      title: t('bitrixIntegrationPage.clientInteractionPreview.warningModal.title'),
      content: t('bitrixIntegrationPage.clientInteractionPreview.warningModal.description')
    });
  };

  const route = typeToRoute[clientInteractionType];
  const handleMenuClick = ({ key }) => {
    switch (key) {
      case 'open-in-app':
        isEmpty(operatorId) ? warning() : window.open(`/${route}/${id}`);
        break;

      default:
        break;
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="open-in-app">
        <Icon icon={LogOut} />
        {t('bitrixIntegrationPage.clientInteractionPreview.buttons.openInApp')}
      </Menu.Item>
    </Menu>
  );

  const Direction = () => {
    switch (direction) {
      case 'incoming':
        return (
          <span>{t('bitrixIntegrationPage.clientInteractionPreview.directions.incoming')}</span>
        );
      case 'outcoming':
        return (
          <span>{t('bitrixIntegrationPage.clientInteractionPreview.directions.outcoming')}</span>
        );
      default:
        return (
          <Tag color="red">
            {t('bitrixIntegrationPage.clientInteractionPreview.directions.notInstalledType')}
          </Tag>
        );
    }
  };

  return (
    <ListItemContainer>
      <Row type="flex" align="middle" justify="space-between" gutter={[16, 16]}>
        <Col span={1}>
          <ClientInteractionLink
            id={id}
            communication={interaction}
            link={`/bitrix/${route}/${id}`}
            useDrawer={false}
          />
        </Col>
        <Col span={5}>
          <UserPreview userId={operatorId} disabled />
        </Col>
        <Col span={3}>
          <Direction />
        </Col>
        <SCol display="flex" justifyContent="center" span={5}>
          <span>{moment(startedAt).format('DD/MM/YYYY, HH:mm')}</span>
        </SCol>
        <SCol display="flex" justifyContent="center" span={3}>
          <span>
            {utils.formatTime(duration) ||
              t('bitrixIntegrationPage.clientInteractionPreview.columns.unistalledTime')}
          </span>
        </SCol>
        <SCol display="flex" justifyContent="center" span={3}>
          <span>
            {!isEmpty(reviewsIds)
              ? t('bitrixIntegrationPage.clientInteractionPreview.columns.reviewed')
              : t('bitrixIntegrationPage.clientInteractionPreview.columns.unReviewed')}
          </span>
        </SCol>
        <Col span={1} onClick={e => e.stopPropagation()}>
          <Dropdown overlay={menu} trigger={['click']}>
            <SettingsButton
              style={{ background: 'transparent' }}
              icon={<Icon icon={MoreVertical} />}
            />
          </Dropdown>
        </Col>
      </Row>
    </ListItemContainer>
  );
};

export default ClientInteractionPreview;
