export default {
  dashboardPages: {
    unitsAnalyticsWidgetTitle: 'Units analytics',
    reportSettings: 'Report Settings',
    reportName: 'Report name',
    changeUnit: 'Choose units',
    changeUser: 'Choose users',
    addReport: 'Add a report',
    selectAll: 'Select all',
    deleteReport: 'Delete report',
    deleteWidgetConfirmModalTitle: 'Delete a widget?',
    deleteWidgetConfirmModalContent: 'It will be impossible to cancel this action',
    noCreatedReports: 'No reports were created',
    clickAddReportButton: 'Click on the "Add Report" button to display the data',
    conflicts: {
      unitsAnalyticsPage: {
        checklistsConflict: {
          title: 'No access to the evaluation checklist',
          description1:
            'Due to a change in profile settings, you have lost access to your previously selected evaluation form.',
          description2:
            'To add or view the report, select another evaluation form. To regain access to your previously selected evaluation form, contact the Admin.'
        }
      },
      appealsAnalyticsPage: {
        checklistsConflict: {
          title: 'No access to evaluation forms',
          description1:
            'Due to a change in profile settings, you have lost access to one or more previously selected evaluation forms.',
          description2:
            'Select other evaluation forms to view the report. To regain access to your previously selected evaluation forms, contact the Admin.'
        }
      },
      unitsConflict: {
        title: 'The set settings are unavailable',
        description1:
          'Due to a change in profile settings, you have lost access to one or more of the departments set in the report settings.',
        description2:
          'To change the filters, select new departments in the report settings. To regain access to the previously set settings, contact the Admin.'
      },

      usersConflict: {
        title: 'The set settings are unavailable',
        description1:
          'Due to changes in profile settings, you have lost access to the data of one or more employees set in the report settings.',
        description2:
          'To change the filters, select other employees in the report settings. To restore access to the previously set settings, contact the Admin.'
      }
    },

    autoFailScores: {
      autoFailWidgetTitle: 'Dynamics of auto fails',
      columns: {
        date: 'Period',
        reviewsCountHistory: 'Number of checks',
        percentOfClientInteractions: '% Interactions',
        anyTypeAutofailsCountHistory: 'Total auto fails',
        clientInteractionsCountHistory: 'Interactions',
        reviewsWithAnyTypeAutofailCount: 'Reviews with auto fail',
        checklistDefinitionAverageScoreHistory: 'AVG Score',
        percentOfReviewsWithAnyTypeAutofailCount: '% reviews with auto fail'
      },
      footer: {
        total: 'Total'
      }
    }
  }
};
