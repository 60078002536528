import { QuestionCircleOutlined, BulbOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Avatar, Col, Dropdown, Menu, Row, Typography } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { HelpMenuItem } from './Components/MenuItems';

const { Text } = Typography;

const HelpMenu = () => {
  const { t } = useTranslation();

  const handleMenuClick = ({ key }) => {
    const keyToAction = {
      helpCenter: () => window.open('https://qolio.gitbook.io/qolio-or-baza-znanii/'),
      updates: () =>
        window.open('https://qolio.gitbook.io/qolio-or-baza-znanii/poslednie-obnovleniya'),
      suggestAnIdea: () => window.open('https://qolioio.ducalis.io/predlozenia-klientov/summary'),
      hotKeys: () => window.open('https://qolio.gitbook.io/qolio-or-baza-znanii/bystrye-klavishi')
    };
    return keyToAction[key] ? keyToAction[key]() : undefined;
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <HelpMenuItem key="helpCenter" className="userMenu">
        <CustomLink>
          <QuestionCircleOutlined />
          <Text>{t('menu.helpMenu.helpCenter')}</Text>
        </CustomLink>
      </HelpMenuItem>

      <HelpMenuItem key="updates" className="userMenu">
        <CustomLink>
          <ClockCircleOutlined />
          <Text>{t('menu.helpMenu.updates')}</Text>
        </CustomLink>
      </HelpMenuItem>

      <HelpMenuItem key="suggestAnIdea" className="userMenu">
        <CustomLink>
          <BulbOutlined />
          <Text>{t('menu.helpMenu.suggestAnIdea')}</Text>
        </CustomLink>
      </HelpMenuItem>

      <Menu.Divider />

      <HelpMenuItem key="hotKeys" className="userMenu">
        <CustomLink>
          <Text>{t('menu.helpMenu.hotKeys')}</Text>
        </CustomLink>
      </HelpMenuItem>
    </Menu>
  );

  return (
    <Row type="flex" justify="end">
      <UserNameCol span={24}>
        <StyledDropdown
          overlay={menu}
          trigger={['click']}
          placement="bottomCenter"
          style={{ height: 48 }}
        >
          <StyledAvatar
            shape="square"
            icon={<QuestionCircleOutlined style={{ color: '#a6adb4', marginRight: 16 }} />}
          />
        </StyledDropdown>
      </UserNameCol>
    </Row>
  );
};

const StyledAvatar = styled(Avatar)`
  &.ant-avatar {
    background: none;
    height: 42px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 12px;
  }
`;

const UserNameCol = styled(Col)`
  &.ant-col {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #a6adb4;
    display: flex;
    align-items: center;
  }
`;

const CustomLink = styled(Link)`
  display: flex !important;
  align-items: center;
  width: 100%;
  margin: 0 !important;
  padding: 5px 6px !important;

  & {
    .anticon {
      display: inline;
      padding-right: 6px;
      font-size: 12px;
    }
  }
`;

const StyledDropdown = styled(Dropdown)`
  margin-left: 10px;
  color: #a6adb4;
  :hover {
    color: white;
  }
  max-width: 155px;
`;

export default HelpMenu;
