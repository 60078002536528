export default {
  bitrixIntegrationPage: {
    clientInteractionPreview: {
      warningModal: {
        title: 'Attention!',
        description: 'This call has no installed operator'
      },
      buttons: { openInApp: 'Open in application' },
      directions: {
        incoming: 'Incoming',
        outcoming: 'Outcoming',
        notInstalledType: 'Type is not set'
      },
      columns: {
        unistalledTime: 'Time is not set',
        reviewed: 'Evaluated',
        unReviewed: 'Not evaluated'
      }
    },
    clientInteractions: {
      empty: {
        linkText: 'Read more about evaluation',
        description: 'There are no communications available for evaluation in this card'
      },
      columns: {
        operator: 'Operator',
        direction: 'Direction',
        date: 'Date',
        duration: 'Duration',
        status: 'Status'
      }
    },
    reviewPreview: {
      menu: { openInApp: 'Open in application' },
      directions: {
        incoming: 'Incoming',
        outcoming: 'Outcoming',
        notInstalledType: 'Type is not set'
      },
      columns: { unistalledTime: 'Time is not set' }
    },
    review: {
      empty: {
        linkText: 'Read more about evaluation',
        description: 'Until you have added a score to the communications on this card'
      },
      columns: {
        operator: 'Operator',
        direction: 'Direction',
        date: 'Date',
        duration: 'Duration',
        comments: 'Comments',
        score: 'Evaluation',
        author: 'Evaluation author'
      }
    }
  }
};
