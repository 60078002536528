import { Empty } from 'antd';
import EmptyDescriptionWithLink from 'components/EmptyDescriptionWithLink';
import AmoService from 'core/services/amocrm';
import { AMO_COMMANDS } from 'core/utils/constants';
import { isEmpty } from 'lodash';
import LoadingPage from 'pages/ErrorPages/LoadingPage';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { clientInteractionsResource } from 'redux/resources/clientInteractions';

const CommunicationPage = ({ history, loadClientInteraction, loading }) => {
  const [empty, setEmpty] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    AmoService.initMessageListener({
      command: AMO_COMMANDS.SHOW_AMMO_NOTE_CALL,
      callback: async ({ noteId }) => {
        const response = await loadClientInteraction({
          filters: { integration_uids: `${noteId}` }
        });

        if (isEmpty(response?.resources)) {
          return setEmpty(true);
        }

        const clientInteraction = response.resources[Object.keys(response.resources)[0]];

        if (clientInteraction.reviewsIds[0]) {
          return history.push(`/reviews/${clientInteraction.reviewsIds[0]}`);
        }

        clientInteraction.id && history.push(`/calls/${clientInteraction.id}`);
      }
    });

    AmoService.sendMessage({ command: AMO_COMMANDS.SEND_AMMO_NOTE_CALL });

    return () => AmoService.removeMessageListener({ command: AMO_COMMANDS.SHOW_AMMO_NOTE_CALL });
  }, []);

  if (empty && !loading) {
    return (
      <Empty
        style={{ margin: '12px' }}
        description={
          <EmptyDescriptionWithLink
            linkText={t('amoIntegrationPage.emptyDescriptionWithLink.linkText')}
            link="https://intercom.help/qolio/ru/articles/5422028-проверка-коммуникаций"
            description={t('amoIntegrationPage.emptyDescriptionWithLink.description')}
          />
        }
      />
    );
  }

  return <LoadingPage />;
};

const mapStateToProps = state => ({
  loading: state.clientInteractionsResource.loading
});

const mapDispatchToProps = {
  loadClientInteraction: clientInteractionsResource.operations.load
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CommunicationPage));
