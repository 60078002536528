import React from 'react';
import { updatePlayerState } from 'redux/ui/recordPlayer/reducer';
import { utils } from 'react-media-player';
import { useDispatch } from 'react-redux';
import SText from 'components/Standard/SText';
import { useTranslation } from 'react-i18next';

const TimestampButton = ({ timestamp, commentType }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const updateTime = event => {
    event.preventDefault();
    dispatch(updatePlayerState({ playedSeconds: timestamp, forcePlayed: true }));
  };

  if (commentType !== 'review_comment' && !timestamp) return null;

  return (
    <>
      <SText marginLeft="8px" color="var(--black_6)">
        {t('general.time')}
      </SText>
      <SText
        style={{ cursor: 'pointer' }}
        marginLeft="3px"
        color="var(--blue_6)"
        onClick={updateTime}
        fontWeight={600}
      >
        {utils.formatTime(timestamp)}
      </SText>
    </>
  );
};

export default TimestampButton;
