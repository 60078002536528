import { get } from 'redux-bees';

const workPlanTaskAssignments = apiPath => ({
  getWorkPlanTaskAssignments: {
    method: get,
    path: `${apiPath}/work_plan/task_assignments`
  }
});

export default workPlanTaskAssignments;
