import BitrixClientInteractionRoutes from 'core/routes/BitrixClientInteractionsRoutes';
import BitrixIntegratedRoutes from 'core/routes/BitrixIntegratedRoutes';
import BitrixService from 'core/services/bitrix';
import { BITRIX_INTEGRATION_PLACEMENTS } from 'core/utils/constants';
import { get } from 'lodash';

let routes = [];

export const addBitrixIntegrationRoutes = async () => {
  let bitrixPlacement = false;

  if (BitrixService.isIntegrated) {
    await BitrixService.init();

    BitrixService.resizeWindow({
      width: document.querySelector('#root').clientWidth,
      height: document.querySelector('#root').clientHeight + 300
    });

    bitrixPlacement = get(BitrixService.getPlacementInfo(), 'placement', false);

    if (BITRIX_INTEGRATION_PLACEMENTS[bitrixPlacement]) {
      routes = BitrixClientInteractionRoutes;
      return;
    }

    routes = BitrixIntegratedRoutes;
  }
};

export const getIntegrationRoutes = async (history = {}) => {
  // * every method should detect integration and assign it's routes
  await addBitrixIntegrationRoutes();
  return routes;
};
