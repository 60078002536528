import moment from 'moment';
import { SYMBOLIC_TIME_RANGE } from 'core/utils/constants';

export const isDisabledDayOption = ({ filters, hasConflicts }) => {
  const start = moment(filters?.clientInteractionTimeTo);
  const end = moment(filters?.clientInteractionTimeFrom);
  const diff = start.diff(end, 'days');

  const disabledByPeriod = [
    SYMBOLIC_TIME_RANGE.THIS_YEAR,
    SYMBOLIC_TIME_RANGE.PREVIOUS_YEAR
  ].includes(filters?.clientInteractionSymbolicTimeRange);

  const disabledByDiff = diff > 60;

  if (hasConflicts) return false;
  if (disabledByDiff || disabledByPeriod) return true;
};
