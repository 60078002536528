export default {
  bitrixIntegrationPage: {
    clientInteractionPreview: {
      warningModal: {
        title: 'Внимание!',
        description: 'У данного звонка не установлен оператор'
      },
      buttons: {
        openInApp: 'Открыть в приложении'
      },
      directions: {
        incoming: 'Входящий',
        outcoming: 'Исходящий',
        notInstalledType: 'Тип не установлен'
      },
      columns: {
        unistalledTime: 'Время не определено',
        reviewed: 'Оценён',
        unReviewed: 'Не оценён'
      }
    },
    clientInteractions: {
      empty: {
        linkText: 'Подробнее об оценке',
        description: 'В данной карточке нет доступных для оценки коммуникаций'
      },
      columns: {
        operator: 'Оператор',
        direction: 'Направление',
        date: 'Дата',
        duration: 'Прод-ть',
        status: 'Статус'
      }
    },
    reviewPreview: {
      menu: {
        openInApp: 'Открыть в приложении'
      },
      directions: {
        incoming: 'Входящий',
        outcoming: 'Исходящий',
        notInstalledType: 'Тип не установлен'
      },
      columns: {
        unistalledTime: 'Время не определено'
      }
    },
    review: {
      empty: {
        linkText: 'Подробнее об оценке',
        description: 'Пока вы не добавили оценки к коммуникациям в данной карточке'
      },
      columns: {
        operator: 'Оператор',
        direction: 'Направление',
        date: 'Дата',
        duration: 'Прод-ть',
        comments: 'Комментарии',
        score: 'Оценка',
        author: 'Автор оценки'
      }
    }
  }
};
