import SSelect from 'components/Standard/SSelect';
import { COLOR_ZONE_LITERALS } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import { get } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const { Option } = SSelect;

const ColorZonesFilter = ({ loading, filters, onChangeFilters }) => {
  const { t } = useTranslation();
  const user = useSelector(state => state.uiUserAnalyticsPage?.currentUser);
  const userId = get(user, 'id');

  return (
    <SSelect
      loading={loading}
      disabled={loading}
      style={{ width: '100%' }}
      placeholder={t('userAnalyticsPage.userReviewsTable.colorZoneSelect.placeholder')}
      mode="multiple"
      value={filters.checklistDefinitionColorZones || []}
      onChange={zones => {
        onChangeFilters({
          userId,
          filters: {
            ...filters,
            checklistDefinitionColorZones: zones
          }
        });
      }}
      optionLabelProp="label"
      allowClear
      maxTagCount={0}
      maxTagPlaceholder={selectedKeys => `${t('general.selected')}: ${selectedKeys.length}`}
      filterOption={(input, option) => selectSearch({ input, option, searchProp: 'searchValue' })}
    >
      <Option
        key="high"
        value="high"
        searchValue={t(COLOR_ZONE_LITERALS.high)}
        label={t(COLOR_ZONE_LITERALS.high)}
      >
        {t(COLOR_ZONE_LITERALS.high)}
      </Option>
      <Option
        key="medium"
        value="medium"
        searchValue={t(COLOR_ZONE_LITERALS.medium)}
        label={t(COLOR_ZONE_LITERALS.medium)}
      >
        {t(COLOR_ZONE_LITERALS.medium)}
      </Option>
      <Option
        key="low"
        value="low"
        searchValue={t(COLOR_ZONE_LITERALS.low)}
        label={t(COLOR_ZONE_LITERALS.low)}
      >
        {t(COLOR_ZONE_LITERALS.low)}
      </Option>
    </SSelect>
  );
};

export default ColorZonesFilter;
