import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  direction: flex;
  flex-direction: column;
`;

const Description = styled.span`
  display: flex;
  justify-content: center;
`;

export default ({ description, link, linkText }) => (
  <Container>
    <Description>{description}</Description>
    <a href={link} rel="noopener noreferrer" target="_blank">
      {linkText}
    </a>
  </Container>
);
