import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  viewedItem: undefined,
  comment: '',
  loading: false
};

export const setViewedItem = createAction('uiCalibrationResultPage/setViewedItem');
export const setComment = createAction('uiCalibrationResultPage/setComment');
export const setLoading = createAction('uiCalibrationResultPage/setLoading');

export const uiCalibrationResultPageReducer = createReducer(defaultState, {
  [setViewedItem]: (state, { payload }) => ({ ...state, viewedItem: payload }),
  [setComment]: (state, { payload }) => ({ ...state, comment: payload }),
  [setLoading]: (state, { payload }) => ({ ...state, loading: payload })
});
