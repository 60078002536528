import React from 'react';
import { Col } from 'antd';
import { PreviewRow, WidgetPreview } from './styled';

const SIXS = ({ createNewRow, activeDashboardId, rowsCount, setCollapse }) => {
  const createRow = () => {
    setCollapse(false);
    createNewRow({
      position: rowsCount + 1,
      dashboard_id: activeDashboardId,
      widgets_sizes: ['small', 'small', 'small', 'small', 'small', 'small']
    });
  };

  return (
    <PreviewRow gutter={[4, 4]} type="flex" align="middle" onClick={createRow}>
      <Col span={4}>
        <WidgetPreview>
          <span>S</span>
        </WidgetPreview>
      </Col>
      <Col span={4}>
        <WidgetPreview>
          <span>S</span>
        </WidgetPreview>
      </Col>
      <Col span={4}>
        <WidgetPreview>
          <span>S</span>
        </WidgetPreview>
      </Col>
      <Col span={4}>
        <WidgetPreview>
          <span>S</span>
        </WidgetPreview>
      </Col>
      <Col span={4}>
        <WidgetPreview>
          <span>S</span>
        </WidgetPreview>
      </Col>
      <Col span={4}>
        <WidgetPreview>
          <span>S</span>
        </WidgetPreview>
      </Col>
    </PreviewRow>
  );
};

export default SIXS;
