import { Dropdown, Menu } from 'antd';
import SButton from 'components/Standard/SButton';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import Icon from 'components/Icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FundOutlined, DownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import { createAutofaiDashboardPageWidget } from 'redux/entities/dashboardPages/autofailsDashboardPage/operations';
import { AUTO_FAIL_TYPES } from 'core/utils/constants';

const CreateNewReportButton = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const autoFailDashboard = useSelector(
    state => get(state, 'dashboardPages.dashboardsByTypes.autofail_scores', {}),
    isEqual
  );

  const addNewWidget = ({ key }) => {
    dispatch(
      createAutofaiDashboardPageWidget({
        dashboardPageId: autoFailDashboard?.id,
        page: autoFailDashboard.page,
        type: key
      })
    );
  };

  const menu = (
    <Menu onClick={addNewWidget}>
      <Menu.Item key={AUTO_FAIL_TYPES.HISTORY_REPORT}>
        <SRow padding="8px">
          <SCol
            borderRadius={40}
            height={80}
            width={80}
            margin="auto 8px"
            background="var(--blue_4)"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Icon icon={FundOutlined} style={{ fontSize: '50px' }} color="var(--blue_6)" />
          </SCol>
          <SCol maxWidth="411px" marginLeft="4px">
            <SRow>{t('autoFailScoresPage.buttonAddReport.dynamicCriticalErrors.header')}</SRow>
            <SRow color="var(--black_6)" style={{ whiteSpace: 'break-spaces' }}>
              {t('autoFailScoresPage.buttonAddReport.dynamicCriticalErrors.description')}
            </SRow>
          </SCol>
        </SRow>
      </Menu.Item>
      <Menu.Item key={AUTO_FAIL_TYPES.CRITICAL_ERRORS_BY_OPERATORS}>
        <SRow padding="8px">
          <SCol
            borderRadius={40}
            height={80}
            width={80}
            margin="auto 8px"
            background="var(--blue_4)"
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            <Icon icon={FundOutlined} style={{ fontSize: '50px' }} color="var(--blue_6)" />
          </SCol>
          <SCol maxWidth="411px" marginLeft="4px">
            <SRow>{t('autoFailScoresPage.buttonAddReport.criticalErrorsCriteria.header')}</SRow>
            <SRow color="var(--black_6)" style={{ whiteSpace: 'break-spaces' }}>
              {t('autoFailScoresPage.buttonAddReport.criticalErrorsCriteria.description')}
            </SRow>
          </SCol>
        </SRow>
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomRight">
      <SButton type="primary">
        {t('dashboardPages.addReport')}
        <DownOutlined />
      </SButton>
    </Dropdown>
  );
};

export default React.memo(CreateNewReportButton);
