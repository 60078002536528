import SSelect from 'components/Standard/SSelect';
import { FLAGS_COLORS_LITERALS_RUS, FLAGS_TO_COLORS_RELATIONS } from 'core/utils/constants';
import React from 'react';
import { FlagFilled, MessageFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import { selectSearch } from 'core/utils/selectSearch';

const { Option } = SSelect;

const FlagsFilter = ({ loading, filters, onChangeFilters, userId, flagSelectRef }) => {
  const { t } = useTranslation();

  return (
    <SSelect
      loading={loading}
      disabled={loading}
      style={{ width: '100%' }}
      placeholder={t('general.flagsAndComments')}
      mode="multiple"
      onChange={flags => {
        onChangeFilters({
          userId,
          filters: {
            ...filters,
            commentsRatingFlags: flags
          }
        });
      }}
      value={filters.commentsRatingFlags || []}
      optionLabelProp="label"
      ref={flagSelectRef}
      allowClear
      filterOption={(input, option) => selectSearch({ input, option, searchProp: 'searchValue' })}
    >
      <Option
        key="green"
        value="green"
        searchValue={t(FLAGS_TO_COLORS_RELATIONS.green)}
        label={<Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.green} />}
      >
        <Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.green} />
      </Option>
      <Option
        key="yellow"
        value="yellow"
        searchValue={t(FLAGS_COLORS_LITERALS_RUS.yellow)}
        label={<Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.yellow} />}
      >
        <Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.yellow} />
      </Option>
      <Option
        key="red"
        value="red"
        searchValue={t(FLAGS_COLORS_LITERALS_RUS.red)}
        label={<Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.red} />}
      >
        <Icon icon={FlagFilled} color={FLAGS_TO_COLORS_RELATIONS.red} />
      </Option>
      <Option
        key="empty"
        value="empty"
        searchValue={t(FLAGS_COLORS_LITERALS_RUS.gray)}
        label={<Icon icon={MessageFilled} color={FLAGS_TO_COLORS_RELATIONS.without_flag} />}
      >
        <Icon icon={MessageFilled} color={FLAGS_TO_COLORS_RELATIONS.without_flag} />
      </Option>
    </SSelect>
  );
};

export default FlagsFilter;
