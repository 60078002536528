export default {
  workPlanTasks: {
    taskPlaceholder: 'Tarea',
    colorizedNumbers: {
      completed: 'Número de controles realizados',
      incompleted: 'Número de cheques vencidos',
      total: 'Número de cheques restantes'
    },
    tableColumns: {
      reviewsCount: 'Evaluar',
      reviewsCount2: 'Evaluaciones',
      completed: 'Realizado',
      incompleted: 'Izquierda',
      closeTask: 'Completa la tarea',
      stopRepeating: 'Detener repeticiones',
      name: 'Nombre de la tarea',
      lastTaskTimeFrom: 'La fecha del comienzo',
      lastTaskTimeTo: 'Fecha de realización',
      creator: 'El creador',
      schedule: 'Repetición',
      repeatedCount: 'Lanza',
      reviewCreatedAt: 'Fecha y hora de la evaluación',
      progress: 'Progreso',
      repeat: 'Repetición'
    },
    assignments: {
      closeTask: 'Completa la tarea',
      close: 'Terminar',
      taskStartedAt: 'Fecha de lanzamiento',
      assignmentsCount: 'Numero de inspectores',
      completedTaskItemsCount: 'Evaluaciones asignadas'
    },
    filters: { createTask: 'Crear tarea' },
    confirmStopRepeating: 'Detener repeticiones?',
    confirmClosing: 'Completa la tarea?',
    confirmDelete: '¿Eliminar configuración?',
    tasksStartedAt: 'Primera fecha de lanzamiento',
    reviewersCount: 'Numero de inspectores',
    operatorsCount: 'Numero de operadores',
    reviewedOperator: 'Empleado chequeado',
    communicationsNotFound: 'No se han encontrado comunicaciones',
    nextCommunicationDate:
      'Las comunicaciones se añadirán en la próxima ejecución que tendrá lugar ',
    checkCommunication: 'Comprobación de la configuración de la tarea',
    howToBlock: {
      howToButton: 'Cómo utilizar',
      watchVideo: 'Ver el vídeo',
      readManual: 'Lea las instrucciones completas',
      startTour: 'Tour interactivo'
    },
    menu: {
      inProgress: 'Durante',
      pending: 'Pendiente',
      total: 'Ejecución general'
    },
    tooltips: {
      configurationProgress:
        'La relación entre el número de comunicaciones comprobadas y el número total de la muestra. La información de progreso indica la última tarea.',
      progress:
        'La relación entre el número de comunicaciones verificadas y el número total de la muestra.',
      schedule:
        'Periodo de repetición de muestreo de comunicación. La muestra puede actualizarse automáticamente y ejecutarse diaria, semanal o mensualmente.',
      repeatedCount: 'El número de ejecuciones de tareas para comprobar la selección.'
    },
    repeat: 'repetición',
    confirmModal: {
      title: 'Estás a punto de completar una tarea. ¿Continuar?',
      content: 'Todas sus repeticiones en el futuro también se completarán'
    }
  }
};
