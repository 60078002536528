import BitrixService from 'core/services/bitrix';
import { isEmpty } from 'lodash';
import { BITRIX_INTEGRATION_PLACEMENTS } from 'core/utils/constants';
import { operations as reviewsOperations } from 'redux/lists/bitrixReviewsListReducer';
import { operations as clientInteractionsOperations } from 'redux/lists/bitrixClientInteractionsList';
import { interactionsLoadStarted } from './reducer';

export const loadInteractions = () => async dispatch => {
  dispatch(interactionsLoadStarted());
  const { placement, options } = BitrixService.getPlacementInfo();
  const id = options.ID;
  if (placement === BITRIX_INTEGRATION_PLACEMENTS.CRM_LEAD_DETAIL_TAB) {
    const clientIntegrationUidsList = [`lead-${id}`];
    const contactId = options.CONTACT_ID;
    const companyId = options.COMPANY_ID;
    if (!isEmpty(contactId) && contactId != '0') {
      clientIntegrationUidsList.push(`contact-${contactId}`);
    }
    if (!isEmpty(companyId) && companyId != '0') {
      clientIntegrationUidsList.push(`company-${companyId}`);
    }

    return dispatch(
      clientInteractionsOperations.load({
        filters: { clientIntegrationUids: clientIntegrationUidsList },
        include: 'operator'
      })
    );
  }

  if (placement === BITRIX_INTEGRATION_PLACEMENTS.CRM_DEAL_DETAIL_TAB) {
    BitrixService.callApiMethod({
      methodName: 'crm.deal.contact.items.get',
      params: { id },
      callback: async res => {
        if (!isEmpty(res)) {
          await dispatch(
            clientInteractionsOperations.load({
              include: 'client,operator',
              filters: {
                clientIntegrationUids: res.map(({ CONTACT_ID }) => `contact-${CONTACT_ID}`)
              }
            })
          );
        }
      }
    });

    BitrixService.callApiMethod({
      methodName: 'crm.deal.get',
      params: { id },
      callback: async deal => {
        if (!isEmpty(deal)) {
          const clientIntegrationUidsList = [];
          if (!isEmpty(deal['COMPANY_ID'])) {
            clientIntegrationUidsList.push(`company-${deal['COMPANY_ID']}`);
          }
          if (!isEmpty(deal['CONTACT_ID'])) {
            clientIntegrationUidsList.push(`contact-${deal['CONTACT_ID']}`);
          }
          if (!isEmpty(deal['LEAD_ID'])) {
            clientIntegrationUidsList.push(`lead-${deal['LEAD_ID']}`);
          }

          if (!isEmpty(clientIntegrationUidsList)) {
            await dispatch(
              clientInteractionsOperations.load({
                include: 'client,operator',
                filters: {
                  clientIntegrationUids: clientIntegrationUidsList
                }
              })
            );
          }
        }
      }
    });
  }
};

export const loadReviews = ({ ...props }) => async dispatch => {
  dispatch(interactionsLoadStarted());
  const { placement, options } = BitrixService.getPlacementInfo();
  const id = options.ID;

  if (placement === BITRIX_INTEGRATION_PLACEMENTS.CRM_LEAD_DETAIL_TAB) {
    const clientIntegrationUidsList = [`lead-${id}`];
    const contactId = options.CONTACT_ID;
    const companyId = options.COMPANY_ID;
    if (!isEmpty(contactId) && contactId != '0') {
      clientIntegrationUidsList.push(`contact-${contactId}`);
    }
    if (!isEmpty(companyId) && companyId != '0') {
      clientIntegrationUidsList.push(`company-${companyId}`);
    }

    return dispatch(
      reviewsOperations.load({
        filters: { clientIntegrationUids: clientIntegrationUidsList },
        ...props
      })
    );
  }

  if (placement === BITRIX_INTEGRATION_PLACEMENTS.CRM_DEAL_DETAIL_TAB) {
    BitrixService.callApiMethod({
      methodName: 'crm.deal.contact.items.get',
      params: { id },
      callback: async res => {
        if (!isEmpty(res)) {
          await dispatch(
            reviewsOperations.load({
              filters: {
                clientIntegrationUids: res.map(({ CONTACT_ID }) => `contact-${CONTACT_ID}`)
              },
              ...props
            })
          );
        }
      }
    });

    BitrixService.callApiMethod({
      methodName: 'crm.deal.get',
      params: { id },
      callback: async deal => {
        if (!isEmpty(deal)) {
          const clientIntegrationUidsList = [];
          if (!isEmpty(deal['COMPANY_ID'])) {
            clientIntegrationUidsList.push(`company-${deal['COMPANY_ID']}`);
          }
          if (!isEmpty(deal['CONTACT_ID'])) {
            clientIntegrationUidsList.push(`contact-${deal['CONTACT_ID']}`);
          }
          if (!isEmpty(deal['LEAD_ID'])) {
            clientIntegrationUidsList.push(`lead-${deal['LEAD_ID']}`);
          }

          if (!isEmpty(clientIntegrationUidsList)) {
            await dispatch(
              reviewsOperations.load({
                filters: {
                  clientIntegrationUids: clientIntegrationUidsList
                },
                ...props
              })
            );
          }
        }
      }
    });
  }
};
