import { get } from 'redux-bees';

const analyticsEndpoints = apiPath => ({
  getQuestionGroupsAnalytics: {
    method: get,
    path: `${apiPath}/analytics/checklist_definition/:checklist_definition_id/groups`
  },
  getQuestionGroupsAnalyticsHistory: {
    method: get,
    path: `${apiPath}/analytics/checklist_definition/:checklist_definition_id/groups/history`
  },
  getQuestionsAnalytics: {
    method: get,
    path: `${apiPath}/analytics/checklist_definition/:checklist_definition_id/questions`
  },
  getQuestionsAnalyticsHistory: {
    method: get,
    path: `${apiPath}/analytics/checklist_definition/:checklist_definition_id/questions/history`
  },
  getSkillsAnalytics: {
    method: get,
    path: `${apiPath}/analytics/checklist_definition/:checklist_definition_id`
  },
  getSkillsAnalyticsHistory: {
    method: get,
    path: `${apiPath}/analytics/checklist_definition/:checklist_definition_id/history`
  },
  getDashboardAnalytics: {
    method: get,
    path: `${apiPath}/analytics/dashboard`
  },
  getUserAnalytics: { method: get, path: `${apiPath}/analytics/users/:id/` },
  getCommentsAnalytics: { method: get, path: `${apiPath}/analytics/comments` },
  loadAnalyticsWidgets: { method: get, path: `${apiPath}/analytics/widgets` },
  loadAnalyticsWidgetsWithDynamics: {
    method: get,
    path: `${apiPath}/analytics/widgets/with_dynamics`
  }
});

export default analyticsEndpoints;
