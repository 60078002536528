import { Avatar, Badge, Tooltip, Row } from 'antd';
import { mapColorToId } from 'core/utils/colors';
import truncateString from 'core/utils/truncateString';
import { get, isEqual, compact } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { variant } from 'styled-system';
import { getCurrentUser, getCurrentUserPermissions, getUserObject } from 'redux/selectors/users';
import { PERMISSIONS } from 'core/utils/constants';
import ninjaIcon from 'assets/images/ninja-icon.svg';
import Truncated from 'components/Truncated';
import SText from 'components/Standard/SText';
import SCol from 'components/Standard/SCol';
import { getUserName, getUserInitials } from './getUserName';
import { LEVEL_RANK_TO_ICON } from './LevelIcon';

const TruncatedCol = Truncated(SCol);
const TruncatedRow = Truncated(Row);

const UserPreview = ({
  user,
  size = 'small',
  userId,
  disabled = false,
  showAvatar,
  showUnit,
  showEmail,
  truncateSize,
  disabledInitials,
  link,
  hidden,
  fontSize = 14
}) => {
  const { t } = useTranslation();
  const levelsByIds = useSelector(state => state.levelsResource.byIds, isEqual);

  const shownUser = useSelector(state => getUserObject(state, { user, userId }), isEqual);
  const shownUserUnitName = useSelector(
    state => state.unitsResource.byIds[shownUser?.unitId]?.name
  );
  const shownUserEmail = shownUser?.email;

  const currentUserPermissions = useSelector(state => getCurrentUserPermissions(state) || []);
  const currentUserId = useSelector(state => getCurrentUser(state).id);

  // ! надо подумать как написать понятнее
  // * если спятано - проверить наличие права на просмотр, иначе покажи
  const isVisibleReviewerName = hidden
    ? currentUserPermissions.includes(PERMISSIONS.CAN_SEE_REVIEWER_NAME) || currentUserId === userId
    : true;

  if (!shownUser) return t('components.userPreview.userNotInstalled');

  const levelRank = get(levelsByIds, [shownUser.levelId, 'rank']);

  const initials = getUserInitials(shownUser, 2);
  const name = isVisibleReviewerName
    ? getUserName({ user: shownUser })
    : t('constants.hiddenNamePlaceholder');

  const avatarSrc = isVisibleReviewerName
    ? shownUser.avatarUrl || shownUser['avatar-url']
    : ninjaIcon;

  const LevelIcon = LEVEL_RANK_TO_ICON[levelRank];

  const getAdditionalInfo = () => {
    return compact([
      showUnit && (
        <SText key="showUnit" type="secondary" display="block" lineHeight="20px">
          {shownUserUnitName}
        </SText>
      ),
      showEmail && (
        <SText key="showEmail" type="secondary" display="block" lineHeight="20px">
          {shownUserEmail}
        </SText>
      )
    ]);
  };

  return (
    <Row type="flex" align="middle" wrap={false}>
      <SCol flex="none" display="flex">
        {showAvatar && (
          <Badge
            style={{ display: 'none' }}
            count={levelRank && isVisibleReviewerName ? <LevelIcon /> : null}
          >
            <Tooltip title={disabledInitials ? name : ''}>
              <CustomAvatar
                src={avatarSrc}
                size={size}
                style={{ backgroundColor: avatarSrc ? 'none' : mapColorToId(shownUser.id) }}
              >
                {initials}
              </CustomAvatar>
            </Tooltip>
          </Badge>
        )}
      </SCol>
      <TruncatedCol flex="auto">
        {disabled || !isVisibleReviewerName ? (
          <TruncatedRow style={{ width: 'inherit', display: 'block', fontSize }}>
            <TruncatedCol
              style={{
                width: 'inherit',
                height: (showUnit || showEmail) && isVisibleReviewerName ? 20 : 'inherit',
                lineHeight: (showUnit || showEmail) && isVisibleReviewerName ? '20px' : 'inherit'
              }}
            >
              {!disabledInitials && <SText lineHeight="20px">{name}</SText>}
            </TruncatedCol>
            {isVisibleReviewerName && (
              <TruncatedCol style={{ width: 'inherit' }}>{getAdditionalInfo()}</TruncatedCol>
            )}
          </TruncatedRow>
        ) : (
          <TruncatedRow style={{ width: 'inherit', display: 'block', fontSize }}>
            <TruncatedCol
              style={{
                width: 'inherit',
                height: (showUnit || showEmail) && isVisibleReviewerName ? 20 : 'inherit',
                lineHeight: (showUnit || showEmail) && isVisibleReviewerName ? '20px' : 'inherit'
              }}
            >
              {disabled || !isVisibleReviewerName ? (
                <SText lineHeight="20px">
                  {truncateSize ? truncateString(name, truncateSize) : name}
                </SText>
              ) : (
                <Tooltip title={name}>
                  <Link to={link || `/user/${userId || shownUser.id}/charts`} type="link">
                    {truncateSize ? truncateString(name, truncateSize) : name}
                  </Link>
                </Tooltip>
              )}
            </TruncatedCol>
            {(showUnit || showEmail) && isVisibleReviewerName && (
              <TruncatedCol style={{ width: 'inherit' }}>{getAdditionalInfo()}</TruncatedCol>
            )}
          </TruncatedRow>
        )}
      </TruncatedCol>
    </Row>
  );
};

export const CustomAvatar = styled(Avatar)`
  &.ant-avatar {
    ${variant({
      prop: 'size',
      variants: {
        extraSmall: {
          marginRight: '4px !important'
        },
        littleSmall: {
          marginRight: '6px !important'
        },
        small: {
          marginRight: '8px !important'
        },
        medium: {
          marginRight: '12px !important'
        },
        mediumLarge: {
          marginRight: '14px !important'
        },
        large: {
          marginRight: '16px !important'
        }
      }
    })}
    display: inline-flex;
    align-items: center;
    ${variant({
      prop: 'size',
      variants: {
        extraSmall: {
          width: 20,
          height: 20
        },
        littleSmall: {
          width: 24,
          height: 24
        },
        small: {
          width: 32,
          height: 32
        },
        medium: {
          width: 42,
          height: 42
        },
        mediumLarge: {
          width: 56,
          height: 56
        },
        large: {
          width: 80,
          height: 80
        }
      }
    })};
  }

  &.ant-avatar .ant-avatar-string {
    line-height: 29px !important;
    ${variant({
      prop: 'size',
      variants: {
        extraSmall: {
          fontSize: 8
        },
        littleSmall: {
          fontSize: 10
        },
        small: {
          fontSize: 12
        },
        medium: {
          fontSize: 16
        },
        mediumLarge: {
          fontSize: 20
        },
        large: {
          fontSize: 24
        }
      }
    })}
  }

  &.ant-avatar + .anticon {
    position: absolute;
    ${variant({
      prop: 'size',
      variants: {
        extraSmall: {
          top: '10px !important',
          left: '10px !important'
        },
        small: {
          top: '18px !important',
          left: '22px !important'
        },
        mediumLarge: {
          top: '41px !important',
          left: '41px !important'
        },
        large: {
          top: '64px !important',
          left: '64px !important'
        }
      }
    })}
  }
`;

export default UserPreview;
