import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: false,
  userIdToAnalytics: {}
};

export const userAnalyticsLoadStarted = createAction('userAnalytics/loadStarted');
export const userAnalyticsLoadFailed = createAction('userAnalytics/loadFailed');
export const userAnalyticsLoadSuccess = createAction('userAnalytics/loadSuccess');

export const userAnalyticsReducer = createReducer(defaultState, {
  [userAnalyticsLoadStarted]: state => ({ ...state, loading: true }),
  [userAnalyticsLoadSuccess]: (state, { payload }) => {
    const oldAnalytics = state.userIdToAnalytics[payload.userId] || {};
    const newAnalytics = { ...oldAnalytics, ...payload };
    return {
      ...state,
      loading: false,
      userIdToAnalytics: {
        ...state.userIdToAnalytics,
        [payload.userId]: newAnalytics
      }
    };
  },
  [userAnalyticsLoadFailed]: state => ({ ...state, loading: false })
});
