import createListReducer from 'core/redux/lists/listReducer';
import { communicationReviewsResource } from 'redux/resources/communicationReviews';

const defaultState = {
  page: {
    size: '10',
    number: '1'
  }
};

export const listParams = {
  name: 'communicationReviewsList',
  loader: communicationReviewsResource.operations.load,
  keepResults: false
};

const { actions, reducer, operations } = createListReducer({
  ...listParams,
  defaultState
});

const communicationReviewsListReducer = reducer;

export { actions, communicationReviewsListReducer, operations };
