import { LoadingOutlined } from '@ant-design/icons';
import { Typography, Alert } from 'antd';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { MAX_COUNT_WIDGET_CLIENT_INTERACTIONS } from 'core/utils/constants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isEmpty, omit } from 'lodash';
import { loadReviewsCount } from 'redux/ui/widgetSettingsModal/operations';

const ClientInteractionsMeta = () => {
  const { Text } = Typography;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { loading, count } = useSelector(state => state.uiWidgetSettingsModal.reviewsCount);
  const { filters, type } = useSelector(state => state.uiWidgetSettingsModal);

  useEffect(() => {
    if (!isEmpty(filters)) {
      dispatch(
        loadReviewsCount({
          options: {
            filters: {
              ...omit(filters, 'operatorsIds'),
              ...(filters?.operatorsIds && { operatorsIds: filters?.operatorsIds })
            }
          }
        })
      );
    }
  }, [filters]);

  const isVisibleSlowSortMessage =
    count > MAX_COUNT_WIDGET_CLIENT_INTERACTIONS.SLOW_SORT_COUNT &&
    count <= MAX_COUNT_WIDGET_CLIENT_INTERACTIONS.MAX_CLIENT_INTERACTIONS_COUNT &&
    type === 'table_reviews_with_scores';

  const isVisibleMaxCommunicationsCountMessage =
    count > MAX_COUNT_WIDGET_CLIENT_INTERACTIONS.SLOW_SORT_COUNT &&
    count > MAX_COUNT_WIDGET_CLIENT_INTERACTIONS.MAX_CLIENT_INTERACTIONS_COUNT &&
    type === 'table_reviews_with_scores';

  return (
    <SCol span={24}>
      <SRow type="flex" align="middle" justify="start">
        <SCol>
          {isVisibleSlowSortMessage && (
            <StyledAlert
              message={<Text>{t('dashboardPage.widgetMeta.longSortMessage')}</Text>}
              type="warning"
            />
          )}
          {isVisibleMaxCommunicationsCountMessage && (
            <Alert
              message={<Text>{t('dashboardPage.widgetMeta.maxItemsCount')}</Text>}
              type="error"
            />
          )}
        </SCol>
        <SCol marginTop="8px" key={loading}>
          <Text>{t('dashboardPage.widgetMeta.foundReviews')}</Text>
          {loading ? <LoadingOutlined style={{ color: '#0061D0' }} /> : <Text strong>{count}</Text>}
        </SCol>
      </SRow>
    </SCol>
  );
};

const StyledAlert = styled(Alert)`
  &.ant-alert-warning {
    border: 1px solid var(--orange_primary);
  }
`;

export default ClientInteractionsMeta;
