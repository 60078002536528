import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { get, isEqual } from 'lodash';
import HowToItUseButton from 'components/HowToItUseButton';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import { updateAutofailDashboardPageFilters } from 'redux/entities/dashboardPages/autofailsDashboardPage/operations';
import { CHECKLIST_DEFINITION_STATUS } from 'core/utils/constants';
import CreateNewReportButton from 'pages/DashboardPages/AutofailScoresPage/Components/CreateNewReportButton';
import { BaseFilter } from 'pages/DashboardPages/Components/BaseFilter';
import { getChecklistsDefinitionsByStatuses } from '../../../../redux/selectors/checklists';

const Filters = () => {
  const dispatch = useDispatch();
  const loading = useSelector(state => state.dashboardPages.loading);
  const checklistDefinitions = useSelector(
    state =>
      getChecklistsDefinitionsByStatuses(state, {
        statuses: [
          CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
          CHECKLIST_DEFINITION_STATUS.ARCHIVED.value
        ]
      }),
    isEqual
  );

  const dashboardPage = useSelector(
    state => get(state, 'dashboardPages.dashboardsByTypes.autofail_scores', {}),
    isEqual
  );
  const { filters, page } = dashboardPage;

  const onChange = updatedFilter => {
    dispatch(
      updateAutofailDashboardPageFilters({
        filters: {
          ...filters,
          ...updatedFilter
        },
        page: {
          number: page?.page,
          size: 5
        }
      })
    );
  };

  return (
    <SCard>
      <SRow type="flex" justify="space-between" flexWrap="nowrap">
        <SCol flex="auto">
          <BaseFilter
            onChange={onChange}
            filters={filters}
            loading={loading}
            checklistDefinitions={checklistDefinitions}
          />
        </SCol>
        <SCol flex="none">
          <HowToItUseButton videoLink="" docsLink="" />
        </SCol>
        <SCol flex="none">
          <CreateNewReportButton />
        </SCol>
      </SRow>
    </SCard>
  );
};

export default Filters;
