import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: true
};

export const setUiPriority = createAction('uiTaskPrioritySettings/setUiPriority');
export const updateUiPriority = createAction('uiTaskPrioritySettings/updateUiPriority');

export const uiTaskPrioritySettingsReducer = createReducer(defaultState, {
  [setUiPriority]: (state, { payload }) => ({
    ...state,
    ...payload,
    loading: false
  }),
  [updateUiPriority]: (state, { payload }) => ({
    ...state,
    [payload.type]: payload.value,
    loading: false
  })
});
