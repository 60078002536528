import { get, put, destroy, post } from 'redux-bees';

const questionsEndpoints = apiPath => ({
  createChecklistQuestion: { method: post, path: `${apiPath}/checklist/questions` },
  getChecklistQuestions: { method: get, path: `${apiPath}/checklist/questions` },
  updateChecklistQuestion: { method: put, path: `${apiPath}/checklist/questions/:id` },
  deleteChecklistQuestion: { method: destroy, path: `${apiPath}/checklist/questions/:id` }
});

export default questionsEndpoints;
