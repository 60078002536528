import React, { useState } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import SText from 'components/Standard/SText';
import { useDispatch, useSelector } from 'react-redux';
import RedesignWidgetTable from 'components/Dashboard/RedesignWidgetTable';
import { IconButton } from 'components/Buttons';
import { Activity, Columns, Download, Settings } from 'react-feather';
import { get, isEqual } from 'lodash';
import { getDatesArray, widgetPeriodSetter } from 'pages/DashboardPage/utils';
import { useTranslation } from 'react-i18next';
import { Empty, Tooltip } from 'antd';
import { StyledEmpty, StyledErrorMessage } from 'pages/DashboardPage/styled';
import camelcaseKeys from 'camelcase-keys';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import { calcPercent } from 'pages/DashboardPages/AutofailScoresPage/Components/Widgets/utils';
import SummaryRow from 'pages/DashboardPages/AutofailScoresPage/Components/Widgets/AutoFailHistoryReport/SummaryRow';
import {
  AUTO_FAIL_SCORES_COLUMNS,
  onTableChange,
  startExport,
  BACKEND_WIDGET_DATE_FORMAT
} from '../AutoFailCountsBy/utils';
import { prepareTableColumns, prepareTableData } from './prepareTableData';
import ChartLineColumnAnalytics from './prepareChartData';

// Динамика критичных ошибок
const AutoFailHistoryReport = ({
  changeViewMode,
  widgetData = {},
  setVisibleWidgetId,
  viewMode = WIDGET_VIEW_MODES.TABLE.value
}) => {
  const { t } = useTranslation();
  const [loadingExport, setLoadingExport] = useState();
  const dispatch = useDispatch();

  const autoFailsDashboard = useSelector(
    state => state.dashboardPages.dashboardsByTypes?.autofail_scores,
    isEqual
  );
  const dashboardPageFilters = useSelector(
    state => state.dashboardPages.dashboardsByTypes?.autofail_scores?.filters,
    isEqual
  );
  const checklistDefinitionsByIds = useSelector(state => state.checklistDefinitionsResource.byIds);
  const checklistDefinition = get(
    checklistDefinitionsByIds,
    dashboardPageFilters?.checklistDefinitionsIds,
    {}
  );

  const { id, name, page, type, filters } = widgetData;
  const loadingWidgetId = useSelector(state => state.dashboardPages.loadingWidgetId);
  const loadingTable = loadingWidgetId === id || loadingWidgetId === 'filters' || loadingExport;

  const [initialState, setInitialState] = useState(widgetData?.page);

  const widgetAnalytics = useSelector(
    state => get(state.dashboardAnalytics.byIds, id, {}),
    isEqual
  );

  const loading = useSelector(state => state.dashboardPages.loading);

  // TODO remove `otherData` when backend fixed it
  const { meta, metadata, total, ...otherData } = camelcaseKeys(widgetAnalytics);
  let data = otherData;

  const datesArray = getDatesArray({
    timeFrom: metadata?.time_from,
    timeTo: metadata?.time_to,
    isWeekendView: true,
    historyGroupingPeriod: dashboardPageFilters?.historyGroupingPeriod
  });

  datesArray.forEach(item => {
    const dateKey = item.format(BACKEND_WIDGET_DATE_FORMAT);
    const percentOfClientInteractions = calcPercent(
      data?.reviewsCountHistory?.[dateKey],
      data?.clientInteractionsCountHistory?.[dateKey]
    );
    const percentOfReviewsWithAnyTypeAutofailCount = calcPercent(
      data?.reviewsWithAnyTypeAutofailCount?.[dateKey],
      data?.reviewsCountHistory?.[dateKey]
    );

    data = {
      ...data,
      percentOfClientInteractions: {
        ...data.percentOfClientInteractions,
        ...{ [dateKey]: percentOfClientInteractions }
      },
      percentOfReviewsWithAnyTypeAutofailCount: {
        ...data.percentOfReviewsWithAnyTypeAutofailCount,
        ...{ [dateKey]: percentOfReviewsWithAnyTypeAutofailCount }
      }
    };
  });

  const tableData = prepareTableData({
    data,
    datesArray,
    columns: AUTO_FAIL_SCORES_COLUMNS,
    checklistDefinition
  });
  const tableColumns = prepareTableColumns({ columns: AUTO_FAIL_SCORES_COLUMNS, t });

  const renderDescription = () => {
    return (
      <>
        <StyledErrorMessage level={3} type="secondary">
          {t('dashboardPage.widget.checkFilters')}
        </StyledErrorMessage>
        <SText type="secondary">{t('dashboardPage.widget.emptyFiltersData')}</SText>
      </>
    );
  };

  const tableHeaderName = !name
    ? t('dashboardPages.autoFailScores.autoFailScoresAnalyticsWidgetTitle')
    : name;

  return (
    <SCol marginBottom={15} span={24}>
      <SCard bordered shadowed rounded="4px" bodyPadding="12px 20px 6px 20px">
        <SRow type="flex" justify="space-between" wrap={false} width="100%">
          <SCol flex="auto">
            <SRow width="100%">
              <Tooltip title={tableHeaderName}>
                <SText fontSize="24px" strong ellipsis>
                  {tableHeaderName}
                </SText>
              </Tooltip>
            </SRow>
            <SRow>
              <SText type="secondary" strong ellipsis>
                {widgetPeriodSetter({
                  widgetsFilters: get(autoFailsDashboard, 'filters', {}),
                  widgetType: 'auto_fail_history_report'
                })}
              </SText>
            </SRow>
          </SCol>
          <SCol marginTop="6px" flex="none">
            <IconButton
              button={{
                icon: <Download />,
                color: 'var(--black_4)',
                marginRight: '6px',
                onClick: () => {
                  startExport({
                    id,
                    meta,
                    type,
                    filters,
                    dispatch,
                    initialState,
                    setLoadingExport,
                    columns: tableColumns,
                    datesArray,
                    checklistDefinition
                  });
                }
              }}
            />
            <IconButton
              button={{
                icon: viewMode === WIDGET_VIEW_MODES.TABLE.value ? <Activity /> : <Columns />,
                color: 'var(--black_4)',
                onClick: () => {
                  changeViewMode(
                    viewMode !== WIDGET_VIEW_MODES.TABLE.value
                      ? WIDGET_VIEW_MODES.TABLE.value
                      : WIDGET_VIEW_MODES.CHART.value
                  );
                }
              }}
            />
            <IconButton
              button={{
                icon: <Settings />,
                color: 'var(--black_4)',
                onClick: () => setVisibleWidgetId(id)
              }}
            />
          </SCol>
        </SRow>

        <SRow>
          <SCol marginTop="10px" span={24}>
            {tableData?.length === 0 && (
              <SCol marginBottom="40px">
                <StyledEmpty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={renderDescription()}
                />
              </SCol>
            )}
            {tableData?.length !== 0 && viewMode === WIDGET_VIEW_MODES.TABLE.value && (
              <RedesignWidgetTable
                dataSource={widgetAnalytics?.data?.length === 0 ? [] : tableData}
                columns={loading || widgetAnalytics?.data?.length === 0 ? [] : tableColumns}
                loading={loadingTable}
                size="small"
                showSorterTooltip={false}
                scroll={{ x: 1500, y: 625 }}
                pagination={{
                  position: 'bottomLeft',
                  showSizeChanger: true,
                  total: tableData?.length || 0,
                  current: meta?.page || 1,
                  pageSize: page?.size + 1,
                  pageSizeOptions: ['10', '25', '50']
                }}
                onChange={(pagination, _, sorter) =>
                  onTableChange({
                    pagination,
                    sorter,
                    widgetId: id,
                    filters,
                    dispatch,
                    setInitialState,
                    tableColumns: AUTO_FAIL_SCORES_COLUMNS
                  })
                }
                summary={pageData => (
                  <SummaryRow
                    pageData={pageData}
                    total={camelcaseKeys(total || {})}
                    checklistDefinition={checklistDefinition}
                  />
                )}
              />
            )}
            {tableData?.length !== 0 && viewMode === WIDGET_VIEW_MODES.CHART.value && (
              <ChartLineColumnAnalytics
                dataSource={data}
                datesArray={datesArray}
                loading={loading}
              />
            )}
          </SCol>
        </SRow>
      </SCard>
    </SCol>
  );
};

export default AutoFailHistoryReport;
