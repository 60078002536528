import { isEmpty, isEqual, orderBy, pick, pickBy } from 'lodash';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser } from 'redux/selectors/users';
import {
  setQuestionIdToAppealComment,
  setQuestionIdToAppealObjection
} from 'redux/ui/checklistManager/reducer';
import moment from 'moment';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import UserPreview from 'components/UserPreview';
import DOMPurify from 'dompurify';
import SText from 'components/Standard/SText';
import SButton from 'components/Standard/SButton';
import Editor from 'components/Comments/Editor';
import Icon from 'components/Icon';
import { CornerUpLeft, Edit3 } from 'react-feather';
import UploadedFile from 'components/Comments/Editor/UploadedFile';
import { uploadedFilesResource } from 'redux/resources/uploadedFiles';
import { commentsResource } from 'redux/resources/comments';
import { message } from 'antd';
import { appellationCommentsResource } from 'redux/resources/appellationComments';
import { useTranslation } from 'react-i18next';
import { InlineCommentText } from 'components/Comments/InlineCommentText';
import { StyledButton } from 'components/Checklist/ChecklistManager/components/styled';
import QuestionAppealAnswer from './QuestionAppealAnswer';

const QuestionAppealComment = ({ appealObjection, appealComment, questionId, appealId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser, isEqual);
  const [replyComment, setReplyComment] = useState(undefined);
  const [appealCommentState, setAppealCommentState] = useState({ text: '', ...appealComment });


  const replies = useSelector(state => {
    if (appealCommentState?.id) {
      return orderBy(
        pickBy(state.appellationCommentsResource.byIds, { parentId: appealCommentState.id }),
        'createdAt'
      );
    }
    return [];
  }, isEqual);

  const onSaveAppealComment = async () => {
    if (appealId === 'new') {
      dispatch(
        setQuestionIdToAppealComment({
          questionId,
          comment: {
            ...appealCommentState,
            authorId: currentUser.id,
            updatedAt: moment().toISOString(),
            saved: true
          }
        })
      );
    } else {
      try {
        await dispatch(
          commentsResource.operations.updateById({
            ...pick(appealCommentState, ['id', 'text']),
            uploadedFilesIds: appealCommentState.uploadedFiles?.map(({ id }) => id)
          })
        );
        dispatch(
          setQuestionIdToAppealComment({
            questionId,
            comment: {
              ...appealCommentState,
              authorId: currentUser.id,
              updatedAt: moment().toISOString(),
              saved: true
            }
          })
        );
        message.success(t('messages.success.commentSaved'));
      } catch (error) {
        console.log(error);
      }
    }
  };

  const editAppealComment = () => {
    dispatch(
      setQuestionIdToAppealComment({ questionId, comment: { ...appealCommentState, saved: false } })
    );
  };

  const onCancelAppealComment = () => {
    if (isEmpty(appealComment)) {
      dispatch(setQuestionIdToAppealObjection({ questionId, objection: null }));
      dispatch(setQuestionIdToAppealComment({ questionId, comment: null }));
    } else {
      dispatch(
        setQuestionIdToAppealComment({
          questionId,
          comment: {
            ...appealComment,
            saved: true
          }
        })
      );
    }
  };

  const onDeleteFile = id => {
    dispatch(
      setQuestionIdToAppealComment({
        questionId,
        comment: {
          ...appealComment,
          uploadedFilesIds: appealComment?.uploadedFilesIds.filter(fileId => fileId !== id),
          uploadedFiles: appealComment?.uploadedFiles.filter(({ id: fileId }) => fileId !== id)
        }
      })
    );
    dispatch(uploadedFilesResource.operations.deleteById({ id }));
  };

  const sendReplyToAppealComment = async () => {
    try {
      const comment = {
        text: replyComment?.text,
        uploadedFilesIds: replyComment?.uploadedFiles?.map(({ id }) => id),
        appellationId: appealId,
        parentId: appealComment?.id,
        commentType: 'appellant_comment_reply'
      };
      await dispatch(appellationCommentsResource.operations.create(comment));
      setReplyComment(null);
      message.success(t('messages.success.answerSended'));
    } catch (error) {
      console.log(error);
    }
  };

  if (!appealObjection) return null;

  return (
    <SCol span={24} marginTop="4px">
      <SRow gutter={[0, 16]} style={{ marginBottom: '-8px' }} type="flex" justify="end">
        {appealCommentState?.saved ? (
          <SCol span={24}>
            <SCard bordered="1px solid #839df2" background="#eff3fe">
              <SRow type="flex" wrap={false}>
                <SCol flex="auto">
                  <SRow type="flex" wrap={false}>
                    <SCol marginRight="6px">
                      <UserPreview
                        userId={appealCommentState?.authorId}
                        size="extraSmall"
                        showAvatar
                        disabled
                      />
                    </SCol>
                    <SText
                      ellipsis
                      type="secondary"
                      title={moment(appealCommentState?.updatedAt).format('DD/MM/YYYY, HH:mm')}
                    >
                      {moment(appealCommentState?.updatedAt).format('DD/MM/YYYY, HH:mm')}
                    </SText>
                  </SRow>
                </SCol>
                {appealCommentState?.authorId === currentUser?.id ? (
                  <SCol flex="none" display="flex" justifyContent="flex-end">
                    <StyledButton
                      size="icon"
                      icon={<Icon icon={Edit3} size="16px" />}
                      color="var( --black_6)"
                      backgroundColor="transparent"
                      onClick={editAppealComment}
                    />
                  </SCol>
                ) : (
                  <StyledButton
                    size="icon"
                    icon={<Icon icon={CornerUpLeft} size="20px" />}
                    flex="none"
                    display="flex"
                    justifyContent="end"
                    onClick={() =>
                      setReplyComment({
                        parentId: appealCommentState?.id,
                        appelationId: appealId,
                        commentType: 'appellant_comment_reply'
                      })
                    }
                    color="var(--black_6)"
                    backgroundColor="transparent"
                  />
                )}
              </SRow>
              <SRow>
                <SCol span={24}>
                  <InlineCommentText
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(appealCommentState?.text, {
                        ALLOWED_ATTR: ['target', 'href']
                      })
                    }}
                  />
                </SCol>
                <SCol span={24}>
                  {appealCommentState?.uploadedFiles?.map(file => (
                    <UploadedFile
                      key={file.id}
                      uploadedFile={file}
                      allFiles={appealCommentState?.uploadedFiles}
                      onDelete={onDeleteFile}
                    />
                  ))}
                </SCol>
              </SRow>
            </SCard>
          </SCol>
        ) : (
          <SCol span={24}>
            <SCard
              width="100%"
              bordered="2px solid #C5D2FB"
              shadowed="inset 0px -1px 0px #597ef7, inset 0px 1px 0px #597ef7, inset -1px 0px 0px #597ef7, inset 1px 0px 0px #597ef7"
            >
              <Editor
                commentState={appealCommentState}
                setCommentState={setAppealCommentState}
                showTemplates={false}
                showFlags={false}
                showAttachments
                actionsComponent={
                  <>
                    <SButton onClick={onCancelAppealComment}>{t('general.cancel')}</SButton>
                    <SButton
                      type="primary"
                      onClick={onSaveAppealComment}
                      disabled={
                        isEmpty(appealCommentState?.uploadedFiles)
                          ? isEmpty(appealCommentState?.text)
                          : false
                      }
                      className="BraftEditor-actions"
                    >
                      {t('general.send')}
                    </SButton>
                  </>
                }
              />
            </SCard>
          </SCol>
        )}

        {!isEmpty(replyComment) && (
          <SCol span={24}>
            <SCard
              width="100%"
              bordered="2px solid #C5D2FB"
              shadowed="inset 0px -1px 0px #597ef7, inset 0px 1px 0px #597ef7, inset -1px 0px 0px #597ef7, inset 1px 0px 0px #597ef7"
            >
              <Editor
                commentState={replyComment}
                setCommentState={setReplyComment}
                showTemplates={false}
                showFlags={false}
                showAttachments
                actionsComponent={
                  <>
                    <SButton key="cancel" onClick={() => setReplyComment(null)}>
                      {t('general.cancel')}
                    </SButton>
                    <SButton
                      key="send"
                      type="primary"
                      onClick={sendReplyToAppealComment}
                      showLoad
                      disabled={
                        isEmpty(replyComment?.uploadedFiles) ? isEmpty(replyComment?.text) : false
                      }
                      className="BraftEditor-actions"
                    >
                      {t('general.send')}
                    </SButton>
                  </>
                }
              />
            </SCard>
          </SCol>
        )}

        {replies?.map(comment => (
          <QuestionAppealAnswer
            key={comment.id}
            comment={comment}
            appealComment={appealCommentState}
            appealId={appealId}
            setReplyComment={setReplyComment}
            onDeleteFile={onDeleteFile}
          />
        ))}
      </SRow>
    </SCol>
  );
};

export default QuestionAppealComment;
