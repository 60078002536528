import api from 'core/api';
import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';
import decamelizeKeysDeep from 'decamelize-keys-deep';

export const appellationsResource = createReduxResource({
  name: 'appellations',
  customOperations: {
    processObjections: {
      callEndpoint: ({ id, ...options }) => api.processObjections({ id }, options),
      parseOptions: true,
      parseOptionsFunction: decamelizeKeysDeep,
      onSuccess: ({ result, dispatch }) => {
        updateResource({ dispatch, type: 'appellations', resource: result });
        return result;
      }
    },
    updateWatchers: {
      callEndpoint: ({ id, ...options }) => api.updateWatchers({ id }, options),
      parseOptions: true,
      parseOptionsFunction: decamelizeKeysDeep,
      onSuccess: ({ result, dispatch, requestOptions, getState }) => {
        const state = getState();
        const appeal = state.appellationsResource.byIds[requestOptions.id];
        if (appeal)
          updateResource({
            dispatch,
            type: 'appellations',
            resource: { ...appeal, watchersIds: requestOptions.watchersIds }
          });
        return result;
      }
    },
    changeStatus: {
      callEndpoint: ({ id, ...options }) => api.changeAppellationStatus({ id }, options),
      parseOptions: true,
      parseOptionsFunction: decamelizeKeysDeep,
      onSuccess: ({ result, dispatch, requestOptions, getState }) => {
        const state = getState();
        const appeal = state.appellationsResource.byIds[requestOptions.id];
        if (appeal)
          updateResource({
            dispatch,
            type: 'appellations',
            resource: { ...appeal, status: requestOptions.status }
          });
        return result;
      }
    }
  }
});
