import createListReducer from 'core/redux/lists/listReducer';
import { workPlanTaskConfigurationsResource } from 'redux/resources/workPlanTaskConfigurations';

const workPlanTasksParams = {
  name: 'workPlanTasks',
  defaultState: {},
  rebuildQuery: true,
  loader: workPlanTaskConfigurationsResource.operations.getConfigurationTasksById
};

const { actions, reducer, operations } = createListReducer(workPlanTasksParams);

const workPlanTasksListReducer = reducer;

export { actions, workPlanTasksListReducer, operations };
