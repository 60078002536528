import { destroy, get, put, post } from 'redux-bees';

const dashboardEndpoints = apiPath => ({
  getDashboards: { method: get, path: `${apiPath}/dashboards` },
  getDashboard: { method: get, path: `${apiPath}/dashboards/:id` },
  createDashboard: { method: post, path: `${apiPath}/dashboards` },
  updateDashboard: { method: put, path: `${apiPath}/dashboards/:id` },
  deleteDashboard: { method: destroy, path: `${apiPath}/dashboards/:id` },
  copyDashboard: { method: post, path: `${apiPath}/dashboards/:id/duplicate` }
});

export default dashboardEndpoints;
