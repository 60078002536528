import camelcaseKeysDeep from 'camelcase-keys-deep';
import { buildResourceApiRequest } from 'core/api';
import { processJsonApiResponse } from 'core/jsonapi';
import parseOptionsForApi from 'core/utils/parseOptionsForApi';
import { get, isFunction } from 'lodash';
import { ACTIONS } from './constants';
import loadIncludedObjectsToRedux from './loadIncludedObjects';

const createCustomOperations = ({ resource, customOperations, actions }) => {
  const operations = {};
  Object.keys(customOperations).forEach(operationName => {
    //  * extracting operation props
    const { callEndpoint, onSuccess, action, parseOptions = true } = customOperations[
      operationName
    ];
    const parseOptionsFunction = get(
      customOperations,
      `${operationName}.parseOptionsFunction`,
      parseOptionsForApi
    );

    // * to send back same operations mapping
    operations[operationName] = requestOptions => {
      // * if api endpoint is not passed - trying to find one in core api
      const makeRequest = isFunction(callEndpoint)
        ? callEndpoint
        : buildResourceApiRequest({ resource, ...(action || ACTIONS[operationName]) });
      // * building operation
      return async (dispatch, getState) => {
        dispatch({ type: `${resource}/${operationName}Started`, payload: requestOptions });
        try {
          // debugger;
          const response = await makeRequest(
            parseOptions ? parseOptionsFunction(requestOptions) : requestOptions
          );
          const result = response.body.data
            ? processJsonApiResponse(response.body.data)
            : camelcaseKeysDeep(response?.body || {});
          response.body.included &&
            loadIncludedObjectsToRedux({ objects: response.body.included, dispatch });
          dispatch({ type: `${resource}/${operationName}Succeed`, payload: result });
          return onSuccess({ result, dispatch, getState, requestOptions, response, actions });
        } catch (error) {
          console.log(error);
          dispatch({ type: `${resource}/${operationName}Failed`, payload: error });
        }
      };
    };
  });
  return operations;
};

export default createCustomOperations;
