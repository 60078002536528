import { isEmpty, get, reduce } from 'lodash';
import { usersResource } from 'redux/resources/users';
import { setUsers } from './reducer';

export const loadUsers = ({ ...props }) => async (dispatch, getState) => {
  await dispatch(usersResource.operations.load(props));

  const { usersResource: users, unitsResource: units, rolesResource: roles } = getState();

  const fullUsers = reduce(
    users.byIds,
    (users, user) => {
      const role = get(roles, get(user, 'roleId', ''), null);
      const unit = get(units.byIds, get(user, 'unitId', ''), '');
      return {
        ...users,
        [user.id]: { ...user, role, unit, active: isEmpty(user.collisionsOnCreate) }
      };
    },
    {}
  );

  dispatch(setUsers(fullUsers));
};
