import createListReducer from 'core/redux/lists/listReducer';
import { usersResource } from 'redux/resources/users';

const defaultState = {
  defaultState: {
    search: undefined
  },

  page: {
    size: '10',
    number: '1'
  },
  filters: {
    withInactive: 'true'
  }
};

const additionalReducer = {
  setSearch: (state, { payload }) => ({
    ...state,
    search: payload,
    page: { ...state.page, number: '1' }
  })
};

const { actions, reducer, operations } = createListReducer({
  name: 'users',
  loader: usersResource.operations.load,
  defaultState,
  additionalReducer,
  rebuildQuery: true
});

const usersListReducer = reducer;

export { actions, usersListReducer, operations };
