import { Button, Checkbox, Col, Form, Input, message, Row, Select, Typography } from 'antd';
import SModal from 'components/Standard/SModal';
import { getAllChildNodesIdsByParentId } from 'core/utils/getAllChildrensIdsByParentId';
import { usePrevious } from 'core/utils/hooks';
import { find, isEmpty, isEqual, isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { unitsResource } from 'redux/resources/units';
import { usersResource } from 'redux/resources/users';
import { resetEmployeeModal } from 'redux/ui/employeeModal/reducer';
import { WidgetTitle } from 'components/WidgetTitle';
import { selectSearch } from 'core/utils/selectSearch';
import { isEmail } from 'core/utils/isEmail';

const { Option } = Select;

const EmployeeModal = ({
  isVisible = false,
  createUser,
  updateUser,
  inviteUser,
  users,
  units,
  roles,
  rolesByIds,
  loadUnits,
  resetEmployeeModal,
  user = {},
  isEditMode,
  onCreateUser = () => {},
  updateByIdStarted,
  createStarted
}) => {
  const [managedUnits, setManagedUnits] = useState([]);
  const [isSelectedRootUnit, setSelectedRootUnit] = useState(false);
  const [isSelectedAdminRole, setSelectedAdminRole] = useState(false);
  const [isDisabledSaveButton, setDisabledSaveButton] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    loadUnits();
  }, []);

  const {
    id,
    firstName,
    lastName,
    email,
    unitId,
    managedUnitsIds,
    roleId,
    integrationUid,
    collisionsOnCreate,
    originIntegrationId
  } = user;

  const getManagedUnits = ({ unitId }) => {
    if (!unitId) return;
    const allChildrensIds = getAllChildNodesIdsByParentId(unitId, units);
    const managedUnitsIds = units.filter(
      unit => !allChildrensIds.includes(unit.id) && unit.id !== unitId
    );

    setManagedUnits(managedUnitsIds);
  };

  const initialValues = {
    lastName,
    firstName,
    email: isEmpty(email) ? (collisionsOnCreate || {})?.email : email,
    roleId,
    integrationUid,
    unitId,
    managedUnitsIds,
    invite: true
  };

  // * блокировать поле managedUnitsIds если не выбран отдел
  // * блокировать поле managedUnitsIds если не выбрана роль
  // * блокировать поле managedUnitsIds если выбран головной отдел
  const idDisabledManagedUnitsField =
    isEmpty(form.getFieldValue('unitId')) ||
    isEmpty(form.getFieldValue('roleId')) ||
    isSelectedRootUnit;

  const onValuesChange = (changedValues, allValues) => {
    // * если впервые был установлен unitId заполнить поле managedUnitsIds
    if (Object.keys(changedValues).includes('unitId') && isEmpty(allValues.managedUnitsIds)) {
      getManagedUnits({ unitId: changedValues.unitId });
    }

    // * если был установлен новый unitId обнулять managedUnits
    if (Object.keys(changedValues).includes('unitId') && !isEmpty(allValues.managedUnitsIds)) {
      setManagedUnits([]);
      form.setFieldsValue({ managedUnitsIds: [] });
      getManagedUnits({ unitId: changedValues.unitId });
    }

    // * если был выбран админ, автоматически устанаваливать головной отдел и очищать поле managedUnitsIds
    if (
      Object.keys(changedValues).includes('roleId') &&
      rolesByIds[changedValues.roleId]?.isAdmin
    ) {
      form.setFieldsValue({ unitId: find(units, { parentId: null })?.id });
      form.setFieldsValue({ managedUnitsIds: [] });
    }

    // * устанавливать блок кнопки "сохранить" если не заполнено одно из обязательных полей
    setDisabledSaveButton(
      isEmpty(form.getFieldValue('lastName')) ||
        isEmpty(form.getFieldValue('firstName')) ||
        isEmpty(form.getFieldValue('email')) ||
        !isEmail(form.getFieldValue('email')) ||
        isEmpty(form.getFieldValue('roleId')) ||
        isEmpty(form.getFieldValue('unitId'))
    );

    setSelectedAdminRole(rolesByIds[form.getFieldValue('roleId')]?.isAdmin);
    setSelectedRootUnit(form.getFieldValue('unitId') === find(units, { parentId: null })?.id);
  };

  const prevInitialValues = usePrevious(initialValues);

  useEffect(() => {
    if (isVisible) {
      getManagedUnits({ unitId: initialValues.unitId });
      setSelectedAdminRole(rolesByIds[initialValues.roleId]?.isAdmin);
      setSelectedRootUnit(initialValues?.unitId === find(units, { parentId: null })?.id);
      setDisabledSaveButton(
        isEmpty(initialValues?.lastName) ||
          isEmpty(initialValues?.firstName) ||
          isEmpty(initialValues?.email) ||
          isEmpty(initialValues?.roleId) ||
          isEmpty(initialValues?.unitId)
      );
    }
  }, [isVisible]);

  useEffect(() => {
    if (!isEqual(prevInitialValues, initialValues)) {
      form.setFieldsValue(initialValues);
    }
  }, [initialValues, prevInitialValues, isVisible]);

  const { t } = useTranslation();

  const closeModal = () => {
    form.resetFields();
    resetEmployeeModal();
    setManagedUnits([]);
    setSelectedRootUnit(false);
    setSelectedAdminRole(false);
  };

  const createNewEmployee = async ({ values }) => {
    const {
      firstName,
      lastName,
      email,
      roleId,
      unitId,
      managedUnitsIds,
      integrationUid,
      invite: shouldInvite
    } = values;

    const createdUser = await createUser({
      firstName,
      lastName,
      email,
      integrationUid,
      unitId,
      managedUnitsIds,
      roleId,
      active: true
    });

    if (createdUser) {
      shouldInvite && inviteUser({ id: createdUser.id });
      onCreateUser();
      closeModal();
      message.success(
        `${t(
          'organizationStructure.tabs.employees.modal.messages.employee'
        )} ${lastName} ${firstName} ${t(
          'organizationStructure.tabs.employees.modal.messages.created'
        )}`
      );
    }
  };

  const updateEmployee = async ({ values }) => {
    const user = find(users, ['id', id]);
    const { firstName, lastName, roleId, unitId, managedUnitsIds, integrationUid, email } = values;

    const objectToUpdate = {
      id,
      firstName,
      lastName,
      roleId,
      unitId,
      managedUnitsIds
    };

    if (integrationUid !== user.integrationUid) {
      objectToUpdate.integrationUid = integrationUid;
    }

    if (email !== user.email) {
      objectToUpdate.email = email;
    }

    const updatedUser = await updateUser({
      ...objectToUpdate
    });

    if (updatedUser) {
      closeModal();

      message.success(
        `${t(
          'organizationStructure.tabs.employees.modal.messages.employee'
        )} ${lastName} ${firstName} ${t(
          'organizationStructure.tabs.employees.modal.messages.updated'
        )}`
      );
    }
  };

  const { Text } = Typography;

  const handleSubmit = values => {
    isEditMode ? updateEmployee({ values }) : createNewEmployee({ values });
  };

  return (
    <SModal
      title={<WidgetTitle>{t('organizationStructure.tabs.employees.modal.title')}</WidgetTitle>}
      centered
      visible={isVisible}
      onCancel={closeModal}
      confirmLoading={updateByIdStarted}
      footer={null}
      destroyOnClose
      size="small"
    >
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        scrollToFirstError
        form={form}
        initialValues={initialValues}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          label={
            <Text strong>
              {t('organizationStructure.tabs.employees.modal.form.fields.lastName')}
            </Text>
          }
          name="lastName"
          rules={[
            {
              required: true,
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterLastName'),
              whitespace: true
            }
          ]}
        >
          <Input
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.lastNamePlaceholder'
            )}
          />
        </Form.Item>

        <Form.Item
          label={
            <Text strong>
              {t('organizationStructure.tabs.employees.modal.form.fields.firstName')}
            </Text>
          }
          name="firstName"
          rules={[
            {
              required: true,
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterFirstName'),
              whitespace: true
            }
          ]}
        >
          <Input
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.firstNamePlaceholder'
            )}
          />
        </Form.Item>

        <Form.Item
          label={
            <Text strong>{t('organizationStructure.tabs.employees.modal.form.fields.email')}</Text>
          }
          name="email"
          rules={[
            {
              required: true,
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterEmail')
            },
            {
              type: 'email',
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterValidEmail')
            }
          ]}
        >
          <Input
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.emailPlaceHolder'
            )}
          />
        </Form.Item>

        <Form.Item
          label={
            <Text strong>
              {t('organizationStructure.tabs.employees.modal.form.fields.integrationId')}
            </Text>
          }
          name="integrationUid"
        >
          <Input
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.integrationIdPlaceholder'
            )}
            disabled={isEditMode && !isNil(originIntegrationId)}
          />
        </Form.Item>

        <Form.Item
          label={
            <Text strong>{t('organizationStructure.tabs.employees.modal.form.fields.role')}</Text>
          }
          name="roleId"
          rules={[
            {
              required: true,
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterRole')
            }
          ]}
        >
          <Select
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.rolePlaceholder'
            )}
            style={{ width: '100%' }}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {roles.map(role => (
              <Option value={role.id} key={role.id}>
                {role.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <Text strong>{t('organizationStructure.tabs.employees.modal.form.fields.unit')}</Text>
          }
          rules={[
            {
              required: true,
              message: t('organizationStructure.tabs.employees.modal.form.messages.enterUnit')
            }
          ]}
          name="unitId"
          help={
            isSelectedRootUnit && isSelectedAdminRole
              ? t('organizationStructure.tabs.employees.modal.form.messages.onlyRootUnitForAdmin')
              : null
          }
        >
          <Select
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.unitPlaceHolder'
            )}
            style={{ width: '100%' }}
            disabled={isSelectedRootUnit && isSelectedAdminRole}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
          >
            {units.map(unit => (
              <Option value={unit.id} key={unit.id}>
                {unit.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          label={
            <Text strong>
              {t('organizationStructure.tabs.employees.modal.form.fields.managedUnits')}
            </Text>
          }
          name="managedUnitsIds"
          help={
            isSelectedRootUnit
              ? t('organizationStructure.tabs.employees.modal.form.messages.allUnitsAreVisible')
              : null
          }
        >
          <Select
            placeholder={t(
              'organizationStructure.tabs.employees.modal.form.fields.managedUnitsPlaceholder'
            )}
            style={{ width: '100%' }}
            mode="multiple"
            disabled={idDisabledManagedUnitsField}
            allowClear
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              selectSearch({ input, option, searchProp: 'children' })
            }
            maxTagCount={0}
            maxTagPlaceholder={selectedKeys =>
              `${t('clientInteractionsPage.tableFilters.tableGeneralFilters.form.units')} ${
                selectedKeys.length
              }`
            }
          >
            {managedUnits.map(unit => (
              <Option value={unit.id} key={unit.id}>
                {unit.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {!isEditMode && (
          <Form.Item
            name="invite"
            valuePropName="checked"
            rules={[
              {
                transform: value => value || undefined,
                type: 'boolean'
              }
            ]}
          >
            <Checkbox>
              <Text strong>
                {t('organizationStructure.tabs.employees.modal.buttons.sendInvite')}
              </Text>
            </Checkbox>
          </Form.Item>
        )}

        <Row type="flex" justify="end" gutter={[16, 16]} style={{ margin: '-8px' }}>
          <Col>
            <Form.Item style={{ marginBottom: 0 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={updateByIdStarted || createStarted}
                disabled={isDisabledSaveButton}
              >
                {t('organizationStructure.tabs.employees.modal.buttons.save')}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </SModal>
  );
};

const mapStateToProps = state => {
  return {
    editableUserData: state.uiEmployeeModal,
    user: state.usersResource.byIds[state.uiEmployeeModal.userId],
    isEditMode: state.uiEmployeeModal.isEditMode,
    isVisible: state.uiEmployeeModal.isVisibleEmployeeModal,
    users: Object.values(state.usersResource.byIds),
    units: Object.values(state.unitsResource.byIds),
    roles: Object.values(state.rolesResource.byIds),
    rolesByIds: state.rolesResource.byIds,
    updateByIdStarted: state.usersResource.updateByIdStarted,
    createStarted: state.usersResource.createStarted
  };
};

const mapDispatchToProps = {
  createUser: usersResource.operations.create,
  updateUser: usersResource.operations.updateById,
  loadUnits: unitsResource.operations.load,
  inviteUser: usersResource.operations.inviteById,
  resetEmployeeModal
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeModal);
