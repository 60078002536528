import { Col } from 'antd';
import React from 'react';
import SRow from 'components/Standard/SRow';
import CombinedChart from './Components/CombinedChart';
import UsersTable from './Components/UsersTable';
import WidgetCardDivider from '../../../Components/WidgetCardDivider';

const ChartView = ({ widgetId, chartData, selectedUserId }) => {
  return (
    <SRow type="flex" justify="space-between">
      <Col xs={{ span: 24 }} md={{ span: 13 }}>
        <CombinedChart chartData={chartData.chart} selectedUserId={selectedUserId} />
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 1 }}>
        <WidgetCardDivider />
      </Col>
      <Col xs={{ span: 24 }} md={{ span: 10 }}>
        <UsersTable
          tableData={chartData.table}
          widgetId={widgetId}
          selectedUserId={selectedUserId}
        />
      </Col>
    </SRow>
  );
};

export default ChartView;
