import { get, put } from 'redux-bees';

const taskDefinitionsEndpoints = apiPath => ({
  getTaskPriority: {
    method: get,
    path: `${apiPath}/organization/settings/task_completion_period_by_priority_in_hours`
  },
  updateTaskPriority: {
    method: put,
    path: `${apiPath}/organization/settings/task_completion_period_by_priority_in_hours`
  }
});

export default taskDefinitionsEndpoints;
