import React from 'react';
import { Col, Form } from 'antd';
import SInput from 'components/Standard/SInput';

const MysqlFields = ({ canEdit, editing }) => {
  return (
    <>
      <Col>
        <Form.Item
          name="mysqlHost"
          label="Mysql Host"
          rules={[
            {
              required: true
            }
          ]}
        >
          <SInput disabled={canEdit ? !editing : false} size="large" style={{ width: '304px' }} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          name="mysqlPort"
          label="Mysql Port"
          rules={[
            {
              required: true
            }
          ]}
        >
          <SInput disabled={canEdit ? !editing : false} size="large" style={{ width: '304px' }} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          name="mysqlUsername"
          label="Mysql User"
          rules={[
            {
              required: true
            }
          ]}
        >
          <SInput disabled={canEdit ? !editing : false} size="large" style={{ width: '304px' }} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          name="mysqlPassword"
          label="Mysql Password"
          rules={[
            {
              required: true
            }
          ]}
        >
          <SInput disabled={canEdit ? !editing : false} size="large" style={{ width: '304px' }} />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          name="mysqlDatabase"
          label="Mysql Database"
          rules={[
            {
              required: true
            }
          ]}
        >
          <SInput disabled={canEdit ? !editing : false} size="large" style={{ width: '304px' }} />
        </Form.Item>
      </Col>
    </>
  );
};

export default MysqlFields;
