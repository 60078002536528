import { get, put, post, destroy } from 'redux-bees';

const autofailDashboardPageEndpoints = apiPath => ({
  getAutofailScoresDashboard: {
    method: get,
    path: `${apiPath}/dashboards/pages/autofail_scores`
  },
  getAutofailDashboardPart: {
    method: get,
    path: `${apiPath}/dashboards/pages/autofail_scores/widgets`
  },
  getAutofailScoresDashboardAnalytics: {
    method: get,
    path: `${apiPath}/analytics/dashboards/pages/autofail_scores/widgets`
  },
  updateAutofailDashboard: {
    method: put,
    path: `${apiPath}/dashboards/pages/autofail_scores`
  },
  createAutofailScoresPageWidget: {
    method: post,
    path: `${apiPath}/dashboards/pages/autofail_scores/widgets`
  },
  deleteAutofailScoresPageWidgetById: {
    method: destroy,
    path: `${apiPath}/dashboards/pages/autofail_scores/widgets/:id`
  }
});

export default autofailDashboardPageEndpoints;
