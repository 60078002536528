import { Table } from 'antd';
import styled from 'styled-components';

const WidgetTable = styled(Table)`
  &.ant-table-wrapper .ant-table {
    border: none !important;
  }
  &.ant-table-wrapper .ant-table .ant-table-container {
    border: none !important;
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-header table {
    border: none !important;
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-header tr th {
    border-top: 1px solid var(--gray_4);
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-header .ant-table-cell-scrollbar {
    background-color: white;
    border: none;
    outline: 1px solid var(--gray_4);
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-body table {
    border: none !important;
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-body .ant-table-cell-fix-left {
    border-left: 1px solid var(--gray_4);
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-header .ant-table-cell-fix-left {
    border-left: 1px solid var(--gray_4);
    border-radius: 0px;
  }

  &.ant-table-wrapper .ant-table .ant-table-thead .ant-table-column-sorters > span:first-child {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default WidgetTable;
