import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { Helmet } from 'react-helmet';
import {
  deleteUnitDashboardPageWidget,
  loadUnitsDashboard
} from 'redux/entities/dashboardPages/unitDashboardPage/operations';
import LoadingDashboard from 'pages/DashboardPage/Components/LoadingDashboard';
import { concat, get, isEqual, isString, orderBy } from 'lodash';
import { Empty, Pagination } from 'antd';
import ReportSettingsModal from 'components/ReportSettingsModal';
import SText from 'components/Standard/SText';
import SCard from 'components/Standard/SCard';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import WarningIcon from 'assets/images/warning-icon.svg';
import { onAdd } from './Components/Widgets/utils';
import Filters from './Components/Filters';
import UnitsAnalytics from './Components/Widgets/UnitsAnalytics';
import { StyledEmpty, StyledErrorMessage } from '../../DashboardPage/styled';
import { hasConflictFilters } from '../../DashboardPage/utils';
import { unitsResource } from '../../../redux/resources/units';

const Units = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [visibleWidgetId, setVisibleWidgetId] = useState();
  const dashboardPage = useSelector(
    state => get(state, 'dashboardPages.dashboardsByTypes.scores_by_units', {}),
    isEqual
  );
  const checklists = useSelector(state => getChecklistsDefinitionsByIds(state));
  const arrayOfChecklists = Object.keys(checklists);

  const { page } = dashboardPage;

  useEffect(() => {
    dispatch(
      unitsResource.operations.load({
        pagination: false
      })
    );
    dispatch(
      loadUnitsDashboard({
        page: {
          number: 1,
          size: 5
        }
      })
    );
  }, []);

  const unitDashboardWidgetsIds = useSelector(
    state => get(state, 'dashboardPages.dashboardsByTypes.scores_by_units.widgetsIds', []),
    isEqual
  );

  const widgetsByIds = useSelector(state => state.dashboardWidgetsResource.byIds, isEqual);

  const orderedWidgets = orderBy(
    unitDashboardWidgetsIds.map(widgetId => widgetsByIds[widgetId]),
    'createdAt',
    'desc'
  );

  const loading = useSelector(state => state.dashboardPages.loading);

  const dashboardChecklistsFilters = get(dashboardPage?.filters, 'checklistDefinitionsIds', []);
  const widgetChecklistsIds = isString(dashboardChecklistsFilters)
    ? concat([], dashboardChecklistsFilters)
    : [...dashboardChecklistsFilters];

  const hasConflicts = hasConflictFilters({
    currentFilters: widgetChecklistsIds,
    availableReduxFilters: arrayOfChecklists
  });

  const renderDescription = () => {
    if (hasConflicts) {
      return (
        <>
          <SText color="#333333" fontSize="21px" fontWeight="600" style={{ lineHeight: '25px' }}>
            {t('dashboardPages.conflicts.unitsAnalyticsPage.checklistsConflict.title')}
          </SText>
          <SRow align="center" marginTop="10px">
            <SText maxWidth="396px" type="secondary" style={{ lineHeight: '20px' }}>
              {t('dashboardPages.conflicts.unitsAnalyticsPage.checklistsConflict.description1')}
            </SText>
          </SRow>
          <SRow align="center" marginTop="10px">
            <SText maxWidth="396px" type="secondary" style={{ lineHeight: '20px' }}>
              {t('dashboardPages.conflicts.unitsAnalyticsPage.checklistsConflict.description2')}
            </SText>
          </SRow>
        </>
      );
    }
    return (
      <>
        <StyledErrorMessage level={3} type="secondary">
          {t('dashboardPages.noCreatedReports')}
        </StyledErrorMessage>
        <SText type="secondary">{t('dashboardPages.clickAddReportButton')}</SText>
      </>
    );
  };

  const onPaginationChange = pageNumber => {
    dispatch(
      loadUnitsDashboard({
        page: {
          number: pageNumber,
          size: 5
        }
      })
    );
  };

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.unitAnalyticsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <Filters checklistDefinitionsSelectAllowAll hasConflicts={hasConflicts} />
      </SCol>
      <SCol span={24} padding="16px">
        {loading && <LoadingDashboard />}
        {!loading && hasConflicts && (
          <SCard bordered shadowed bodyPadding="44px" headPadding="25px">
            <StyledEmpty
              image={<img src={WarningIcon} alt="warning" width="70px" />}
              description={renderDescription()}
            />
          </SCard>
        )}
        {!loading && orderedWidgets.length > 0 && !hasConflicts && (
          <>
            {orderedWidgets.map(widgetData => (
              <UnitsAnalytics
                key={widgetData.id}
                widgetData={widgetData}
                setVisibleWidgetId={setVisibleWidgetId}
              />
            ))}
            <SRow type="flex" justify="end" style={{ marginTop: '20px' }} gutter={[0, 16]}>
              <Pagination
                size="small"
                current={page?.page}
                total={page?.totalCount}
                pageSize={5}
                onChange={onPaginationChange}
              />
            </SRow>
          </>
        )}
        {!loading && orderedWidgets.length === 0 && (
          <SCard bordered shadowed bodyPadding="50px" headPadding="50px">
            <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description={renderDescription()} />
          </SCard>
        )}
      </SCol>
      <ReportSettingsModal
        id={visibleWidgetId}
        setVisibleFilterModal={setVisibleWidgetId}
        onCancel={() => setVisibleWidgetId(false)}
        visible={visibleWidgetId}
        onAdd={onAdd}
        deleteDashboardPageWidget={deleteUnitDashboardPageWidget}
      />
    </SRow>
  );
};
export default Units;
