import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { CALIBRATION_SESSIONS_STATUS } from 'core/utils/constants';

const CalibrationSessionStatusTag = ({ status = 'pending' }) => {
  const { t } = useTranslation();
  if (!status) {
    return <Tag>{t('components.calibrationSessionStatusTag.unistalledTag')}</Tag>;
  }

  const { color, text } = CALIBRATION_SESSIONS_STATUS[status.toUpperCase()];
  return (
    <Tag color={color} style={{ margin: 0 }}>
      {t(text)}
    </Tag>
  );
};

export default CalibrationSessionStatusTag;
