import { createAction, createReducer } from '@reduxjs/toolkit';
import { sortBy } from 'lodash';

const defaultState = {
  levelsRoleId: null,
  roleIdToLevels: {}
};

export const setLevelsRoleId = createAction('uiLevelsPage/setLevelsRoleId');
export const setRoleIdToLevels = createAction('uiLevelsPage/setRoleIdToLevels');
export const addLevel = createAction('uiLevelsPage/addLevel');
export const updateLevel = createAction('uiLevelsPage/updateLevel');
export const deleteLevel = createAction('uiLevelsPage/deleteLevel');

export const levelsPageReducer = createReducer(defaultState, {
  [setLevelsRoleId]: (state, { payload }) => ({ ...state, levelsRoleId: payload }),
  [setRoleIdToLevels]: (state, { payload }) => ({ ...state, roleIdToLevels: payload }),
  [addLevel]: (state, { payload }) => ({
    ...state,
    roleIdToLevels: {
      ...state.roleIdToLevels,
      [payload?.roleId]: {
        ...(state.roleIdToLevels[payload?.roleId] || {}),
        [payload?.id]: payload
      }
    }
  }),
  [updateLevel]: (state, { payload }) => ({
    ...state,
    roleIdToLevels: {
      ...state.roleIdToLevels,
      [payload.roleId]: {
        ...(state.roleIdToLevels[payload.roleId] || {}),
        [payload.id]: {
          ...(state.roleIdToLevels[payload.roleId][payload.id] || {}),
          ...payload
        }
      }
    }
  }),
  [deleteLevel]: (state, { payload }) => {
    const roleLevels = state.roleIdToLevels[payload.roleId];

    // recalc ranks
    const newLevels = sortBy(Object.values({ ...roleLevels }), 'rank').reduce((acc, level) => {
      if (!level) return acc;
      if (level.id === payload.id) return acc;

      return { ...acc, [level.id]: { ...level, rank: Object.keys(acc).length + 1 } };
    }, {});

    return {
      ...state,
      roleIdToLevels: {
        ...state.roleIdToLevels,
        [payload.roleId]: newLevels
      }
    };
  }
});
