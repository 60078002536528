export default {
  infoPage: {
    drawer: {
      title: 'Информация',
      cards: {
        wiki: {
          title: 'Qolio Wiki',
          description: 'Помощь в использовании приложения'
        },
        supportRequest: {
          title: 'Написать в тех. поддержку',
          description: 'Получите быстрый ответ на вопросы по работе приложения'
        }
      }
    },
    feedBackForm: {
      messages: {
        requestSuccessfullySended: 'Письмо отправлено',
        sendRequestfailed: 'Не удалось отправить письмо'
      },
      form: {
        fields: {
          name: 'Имя',
          namePlaceholder: 'Введите ваше имя',
          email: 'Email',
          emailPlaceholder: 'Введите email',
          topic: 'Тема',
          topicPlaceholder: 'Выберите тему обращения',
          message: 'Сообщение',
          messagePlaceholder: 'Введите тему сообщения'
        },
        messages: {
          enterName: 'Пожалуйста, введите ваше имя',
          enterEmail: 'Пожалуйста, введите ваш e-mail',
          wrongEmail: 'E-mail не соответствует формату',
          enterTopic: 'Пожалуйста, выберите тему письма',
          enterTopicMessage: 'Пожалуйста, введите сообщение'
        },
        topicOptions: {
          ask_question: 'Задать вопрос',
          bug_report: 'Сообщить о баге',
          offer_feature: 'Предложить улучшение',
          other: 'Другое'
        },
        buttons: {
          send: 'Отправить '
        }
      }
    }
  }
};
