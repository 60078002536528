import React from 'react';
import { keyBy, orderBy, sum, pick, isEmpty, reduce } from 'lodash';
import { Tooltip, Typography } from 'antd';
import moment from 'moment';
import { timeFormatter } from 'core/utils/timeFormatter';
import { beatifyFloat } from 'core/utils/convertFloat';
import { Trans } from 'react-i18next';
import { WIDGET_VIEW_MODES } from 'core/utils/constants';
import UserPreview from 'components/UserPreview/UserPreview';
import { getDatesArray } from '../../utils';

const { Text } = Typography;

export const prepareChartTable = ({
  selectedUserId,
  viewData,
  viewMode,
  isWeekendView,
  timeFrom,
  timeTo,
  historyGroupingPeriod
}) => {
  const datesArray = getDatesArray({
    timeFrom,
    timeTo,
    isWeekendView,
    historyGroupingPeriod
  });

  const {
    reviewedClientInteractionsDurationByReviewersHistory,
    reviewedClientInteractionsDurationByUnitsHistory,
    reviewsCountByReviewersHistory,
    reviewsCountByUnitsHistory
  } = viewData;

  const reviewsCount = reduce(
    viewMode === WIDGET_VIEW_MODES.REVIEWERS.value
      ? reviewsCountByReviewersHistory
      : reviewsCountByUnitsHistory,
    (acc, item) => {
      if (isEmpty(item.history)) return acc;

      return [
        ...acc,
        {
          ...item,
          name: item.user_name,
          value: sum(
            Object.values(
              pick(
                item.history,
                datesArray.map(date => moment(date).format('YYYY-MM-DD'))
              )
            )
          )
        }
      ];
    },
    []
  );

  const userIds = (viewMode === WIDGET_VIEW_MODES.REVIEWERS.value
    ? reviewsCountByReviewersHistory
    : reviewsCountByUnitsHistory
  ).map(({ id }) => id);

  const reviewedClientInteractionsDuration = reduce(
    (viewMode === WIDGET_VIEW_MODES.REVIEWERS.value
      ? reviewedClientInteractionsDurationByReviewersHistory
      : reviewedClientInteractionsDurationByUnitsHistory
    ).filter(item => userIds.includes(item.id)),
    (acc, item) => ({
      ...acc,
      [item.id]: {
        value: sum(
          Object.values(
            pick(
              item.history,
              datesArray.map(date => moment(date).format('YYYY-MM-DD'))
            )
          )
        )
      }
    }),
    {}
  );

  const totalReviewsCount = sum(reviewsCount.map(item => item.value));

  const totalReviewsTime = sum(
    Object.values(reviewedClientInteractionsDuration).map(item => item.value)
  );

  const columns = [
    {
      title: (
        <Trans i18nKey="dashboardPage.tableReviewsCountByReviewers.viewModes.chart.table.columns.name" />
      ),
      dataIndex: 'name',
      key: 'name',
      children: [
        {
          title: (
            <Trans i18nKey="dashboardPage.tableReviewsCountByReviewers.viewModes.chart.table.rows.total" />
          ),
          fixed: 'left',
          dataIndex: 'name',
          ellipsis: true,
          width: 200,
          render: (text, record) => {
            return {
              props: {
                style: {
                  backgroundColor: selectedUserId === record.id && '#FAFAFA'
                }
              },
              children:
                viewMode !== WIDGET_VIEW_MODES.REVIEWERS.value ? (
                  <Tooltip title={text}>
                    <Text>{text}</Text>
                  </Tooltip>
                ) : (
                  <Tooltip title={text}>
                    <UserPreview
                      userId={record.id}
                      margin="0px"
                      disabled
                      showAvatar
                      showUnit
                      hidden
                    />
                  </Tooltip>
                )
            };
          }
        }
      ],
      sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
      title: (
        <Trans i18nKey="dashboardPage.tableReviewsCountByReviewers.viewModes.chart.table.columns.reviewsCount" />
      ),
      dataIndex: 'reviewsCount',
      key: 'reviewsCount',
      children: [
        {
          title: totalReviewsCount,
          dataIndex: 'reviewsCount',
          key: 'reviewsCountHeader',
          width: 125,
          render: (text, record) => {
            return {
              props: {
                style: {
                  backgroundColor: selectedUserId === record.id && '#FAFAFA'
                }
              },
              children: text
            };
          }
        }
      ],
      sorter: (a, b) => a.reviewsCount - b.reviewsCount
    },
    {
      title: (
        <Trans i18nKey="dashboardPage.tableReviewsCountByReviewers.viewModes.chart.table.columns.reviewsPercentage" />
      ),
      dataIndex: 'reviewsPercentage',
      key: 'reviewsPercentage',
      children: [
        {
          title: `${beatifyFloat(100)}%`,
          dataIndex: 'reviewsPercentage',
          key: 'reviewsPercentageHeader',
          width: 135,
          render: (text, record) => {
            return {
              props: {
                style: {
                  backgroundColor: selectedUserId === record.id && '#FAFAFA'
                }
              },
              children: `${beatifyFloat(text)}%`
            };
          }
        }
      ],
      sorter: (a, b) => a.reviewsPercentage - b.reviewsPercentage
    },
    {
      title: (
        <Trans i18nKey="dashboardPage.tableReviewsCountByReviewers.viewModes.chart.table.columns.totalReviewsTime" />
      ),
      dataIndex: 'totalReviewsTime',
      key: 'totalReviewsTime',
      children: [
        {
          title: timeFormatter({ timeInSeconds: totalReviewsTime }),
          dataIndex: 'totalReviewsTime',
          key: 'totalReviewsTime',
          width: 100,
          render: (text, record) => {
            return {
              props: {
                style: {
                  backgroundColor: selectedUserId === record.id && '#FAFAFA'
                }
              },
              children: timeFormatter({ timeInSeconds: text })
            };
          }
        }
      ],
      sorter: (a, b) => a.totalReviewsTime - b.totalReviewsTime
    }
  ];

  const rows = [
    ...orderBy(
      reviewsCount.map(item => ({
        id: item.id,
        name: item.name,
        reviewsCount: item.value,
        reviewsPercentage: (item.value / totalReviewsCount) * 100,
        totalReviewsTime: reviewedClientInteractionsDuration[item.id]?.value
      })),
      'name'
    )
  ];

  return {
    columns,
    rows
  };
};

export const prepareChartData = ({ viewData, filters, timeFrom, timeTo }) => {
  const { isWeekendView, viewMode, historyGroupingPeriod } = filters;
  const datesArray = getDatesArray({ timeFrom, timeTo, isWeekendView, historyGroupingPeriod });
  const { reviewsCountByReviewersHistory, reviewsCountByUnitsHistory } = viewData;

  const reviewsCountHistory =
    viewMode === WIDGET_VIEW_MODES.REVIEWERS.value
      ? reviewsCountByReviewersHistory
      : reviewsCountByUnitsHistory;

  const line = datesArray.reduce((acc, date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const data = {
      ...acc,
      [formattedDate]: sum(reviewsCountHistory.map(item => item.history[formattedDate])) || 0
    };
    return data;
  }, {});

  const bar = keyBy(
    reviewsCountHistory.map(item => ({
      ...item,
      history: datesArray.reduce((acc, day) => {
        const formattedDate = moment(day).format('YYYY-MM-DD');
        const data = {
          ...acc,
          [formattedDate]: item.history[formattedDate] || 0
        };
        return data;
      }, {})
    })),
    'id'
  );

  return {
    line,
    bar
  };
};
