import { Modal } from 'antd';
import styled from 'styled-components';
import { flexbox, space, layout, color, variant } from 'styled-system';

export default styled(Modal)`
    ${flexbox}
    ${space}
    ${layout}
    ${color}

&&.ant-modal {
  .ant-modal-content {
    border-radius: 8px;
    border: 1px solid var(--gray-border);
    .ant-modal-header {
      padding: 24px;
      border-radius: 8px 8px 0 0;
      .ant-modal-title {
        font-size: 18px;
      }
    }

    .ant-modal-close {
      top: 12px;
      right: 6px;
    }

    .ant-modal-footer {
      ${variant({
        prop: 'size',
        variants: {
          big: {
            padding: '24px'
          },
          small: {
            padding: '0 24px 24px',
            border: 'none'
          }
        }
      })}
    }

    .ant-modal-footer button + button {
      margin-left: 20px;
    }

    .ant-modal-body {
      ${variant({
        prop: 'size',
        variants: {
          big: {
            padding: '24px 24px 0'
          },
          small: {
            padding: '24px',
            border: 'none'
          }
        }
      })}

      // margin-bottom: -24px;
    }
  }
}
`;
