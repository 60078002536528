export default {
  checklistSettingsPage: {
    questionsWithoutGroup: 'Questions without group',
    checklistSuccessfullyCreated: 'Scorecard successfully created',
    checklistSuccessfullyUpdated: 'Scorecard successfully updated',
    updateChecklistFailed: 'Failed to update scorecard',
    createChecklistFailed: 'Failed to create scorecard',
    breadcrumbs: {
      checklists: 'Scorecards',
      checklistSettings: 'Scorecard settings'
    },
    title: 'Scorecard settings',
    info:
      'Here you can specify the general settings of the selected checklist that will apply to all checklist questions. Click the "Save" button to go to the group creation page and add questions to the checklist data. \n You can change these settings at any time. You can learn more about all settings and features',
    infoButton: 'here.',
    form: {
      title: 'General settings',
      fields: {
        name: 'Name',
        nameInfo:
          'This name is displayed when you select the scorecard for evaluation or in the analytics widgets.',
        namePlaceholder: 'Enter scorecard name',
        description: 'Description',
        descriptionInfo:
          'Describe the purposes which you are using this scorecard for, who or what you want to evaluate with it.',
        descriptionPlaceholder: 'Enter scorecard description',
        units: 'Departments',
        unitsInfo:
          'The scorecard will be available for evaluation of communications only from selected departments. Employees from other departments will not be able to evaluate it.',
        unitsPlaceholder: 'Choose department',
        unitsPlaceholderMax: 'Departments:',
        communicationTypes: 'Communications types',
        communicationType: 'Communication type:',
        communicationTypesPlaceholder: 'Choose communications types',
        communicationTypesPlaceholderMax: 'Communications types:',
        communicationTypesInfo:
          'The scorecard will be available for evaluating only the selected types of communications.',
        ratingMethod: 'Evaluation method',
        ratingMethodInfo:
          'In a simple evaluation, each question (item) on the scorecard has the same weight. If you choose "Weighted", you will need to specify % of the value of the score for the question (item) in the calculation of the average scorecard score.',
        ratingCalculation: 'Evaluation calculation',
        ratingCalculationInfo: 'Choose what final score of the scorecard you want to get.',
        ratingMode: 'Evaluation display',
        ratingModeInfo:
          'Choose how you want to see the final result - gained points or their percentage of the maximum possible result. After saving the assessment form, and adding one criterion or group of criteria, it will be impossible to change the display.',
        additionalSettings: 'Additional settings',
        scaleType: 'Evaluation scale',
        scaleTypeInfo:
          'Choose a ready-made evaluation scale or specify the points that you need to score on the scorecard in an "arbitrary scale".',
        valuesDisplayType: 'Display in a scorecard',
        valuesDisplayTypeInfo:
          'The graphical view (emoji) of the scale will be available only on the evaluation page. The final result will be displayed in a numerical form.',
        autofailEnabled: 'Scorecard autofail',
        autofailEnabledInfo:
          'Choose "yes" if you want to add questions to the scorecard, the minimum score of which will automatically evaluate the entire scorecard. In this case the final score for the entire scorecard will be the lowest possible.',
        noAnswerAsMax: 'Consider N/A as the maximum criterion score',
        noAnswerAsMaxInfo:
          'Select "Yes" if you want the criteria that were left unanswered to be counted as the maximum possible value for that criterion',
        onlyPositiveScores: 'Specify zero as min. possible score value',
        onlyPositiveScoresInfo:
          'The result of an evaluation form or a group of criteria cannot be less than zero'
      },
      messages: { enterName: 'Please enter name' },
      alerts: {
        disabledByQuestions: {
          title:
            'You cannot change your evaluation scale while the sore card contains questions with an evaluation scale already set.'
        },
        colorZones: {
          title: 'Evaluation zones',
          descriptionPT1:
            'Since you have selected Evaluation Calculation - Score Sum or Evaluation Scale - Random Scale, the setting of evaluation zones will be active before the scorecard is published.',
          descriptionPT2:
            'Zones allow you to add and set the color designation of the final scorecards score results. I.e. when the final score falls within the range, specified in the zone, it will be displayed with the selected zone color. In addition, it will allow you to easily understand and analyze which zones employees evaluations fall into most often.'
        },
        button: 'Learn more here'
      }
    },
    buttons: {
      cancel: 'Cancel',
      save: 'Save'
    }
  }
};
