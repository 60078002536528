import React from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { PERMISSIONS, PERMISSIONS_DESCRIPTIONS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';
import { Typography } from 'antd';

export const AppealsPermissions = ({ fields, getCheckbox }) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <SRow gutter={[8, 8]} type="flex">
      <SCol span={24}>
        <Text strong>{t('menu.appMenu.appeals')}</Text>
      </SCol>

      <SCol span={24}>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_CREATE_APPELLATIONS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_CREATE_APPELLATIONS.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_WATCH_APPELLATIONS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_WATCH_APPELLATIONS.title)
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_SEE_UNIT_APPELLATIONS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_SEE_UNIT_APPELLATIONS.title),
            [],
            [
              fields[PERMISSIONS.CAN_ADMINISTRATE_APPELLATIONS],
              fields[PERMISSIONS.CAN_ACCESS_APPELLATIONS_ANALYTICS_PAGE]
            ]
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_ADMINISTRATE_APPELLATIONS,
            t(PERMISSIONS_DESCRIPTIONS.CAN_ADMINISTRATE_APPELLATIONS.title),
            [PERMISSIONS.CAN_SEE_UNIT_APPELLATIONS]
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_ACCESS_APPELLATIONS_ANALYTICS_PAGE,
            t(PERMISSIONS_DESCRIPTIONS.CAN_ACCESS_APPELLATIONS_ANALYTICS_PAGE.title),
            [PERMISSIONS.CAN_SEE_UNIT_APPELLATIONS]
          )}
        </SRow>
        <SRow>
          {getCheckbox(
            PERMISSIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_OPERATORS_APPELLATIONS_TAB,
            t(
              PERMISSIONS_DESCRIPTIONS.CAN_ACCESS_USER_ANALYTICS_PAGE_OPERATORS_APPELLATIONS_TAB
                .title
            )
          )}
        </SRow>
      </SCol>
    </SRow>
  );
};
