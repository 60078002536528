export default {
  autoFailScoresPage: {
    buttonAddReport: {
      dynamicCriticalErrors: {
        header: 'La dinamica degli errori critici',
        description:
          'Mostra il numero di comunicazioni, il numero e la percentuale di controlli, il numero e la percentuale di errori critici nel tempo. Ci sono due opzioni di visualizzazione: una tabella e un grafico.'
      },
      criticalErrorsCriteria: {
        header: 'Criteri con errori critici',
        description:
          'Mostra la percentuale di errori critici per criterio. Ci sono due opzioni di visualizzazione: dipendenti e reparti. Il rapporto mostra solo i criteri per i quali gli errori critici sono impostati nel modulo di valutazione.'
      }
    }
  }
};
