import { get, put, post, destroy } from 'redux-bees';

const taskDefinitionsEndpoints = apiPath => ({
  getTaskDefinitions: { method: get, path: `${apiPath}/task_definitions` },
  getTaskDefinition: { method: get, path: `${apiPath}/task_definitions/:id` },
  updateTaskDefinition: { method: put, path: `${apiPath}/task_definitions/:id` },
  deleteTaskDefinition: { method: destroy, path: `${apiPath}/task_definitions/:id` },
  createTaskDefinition: { method: post, path: `${apiPath}/task_definitions` }
});

export default taskDefinitionsEndpoints;
