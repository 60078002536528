import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  levelsRoleId: null,
  statusesByIds: {},
  oldStatusesByIds: {}
};

export const setStatusesByIds = createAction('uiLevelsPage/setStatusesByIds');
export const addStatus = createAction('uiLevelsPage/addStatus');
export const updateStatus = createAction('uiLevelsPage/updateStatus');
export const deleteStatus = createAction('uiLevelsPage/deleteStatus');

export const statusesPageReducer = createReducer(defaultState, {
  [setStatusesByIds]: (state, { payload }) => ({
    ...state,
    statusesByIds: payload,
    oldStatusesByIds: payload
  }),
  [addStatus]: (state, { payload }) => ({
    ...state,
    statusesByIds: {
      ...state.statusesByIds,
      [payload?.id]: payload
    }
  }),
  [updateStatus]: (state, { payload }) => ({
    ...state,
    statusesByIds: {
      ...state.statusesByIds,
      [payload.id]: {
        ...(state.statusesByIds[payload.id] || {}),
        ...payload
      }
    }
  }),
  [deleteStatus]: (state, { payload }) => {
    const newStatuses = Object.values(state.statusesByIds).reduce((acc, status) => {
      if (!status) return acc;
      if (status.id === payload?.id) return acc;

      return { ...acc, [status?.id]: status };
    }, {});

    return {
      ...state,
      statusesByIds: newStatuses
    };
  }
});
