import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { loadReviews } from 'redux/ui/bitrixIntegration/operations';
import { actions } from 'redux/lists/bitrixReviewsListReducer';
import { get, pick, throttle, isEmpty } from 'lodash';
import { commentsResource } from 'redux/resources/comments';
import { Skeleton, Empty, Row, Col } from 'antd';
import EmptyDescriptionWithLink from 'components/EmptyDescriptionWithLink';
import { useTranslation } from 'react-i18next';
import SCol from 'components/Standard/SCol';
import ReviewPreview from './components/ReviewPreview';
import { ColumnName } from '../styled';
import SCard from 'components/Standard/SCard';

const Reviews = ({
  loadReviews,
  loading = true,
  reviews,
  loadReviewsCommentsWithoutReplies,
  page,
  clearList,
  hasNextPage
}) => {
  useEffect(() => {
    clearList();
    loadReviewsCommentsWithoutReplies({ include: 'author' });
    loadReviews({
      include:
        'reviewer,custom_communication,text_communication,phone_call,client_interaction,client_interaction.operator,checklist.answers.question,checklist.checklist-definition'
    });
    return () => {
      clearList();
    };
  }, []);
  const { t } = useTranslation();

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      await loadReviewsCommentsWithoutReplies({ include: 'author' });
      await loadReviews({
        include:
          'reviewer,custom_communication,text_communication,phone_call,client_interaction,client_interaction.operator,checklist.answers.question,checklist.checklist-definition',
        page: { ...page, number: newPage }
      });
    }
  }, 250);

  if (loading) {
    return (
      <SCard>
        <Skeleton active paragraph={false} />
      </SCard>
    );
  }

  if (isEmpty(reviews)) {
    return (
      <Empty
        style={{ margin: '12px' }}
        description={
          <EmptyDescriptionWithLink
            linkText={t('bitrixIntegrationPage.review.empty.linkText')}
            link="https://intercom.help/qolio/ru/articles/5422028-проверка-коммуникаций"
            description={t('bitrixIntegrationPage.review.empty.description')}
          />
        }
      />
    );
  }

  return (
    <>
      <Row type="flex" align="middle" justify="space-between" gutter={[16, 16]}>
        <Col span={1} />
        <Col span={3}>
          <ColumnName>{t('bitrixIntegrationPage.review.columns.operator')}</ColumnName>
        </Col>
        <SCol display="flex" justifyContent="center" span={2}>
          <ColumnName>{t('bitrixIntegrationPage.review.columns.direction')}</ColumnName>
        </SCol>
        <SCol display="flex" justifyContent="center" span={4}>
          <ColumnName>{t('bitrixIntegrationPage.review.columns.date')}</ColumnName>
        </SCol>
        <SCol display="flex" justifyContent="center" span={2}>
          <ColumnName>{t('bitrixIntegrationPage.review.columns.duration')}</ColumnName>
        </SCol>
        <SCol display="flex" justifyContent="center" span={3}>
          <ColumnName>{t('bitrixIntegrationPage.review.columns.comments')}</ColumnName>
        </SCol>
        <SCol display="flex" justifyContent="center" span={2}>
          <ColumnName>{t('bitrixIntegrationPage.review.columns.score')}</ColumnName>
        </SCol>
        <Col span={4}>
          <ColumnName>{t('bitrixIntegrationPage.review.columns.author')}</ColumnName>
        </Col>
        <Col span={1} onClick={e => e.stopPropagation()} />
      </Row>
      <PerfectScrollbar
        style={{ maxHeight: 'calc(100vh - 56px)' }}
        onScrollY={onScrollY}
        options={{ suppressScrollX: true }}
      >
        {reviews.map(review => (
          <ReviewPreview key={review.id} review={review} />
        ))}
      </PerfectScrollbar>
    </>
  );
};

const mapStateToProps = state => {
  const { page, ids, totalPages, loading } = state.bitrixReviewsList;
  const reviews = ids
    .map(id => state.reviewsResource.byIds[id])
    .map(review => ({
      ...review,
      communication: get(state.clientInteractionsResource.byIds, review.clientInteractionId, {}),
      comments: Object.values(pick(state.commentsResource.byIds, review.commentsIds)),
      checklist: get(state.checklistsResource.byIds, review.checklistId, {})
    }));

  return {
    reviews,
    hasNextPage: totalPages > page.number,
    page,
    loading
  };
};

const mapDispatchToProps = {
  loadReviews,
  clearList: actions.clearList,
  loadReviewsCommentsWithoutReplies: commentsResource.operations.loadReviewsCommentsWithoutReplies
};

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
