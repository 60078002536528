import React, { useState } from 'react';
import { Radio } from 'antd';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import SButton from 'components/Standard/SButton';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import { actions } from 'redux/lists/commentTemplatesListReducer';
import { useTranslation } from 'react-i18next';
import TemplatesList from './TemplatesList';
import CreateTemplateForm from './CreateTemplateForm';

const TemplateSelector = ({
  setCommentState,
  commentState,
  setEditorState,
  setVisibleSelector
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isOpenEditor, setOpenEditor] = useState(false);

  const { filters, page } = useSelector(state => state.commentTemplatesList, isEqual);

  const { updateFilters, updatePage } = actions;

  const toogleFilters = e => {
    dispatch(
      updatePage({
        size: page.size,
        number: 1
      })
    );
    if (filters?.commentsRatingFlags === e.target.value) {
      return dispatch(updateFilters({ commentsRatingFlags: '' }));
    }
    return dispatch(updateFilters({ commentsRatingFlags: e.target.value }));
  };

  return (
    <SRow gutter={[20, 20]}>
      <SCol span={24} style={{ paddingBottom: 0 }}>
        <SRow type="flex" align="middle" justify="space-between">
          <SCol>
            <SText>{t('templates.templates')}</SText>
          </SCol>
          <SCol>
            <SButton type="link" padding="4px 0px" onClick={() => setOpenEditor(true)}>
              {t('templates.addTemplate')}
            </SButton>
          </SCol>
        </SRow>
      </SCol>
      <SCol span={24}>
        <Radio.Group style={{ width: '100%' }} value={filters?.commentsRatingFlags}>
          <Radio.Button
            style={{ width: '25%', textAlign: 'center' }}
            value="red"
            onClick={toogleFilters}
          >
            {t('general.redFlag')}
          </Radio.Button>
          <Radio.Button
            style={{ width: '25%', textAlign: 'center' }}
            value="yellow"
            onClick={toogleFilters}
          >
            {t('general.yellowFlag')}
          </Radio.Button>
          <Radio.Button
            style={{ width: '25%', textAlign: 'center' }}
            value="green"
            onClick={toogleFilters}
          >
            {t('general.greenFlag')}
          </Radio.Button>
          <Radio.Button
            style={{ width: '25%', textAlign: 'center' }}
            value="empty"
            onClick={toogleFilters}
          >
            {t('general.withoutFlag')}
          </Radio.Button>
        </Radio.Group>
      </SCol>
      {isOpenEditor && <CreateTemplateForm setOpenEditor={setOpenEditor} />}

      <TemplatesList
        commentState={commentState}
        setCommentState={setCommentState}
        setEditorState={setEditorState}
        setVisibleSelector={setVisibleSelector}
      />
    </SRow>
  );
};

export default TemplateSelector;
