import { createReduxResource } from 'core/redux/resources';
import { updateResource } from 'core/redux/resources/updateResource';

export const reviewsResource = createReduxResource({
  name: 'reviews',
  reactors: {
    onCreateSucceed: ({ resource, dispatch, getState }) => {
      const state = getState();
      const clientInteraction =
        state.clientInteractionsResource.byIds[resource.clientInteractionId];
      if (clientInteraction) {
        updateResource({
          dispatch,
          type: 'clientInteractions',
          resource: {
            ...clientInteraction,
            reviewsIds: [...(clientInteraction?.reviewsIds || []), resource.id]
          }
        });
      }
    },
    onDeleteByIdSucceed: ({ id, dispatch, getState }) => {
      const state = getState();
      const review = state.reviewsResource.byIds[id];
      const clientInteraction = state.clientInteractionsResource.byIds[review.clientInteractionId];
      if (clientInteraction) {
        updateResource({
          dispatch,
          type: 'clientInteractions',
          resource: {
            ...clientInteraction,
            reviewsIds: (clientInteraction?.reviewsIds || []).filter(reviewId => reviewId !== id)
          }
        });
      }
    }
  }
});
