import { get, put, destroy, post } from 'redux-bees';

const tasksEndpoints = apiPath => ({
  getTasks: { method: get, path: `${apiPath}/tasks` },
  deleteTask: { method: destroy, path: `${apiPath}/tasks/:id` },
  createTask: { method: post, path: `${apiPath}/tasks` },
  updateTask: { method: put, path: `${apiPath}/tasks/:id` }
});

export default tasksEndpoints;
