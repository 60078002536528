import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import TaskDefinitionsFilters from './TaskDefinitionsFilters';
import TaskDefinitionsList from './TaskDefinitionsList';

const TaskDefinitions = () => {
  const { t } = useTranslation();

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.triggersSettingsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <TaskDefinitionsFilters />
      </SCol>
      <SCol span={24} padding="16px">
        <TaskDefinitionsList />
      </SCol>
    </SRow>
  );
};

export default TaskDefinitions;
