import { valueFormatter } from 'core/utils/valueFormatter';
import { Trans } from 'react-i18next';
import { isEmpty, orderBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import PeriodInfo from 'components/PeriodInfo';
import UserPreview from 'components/UserPreview';
import { stringify } from 'core/utils/queryString';
import { detectKpi } from 'core/utils/kpi';
import { getDatesArray, getFilters, valueToColorZoneColor } from '../../utils';

export const prepareColumns = ({ checklistDefinition, filters, timeFrom, timeTo }) => {
  const { isWeekendView, historyGroupingPeriod } = filters;
  const datesArray = getDatesArray({ timeFrom, timeTo, isWeekendView, historyGroupingPeriod });

  const datesColumns = datesArray.map(day => ({
    title: (
      <PeriodInfo startDate={day} groupingPeriod={historyGroupingPeriod} isVisibleYear={false} />
    ),
    dataIndex: moment(day).format('YYYY/MM/DD'),
    key: moment(day).format('YYYY/MM/DD'),
    align: 'center',
    // width: datesArray.length < 15 ? null : 90,
    width: null,
    render: text => {
      return {
        props: {
          style: {
            background: text?.color
          }
        },
        children: valueFormatter({
          value: text?.value,
          ratingMode: checklistDefinition.ratingMode
        })
      };
    }
  }));

  const kpiDatesColumns = datesArray.map(day => ({
    title: (
      <PeriodInfo startDate={day} groupingPeriod={historyGroupingPeriod} isVisibleYear={false} />
    ),
    dataIndex: `${moment(day).format('YYYY/MM/DD')}-kpi`,
    key: `${moment(day).format('YYYY/MM/DD')}-kpi`,
    align: 'center',
    width: null,
    render: text => {
      return text?.value;
    }
  }));

  return [
    {
      title: <Trans i18nKey="dashboardPage.tableReviewsCountByOperators.table.columns.dates" />,
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 270,
      render: (text, record) => {
        return {
          props: {
            style: {
              padding: '2px 5px'
            }
          },
          children: (
            <UserPreview
              userId={record.key}
              margin="0px"
              showAvatar
              showUnit
              link={`/user/${record.key}/charts${stringify({
                filters: {
                  ...getFilters(filters)
                }
              })}`}
            />
          )
        };
      }
    },

    {
      title: <Trans i18nKey="dashboardPage.widget.unitName" />,
      dataIndex: 'unitName',
      key: 'unitName',
      fixed: 'left',
      width: 80
    },
    {
      title: 'Оценки',
      children: [...datesColumns]
    },
    {
      title: 'KPI',
      children: [...kpiDatesColumns]
    }
  ];
};

export const prepareTableData = ({
  filters,
  timeFrom,
  timeTo,
  usersByIds,
  unitsByIds,
  checklistDefinition,
  checklistDefinitionAverageScoreByOperatorsHistory
}) => {
  const { isWeekendView, historyGroupingPeriod, settings } = filters;
  const datesArray = getDatesArray({ timeFrom, timeTo, isWeekendView, historyGroupingPeriod });

  const mappedUsersScore = () => {
    return orderBy(
      checklistDefinitionAverageScoreByOperatorsHistory.reduce((acc, item) => {
        if (isEmpty(item.history)) return acc;

        const history = datesArray.reduce((acc, day) => {
          const value = parseFloat(item.history[moment(day).format('YYYY-MM-DD')]);
          const kpiValue = detectKpi({ score: value, conditions: settings });
          return {
            ...acc,
            [moment(day).format('YYYY/MM/DD')]: {
              value,
              color: valueToColorZoneColor({
                value,
                colorZones: checklistDefinition.colorZones || 'white'
              })
            },
            [`${moment(day).format('YYYY/MM/DD')}-kpi`]: {
              value: kpiValue,
              color: null
            }
          };
        }, {});

        const data = {
          key: item.id,
          name: item.user_name,
          unitName: unitsByIds[usersByIds[item.id]?.unitId]?.name,
          lastName: usersByIds[item.id]?.lastName,
          ...history
        };
        return [...acc, data];
      }, []),
      'lastName'
    );
  };

  return [...mappedUsersScore()];
};
