export default {
  infoPage: {
    drawer: {
      title: 'Información',
      cards: {
        wiki: {
          title: 'Qolio Wiki',
          description: 'Ayuda para utilizar la aplicación'
        },
        supportRequest: {
          title: 'Escribir a soporte técnico',
          description: 'Reciba respuesta rápida sobre el funcionamiento de la aplicación'
        }
      }
    },
    feedBackForm: {
      messages: {
        requestSuccessfullySended: 'Mensaje enviado',
        sendRequestfailed: 'No se logró enviar el mensaje'
      },
      form: {
        fields: {
          name: 'Nombre',
          namePlaceholder: 'Introduzca el nombre',
          email: 'Correo electrónico',
          emailPlaceholder: 'Introduzca el correo electrónico',
          topic: 'Tema',
          topicPlaceholder: 'Introduzca el tema de la pregunta',
          message: 'Mensaje',
          messagePlaceholder: 'Introduzca el tema de la pregunta'
        },
        messages: {
          enterName: 'Por favor introduzca su nombre',
          enterEmail: 'Por favor introduzca su correo electrónico',
          wrongEmail: 'El correo el electrónico no tiene el formato correcto',
          enterTopic: 'Por favor elija el tema del mensaje',
          enterTopicMessage: 'Por favor introduzca el mensaje'
        },
        topicOptions: {
          ask_question: 'Hacer preguntas',
          bug_report: 'Informar sobre un error',
          offer_feature: 'Proponer mejorías',
          other: 'Otro'
        },
        buttons: { send: 'Enviar ' }
      }
    }
  }
};
