export default {
  customCommunicationPage: {
    title: 'Новая оценка коммуникации',
    form: {
      title: 'Автор: ',
      fields: {
        operator: 'Сотрудник',
        communicationType: 'Тип коммуникации',
        clientPhoneNumber: 'Номер телефона',
        communicationDate: 'Дата коммуникации',
        duration: 'Продолжительность',
        direction: 'Направление коммуникации',
        email: 'Email',
        additional: 'Дополнительно',
        communicationId: 'ID коммуникации',
        mediaUrl: 'Ссылка на медиафайл'
      },
      messages: {
        requiredOperator: 'Пожалуйста выберите сотрудника',
        requiredCommunicationType: 'Пожалуйста выберите тип коммуникации',
        requiredClientPhoneNumber: 'Пожалуйста введите номер телефона',
        requiredCommunicationDate: 'Пожалуйста выберите дату коммуникации',
        requiredDirection: 'Пожалуйста выберите направление',
        requiredEmail: 'Пожалуйста введите email',
        wrongEmail: 'Пожалуйста введите корректный email',
        requiredCommunicationId: 'Пожалуйста введите id коммуникации',
        wrongMediaUrl: 'Пожалуйста введите корректный url',
        communicationIdTooltip:
          'В данное поле вы можете добавить любой идентификатор, который поможет вам найти нужную коммуникацию в будущем'
      },
      buttons: {
        openFile: 'Открыть прикрепленный файл',
        addUrl: 'Добавить ссылку на файл'
      }
    },
    messages: {
      needToChangeMode: 'Чтобы выполнить действие перейдите в режим редактирования оценки',
      commentSuccessfullyUpdated: 'Комментарий обновлён',
      updateCommentFailed: 'Не удалось обновить комментарий',
      communicationSuccessfullyCreated: 'Оценка создана',
      communicationSuccessfullyUpdated: 'Оценка обновлена',
      createCommunicationFailed: 'Не удалось создать коммуникацию',
      updateCommunicationFailed: 'Не удалось обновить оценку',
      reviewSuccessfullyDeleted: 'Оценка удалена',
      deleteReviewFailed: 'Не удалось удалить оценку',
      disabledOperator: 'Эммм… данный пользователь не активен 😢',
      needToActivateOperator: 'Для перехода к оценке, сделайте пользователя активным',
      unsavedReview: 'При переходе на другую страницу проверка не будет сохранена',
      isEmptyQuestionsValues:
        'Нельзя сохранить пустую оценку. Оцените хотя бы один критерий формы оценки'
    },
    buttons: {
      viewGeneralComment: 'Просмотреть общий комментарий',
      addGeneralComment: 'Добавить общий комментарий',
      editGeneralComment: 'Редактировать общий комментарий',
      viewOperatorSettings: 'Перейти в настройки',
      saveReview: 'Сохранить оценку',
      editReview: 'Редактировать оценку'
    },
    confirmDeleteReview: {
      title: 'Удалить оценку?',
      description: 'Удаленная оценка не подлежит восстановлению'
    }
  }
};
