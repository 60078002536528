import { get, put, destroy, post } from 'redux-bees';

const integrationsEndpoints = apiPath => ({
  getIntegrations: { method: get, path: `/api/v4/list/:id` },
  getIntegration: { method: get, path: `${apiPath}/integrations/:id` },
  getIntegrationV4: { method: get, path: `/api/v4/:id` },
  createIntegration: { method: post, path: `${apiPath}/integrations` },
  deleteIntegration: { method: destroy, path: `${apiPath}/integrations/:id` },
  deleteIntegrationV4: { method: destroy, path: `/api/v4/:id` },
  updateIntegration: { method: put, path: `${apiPath}/integrations/:id` },
  updateCustomHttpTelephonyAuthorizationToken: {
    method: put,
    path: `${apiPath}/integrations/telephony/custom_http/:id/update_authorization_token/`
  },
  updateCustomHttpTextAuthorizationToken: {
    method: put,
    path: `${apiPath}/integrations/text/custom_http/:id/update_authorization_token/`
  },
  getAvailableIntegrations: { method: get, path: `${apiPath}/integrations/available` }
});

export default integrationsEndpoints;
