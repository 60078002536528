const it = {
  name: 'it',
  options: {
    months: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre'
    ],
    shortMonths: [
      'Genn',
      'Febr',
      'Mar',
      'Apr',
      'Magg',
      'Giugno',
      'Luglio',
      'Ag',
      'Sett',
      'Ott',
      'Nov',
      'Dic'
    ],
    days: ['Domenica', 'Lunedi', 'Martedi', 'Mercoledi', 'Giovedi', 'Venerdi', 'Sabato'],
    shortDays: ['Dom', 'Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab'],
    toolbar: {
      download: 'Carica SVG',
      selection: 'Scelta',
      selectionZoom: 'Ingrandire il periodo',
      zoomIn: 'Ingrandire',
      zoomOut: 'Allontanare',
      pan: 'Movimento',
      reset: "Resetta l'approssimazione"
    }
  }
};
export default it;
