import React, { useEffect, useState } from 'react';
import { Button, Col, message, Select } from 'antd';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { SCard, SRow, SSelect } from '../../../../components/Standard';
import {
  connectNaumenProjectById,
  getEntityProject
} from '../../../../redux/entities/naumenIntegration/operations';

const ProjectManager = ({ integrationId, onOk, okText }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState(null);

  useEffect(() => {
    if (integrationId && isEmpty(projects)) {
      setLoading(true);
        getEntityProject(integrationId).then(res => {
          setProjects(res);
          setLoading(false);
        });

    }
  }, []);

  const handleSelectProject = () => {
    if (project) {
        connectNaumenProjectById(project, integrationId).then(res => {
          if (res.status === 'done') {
            message.success(res.message);
            onOk();
          } else {
            message.warning(res.message);
          }
        });
    } else {
      onOk();
    }
  };

  if (loading) {
    return <SCard padding="16px" loading={loading} />;
  }

  return (
    <SCard bordered shadowed bodyPadding="24px">
      <SRow
        gutter={[0, 16]}
        style={{ marginBottom: '-16px' }}
        type="flex"
        align="middle"
        justify="space-between"
      >
        <Col>
          <SSelect width="341px" value={project} onSelect={value => setProject(value)}>
            {projects?.map(project => (
              <Select.Option key={project.id} value={project.id}>
                {project.attributes.name}
              </Select.Option>
            ))}
          </SSelect>
        </Col>
        <Col>
          <Button type="primary" onClick={handleSelectProject}>
            {isEmpty(okText) ? t('general.continue') : okText}
          </Button>
        </Col>
      </SRow>
    </SCard>
  );
};

export default ProjectManager;
