import { compact, filter, get, isEqual } from 'lodash';
import React from 'react';
import {
  getQuestionGroupBindings,
  getQuestionGroupQuestions
} from 'redux/selectors/checklistItems/checklistItems';
import { useSelector } from 'react-redux';
import ChecklistDefinitionItem from './ChecklistDefinitionItem';

const QuestionGroup = ({ currentChecklist, questionGroupId, isPercentageChecklist }) => {
  const questionGroupsByIds = useSelector(state => state.questionGroupsResource.byIds, isEqual);

  const questionsByIds = useSelector(state => state.questionsResource.byIds, isEqual);

  const currentQuestionGroup = get(questionGroupsByIds, questionGroupId, {});

  const currentQuestionGroupBindings = useSelector(
    state =>
      filter(
        getQuestionGroupBindings(state, currentQuestionGroup),
        questionGroupBinding => questionGroupBinding
      ),
    isEqual
  );

  const currentQuestionGroupQuestions = useSelector(
    state => compact(getQuestionGroupQuestions(state, currentQuestionGroup)),
    isEqual
  );

  return (
    <>
      <ChecklistDefinitionItem
        name={currentQuestionGroup?.name}
        min={isPercentageChecklist ? 0 : currentQuestionGroup?.min}
        max={isPercentageChecklist ? 100 : currentQuestionGroup?.max}
        color="var(--gray_4)"
        key={questionGroupId}
        checklistDefinitionItemId={questionGroupId}
        type="question_group"
        checklistDefinitionId={currentChecklist.id}
        childrenQuestionsIds={currentQuestionGroupQuestions.map(question => question.id)}
      />
      {currentQuestionGroupBindings.map(binding => (
        <ChecklistDefinitionItem
          name={get(questionsByIds[binding.questionId], 'name', '')}
          min={isPercentageChecklist ? 0 : get(questionsByIds[binding.questionId], 'min', 0)}
          max={isPercentageChecklist ? 100 : get(questionsByIds[binding.questionId], 'max', 0)}
          color="white"
          key={get(questionsByIds[binding.questionId], 'id', '')}
          checklistDefinitionItemId={get(questionsByIds[binding.questionId], 'id', '')}
          type="question"
          checklistDefinitionId={currentChecklist.id}
          questionGroupId={questionGroupId}
        />
      ))}
    </>
  );
};

export default QuestionGroup;
