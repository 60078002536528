export default {
  calibrationResultPage: {
    messages: {
      loadSessionsFailed: 'No se pudo descargar la sesión',
      sessionSuccessfullyCompleted: 'La secsón ha terminado satisfactoriamente',
      emptyReviews: 'No hay evaluación'
    },
    commentPlaceholder: 'Lugar para sus comentarios sobre la sesión',
    buttons: { completeSession: 'Terminar la sesión' },
    table: {
      organizer: 'Organizador:',
      columns: {
        gradedPoints: 'Evalue los puntos',
        coincidence: 'Coincidencias',
        result: 'Resultado de la evaluación'
      }
    }
  }
};
