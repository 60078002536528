import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  updatedToken: '',
  loading: false
};

export const updateTokenStarted = createAction('customHttpTextIntegration/updateTokenStarted');
export const tokenUpdatedSucceed = createAction('customHttpTextIntegration/tokenUpdatedSucceed');

export const customHttpTextReducer = createReducer(defaultState, {
  [updateTokenStarted]: () => ({
    loading: true
  }),
  [tokenUpdatedSucceed]: (state, { payload }) => ({
    ...state,
    updatedToken: payload,
    loading: false
  })
});

export default customHttpTextReducer;
