import React, { useState } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import UserPreview from 'components/UserPreview';
import { useTranslation } from 'react-i18next';
import SText from 'components/Standard/SText';
import { beatifyFloat } from 'core/utils/convertFloat';
import { useSelector, useDispatch } from 'react-redux';
import RedesignWidgetTable from 'components/Dashboard/RedesignWidgetTable';
import { IconButton } from 'components/Buttons';
import AddUsersModal from 'components/AddUsersModal';
import { get, isEqual } from 'lodash';
import {
  hasConflictFilters,
  appellationsPeriodSetter
} from 'pages/DashboardPage/utils';
import { PERMISSIONS } from 'core/utils/constants';
import { Empty, Tooltip } from 'antd';
import { LoadingOutlined, SettingOutlined } from '@ant-design/icons';
import ExcelIcon from 'images/ExcelIcon';
import Icon from 'components/Icon';
import { WidgetTitle } from 'components/WidgetTitle';
import { TimePeriod } from 'components/TimePeriod';
import { StyledEmpty, StyledErrorMessage } from '../../../../DashboardPage/styled';
import WarningIcon from '../../../../../assets/images/warning-icon.svg';
import { getColorizedColumn, getOrderString, onTableChange, onAdd, startExport } from './utils';

const AppellationsByReviewers = () => {
  const { t } = useTranslation();
  const [isVisibleFilterModal, setVisibleFilterModal] = useState(false);
  const [loadingExport, setLoadingExport] = useState();
  const dispatch = useDispatch();

  const appealDashboard = useSelector(
    state => state.dashboardPages.dashboardsByTypes?.appellations,
    isEqual
  );
  const id = get(appealDashboard, 'widgetsByTypes.appellationStatusesCountByReviewers.id', {});
  const loading = useSelector(state => state.dashboardPages.loading);
  const loadingWidgetId = useSelector(state => state.dashboardPages.loadingWidgetId);
  const loadingTable = loadingWidgetId === id || loadingWidgetId === 'filters' || loadingExport;
  const filters = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.filters, isEqual);
  const page = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.page, isEqual);
  const type = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.type, isEqual);
  const users = useSelector(state => state.usersResource.byIds);
  const [initialState, setInitialState] = useState(page);
  const widgetAnalytics = useSelector(
    state => get(state.dashboardAnalytics.byIds, id, {}),
    isEqual
  );
  const { meta } = widgetAnalytics;
  const tableData = [
    ...get(widgetAnalytics, 'data', []),
    { ...widgetAnalytics?.total_appellations_count, id: 'footer' }
  ];

  const tableColumns = [
    {
      title: t('general.reviewer'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <div key={record?.id} style={{ height: '42px', display: 'flex', alignItems: 'center' }}>
              <SText>{`${t('general.total')}:`}</SText>
            </div>
          ) : (
            <UserPreview key={text} userId={text} showAvatar showEmail disabled />
          );

        return getColorizedColumn({
          color: 'var(--gray_4)',
          component
        });
      }
    },

    {
      title: t('appealsPage.analytics.pending'),
      dataIndex: 'open_appellations_count',
      key: 'open_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.open_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--gray_4)',
          component
        });
      }
    },

    {
      title: t('appealsPage.analytics.noAnswer'),
      dataIndex: 'without_answer_appellations_count',
      key: 'without_answer_appellations_count',
      defaultSortOrder: getOrderString({
        orderString: meta?.without_answer_appellations_count
      }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--yellow_4)',
          component
        });
      }
    },

    {
      title: t('general.rejected'),
      dataIndex: 'rejected_appellations_count',
      key: 'rejected_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.rejected_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--green_4)',
          component
        });
      }
    },

    {
      title: t('general.accepted'),
      dataIndex: 'accepted_appellations_count',
      key: 'accepted_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.accepted_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--red_4)',
          component
        });
      }
    },

    {
      title: t('general.total'),
      dataIndex: 'total_appellations_count',
      key: 'total_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.total_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      width: 170,
      render: (text, record) => {
        const component = <SText>{text}</SText>;

        return getColorizedColumn({
          color: 'var(--gray_4)',
          component
        });
      }
    }
  ];

  const arrayOfUsers = Object.keys(users) || [];
  const hasConflicts = hasConflictFilters({
    currentFilters: get(filters, 'reviewersIds', []),
    availableReduxFilters: arrayOfUsers
  });

  const renderDescription = () => {
    if (hasConflicts) {
      return (
        <>
          <SText color="#333333" fontSize="21px" fontWeight="600" style={{ lineHeight: '25px' }}>
            {t('dashboardPages.conflicts.usersConflict.title')}
          </SText>
          <SRow align="center" marginTop="10px">
            <SText maxWidth="604px" type="secondary" style={{ lineHeight: '20px' }}>
              {t('dashboardPages.conflicts.usersConflict.description1')}
            </SText>
          </SRow>
          <SRow align="center" marginTop="10px">
            <SText maxWidth="604px" type="secondary" style={{ lineHeight: '20px' }}>
              {t('dashboardPages.conflicts.usersConflict.description2')}
            </SText>
          </SRow>
        </>
      );
    }
    return (
      <>
        <StyledErrorMessage level={3} type="secondary">
          {t('dashboardPage.widget.checkFilters')}
        </StyledErrorMessage>
        <SText type="secondary">{t('dashboardPage.widget.emptyFiltersData')}</SText>
      </>
    );
  };

  const isEmptyWidget = get(widgetAnalytics, 'data', []).length === 0;
  const title = t('appealsPage.analytics.appellationsByReviewers.title');

  return (
    <SCard bordered shadowed>
      <SRow type="flex" wrap={false} alignItems="center" justify="space-between">
        <SCol flex="auto">
          <Tooltip title={title}>
            <WidgetTitle>{title}</WidgetTitle>
          </Tooltip>
        </SCol>
        <SCol display="flex" justifyContent="flex-end" flex="none" alignItems="center">
          {!hasConflicts && !isEmptyWidget && (
            <IconButton
              tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
              button={{
                icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                size: 'icon',
                onClick: () =>
                  startExport({
                    t,
                    id,
                    meta,
                    type,
                    users,
                    filters,
                    dispatch,
                    initialState,
                    setLoadingExport
                  })
              }}
            />
          )}
          <IconButton
            tooltip={{ title: t('dashboardPage.widget.modal.tooltip') }}
            button={{
              onClick: setVisibleFilterModal,
              size: 'icon',
              icon: <Icon icon={SettingOutlined} />
            }}
          />
        </SCol>
      </SRow>
      <SRow>
        <SCol>
          <TimePeriod type="secondary">
            {appellationsPeriodSetter(get(appealDashboard, 'filters', {}))}
          </TimePeriod>
        </SCol>
      </SRow>

      <SRow>
        <SCol marginTop="10px" span={24}>
          {isEmptyWidget && !hasConflicts && (
            <SCol marginBottom="40px">
              <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description={renderDescription()} />
            </SCol>
          )}
          {hasConflicts && (
            <SCol marginBottom="40px">
              <StyledEmpty
                image={<img src={WarningIcon} alt="warning" width="70px" />}
                description={renderDescription()}
              />
            </SCol>
          )}
          {!isEmptyWidget && !hasConflicts && (
            <RedesignWidgetTable
              rowKey="id"
              dataSource={tableData}
              columns={loading ? [] : tableColumns}
              loading={loadingTable}
              size="small"
              showSorterTooltip={false}
              pagination={{
                position: 'bottomLeft',
                showSizeChanger: true,
                total: meta?.total_count,
                current: meta?.page,
                pageSize: page?.size + 1,
                pageSizeOptions: ['10', '25', '50']
              }}
              onChange={(pagination, _, sorter) =>
                onTableChange({
                  pagination,
                  sorter,
                  widgetId: id,
                  tableColumns,
                  filters,
                  dispatch,
                  setInitialState
                })
              }
            />
          )}
          <AddUsersModal
            selectedUsersIds={hasConflicts ? [] : filters?.reviewersIds}
            onCancel={() => setVisibleFilterModal(false)}
            visible={isVisibleFilterModal}
            onAdd={ids =>
              onAdd({
                id,
                updatedFilter: { reviewersIds: ids },
                filters,
                page,
                setVisibleFilterModal,
                dispatch
              })
            }
            addedFilters={{ withRolePermissions: [PERMISSIONS.CAN_MAKE_REVIEW] }}
            columns={['employee', 'unit']}
          />
        </SCol>
      </SRow>
    </SCard>
  );
};

export default AppellationsByReviewers;
