import { TruncatedButton } from 'components/Truncated';
import { COMMUNICATION_TYPES } from 'core/utils/constants';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { setCommunication } from 'redux/ui/clientInteractionDrawer/reducer';

const AddCustomCommunicationButton = ({ disabled, setCommunication }) => {
  const { t } = useTranslation();

  const openCustomCommunicationDrawer = () => {
    setCommunication({ type: COMMUNICATION_TYPES.CUSTOM });
  };

  return (
    <TruncatedButton
      disabled={disabled}
      type="dashed"
      block
      onClick={openCustomCommunicationDrawer}
    >
      {t('menu.appMenu.addReview')}
    </TruncatedButton>
  );
};

const mapDispatchToProps = {
  setCommunication
};

export default connect(null, mapDispatchToProps)(AddCustomCommunicationButton);
