import Icon from '@ant-design/icons';
import React from 'react';
import Low from 'components/UserPreview/levels/Low';
import Medium from 'components/UserPreview/levels/Medium';
import Standard from 'components/UserPreview/levels/Standard';
import High from 'components/UserPreview/levels/High';
import Expert from 'components/UserPreview/levels/Expert';

export const LEVEL_RANK_TO_ICON = {
  1: () => <Icon component={Low} style={{ fontSize: '16px' }} />,
  2: () => <Icon component={Medium} style={{ fontSize: '16px' }} />,
  3: () => <Icon component={Standard} style={{ fontSize: '16px' }} />,
  4: () => <Icon component={High} style={{ fontSize: '16px' }} />,
  5: () => <Icon component={Expert} style={{ fontSize: '16px' }} />
};
