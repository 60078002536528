import React from 'react';
import { ReloadOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import IconButton from 'components/IconButton';

const ReloadButton = ({ reloadFunction }) => {
  return (
    <Tooltip title="Перезагрузить плеер">
      <IconButton
        icon={<ReloadOutlined />}
        shape="circle-outline"
        size="small"
        onClick={reloadFunction}
        style={{ marginTop: 2 }}
      />
    </Tooltip>
  );
};

export default ReloadButton;
