import React from 'react';
import WidgetTable from 'components/Dashboard/WidgetTable';

const scroll = { x: 1500, y: 452 };
const style = { marginTop: 8 };
const rowClassName = record => (record.key === 'header' ? 'table-row-light' : '');

const TableView = ({ columns, dataSource }) => {
  return (
    <WidgetTable
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      size="small"
      bordered
      scroll={scroll}
      style={style}
      rowClassName={rowClassName}
    />
  );
};

export default TableView;
