import questionGroupsAnalyticsReducer from 'redux/entities/analytics/questionGroups/reducer';
import questionsAnalyticsReducer from 'redux/entities/analytics/questions/reducer';
import dashboardAnalyticsReducer from 'redux/entities/analytics/dashboard/reducer';
import checklistDefinitionsAnalyticsReducer from 'redux/entities/analytics/checkilstDefinitions/reducer';
import { commentsAnalyticsReducer } from 'redux/entities/analytics/comments/reducer';
import { userAnalyticsReducer } from 'redux/entities/analytics/users/reducer';
import analyticsWidgetsReducer from 'redux/entities/analytics/widgets/reducer';

export default {
  users: userAnalyticsReducer,
  questionGroupsAnalytics: questionGroupsAnalyticsReducer,
  questionsAnalytics: questionsAnalyticsReducer,
  dashboardAnalytics: dashboardAnalyticsReducer,
  commentsAnalytics: commentsAnalyticsReducer,
  checklistDefinitionsAnalytics: checklistDefinitionsAnalyticsReducer,
  analyticsWidgets: analyticsWidgetsReducer
};
