export default {
  recoverPasswordPage: {
    title: 'Recuperar la contraseña',
    description: 'Introduzca el correo electrónico que usted utiza para su cuenta.',
    buttons: { login: 'Entrar' },
    form: {
      fields: { emailPlaceholder: 'Intruduzca su correo electrónico' },
      messages: {
        enterEmail: 'Intruduzca el correo electrónico',
        wrongEmail: 'Introduzca el correo electrónico correcto'
      },
      buttons: { continue: 'Continuar' }
    },
    success: {
      title: '¡Gracias!',
      description:
        'Le hemos enviado las instrucciones para recuperar la contraseña al correo electrónico señalado por Usted. Si no recibe el mensaje con las instrucciones en un período de 10 minutos, revise si el correo electrónico que señaló es correcto.',
      buttons: {
        login: 'Entrar',
        register: 'Registrarse'
      }
    },
    setNewPassword: {
      title: {
        newUser: '👋 Bienvenido!',
        setPass: '🔐 Piense una contraseña segura'
      },
      form: {
        newPassMessage: 'Piense una contraseña segura para',
        fields: { passwordPlaceholder: 'Introduzca la contraseña' },
        messages: {
          enterPass: 'Introduzca la contraseña',
          minLengthPassword: 'Mínimo 6 simbolos'
        },
        buttons: { continue: 'Continuar' }
      }
    }
  }
};
