import SCol from 'components/Standard/SCol';
import { Row, Tooltip } from 'antd';
import { WidgetTitle } from 'components/WidgetTitle';
import { IconButton } from 'components/Buttons';
import Icon from 'components/Icon';
import { QuestionCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

const CardTableHeader = ({ name }) => {
  const { t } = useTranslation();

  return (
    <Row align="middle">
      <SCol>
        <Tooltip title={name}>
          <WidgetTitle>{name}</WidgetTitle>
        </Tooltip>
      </SCol>
      <SCol display="flex" justifyContent="flex-end">
        <IconButton
          tooltip={{
            title: t('teamPage.unitTable.infoIcons.info'),
            overlayStyle: { width: 500 }
          }}
          button={{
            icon: <Icon icon={QuestionCircleOutlined} />,
            style: { width: 24, marginRight: 4 }
          }}
        />
      </SCol>
    </Row>
  );
};

export default CardTableHeader;
