import { LogOut, MoreVertical, Phone } from 'react-feather';
import { Button, Col, Dropdown, Menu, Row, Tag } from 'antd';
import SettingsButton from 'components/Buttons/SettingsButton';
import CommentsPreview from 'components/Comments/CommentsPreview/CommentsPreview';
import UserPreview from 'components/UserPreview';
import { beatifyFloat } from 'core/utils/convertFloat';
import moment from 'moment';
import { ListItemContainer } from 'pages/BitrixIntegration/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { utils } from 'react-media-player';
import { Link, withRouter } from 'react-router-dom';
import Icon from 'components/Icon';
import SCol from 'components/Standard/SCol';

const ReviewPreview = ({ review }) => {
  const { communication, checklist, id, createdAt, reviewerId, comments = {} } = review;
  const { direction, duration, operatorId } = communication;
  const { t } = useTranslation();

  const handleMenuClick = ({ key }) => {
    switch (key) {
      case 'open-in-app':
        window.open(`/reviews/${id}`);
        break;

      default:
        break;
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="open-in-app">
        <Icon icon={LogOut} />
        {t('bitrixIntegrationPage.reviewPreview.menu.openInApp')}
      </Menu.Item>
    </Menu>
  );

  const Direction = () => {
    switch (direction) {
      case 'incoming':
        return <span>{t('bitrixIntegrationPage.reviewPreview.directions.incoming')}</span>;
      case 'outcoming':
        return <span>{t('bitrixIntegrationPage.reviewPreview.directions.outcoming')}</span>;
      default:
        return (
          <Tag color="red">
            {t('bitrixIntegrationPage.reviewPreview.directions.notInstalledType')}
          </Tag>
        );
    }
  };
  return (
    <ListItemContainer>
      <Row type="flex" align="middle" justify="space-between" gutter={[16, 16]}>
        <Col span={1}>
          <Link to={`/bitrix/reviews/${id}`}>
            <Button type="link" icon={<Icon icon={Phone} />} />
          </Link>
        </Col>
        <Col span={3}>
          <UserPreview userId={operatorId} disabled />
        </Col>
        <SCol display="flex" justifyContent="center" span={2}>
          <Direction />
        </SCol>
        <SCol display="flex" justifyContent="center" span={4}>
          <span>{moment(createdAt).format('DD/MM/YYYY, HH:mm')}</span>
        </SCol>
        <SCol display="flex" justifyContent="center" span={2}>
          <span>
            {utils.formatTime(duration) ||
              t('bitrixIntegrationPage.reviewPreview.columns.unistalledTime')}
          </span>
        </SCol>
        <SCol display="flex" justifyContent="center" span={3}>
          <CommentsPreview comments={comments} checklistData={checklist} />
        </SCol>
        <SCol display="flex" justifyContent="center" span={2}>
          {checklist && beatifyFloat(checklist.score)}
        </SCol>
        <Col span={4}>
          <UserPreview userId={reviewerId} disabled />
        </Col>
        <Col span={1} onClick={e => e.stopPropagation()}>
          <Dropdown overlay={menu} trigger={['click']}>
            <SettingsButton
              style={{ background: 'transparent' }}
              icon={<Icon icon={MoreVertical} />}
            />
          </Dropdown>
        </Col>
      </Row>
    </ListItemContainer>
  );
};

export default withRouter(ReviewPreview);
