import React from 'react';
import { Link } from 'react-router-dom';
import { stringify } from 'core/utils/queryString';
import { getFilters } from '../../../../DashboardPage/utils';

const FlagLink = ({ color, filters, userId, children }) => {
  return (
    <Link
      to={`/user/${userId}/comments${stringify({
        filters: {
          ...getFilters(filters),
          commentsRatingFlags: color
        }
      })}`}
      type="link"
    >
      {children}
    </Link>
  );
};

export default FlagLink;
