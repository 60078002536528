import { Table } from 'antd';
import styled, { css } from 'styled-components';

export default styled(Table)`
  &.ant-table-wrapper th {
    background-color: white !important;
  }

  &.ant-table-wrapper .ant-table {
    border: none;

    .ant-table-hide-scrollbar {
      ::-webkit-scrollbar-thumb {
        background: transparent;
      }
      ::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }
      ::-webkit-scrollbar-track {
        background: transparent;
      }
    }

    .ant-table-row .ant-table-row-indent.indent-level-1 {
      padding-left: 4px !important;
    }
  }

  & .ant-table-pagination.ant-pagination {
    margin-bottom: 0;
    margin-top: 16px;
  }

  &.ant-table-wrapper td {
    background-color: white !important;
    border-bottom: ${props => (props.border ? '1px solid inherit' : 'none')};
  }

  .ant-table-container::before,
  .ant-table-container::after {
    display: ${props => (props.useEdgeShadow ? 'block' : 'none')};
  }

  ${props =>
    props.fixSorterColumns &&
    css`
      &.ant-table-wrapper .ant-table .ant-table-thead .ant-table-column-sorters {
        justify-content: flex-start;
      }
      &.ant-table-wrapper .ant-table .ant-table-thead .ant-table-column-sorters span {
        margin-right: 6px;
      }
    `}

  ${props =>
    props.fixCenterSorterColumns &&
    css`
      &.ant-table-wrapper .ant-table .ant-table-thead .ant-table-column-sorters {
        justify-content: center;
      }
      &.ant-table-wrapper .ant-table .ant-table-thead .ant-table-column-sorters span {
        margin-right: 6px;
      }
    `}
`;
