import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { get, remove, last, isEqual, keys, isEmpty, head } from 'lodash';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import {
  CHECKLIST_DEFINITION_STATUS,
  CLIENT_INTERACTIONS_TYPES_LITERALS,
  DATE_PICKER_MODES,
  SYMBOLIC_TIME_RANGE
} from 'core/utils/constants';
import { getChecklistsDefinitionsByStatuses } from 'redux/selectors/checklists';
import { selectSearch } from 'core/utils/selectSearch';
import { updateAppellationsDashboardPageFilters } from 'redux/entities/dashboardPages/appellationsDashboardPage/operations';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import ChoiceSelect from 'components/Inputs/ChoiceSelect';

const Filters = ({ hasConflicts }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Option } = Select;

  const loading = useSelector(state => state.dashboardPages.loading);
  const checklistDefinitions = useSelector(
    state =>
      getChecklistsDefinitionsByStatuses(state, {
        statuses: [
          CHECKLIST_DEFINITION_STATUS.PUBLISHED.value,
          CHECKLIST_DEFINITION_STATUS.ARCHIVED.value
        ]
      }),
    isEqual
  );

  const appealDashboard = useSelector(
    state => get(state, 'dashboardPages.dashboardsByTypes.appellations', {}),
    isEqual
  );
  const { filters } = appealDashboard;

  const onChange = updatedFilter => {
    dispatch(
      updateAppellationsDashboardPageFilters({
        filters: {
          ...filters,
          ...updatedFilter
        }
      })
    );
  };

  return (
    <SCard rounded={false}>
      <SRow type="flex" justify="space-between">
        <SCol width="calc(100% - 105px)">
          <SRow gutter={[8, 0]}>
            <SCol lg={6} xl={6} xxl={4}>
              <ChoiceSelect
                width="100%"
                placeholder={t('general.clientInteractionType')}
                allowClear
                mode="multiple"
                maxTagCount={0}
                loading={loading}
                value={hasConflicts ? [] : filters?.clientInteractionTypes}
                maxTagPlaceholder={selectedKeys =>
                  `${t('general.clientInteractionTypes')}: ${selectedKeys.length}`
                }
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
                keyName="clientInteractionTypes"
                onChange={type => onChange({ clientInteractionTypes: type })}
                filters={filters?.clientInteractionTypes}
                items={keys(CLIENT_INTERACTIONS_TYPES_LITERALS).reduce(
                  (acc, id) => [...acc, { id }],
                  []
                )}
                updateFilters={updatedFilter =>
                  updateAppellationsDashboardPageFilters({
                    filters: {
                      ...filters,
                      ...updatedFilter
                    }
                  })
                }
              >
                {keys(CLIENT_INTERACTIONS_TYPES_LITERALS).map(type => (
                  <Option key={type} value={type}>
                    <SText>{t(CLIENT_INTERACTIONS_TYPES_LITERALS[type])}</SText>
                  </Option>
                ))}
              </ChoiceSelect>
            </SCol>

            <SCol lg={6} xl={6} xxl={4}>
              <ChecklistDefinitionsSelect
                placeholder={t('general.checklistDefinitions')}
                checklistDefinitions={checklistDefinitions}
                loading={loading}
                onChange={checklistDefinitionsIds => {
                  if (
                    checklistDefinitionsIds.includes('all') &&
                    last(checklistDefinitionsIds) === 'all'
                  ) {
                    onChange({ checklistDefinitionsIds: [] });
                  } else {
                    onChange({
                      checklistDefinitionsIds: remove(checklistDefinitionsIds, n => n !== 'all')
                    });
                  }
                }}
                value={
                  isEmpty(filters?.checklistDefinitionsIds)
                    ? ['all']
                    : filters?.checklistDefinitionsIds
                }
                style={{ width: '100%' }}
                mode="multiple"
                maxTagCount={0}
                maxTagPlaceholder={selectedKeys => {
                  const { length } = selectedKeys;
                  if (length === 1 && head(selectedKeys)?.value === 'all')
                    return t('components.checklistsDefinitionsSelect.allChecklists');
                  return `${t('general.checklistDefinitions')}: ${length}`;
                }}
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'searchValue' })
                }
                allowClear
                showSearch
                allowAll
              />
            </SCol>

            <SCol lg={6} xl={6} xxl={4}>
              <CustomDatePicker
                onChange={filters => {
                  onChange({ ...filters });
                }}
                value={
                  hasConflicts
                    ? { symbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH }
                    : {
                        timeFrom: filters?.appellationCreatedAtTimeFrom,
                        timeTo: filters?.appellationCreatedAtTimeTo,
                        symbolicTimeRange: filters?.appellationCreatedAtSymbolicTimeRange
                      }
                }
                style={{ width: '100%' }}
                position="center"
                mode={DATE_PICKER_MODES.custom.appellation}
                loading={loading}
              />
            </SCol>
          </SRow>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default Filters;
