import React from 'react';
import { Trans } from 'react-i18next';
import { WIDGETS_DESCRIPTIONS } from 'core/utils/constants';

export default {
  reviews_count_by_reviewers_history: {
    type: 'reviews_count_by_reviewers_history',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.REVIEWS_COUNT_BY_REVIEWERS_HISTORY.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.REVIEWS_COUNT_BY_REVIEWERS_HISTORY.title} />,
    dateMode: 'review',
    availableSizes: ['large'],
    availableFilters: [
      {
        type: 'reviewDates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'reviewersIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      }
    ]
  },
  checklist_definition_average_score_by_operators_history: {
    type: 'checklist_definition_average_score_by_operators_history',
    description: (
      <Trans
        i18nKey={
          WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS_HISTORY.description
        }
      />
    ),
    name: (
      <Trans
        i18nKey={WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS_HISTORY.title}
      />
    ),
    dateMode: 'clientInteraction',
    availableSizes: ['large'],
    availableFilters: [
      {
        type: 'clientInteractionDates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'requiredOperatorsIds'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  checklist_definition_average_score_by_units_history: {
    type: 'checklist_definition_average_score_by_units_history',
    description: (
      <Trans
        i18nKey={
          WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_UNITS_HISTORY.description
        }
      />
    ),
    name: (
      <Trans
        i18nKey={WIDGETS_DESCRIPTIONS.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_UNITS_HISTORY.title}
      />
    ),
    dateMode: 'clientInteraction',
    availableSizes: ['large'],
    availableFilters: [
      {
        type: 'clientInteractionDates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  average_score_by_questions_history: {
    type: 'average_score_by_questions_history',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.AVERAGE_SCORE_BY_QUESTIONS_HISTORY.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.AVERAGE_SCORE_BY_QUESTIONS_HISTORY.title} />,
    dateMode: 'clientInteraction',
    availableSizes: ['large'],
    availableFilters: [
      {
        type: 'clientInteractionDates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  average_score_by_question_groups_history: {
    type: 'average_score_by_question_groups_history',
    description: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.AVERAGE_SCORE_BY_QUESTION_GROUPS_HISTORY.description} />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.AVERAGE_SCORE_BY_QUESTION_GROUPS_HISTORY.title} />,
    dateMode: 'clientInteraction',
    availableSizes: ['large'],
    availableFilters: [
      {
        type: 'clientInteractionDates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'singleChecklistDefinitionId'
      }
    ]
  },
  phone_calls_average_duration_by_operators_history: {
    type: 'phone_calls_average_duration_by_operators_history',
    description: (
      <Trans
        i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_AVERAGE_DURATION_BY_OPERATORS_HISTORY.description}
      />
    ),
    name: (
      <Trans
        i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_AVERAGE_DURATION_BY_OPERATORS_HISTORY.title}
      />
    ),
    dateMode: 'clientInteraction',
    availableSizes: ['large'],
    availableFilters: [
      {
        type: 'clientInteractionDates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      },
      {
        type: 'isReviewed'
      },
      {
        type: 'direction'
      }
    ]
  },
  phone_calls_average_duration_by_units_history: {
    type: 'phone_calls_average_duration_by_units_history',
    description: (
      <Trans
        i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_AVERAGE_DURATION_BY_UNITS_HISTORY.description}
      />
    ),
    name: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.PHONE_CALLS_AVERAGE_DURATION_BY_UNITS_HISTORY.title} />
    ),
    dateMode: 'clientInteraction',
    availableSizes: ['large'],
    availableFilters: [
      {
        type: 'clientInteractionDates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      },
      {
        type: 'isReviewed'
      },
      {
        type: 'direction'
      }
    ]
  },
  client_interactions_count_by_operators_history: {
    type: 'client_interactions_count_by_operators_history',
    description: (
      <Trans
        i18nKey={WIDGETS_DESCRIPTIONS.CLIENT_INTERACTIONS_COUNT_BY_OPERATORS_HISTORY.description}
      />
    ),
    name: (
      <Trans i18nKey={WIDGETS_DESCRIPTIONS.CLIENT_INTERACTIONS_COUNT_BY_OPERATORS_HISTORY.title} />
    ),
    dateMode: 'clientInteraction',
    availableSizes: ['large'],
    availableFilters: [
      {
        type: 'clientInteractionDates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'operatorsIds'
      },
      {
        type: 'reviewersIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      },
      {
        type: 'isReviewed'
      },
      {
        type: 'direction'
      }
    ]
  },
  client_interactions_count_by_units_history: {
    type: 'client_interactions_count_by_units_history',
    description: (
      <Trans
        i18nKey={WIDGETS_DESCRIPTIONS.CLIENT_INTERACTIONS_COUNT_BY_UNITS_HISTORY.description}
      />
    ),
    name: <Trans i18nKey={WIDGETS_DESCRIPTIONS.CLIENT_INTERACTIONS_COUNT_BY_UNITS_HISTORY.title} />,
    dateMode: 'clientInteraction',
    availableSizes: ['large'],
    availableFilters: [
      {
        type: 'clientInteractionDates'
      },
      {
        type: 'unitsIds'
      },
      {
        type: 'multipleChecklistDefinitionsIds'
      },
      {
        type: 'isReviewed'
      },
      {
        type: 'direction'
      }
    ]
  }
};
