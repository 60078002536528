import { createSelector } from '@reduxjs/toolkit';
import { getScopedKey } from 'core/utils/addScopeToObject';

// TODO : Один общий селектор с параметрами usedForFilters и тд

export const getCustomFieldsByIds = createSelector(
  state => state.customFieldsResource.byIds,
  (_, camelKeyDeep) => camelKeyDeep,
  (customFieldsByIds, camelKeyDeep) => {
    return camelKeyDeep
      ? customFieldsByIds
      : Object.values(customFieldsByIds).reduce(
          (acc, item) => ({
            ...acc,
            [item.id]: {
              ...customFieldsByIds[item.id],
              key: item.key
            }
          }),
          {}
        );
  }
);

export const getCustomFieldsByKeys = createSelector(
  getCustomFieldsByIds,
  (_, addScope) => addScope,
  (_, camelKeyDeep) => camelKeyDeep,
  (customFieldsByIds, addScope) => {
    return Object.values(customFieldsByIds).reduce(
      (acc, item) => ({
        ...acc,
        [addScope ? getScopedKey('customField', item.key) : item.key]: {
          ...customFieldsByIds[item.id],
          key: item.key
        }
      }),
      {}
    );
  }
);

// export const getCustomFieldsByKeysForFilters = createSelector(
//   getCustomFieldsByKeys,
//   customFieldsByKeys => {
//     return Object.values(customFieldsByKeys).reduce(
//       (acc, customField) =>
//         customField?.usedForFilters
//           ? {
//               ...acc,
//               [camelcase(customField.key)]: { ...customField }
//             }
//           : acc,
//       {}
//     );
//   }
// );

export const getCustomFieldsByKeysForReviews = createSelector(
  getCustomFieldsByKeys,
  (_, options) => options,
  (customFieldsByKeys, addScope) => {
    return Object.values(customFieldsByKeys).reduce(
      (acc, customField) =>
        customField?.usedForReviews
          ? {
              ...acc,
              [addScope ? getScopedKey('customField', customField.key) : customField.key]: {
                ...customField
              }
            }
          : acc,
      {}
    );
  }
);
