import React from 'react';
import { Result } from 'antd';
import mobile from 'images/mobile.gif';
import { useTranslation } from 'react-i18next';
import { StyledDiv } from './styled';

const MobileBLocked = () => {
  const { t } = useTranslation();
  return (
    <StyledDiv>
      <h1>Qolio</h1>
      <Result
        icon={<img src={mobile} width="100%" alt="mobileBLocked" />}
        title={t('errorPages.mobileBlocked.title')}
        subTitle={t('errorPages.mobileBlocked.description')}
      />
    </StyledDiv>
  );
};

export default MobileBLocked;
