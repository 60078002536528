import Editor from 'components/Comments/Editor';
import SButton from 'components/Standard/SButton';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { isEmpty } from 'lodash';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

const ActionCommentForm = ({ onSend }) => {
  const { t } = useTranslation();
  const editorRef = useRef(null);
  const [sendingComment, setSendingComment] = useState(false);
  const [commentState, setCommentState] = useState({ text: '', uploadedFiles: [] });
  const isEmptyComment = isEmpty(commentState.uploadedFiles)
    ? isEmpty(commentState.text?.trim())
    : false;

  const handleSend = async () => {
    setSendingComment(true);
    try {
      await onSend(commentState);
      setCommentState({ text: '', uploadedFiles: [] });
      editorRef.current.setEditorState({ text: '' });
    } catch (error) {
      console.log(error);
    }

    setSendingComment(false);
  };

  return (
    <SRow align="top" gutter={[14, 0]}>
      <SCol span={16}>
        <Editor
          commentState={commentState}
          setCommentState={setCommentState}
          showFlags={false}
          showTemplates
          showAttachments
          ref={editorRef}
          actionsComponent={
            <SButton
              type="primary"
              onClick={handleSend}
              disabled={isEmptyComment || sendingComment}
            >
              {t('general.send')}
            </SButton>
          }
        />
      </SCol>
    </SRow>
  );
};

export default ActionCommentForm;
