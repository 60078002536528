import { pick, isEqual } from 'lodash';

export const customEveryByProps = (items = [], propsName = 'ratingMode') => {
  return items.reduce((previousValue, currentValue, currentIndex) => {
    if (currentIndex === 0) return currentValue[propsName];
    return previousValue &&
      isEqual(pick(currentValue, [propsName]), pick(items[currentIndex - 1], [propsName]))
      ? currentValue[propsName]
      : false;
  }, false);
};
