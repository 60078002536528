import { Button } from 'antd';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  flexbox,
  background,
  space,
  layout,
  color,
  variant,
  typography,
  border,
  shadow,
  position
} from 'styled-system';

const StyledButton = styled(Button)`
&.ant-btn {
    &:hover {
      ${props => props._hover}
    }
    &:focus {
      ${props => props._focus}
    }
    ${variant({
      prop: 'size',
      variants: {
        icon: {
          width: '24px',
          height: '24px'
        },
        small: {
          width: '120px',
          height: '24px',
          fontSize: '12px'
        },
        middle: {
          width: '197px',
          height: '32px'
        },
        big: {
          width: '230px',
          height: '40px',
          fontSize: '16px'
        },
        large: {
          width: '230px',
          height: '52px',
          fontSize: '20px'
        }
      }
    })}
    ${typography}
    ${flexbox}
    ${space}
    ${layout}
    ${color}
    ${border}
    ${background}
    ${shadow}
    ${position}
}
`;

export default props => {
  const mounted = React.useRef(false);
  const [loadingByHandler, setLoadingByHandler] = useState(false);
  React.useEffect(() => {
    // Will set it to true on mount ...
    mounted.current = true;
    return () => {
      // ... and to false on unmount
      mounted.current = false;
    };
  }, []);

  const clickHandler = useCallback(
    async e => {
      setLoadingByHandler(true);
      try {
        if (props?.onClick) await props.onClick(e);
      } catch (error) {
        console.log(error);
      }

      if (mounted.current) {
        setLoadingByHandler(false);
      }
    },
    [props.onClick]
  );
  return (
    <StyledButton
      {...props}
      onClick={clickHandler}
      loading={props.showLoad ? props.loading || loadingByHandler : props.loading}
    />
  );
};
