export default {
  customCommunicationPage: {
    title: 'Nuova valutazione della comunicazione',
    form: {
      title: 'Autore: ',
      fields: {
        operator: 'Dipendente',
        communicationType: 'Tipo di comunicazione',
        clientPhoneNumber: 'Numero di telefono',
        communicationDate: 'Data di comunicazione',
        duration: 'Durata',
        direction: 'Direzione della comunicazione',
        email: 'Email',
        additional: 'Aggiuntivo',
        communicationId: 'ID della comunicazione',
        mediaUrl: 'Link del file multimediale'
      },
      messages: {
        requiredOperator: 'Per favore seleziona un dipendente',
        requiredCommunicationType: 'Per favore seleziona il tipo di comunicazione',
        requiredClientPhoneNumber: 'Per favore inserisci il numero di telefono',
        requiredCommunicationDate: 'Per favore seleziona una data di comunicazione',
        requiredDirection: 'Per favore seleziona una direzione',
        requiredEmail: "Per favore inserisci l'email",
        wrongEmail: 'Per favore inserisci una email valida',
        requiredCommunicationId: "Per favore inserisci l'Id della comunicazione",
        wrongMediaUrl: 'Per favore inserisci un Url valido',
        communicationIdTooltip:
          "In questo campo puoi aggiungere qualsiasi identificatore che ti aiutera' a trovare la comunicazione desiderata in futuro"
      },
      buttons: {
        openFile: 'Apri file allegato',
        addUrl: 'Aggiungi un link del file'
      }
    },
    messages: {
      needToChangeMode: "Per eseguire l'azione, passa alla modalita' di modifica della valutazione",
      commentSuccessfullyUpdated: 'Commento aggiornato',
      updateCommentFailed: 'Impossibile aggiornare il commento',
      communicationSuccessfullyCreated: 'Valutazione creata',
      communicationSuccessfullyUpdated: 'Valutazione aggiornata',
      createCommunicationFailed: 'Impossibile creare la comunicazione',
      updateCommunicationFailed: 'Impossibile aggiornare la valutazione',
      reviewSuccessfullyDeleted: 'Valutazione eliminata',
      deleteReviewFailed: 'Impossibile eliminare la valutazione',
      disabledOperator: "Uff... Questo utente non e' attivo 😢",
      needToActivateOperator: "Per passare alla valutazione, attiva l'utente",
      unsavedReview: "La valutazione non verra' salvata quando si passa a un'altra pagina",
      isEmptyQuestionsValues:
        'Impossibile salvare la valutazione vuota. Valuta almeno un criterio del modulo di valutazione'
    },
    buttons: {
      viewGeneralComment: 'Visualizza commento generale',
      addGeneralComment: 'Aggiungi un commento generale',
      editGeneralComment: 'Modifica commento generale',
      viewOperatorSettings: 'Vai alle impostazioni',
      saveReview: 'Salva valutazione',
      editReview: 'Modifica voto'
    },
    confirmDeleteReview: {
      title: 'Eliminare la valutazione?',
      description: "Valutazione eliminata non puo' essere ripristinata"
    }
  }
};
