import React from 'react';

const ExcelIcon = () => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="14px"
    height="14px"
    viewBox="0 0 500.000000 500.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
      fill="currentColor"
      stroke="none"
    >
      <path
        d="M2420 4978 c-25 -5 -83 -19 -130 -30 -47 -11 -98 -23 -115 -25 -16
-3 -64 -13 -105 -23 -83 -20 -211 -48 -365 -81 -55 -12 -145 -32 -200 -44 -96
-22 -182 -42 -380 -85 -49 -11 -139 -31 -200 -44 -60 -14 -153 -34 -205 -46
-52 -11 -133 -29 -180 -40 -47 -10 -139 -31 -205 -46 -66 -14 -154 -35 -196
-45 -41 -11 -87 -19 -102 -19 l-27 0 0 -1944 0 -1944 77 -17 c43 -9 123 -27
178 -40 55 -13 145 -33 200 -45 55 -12 136 -30 180 -40 182 -41 316 -71 410
-91 138 -29 262 -56 380 -84 104 -24 219 -49 390 -86 55 -12 136 -30 180 -42
44 -11 89 -21 100 -22 22 -1 173 -35 296 -66 63 -16 113 -19 297 -19 l222 0 0
315 0 315 1040 0 1040 0 0 1860 0 1860 -1040 0 -1040 0 0 315 0 315 -227 -1
c-126 -1 -248 -6 -273 -11z m80 -2477 l0 -2079 -27 8 c-16 5 -73 18 -128 30
-55 11 -147 32 -205 45 -58 14 -148 34 -200 45 -52 12 -124 28 -160 37 -36 8
-88 19 -117 23 -28 4 -77 15 -108 25 -32 9 -74 18 -94 21 -20 2 -70 13 -111
23 -74 18 -166 39 -350 80 -52 11 -144 32 -205 45 -60 14 -155 35 -210 46 -55
12 -113 25 -130 29 l-30 7 -3 1615 -2 1615 77 18 c43 9 116 25 163 36 47 10
137 30 200 44 63 14 158 35 210 47 52 11 131 28 175 39 44 10 125 28 180 40
55 11 147 32 205 45 58 12 150 33 205 45 55 12 134 31 176 41 41 10 88 21 105
22 28 4 151 32 289 67 39 10 76 18 83 19 9 1 12 -421 12 -2078z m2090 -1 l0
-1460 -835 0 -835 0 0 215 0 215 205 0 205 0 0 200 0 200 -205 0 -205 0 0 110
0 110 205 0 205 0 0 205 0 205 -205 0 -206 0 3 108 3 107 203 3 202 2 0 200 0
200 -205 0 -205 0 0 105 0 105 205 0 205 0 0 210 0 210 -205 0 -205 0 0 105 0
105 835 0 835 0 0 -1460z"
      />
      <path
        d="M1970 3519 c-69 -10 -171 -23 -227 -30 -90 -11 -103 -15 -109 -33 -3
-12 -24 -59 -46 -106 -59 -123 -106 -226 -139 -300 -16 -36 -36 -78 -45 -94
-8 -17 -29 -73 -46 -125 -33 -103 -37 -104 -62 -10 -8 30 -22 73 -30 96 -8 22
-17 52 -20 65 -2 12 -13 57 -25 98 -37 129 -61 213 -71 257 -6 23 -15 45 -21
48 -16 11 -439 -44 -439 -56 0 -5 9 -28 19 -51 11 -24 28 -63 39 -88 53 -123
157 -361 177 -405 34 -74 61 -138 68 -155 3 -8 16 -40 30 -71 l25 -56 -203
-409 c-176 -351 -202 -410 -187 -415 10 -4 60 -12 112 -18 52 -7 140 -18 195
-26 54 -7 102 -12 106 -10 3 3 16 33 29 67 12 35 30 83 39 108 10 25 24 61 30
80 7 19 36 103 65 185 29 83 56 167 60 188 9 49 23 39 31 -21 4 -26 18 -72 32
-102 14 -30 33 -73 43 -95 10 -22 27 -60 39 -85 11 -25 37 -81 57 -125 20 -44
50 -109 66 -145 17 -36 39 -84 50 -107 17 -38 23 -42 61 -47 23 -2 114 -14
203 -26 223 -30 258 -32 246 -17 -5 6 -33 58 -62 115 -30 56 -58 102 -62 102
-5 0 -7 3 -5 8 3 4 -24 61 -59 127 -35 66 -82 156 -105 200 -22 44 -53 102
-67 128 -15 26 -62 115 -105 198 l-77 150 19 42 c11 23 56 110 101 192 89 167
146 276 214 410 26 50 80 153 121 230 75 141 81 156 68 154 -5 -1 -64 -9 -133
-20z"
      />
      <path d="M3550 3540 l0 -210 410 0 410 0 0 210 0 210 -410 0 -410 0 0 -210z" />
      <path d="M3550 2920 l0 -200 410 0 410 0 0 200 0 200 -410 0 -410 0 0 -200z" />
      <path d="M3550 2295 l0 -205 410 0 410 0 0 205 0 205 -410 0 -410 0 0 -205z" />
      <path d="M3550 1670 l0 -200 410 0 410 0 0 200 0 200 -410 0 -410 0 0 -200z" />
    </g>
  </svg>
);

export default ExcelIcon;
