import { toNumber } from 'lodash';

export const beatifyFloat = (value, numbersAfterComma = 1, isNaNAsString = true) => {
  const result = parseFloat(value)
    .toFixed(numbersAfterComma)
    .replace(/[.,]0$/, '');

  if (result === 'NaN' && isNaNAsString) return 'N/A';
  if (result === 'NaN' && isNaNAsString === false) return null;

  return toNumber(result);
};
