import styled from 'styled-components';

export const InlineCommentText = styled.span`
  && {
    overflow-wrap: break-word;

    p,
    span {
      margin-bottom: 0;
    }
  }
`;
