import React, { useState } from 'react';
import { CornerUpLeft, Edit3, Trash } from 'react-feather';
import { isEmpty, isEqual, pick } from 'lodash';
import moment from 'moment';
import { message, Popconfirm } from 'antd';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { uploadedFilesResource } from 'redux/resources/uploadedFiles';
import { updateResource } from 'core/redux/resources/updateResource';
import { InlineCommentText } from 'components/Comments/InlineCommentText';
import { StyledButton } from 'components/Checklist/ChecklistManager/components/styled';
import SCol from '../../../Standard/SCol';
import SCard from '../../../Standard/SCard';
import SRow from '../../../Standard/SRow';
import Editor from '../../../Comments/Editor';
import SButton from '../../../Standard/SButton';
import Icon from '../../../Icon';
import UserPreview from '../../../UserPreview';
import SText from '../../../Standard/SText';
import UploadedFile from '../../../Comments/Editor/UploadedFile';
import { getCurrentUser } from '../../../../redux/selectors/users';
import { commentsResource } from '../../../../redux/resources/comments';

const QuestionAppealAnswer = ({ comment, appealComment, appealId, setReplyComment }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser, isEqual);
  const [isEditing, setIsEditing] = useState(false);
  const uploadedFilesByIds = useSelector(state => state.uploadedFilesResource.byIds, isEqual);

  const [updatedComment, setUpdatedComment] = useState({
    ...comment,
    uploadedFiles: comment.uploadedFilesIds.map(id => uploadedFilesByIds[id])
  });

  const onDeleteFile = fileToDeleteId => {
    setUpdatedComment({
      ...comment,
      uploadedFiles: comment.uploadedFiles?.filter(file => file.id !== fileToDeleteId),
      uploadedFilesIds: comment.uploadedFilesIds?.filter(id => id !== fileToDeleteId)
    });

    dispatch(uploadedFilesResource.operations.deleteById({ id: fileToDeleteId }));
  };

  const deleteCommentHandler = async id => {
    try {
      await dispatch(commentsResource.operations.deleteById({ id }));
    } catch (error) {
      console.log(error);
    }
  };
  const updateComment = comment => {
    setUpdatedComment({ ...comment, updatedAt: moment().toISOString() });
  };
  const editComment = () => {
    setIsEditing(true);
  };

  const onSaveComment = async () => {
    try {
      await dispatch(
        commentsResource.operations.updateById({
          ...pick(updatedComment, ['id', 'text']),
          uploadedFilesIds: updatedComment?.uploadedFiles?.map(({ id }) => id)
        })
      );

      await updateResource({
        dispatch,
        type: 'appellationComments',
        resource: {
          ...pick(updatedComment, ['id', 'text'])
        }
      });
      setIsEditing(false);
      message.success(t('messages.success.commentSaved'));
    } catch (error) {
      console.log(error);
    }
  };

  const onCancelEditComment = () => {
    setUpdatedComment({ ...comment });
    setIsEditing(false);
  };

  return (
    <>
      {!isEditing ? (
        <SCol span={23} marginTop="-12px">
          <SCard shadowed={false} bordered={false}>
            <SRow justify="space-between" wrap={false}>
              <SCol flex="auto">
                <SRow>
                  <SCol marginRight="6px">
                    <UserPreview userId={comment?.authorId} size="extraSmall" showAvatar disabled />
                  </SCol>
                  <SCol flex="none">
                    <SText type="secondary">
                      {moment(updatedComment?.updatedAt).format('DD/MM/YYYY, HH:mm')}
                    </SText>
                  </SCol>
                </SRow>
              </SCol>
              {comment?.authorId === currentUser?.id ? (
                <SCol flex="none" display="flex" justifyContent="flex-end">
                  <Popconfirm
                    cancelButtonProps={{ fontSize: '14px', size: 'middle' }}
                    okButtonProps={{ fontSize: '14px', size: 'middle' }}
                    title={t(
                      'components.commentList.comment.commentEditButton.confirmDelete.title'
                    )}
                    okText={t('components.commentList.comment.commentEditButton.confirmDelete.ok')}
                    cancelText={t(
                      'components.commentList.comment.commentEditButton.confirmDelete.cancel'
                    )}
                    onConfirm={() => deleteCommentHandler(comment?.id)}
                  >
                    <StyledButton
                      size="icon"
                      icon={<Icon icon={Trash} size="20px" />}
                      color="var(--black_6)"
                      backgroundColor="transparent"
                      _hover="color: var(--red_primary)"
                    />
                  </Popconfirm>

                  <StyledButton
                    size="icon"
                    icon={<Icon icon={Edit3} size="20px" />}
                    color="var(--black_6)"
                    backgroundColor="transparent"
                    onClick={editComment}
                  />
                </SCol>
              ) : (
                <StyledButton
                  size="icon"
                  icon={<Icon icon={CornerUpLeft} size="20px" />}
                  flex="none"
                  display="flex"
                  justifyContent="end"
                  onClick={() =>
                    setReplyComment({
                      parentId: appealComment?.id,
                      appelationId: appealId,
                      commentType: 'appellant_comment_reply'
                    })
                  }
                  color="var(--black_6)"
                  backgroundColor="transparent"
                />
              )}
            </SRow>
            <SRow>
              <SCol span={24}>
                <InlineCommentText
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(updatedComment.text, {
                      ALLOWED_ATTR: ['target', 'href']
                    })
                  }}
                />
              </SCol>
              <SCol span={24}>
                <SRow type="flex" gutter={[4, 4]} width="100%">
                  {updatedComment?.uploadedFiles?.map(file => (
                    <UploadedFile
                      key={file.id}
                      uploadedFile={file}
                      allFiles={updatedComment?.uploadedFiles}
                      onDelete={onDeleteFile}
                    />
                  ))}
                </SRow>
              </SCol>
            </SRow>
          </SCard>
        </SCol>
      ) : (
        <SCol span={23}>
          <SCard
            width="100%"
            bordered="2px solid #C5D2FB"
            boxShadow="inset 0px -1px 0px #597ef7, inset 0px 1px 0px #597ef7, inset -1px 0px 0px #597ef7, inset 1px 0px 0px #597ef7"
          >
            <SRow>
              <SCol span={24}>
                <Editor
                  commentState={updatedComment}
                  setCommentState={updateComment}
                  showTemplates={false}
                  showFlags={false}
                  showAttachments
                  actionsComponent={
                    <>
                      <SButton onClick={onCancelEditComment}>{t('general.cancel')}</SButton>
                      <SButton
                        type="primary"
                        onClick={onSaveComment}
                        showLoad
                        disabled={
                          isEmpty(updatedComment?.uploadedFiles)
                            ? isEmpty(updatedComment?.text)
                            : false
                        }
                      >
                        {t('general.send')}
                      </SButton>
                    </>
                  }
                />
              </SCol>
            </SRow>
          </SCard>
        </SCol>
      )}
    </>
  );
};

export default QuestionAppealAnswer;
