import createListReducer from 'core/redux/lists/listReducer';
import { appellationsResource } from 'redux/resources/appellations';
import { SYMBOLIC_TIME_RANGE } from '../../core/utils/constants';

const defaultState = {
  filters: { appellationCreatedAtSymbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH },
  page: {
    size: '10',
    number: '1'
  }
};

const { actions, reducer, operations } = createListReducer({
  name: 'appellations',
  defaultState,
  rebuildQuery: true,
  loader: appellationsResource.operations.load
});

const appellationsListReducer = reducer;

export { actions, appellationsListReducer, operations };
