import styled from 'styled-components';
import { Button } from 'antd';

export const TruncatedButton = styled(Button)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const TruncatedText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: ${props => (props.block ? 'block' : 'inline')};
`;

export default Component => styled(Component)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  &:empty {
    display: none;
  }
`;
