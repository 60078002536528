import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { DATES_VIEW_FORMAT, DATE_PICKER_MODES, SYMBOLIC_TIME_RANGE } from 'core/utils/constants';
import { Radio } from 'antd';
import ChecklistDefinitionsSelect from 'components/Checklist/ChecklistDefinitionsSelect';
import { selectSearch } from 'core/utils/selectSearch';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CustomDatePicker from 'components/DatePicker/DatePicker';
import { isDisabledDayOption } from './utils';

export const BaseFilter = ({
  onChange,
  filters,
  loading,
  checklistDefinitions,
  checklistDefinitionsSelectAllowAll = false,
  hasConflicts
}) => {
  const { t } = useTranslation();
  const options = [
    {
      label: t('constants.datesViewFormat.day'),
      value: DATES_VIEW_FORMAT.DAY.value,
      disabled: loading || isDisabledDayOption({ filters, hasConflicts })
    },
    {
      label: t('constants.datesViewFormat.week'),
      value: DATES_VIEW_FORMAT.WEEK.value,
      disabled: loading
    },
    {
      label: t('constants.datesViewFormat.month'),
      value: DATES_VIEW_FORMAT.MONTH.value,
      disabled: loading
    }
  ];

  return (
    <SRow gutter={[8, 0]}>
      <SCol lg={6} xl={6} xxl={4}>
        <CustomDatePicker
          onChange={componentFilters => {
            isDisabledDayOption({
              ...componentFilters
            }) && filters.historyGroupingPeriod === DATES_VIEW_FORMAT.DAY.value
              ? onChange({
                  ...componentFilters,
                  historyGroupingPeriod: DATES_VIEW_FORMAT.WEEK.value
                })
              : onChange({
                  ...componentFilters
                });
          }}
          value={
            hasConflicts
              ? { symbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH }
              : {
                  timeFrom: filters?.clientInteractionTimeFrom,
                  timeTo: filters?.clientInteractionTimeTo,
                  symbolicTimeRange: filters?.clientInteractionSymbolicTimeRange
                }
          }
          position="right"
          mode={DATE_PICKER_MODES.clientInteraction}
          style={{ width: '100%' }}
          loading={loading}
        />
      </SCol>
      <SCol lg={6.5} xl={6.5} xxl={3.5}>
        <Radio.Group
          options={options}
          onChange={e => {
            onChange({
              historyGroupingPeriod: e.target.value
            });
          }}
          value={hasConflicts ? DATES_VIEW_FORMAT.DAY.value : filters?.historyGroupingPeriod}
          optionType="button"
          loading={loading}
        />
      </SCol>

      <SCol lg={6} xl={6} xxl={4}>
        <ChecklistDefinitionsSelect
          placeholder={t('general.checklistDefinitionPlaceholder')}
          checklistDefinitions={checklistDefinitions}
          loading={loading}
          onChange={checklistDefinitionsIds => onChange({ checklistDefinitionsIds })}
          value={hasConflicts ? null : filters?.checklistDefinitionsIds}
          style={{ width: '100%' }}
          showArrow
          allowAll={checklistDefinitionsSelectAllowAll}
          maxTagCount={0}
          maxTagPlaceholder={selectedKeys =>
            `${t('general.checklistDefinitions')}: ${selectedKeys.length}`
          }
          filterOption={(input, option) =>
            selectSearch({ input, option, searchProp: 'searchValue' })
          }
        />
      </SCol>
    </SRow>
  );
};
