import createListReducer from 'core/redux/lists/listReducer';
import { foldersResource } from 'redux/resources/folders';

const { actions, reducer, operations } = createListReducer({
  name: 'folders',
  rebuildQuery: true,
  loader: foldersResource.operations.load
});

const foldersListReducer = reducer;

export { actions, foldersListReducer, operations };
