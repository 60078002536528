import { Select } from 'antd';
import SSelect from 'components/Standard/SSelect';
import { isEqual, isEmpty, intersection } from 'lodash';
import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import {
  getWorkPlanTaskConfigurationsByIds,
  getWorkPlanTasksByIds,
  getCurrentUserAssignmentsByTaskStatus
} from 'redux/selectors/workPlanTasks';
import { useTranslation } from 'react-i18next';
import { WORK_PLAN_TASK_CONFIGURATIONS_STATUSES } from 'core/utils/constants';

const { Option } = Select;

const WorkPlanTaskFilter = ({ selectedWorkPlanTaskConfigurationId }) => {
  const { t } = useTranslation();
  const currentUserAssignmentsIds = useSelector(
    state =>
      getCurrentUserAssignmentsByTaskStatus(state, {
        status: WORK_PLAN_TASK_CONFIGURATIONS_STATUSES.in_progress.value
      }).map(({ id }) => id),
    isEqual
  );
  const workPlanTaskConfigurationsByIds = useSelector(getWorkPlanTaskConfigurationsByIds, isEqual);
  const workPlanTasksByIds = useSelector(getWorkPlanTasksByIds, isEqual);
  const dispatch = useDispatch();

  const disabled = useSelector(
    state => state.uiClientInteractions.tableLoading || isEmpty(state.uiClientInteractions.tableId)
  );

  const avaliableTaskConfigurations = useMemo(
    () =>
      Object.values(workPlanTaskConfigurationsByIds).filter(configuration => {
        const lastTask = workPlanTasksByIds[configuration?.lastTaskId];
        return !isEmpty(intersection(lastTask?.assignmentsIds, currentUserAssignmentsIds));
      }),
    [workPlanTaskConfigurationsByIds, currentUserAssignmentsIds]
  );

  const onChange = workPlanTaskConfigurationId => {
    const configuration = workPlanTaskConfigurationsByIds[workPlanTaskConfigurationId];
    const lastTask = workPlanTasksByIds[configuration?.lastTaskId];
    dispatch(
      actions.updateFilters({
        taskAssignmentsIds: intersection(lastTask?.assignmentsIds, currentUserAssignmentsIds)
      })
    );
  };

  return (
    <SSelect
      width="100%"
      allowClear
      onChange={onChange}
      value={selectedWorkPlanTaskConfigurationId}
      disabled={disabled}
      placeholder={t('workPlanTasks.taskPlaceholder')}
    >
      {avaliableTaskConfigurations.map(configuration => (
        <Option key={configuration.id} value={configuration.id}>
          {configuration.name}
        </Option>
      ))}
    </SSelect>
  );
};

export default WorkPlanTaskFilter;
