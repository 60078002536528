import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  dashboardsByIds: {},
  dashboardsByTypes: {},
  loading: false,
  loadingWidgetId: ''
};

export const loadingDashboard = createAction('dashboardPages/loading');
export const dashboardLoaded = createAction('dashboardPages/loaded');
export const setLoadingWidgetId = createAction('dashboardPages/setLoadingWidgetId');

export const dashboardPagesReducer = createReducer(defaultState, {
  [loadingDashboard]: state => {
    return { ...state, loading: true };
  },
  [dashboardLoaded]: (state, { payload }) => ({
    ...state,
    dashboardsByIds: {
      ...state.dashboardsByIds,
      [payload?.id]: payload
    },
    dashboardsByTypes: {
      ...state.dashboardsByTypes,
      [payload?.pageType]: payload
    },
    loading: false
  }),
  [setLoadingWidgetId]: (state, { payload }) => ({
    ...state,
    loadingWidgetId: payload
  })
});

export default dashboardPagesReducer;
