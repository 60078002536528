import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  newItemName: null,
  newFolderName: null,
  currentFolderId: null,
  showModal: false
};

export const setNewItemName = createAction('uiAddToCalibrationModal/setNewItemName');
export const setNewFolderName = createAction('uiAddToCalibrationModal/setNewFolderName');
export const setFolderId = createAction('uiAddToCalibrationModal/setFolderId');
export const openAddToCalibrationModal = createAction(
  'uiAddToCalibrationModal/openAddToCalibrationModal'
);
export const closeAddToCalibrationModal = createAction(
  'uiAddToCalibrationModal/closeAddToCalibrationModal'
);
export const clearLibraryModal = createAction('uiAddToCalibrationModal/clearModal');

export const addToCalibrationModalReducer = createReducer(defaultState, {
  [setNewItemName]: (state, { payload }) => ({ ...state, newItemName: payload }),
  [setNewFolderName]: (state, { payload }) => ({ ...state, newFolderName: payload }),
  [setFolderId]: (state, { payload }) => ({ ...state, currentFolderId: payload }),
  [openAddToCalibrationModal]: state => ({ ...state, showModal: true }),
  [closeAddToCalibrationModal]: () => ({ ...defaultState })
});

export default addToCalibrationModalReducer;
