import React from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { Typography } from 'antd';
import { PERMISSIONS, PERMISSIONS_DESCRIPTIONS } from 'core/utils/constants';
import { useTranslation } from 'react-i18next';

export const LibraryPermissions = ({ fields, getCheckbox }) => {
  const { t } = useTranslation();
  const { Text } = Typography;

  return (
    <SRow gutter={[8, 8]} type="flex">
      <SCol span={24}>
        <Text strong>{t('organizationStructure.tabs.roles.modal.form.libraryCategory')}</Text>
      </SCol>

      <SCol span={12}>
        {getCheckbox(
          PERMISSIONS.CAN_ACCESS_LIBRARY,
          t(PERMISSIONS_DESCRIPTIONS.CAN_ACCESS_LIBRARY.title),
          [],
          fields[PERMISSIONS.CAN_ADD_LIBRARY_ITEMS]
        )}
      </SCol>
      <SCol span={12}>
        {getCheckbox(
          PERMISSIONS.CAN_ADD_LIBRARY_ITEMS,
          t(PERMISSIONS_DESCRIPTIONS.CAN_ADD_LIBRARY_ITEMS.title),
          [PERMISSIONS.CAN_ACCESS_LIBRARY],
          fields[PERMISSIONS.CAN_MANAGE_LIBRARY]
        )}
      </SCol>
    </SRow>
  );
};
