import { destroy, get, post, put } from 'redux-bees';

const customFieldsEndpoints = apiPath => ({
  getCustomFields: { method: get, path: `${apiPath}/custom_fields` },
  createCustomField: { method: post, path: `${apiPath}/custom_fields` },
  updateCustomField: { method: put, path: `${apiPath}/custom_fields/:id` },
  deleteCustomField: { method: destroy, path: `${apiPath}/custom_fields/:id` }
});

export default customFieldsEndpoints;
