import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  callsByIds: {},
  editingCallId: null
};

export const startEditingCall = createAction('uiUploadPage/startEditingCall');
export const finishEditingCall = createAction('uiUploadPage/finishEditingCall');
export const cancelEditingCall = createAction('uiUploadPage/cancelEditingCall');
export const updateEditingCall = createAction('uiUploadPage/updateEditingCall');

export const uploadPageReducer = createReducer(defaultState, {
  [finishEditingCall]: (state, { payload }) => ({
    ...state,
    callsByIds: { ...state.callsByIds, [payload.id]: payload },
    editingCallId: null
  }),
  [updateEditingCall]: (state, { payload }) => {
    const callId = state.editingCallId || payload.id;
    const call = { ...state.callsByIds[callId], ...payload };
    return { ...state, callsByIds: { ...state.callsByIds, [callId]: call } };
  },
  [cancelEditingCall]: state => ({
    ...state,
    editingCallId: null
  }),
  [startEditingCall]: (state, { payload }) => ({
    ...state,
    editingCallId: payload.callId
  })
});
