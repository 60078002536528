export default {
  libraryPage: {
    buttons: { createFolder: 'Creare una cartella' },
    libraryItem: {
      emptyName: 'Nome non impostato',
      added: 'Aggiunto:',
      dropdown: {
        edit: 'Modificare',
        delete: 'Elimina'
      },
      deleteFolderModal: {
        title: 'Sei sicuro di voler eliminare la cartella?',
        description: "Questa azione e' irreversibile",
        buttons: {
          cancel: 'Annulla',
          delete: 'Elimina'
        }
      },
      deleteItemModal: {
        title: "La chiamata verra' rimossa dalla libreria. Continuare?",
        buttons: {
          cancel: 'Annulla',
          delete: 'Elimina'
        }
      },
      empty: {
        folders: 'Nessuna cartella ancora aggiunta',
        calls: 'Non ci sono ancora chiamate in questa cartella'
      }
    },
    modal: {
      title: 'Inserisci un nome per la nuova cartella',
      form: { name: 'Nome' },
      buttons: {
        cancel: 'Annulla',
        create: 'Creare'
      }
    },
    messages: {
      folderSuccessfullyCreated: 'Cartella creata con successo',
      folderCreateFailed: 'Impossibile creare la cartella'
    }
  }
};
