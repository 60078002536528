import styled from 'styled-components';

export const ListItemContainer = styled.div`
  display: flex;
  padding: 8px;
  border: 1px solid var(--gray_4);
  border-radius: 8px;
  margin: 4px;

  .ant-row {
    width: 100%;
  }
`;

export const ColumnName = styled.span`
  color: rgba(0, 0, 0, 0.45);
`;
