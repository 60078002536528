import React from 'react';
import { Tooltip, Spin } from 'antd';
import SCol from 'components/Standard/SCol';
import { TimePeriod } from 'components/TimePeriod';
import { WidgetTitle } from 'components/WidgetTitle';
import { isEmpty } from 'lodash';
import SRow from 'components/Standard/SRow';
import Icon from 'components/Icon';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { IconButton } from 'components/Buttons';
import { SmallWidgetCard, SettingsButton } from '../styled';

const SmallWidget = ({
  loading,
  children,
  info,
  showArrow = false,
  onArrowClick,
  name,
  description,
  headerColor = 'white',
  ...otherProps
}) => {
  const { iconProps, disabled = false, ...otherInfo } = info;

  return (
    <SmallWidgetCard
      headerColor={headerColor}
      {...otherProps}
      title={
        <>
          <SRow type="flex" align="middle" wrap={false}>
            <SCol
              flex="auto"
              display="flex"
              alignItems="center"
              {...(showArrow && { span: 20 })}
              {...(!showArrow && { justifyContent: 'space-between' })}
            >
              <Tooltip title={name}>
                <WidgetTitle>{name}</WidgetTitle>
              </Tooltip>

              <IconButton
                {...otherInfo}
                button={{
                  disabled,
                  icon: <Icon icon={QuestionCircleOutlined} {...iconProps} />,
                  size: 'middle',
                  style: { width: 24, flex: 'none', height: 'inherit' }
                }}
              />
            </SCol>
            {showArrow && (
              <SCol
                display="flex"
                justifyContent="flex-end"
                flex="none"
                span={4}
                alignItems="center"
              >
                <SettingsButton type="down" onClick={onArrowClick} />
              </SCol>
            )}
          </SRow>
          {!isEmpty(description) && (
            <SRow>
              <SCol>
                <TimePeriod type="secondary">{description}</TimePeriod>}
              </SCol>
            </SRow>
          )}
        </>
      }
    >
      <Spin spinning={loading}>{children}</Spin>
    </SmallWidgetCard>
  );
};

export default SmallWidget;
