import React from 'react';
import { Col } from 'antd';
import { PreviewRow, WidgetPreview } from './styled';

const LMM = ({ createNewRow, activeDashboardId, rowsCount, setCollapse }) => {
  const createRow = () => {
    setCollapse(false);
    createNewRow({
      position: rowsCount + 1,
      dashboard_id: activeDashboardId,
      widgets_sizes: ['large', 'medium', 'medium']
    });
  };
  return (
    <PreviewRow gutter={[4, 4]} type="flex" align="middle" onClick={createRow}>
      <Col span={12}>
        <WidgetPreview>
          <span>L</span>
        </WidgetPreview>
      </Col>
      <Col span={6}>
        <WidgetPreview>
          <span>M</span>
        </WidgetPreview>
      </Col>
      <Col span={6}>
        <WidgetPreview>
          <span>M</span>
        </WidgetPreview>
      </Col>
    </PreviewRow>
  );
};

export default LMM;
