import renderPage from 'core/utils/renderPage';
import BitrixLoginPage from 'pages/AuthPages/BitrixLoginPage';
import AuthLayout from 'layouts/AuthLayout';
import BitrixRegisterPage from 'pages/AuthPages/BitrixRegisterPage';

export default [
  {
    exact: true,
    path: '/login',
    component: renderPage({ component: BitrixLoginPage, layout: AuthLayout })
  },
  {
    exact: true,
    path: '/register',
    component: renderPage({ component: BitrixRegisterPage, layout: AuthLayout })
  }
];
