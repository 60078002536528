import { Col, Row, Button, Typography } from 'antd';
import SCard from 'components/Standard/SCard';
import React from 'react';
import integrationsImage from 'assets/images/integrations.png';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import IntegrationsList from './IntegrationsList';

const AddIntegrationText = styled.p`
  margin: 0;
  font-size: 24px;
  font-weight: 500;
`;

const { Title } = Typography;
const AddIntegration = ({ onAdd }) => {
  const { t } = useTranslation();
  return (
    <Row gutter={[0, 16]}>
      <Col span={24}>
        <SCard bordered shadowed>
          <Row align="middle" justify="space-between">
            <Col span={24}>
              <Row align="middle" justify="space-between">
                <Col>
                  <Row align="middle" gutter={[20, 20]} style={{ margin: '-10px' }}>
                    <Col>
                      <img src={integrationsImage} alt="integrations" />
                    </Col>

                    <Col>
                      <AddIntegrationText>
                        {t('integrationsSettingsPage.addIntegration.title')}
                      </AddIntegrationText>
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Button type="primary" size="large" onClick={onAdd}>
                    {t('integrationsSettingsPage.addIntegration.title')}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </SCard>
      </Col>
      <Col span={24}>
        <Row gutter={[0, 20]}>
          <Col span={24}>
            <Title level={4}>{t('integrationsSettingsPage.addIntegration.yourIntegrations')}</Title>
          </Col>
          <Col span={24}>
            <IntegrationsList />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default AddIntegration;
