import { FileDoneOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import DOMPurify from 'dompurify';
import { get, isEmpty } from 'lodash';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { InlineCommentText } from 'components/Comments/InlineCommentText';
import { StyledCol, StyledSpan, StyleIcon } from './styled';

const PreviewIcons = ({ text, color, icon: Icon, commentsLength, checklistData }) => {
  const { t } = useTranslation();
  const StyledIcon = StyleIcon(Icon);
  const CommentsIcons = () => (
    <Popover
      overlayStyle={{
        width: '600px'
      }}
      placement="left"
      content={text}
      title={
        <>
          <StyledIcon color={color} />
          <span>{t('components.commentsPreview.previewIcons.title')}</span>
        </>
      }
    >
      <StyledIcon color={color} />
      <StyledSpan color={color}>{commentsLength}</StyledSpan>
    </Popover>
  );

  const ConditionalCommentsIcons = withConditionalRender(
    { hide: isEmpty(text) || commentsLength === 0 },
    CommentsIcons
  );

  const checklistComment = get(checklistData, 'checklist.comment', '');

  const ReviewIcon = () => (
    <Popover
      overlayStyle={{
        width: '600px'
      }}
      placement="left"
      content={
        <InlineCommentText
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(checklistComment, {
              ALLOWED_ATTR: ['target', 'href']
            })
          }}
        />
      }
      title={
        <>
          <StyledIcon color={color} />
          <span>{t('components.commentsPreview.previewIcons.generalComment')}</span>
        </>
      }
    >
      <StyledIcon color={color} />
    </Popover>
  );

  const ConditionalReviewIcon = withConditionalRender(
    {
      hide: isEmpty(checklistComment)
    },
    ReviewIcon
  );

  return (
    <StyledCol>
      {Icon === FileDoneOutlined ? <ConditionalReviewIcon /> : <ConditionalCommentsIcons />}
    </StyledCol>
  );
};

export default PreviewIcons;
