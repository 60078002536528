import React from 'react';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual, keyBy } from 'lodash';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SText from 'components/Standard/SText';
import SSelect from 'components/Standard/SSelect';
import SButton from 'components/Standard/SButton';
import { actions } from 'redux/lists/appellationsListReducer';
import {
  APPEAL_PERSON_FILTER_VALUES,
  APPEALS_STATUSES,
  CLIENT_INTERACTIONS_TYPES_LITERALS,
  PERMISSIONS,
  DATE_PICKER_MODES
} from 'core/utils/constants';
import UserPreview from 'components/UserPreview';
import { selectSearch } from 'core/utils/selectSearch';
import { getUserName } from 'components/UserPreview/getUserName';
import { useHistory } from 'react-router-dom';
import withConditionalRender from 'components/WithConditionalRender/withConditionalRender';
import { getActiveUsers, getCurrentUserPermissions } from 'redux/selectors/users';
import ChoiceSelect from 'components/Inputs/ChoiceSelect';
import CustomDatePicker from 'components/DatePicker/DatePicker';

const Filters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { Option } = Select;
  const history = useHistory();

  const users = useSelector(state => getActiveUsers(state), isEqual);

  const usersByIds = keyBy(users, 'id');

  const unitsByIds = useSelector(state => state.unitsResource.byIds, isEqual);

  const { filters } = useSelector(state => state.appellationsList);

  const ConditionalSettingsColumn = withConditionalRender(
    { allowedPermissions: [PERMISSIONS.CAN_ADMINISTRATE_APPELLATIONS] },
    SCol
  );

  const currentUserPermissions = useSelector(getCurrentUserPermissions, isEqual);

  const getColumnOffset = () => {
    const isSettingsOpen = currentUserPermissions.includes(
      PERMISSIONS.CAN_ADMINISTRATE_APPELLATIONS
    );

    if (isSettingsOpen) return '116px';

    return '0px';
  };

  return (
    <SCard rounded={false}>
      <SRow type="flex" justify="space-between">
        <SCol width={`calc(100% - ${getColumnOffset()})`}>
          <SRow gutter={[8, 0]}>
            <SCol span={3}>
              <SSelect
                width="100%"
                placeholder={t('general.status')}
                onChange={status => dispatch(actions.updateFilters({ statuses: status }))}
                value={filters?.statuses}
                allowClear
                mode="multiple"
                maxTagCount={0}
                maxTagPlaceholder={selectedKeys =>
                  `${t('general.statuses')}: ${selectedKeys.length}`
                }
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
              >
                {Object.values(APPEALS_STATUSES).map(status => (
                  <Option key={status.value} value={status.value}>
                    {t(status.title)}
                  </Option>
                ))}
              </SSelect>
            </SCol>

            <SCol span={4}>
              <ChoiceSelect
                width="100%"
                placeholder={t('general.applicator')}
                allowClear
                mode="multiple"
                maxTagCount={0}
                value={filters.appellantsIds}
                maxTagPlaceholder={selectedKeys => `${t('general.users')}: ${selectedKeys.length}`}
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'label' })
                }
                onChange={id => dispatch(actions.updateFilters({ appellantsIds: id }))}
                filters={filters?.appellantsIds}
                keyName="appellantsIds"
                items={users}
                updateFilters={actions.updateFilters}
              >
                {users.map(user => (
                  <Option
                    key={user.id}
                    value={user.id}
                    label={getUserName({ user: usersByIds[user.id] })}
                  >
                    <UserPreview disabled userId={user.id} />
                  </Option>
                ))}
              </ChoiceSelect>
            </SCol>

            <SCol span={4}>
              <ChoiceSelect
                width="100%"
                placeholder={t('general.executor')}
                allowClear
                mode="multiple"
                maxTagCount={0}
                value={filters.assignedToIds}
                maxTagPlaceholder={selectedKeys =>
                  `${t('general.executors')}: ${selectedKeys.length}`
                }
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'label' })
                }
                onChange={id => dispatch(actions.updateFilters({ assignedToIds: id }))}
                filters={filters?.assignedToIds}
                keyName="assignedToIds"
                items={users}
                updateFilters={actions.updateFilters}
              >
                {users.map(user => (
                  <Option
                    key={user.id}
                    value={user.id}
                    label={getUserName({ user: usersByIds[user.id] })}
                  >
                    <UserPreview disabled userId={user.id} />
                  </Option>
                ))}
              </ChoiceSelect>
            </SCol>

            <SCol span={3}>
              <SSelect
                width="100%"
                placeholder={t('appealsPage.filters.withCurrentUserRoles')}
                allowClear
                mode="multiple"
                maxTagCount={0}
                value={filters.withCurrentUserRoles}
                maxTagPlaceholder={selectedKeys => `${t('general.roles')}: ${selectedKeys.length}`}
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
                onChange={roleName =>
                  dispatch(actions.updateFilters({ withCurrentUserRoles: roleName }))
                }
              >
                {Object.values(APPEAL_PERSON_FILTER_VALUES).map(filterValue => (
                  <Option key={filterValue.value} value={filterValue.value}>
                    {t(filterValue.title)}
                  </Option>
                ))}
              </SSelect>
            </SCol>

            <SCol span={3}>
              <ChoiceSelect
                width="100%"
                placeholder={t('general.unit')}
                allowClear
                mode="multiple"
                maxTagCount={0}
                value={filters.unitsIds}
                maxTagPlaceholder={selectedKeys => `${t('general.units')}: ${selectedKeys.length}`}
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
                onChange={id => dispatch(actions.updateFilters({ unitsIds: id }))}
                keyName="unitsIds"
                items={Object.values(unitsByIds)}
                updateFilters={actions.updateFilters}
                filters={filters?.unitsIds}
              >
                {Object.values(unitsByIds).map(unit => (
                  <Option key={unit.id} value={unit.id}>
                    <SText>{unit.name}</SText>
                  </Option>
                ))}
              </ChoiceSelect>
            </SCol>

            <SCol span={3}>
              <SSelect
                width="100%"
                placeholder={t('general.clientInteractionType')}
                allowClear
                mode="multiple"
                maxTagCount={0}
                value={filters.clientInteractionTypes}
                maxTagPlaceholder={selectedKeys =>
                  `${t('general.clientInteractionTypes')}: ${selectedKeys.length}`
                }
                filterOption={(input, option) =>
                  selectSearch({ input, option, searchProp: 'children' })
                }
                onChange={id => dispatch(actions.updateFilters({ clientInteractionTypes: id }))}
              >
                {Object.keys(CLIENT_INTERACTIONS_TYPES_LITERALS).map(type => (
                  <Option key={type} value={type}>
                    <SText>{t(CLIENT_INTERACTIONS_TYPES_LITERALS[type])}</SText>
                  </Option>
                ))}
              </SSelect>
            </SCol>

            <SCol span={4}>
              <CustomDatePicker
                onChange={filters => dispatch(actions.updateFilters({ ...filters }))}
                value={{
                  symbolicTimeRange: filters?.appellationCreatedAtSymbolicTimeRange,
                  timeFrom: filters?.appellationCreatedAtTimeFrom,
                  timeTo: filters?.appellationCreatedAtTimeTo
                }}
                position="left"
                mode={DATE_PICKER_MODES.custom.appellation}
              />
            </SCol>
          </SRow>
        </SCol>

        <ConditionalSettingsColumn>
          <SButton type="primary" onClick={() => history.push(`/appeals-settings/`)}>
            {t('general.settings')}
          </SButton>
        </ConditionalSettingsColumn>
      </SRow>
    </SCard>
  );
};

export default Filters;
