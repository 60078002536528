import { PlusCircle } from 'react-feather';
import { Button, Col, Row } from 'antd';
import SCard from 'components/Standard/SCard';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Icon from 'components/Icon';

const TaskDefinitionsFilters = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <SCard rounded={false}>
      <Row type="flex" justify="end">
        <Col>
          <Button
            block
            type="primary"
            icon={<Icon icon={PlusCircle} />}
            onClick={() => history.push(`/settings/triggers/edit/task-definition`)}
          >
            {t('triggersSettingsPage.card.buttons.createTaskDefinitionCard')}
          </Button>
        </Col>
      </Row>
    </SCard>
  );
};

export default TaskDefinitionsFilters;
