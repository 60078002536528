import api from 'core/api';
import { createReduxResource } from 'core/redux/resources';
import decamelizeKeysDeep from 'decamelize-keys-deep';
import { usersResource } from './users';

export const userAccountBindingsResource = createReduxResource({
  name: 'userAccountBindings',
  customOperations: {
    load: {
      callEndpoint: ({ id, ...options }) => {
        if (options?.type === 's2' || options?.type === 'pyrus') {
          return api.getUserAccountBindingsV4({ id });
        }
        return api.getUserAccountBindings({ ...options });
      },
      onSuccess: ({ result }) => {
        return { resources: result };
      }
    },
    downloadCSV: {
      callEndpoint: api.getUserAccountBindingsCSV,
      onSuccess: ({ response }) => {
        return response;
      }
    },
    importSaas: {
      parseOptions: false,
      callEndpoint: async requestOptions => {
        const { id, data } = requestOptions;
        return api.importSaasUserAccountBindings({ id }, { data });
      },
      onSuccess: ({ result }) => result
    },
    importUserAccountBindings: {
      parseOptions: false,
      callEndpoint: async ({ id, data }) =>
        api.importCustomUserAccountBindings({ id }, decamelizeKeysDeep({ data })),
      onSuccess: ({ result, dispatch }) => {
        dispatch(userAccountBindingsResource.actions.loadSucceed(result));
        return result;
      }
    },
    getMatchesAndCollisions: {
      callEndpoint: ({ id, ...options }) => {
        if (options?.type === 's2' || options?.type === 'pyrus') {
          return api.getSaasUserAccountBindingsMatchesV4({ id });
        }
        return api.getSaasUserAccountBindingsMatches({ id });
      },
      onSuccess: ({ result }) => result
    },
    unbind: {
      callEndpoint: async ({ id }) => api.unbindUserAccountBinding({ id }, {}),
      onSuccess: ({ result, requestOptions, dispatch, actions, getState }) => {
        const resource = getState().userAccountBindingsResource.byIds[requestOptions.id] || {};
        dispatch(actions.updateByIdSucceed({ ...resource, userId: undefined }));
        dispatch(usersResource.actions.updateByIdSucceed(result));
        return result;
      }
    }
  }
});
