import { createAction, createReducer } from '@reduxjs/toolkit';

const getVisitedInteractions = () => {
  let visitedInteractions = [];
  try {
    visitedInteractions = JSON.parse(localStorage.getItem('visitedInteractions')) || [];
  } catch (error) {
    console.log(error);
  }

  return visitedInteractions;
};

const defaultState = {
  communication: {},
  visitedInteractions: getVisitedInteractions()
};

export const setCommunication = createAction('uiClientInteractionDrawer/setCommunication');
export const visitInteraction = createAction('uiClientInteractionDrawer/visitInteraction');

export const uiClientInteractionDrawerReducer = createReducer(defaultState, {
  [setCommunication]: (state, { payload }) => ({ ...state, communication: payload }),
  [visitInteraction]: (state, { payload }) => {
    const visited = state.visitedInteractions.includes(payload);

    if (!visited) {
      const visitedInteractions = [...state.visitedInteractions, payload];
      try {
        localStorage.setItem('visitedInteractions', JSON.stringify(visitedInteractions));
      } catch (error) {
        console.log(error);
      }

      return { ...state, visitedInteractions };
    }

    return state;
  }
});
