import React from 'react';
import { isEmpty, compact } from 'lodash';
import { Trans } from 'react-i18next';

export const getUserName = ({ user }) => {
  if (!user) return <Trans i18nKey="components.userPreview.userNotFound" />;

  const lastName = user.lastName || user['last-name'] || user.last_name;
  const firstName = user.firstName || user['first-name'] || user.first_name;
  const name = compact([lastName, firstName]).join(' ');

  return isEmpty(name) ? user.email : name;
};

export const getUserInitials = user => {
  if (!user) return '?';
  const name = user.name || getUserName({ user });
  if (!name) return '?';

  const initialsArr = name.split(' ').reduce((acc, item) => {
    if (!item) return acc;
    return [...acc, item[0]];
  }, []);

  return `${initialsArr[0] || ''}${initialsArr[1] || ''}`.toUpperCase();
};
