import AuthLayout from 'layouts/AuthLayout';
import BitrixLayout from 'layouts/BitrixLayout';
import BitrixDetailsLayout from 'layouts/BitrixLayout/BitrixDetailsLayout';
import BitrixLoginPage from 'pages/AuthPages/BitrixLoginPage';
import BitrixRegisterPage from 'pages/AuthPages/BitrixRegisterPage';
import BackgroundJob from 'pages/BackgroundJob';
import React from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import {
  ProtectedBitrixReviewsPage,
  ProtectedCallDetailsPage,
  ProtectedClientInteractionsPage,
  ProtectedReviewDetailsPage,
  ProtectedTextConversationDetailsPage
} from './pages';

const BitrixClientInteractionRoutes = ({ history }) => (
  <Router history={history}>
    <Switch>
      <Route exact path="/login">
        <AuthLayout>
          <BitrixLoginPage />
        </AuthLayout>
      </Route>
      <Route exact path="/register">
        <AuthLayout>
          <BitrixRegisterPage />
        </AuthLayout>
      </Route>
      <Route exact path="/background">
        <BackgroundJob />
      </Route>
      <Route exact path="/bitrix/client-interactions">
        <BitrixLayout>
          <ProtectedClientInteractionsPage />
        </BitrixLayout>
      </Route>
      <Redirect exact from="/" to="/bitrix/client-interactions" />
      <Route exact path="/bitrix/reviews">
        <BitrixLayout>
          <ProtectedBitrixReviewsPage />
        </BitrixLayout>
      </Route>
      <Route exact path="/bitrix/calls/:id">
        <BitrixDetailsLayout headerProps={{ title: 'Создание оценки' }}>
          <ProtectedCallDetailsPage tab="call" offsetTop={0} />
        </BitrixDetailsLayout>
      </Route>
      <Route exact path="/bitrix/text-communications/:id">
        <BitrixDetailsLayout headerProps={{ title: 'Создание оценки' }}>
          <ProtectedTextConversationDetailsPage
            tab="textCommunication"
            contentType="textCommunication"
            offsetTop={0}
          />
        </BitrixDetailsLayout>
      </Route>
      <Route exact path="/bitrix/reviews/:id">
        <BitrixDetailsLayout headerProps={{ title: 'Просмотр оценки' }}>
          <ProtectedReviewDetailsPage tab="review" offsetTop={0} />
        </BitrixDetailsLayout>
      </Route>
      <Redirect from="/dashboard" to="/bitrix/client-interactions" />
      <Redirect from="/calls/:id" to="/bitrix/client-interactions/:id" />
      <Redirect from="/reviews/:id" to="/bitrix/reviews/:id" />

      <Redirect from="/bitrix" to="/bitrix/client-interactions" />

      <Route
        path="/*"
        component={({ history, location }) => {
          // * opening external links inside integration
          window.open(location.pathname);
          history.goBack();
          return null;
        }}
      />
    </Switch>
  </Router>
);

export default BitrixClientInteractionRoutes;
