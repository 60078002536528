import React, { useCallback } from 'react';
import { Col, Checkbox, Tag, Modal } from 'antd';
import { isNil, isEmpty, intersection, keyBy, isEqual } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
  setTaskDefinitionCondititon,
  deleteTaskDefinitionCondition,
  updateTaskDefinitionCondititon,
  resetConditions
} from 'redux/ui/taskDefinition/reducer';
import { useTranslation } from 'react-i18next';
import { RateInput, RateCol, StyledCard } from '../styled';

const { confirm } = Modal;

const ChecklistDefinitionItem = ({
  name,
  min,
  max,
  color,
  checklistDefinitionItemId,
  type,
  questionGroupId,
  checklistDefinitionId,
  childrenQuestionsIds
}) => {
  const conditions = useSelector(state => keyBy(state.uiTaskDefinition.conditions, 'id'), isEqual);
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const isVisible = Object.keys(conditions).includes(checklistDefinitionItemId);

  const childrenQuestionConditions = intersection(Object.keys(conditions), childrenQuestionsIds);

  const deleteCondition = useCallback(
    ({ checklistDefinitionItemId }) => {
      dispatch(deleteTaskDefinitionCondition({ id: checklistDefinitionItemId }));
    },
    [dispatch]
  );

  const handleSetScoreFrom = useCallback(
    value => {
      dispatch(updateTaskDefinitionCondititon({ id: checklistDefinitionItemId, scoreFrom: value }));
    },
    [dispatch, checklistDefinitionItemId]
  );

  const handleSetScoreTo = useCallback(
    value => {
      dispatch(updateTaskDefinitionCondititon({ id: checklistDefinitionItemId, scoreTo: value }));
    },
    [dispatch, checklistDefinitionItemId]
  );

  const createCondition = useCallback(
    ({
      checklistDefinitionItemId,
      type,
      scoreFrom,
      scoreTo,
      conditions,
      childrenQuestionsIds,
      childrenQuestionConditions
    }) => {
      const confirmationModals = {
        checklist_definition: () => {
          if (!isEmpty(conditions)) {
            confirm({
              title: t(
                'triggersSettingsPage.editTaskDefinition.resetChecklistDefinitionConditionsConfirm.title'
              ),
              content: t(
                'triggersSettingsPage.editTaskDefinition.resetChecklistDefinitionConditionsConfirm.description'
              ),
              okText: t(
                'triggersSettingsPage.editTaskDefinition.resetChecklistDefinitionConditionsConfirm.ok'
              ),
              okType: 'danger',
              cancelText: t(
                'triggersSettingsPage.editTaskDefinition.resetChecklistDefinitionConditionsConfirm.cancel'
              ),
              onOk: () => {
                dispatch(resetConditions());
                dispatch(
                  setTaskDefinitionCondititon({
                    id: checklistDefinitionItemId,
                    type,
                    scoreFrom,
                    scoreTo
                  })
                );
              },
              onCancel: () => {
                deleteCondition({ checklistDefinitionItemId });
              }
            });
          }
        },
        question_group: () => {
          if (!isEmpty(childrenQuestionConditions)) {
            confirm({
              title: t(
                'triggersSettingsPage.editTaskDefinition.resetChecklistDefinitionQuestionsConditionsConfirm.title'
              ),
              content: t(
                'triggersSettingsPage.editTaskDefinition.resetChecklistDefinitionQuestionsConditionsConfirm.description'
              ),
              okText: t(
                'triggersSettingsPage.editTaskDefinition.resetChecklistDefinitionQuestionsConditionsConfirm.ok'
              ),
              okType: 'danger',
              cancelText: t(
                'triggersSettingsPage.editTaskDefinition.resetChecklistDefinitionQuestionsConditionsConfirm.cancel'
              ),
              onOk: () => {
                childrenQuestionsIds.map(questionId =>
                  deleteCondition({ checklistDefinitionItemId: questionId })
                );
              },
              onCancel: () => {
                deleteCondition({ checklistDefinitionItemId });
              }
            });
          }
        },
        question: () => {}
      };

      confirmationModals[type]();

      dispatch(
        setTaskDefinitionCondititon({ id: checklistDefinitionItemId, type, scoreFrom, scoreTo })
      );
    },
    [dispatch, deleteCondition, Object.keys(conditions)]
  );

  const isDisableCheckboxConditionsForCD =
    Object.keys(conditions).includes(checklistDefinitionId) &&
    checklistDefinitionId !== checklistDefinitionItemId;

  const isDisableCheckboxConditionsForQuestion = Object.keys(conditions).includes(questionGroupId);

  const isCheckedCheckboxConditions = Object.keys(conditions).includes(checklistDefinitionItemId);

  const onChange = useCallback(
    () =>
      !isVisible
        ? createCondition({
            checklistDefinitionItemId,
            type,
            scoreFrom: 0,
            scoreTo: max,
            conditions,
            childrenQuestionsIds,
            childrenQuestionConditions
          })
        : deleteCondition({ checklistDefinitionItemId }),
    [createCondition, deleteCondition, isVisible]
  );

  return (
    <StyledCard type="flex" color={color}>
      <Col span={12}>
        <Checkbox
          onChange={onChange}
          disabled={isDisableCheckboxConditionsForCD || isDisableCheckboxConditionsForQuestion}
          checked={isCheckedCheckboxConditions}
        >
          {isNil(name) ? (
            <Tag color="red">
              {t('triggersSettingsPage.editTaskDefinition.empty.checklistDefinitionItemName')}
            </Tag>
          ) : (
            name
          )}
        </Checkbox>
      </Col>
      <RateCol span={12}>
        <span>{t('triggersSettingsPage.editTaskDefinition.text.form')}</span>
        <RateInput
          min={min}
          max={max}
          value={!isVisible ? min : conditions[checklistDefinitionItemId]?.scoreFrom}
          onChange={handleSetScoreFrom}
          disabled={!isVisible}
        />
        <span>{t('triggersSettingsPage.editTaskDefinition.text.to')}</span>
        <RateInput
          min={min}
          max={max}
          value={!isVisible ? max : conditions[checklistDefinitionItemId]?.scoreTo}
          onChange={handleSetScoreTo}
          disabled={!isVisible}
        />
      </RateCol>
    </StyledCard>
  );
};

export default ChecklistDefinitionItem;
