import { INTEGRATIONS } from 'core/utils/constants';
import { integrationsResource } from 'redux/resources/integrations';
import api, { s2SynchronizationEndpoint } from 'core/api';
import decamelizeKeys from 'decamelize-keys';
import moment from 'moment';
import { message } from 'antd';
import parseOptionsForApi from '../../../core/utils/parseOptionsForApi';
import { processJsonApiCollection, processJsonApiObject } from '../../../core/jsonapi';

export const createS2Integration = ({
  name,
  endpoint,
  clientSecret,
  organization_id
}) => async dispatch => {
  try {
    const response = await api.createS2Integration(
      parseOptionsForApi({
        name,
        endpoint,
        clientSecret,
        organizationId: organization_id,
        integrationType: INTEGRATIONS.s2.type
      })
    );
    const integration = processJsonApiObject(response.body.data);
    if (integration.id) {
      dispatch(integrationsResource.actions.createSucceed({ integration, integrationType: 's2' }));
    }
    return integration?.id;
  } catch (error) {
    console.log(error);
  }
};

export const syncDataWithS2 =  ({ id, fetchFrom }) => async dispatch => {
  try {
    await fetch(
      `${s2SynchronizationEndpoint}${id}/sync?from=${moment(fetchFrom).format(
        'YYYY-MM-DD HH:mm:ss.SSS'
      )}&to=${moment(Date.now()).format('YYYY-MM-DD HH:mm:ss.SSS')}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    );
  } catch (error) {
    console.error('Error during synchronization:', error);
  }
};

export const setS2IntegrationData = async (id, otherFields, name, organizationId) => {
  try {
    console.log(otherFields);
    const response = await api.updateS2Integration(
      { id },
      decamelizeKeys({
        name,
        client_secret: otherFields.clientSecret,
        endpoint: otherFields.endpoint,
        organization_id: organizationId
      })
    );

    return processJsonApiCollection(response.body.data);
  } catch (error) {
    console.log(error);
  }
};
