import { rolesResource } from 'redux/resources/roles';
import { isEqual, isEmpty, reduce, get } from 'lodash';
import { levelsResource } from 'redux/resources/levels';
import * as actions from './reducer';

export const loadRolesWithLevels = () => async (dispatch, getState) => {
  const response = await dispatch(rolesResource.operations.load({ include: 'levels' }));
  const state = getState();
  const roleIdToLevels = reduce(
    response.resources || {},
    (result, role, roleId) => {
      const levelsByIds = reduce(
        role.levelsIds,
        (res, levelId) => ({ ...res, [levelId]: get(state.levelsResource.byIds, levelId) }),
        {}
      );

      return { ...result, [roleId]: levelsByIds };
    },
    {}
  );

  return dispatch(actions.setRoleIdToLevels(roleIdToLevels));
};

const createLevelFromUi = levelFromUi => async dispatch => {
  const level = await dispatch(levelsResource.operations.create(levelFromUi));
  await dispatch(actions.addLevel(level));
  await dispatch(actions.deleteLevel(levelFromUi));
};

export const saveLevels = ({ roleId, levelsByIds }) => async (dispatch, getState) => {
  const state = getState();
  const role = state.rolesResource.byIds[roleId];

  const oldLevels = role.levelsIds.map(id => state.levelsResource.byIds[id]).filter(Boolean);
  const newLevels = { ...levelsByIds };

  // * running all requests in parallel
  await Promise.all([
    ...oldLevels.map(level => {
      // * delete old comments that deleted on ui
      if (isEmpty(newLevels[level.id])) {
        return dispatch(levelsResource.operations.deleteById({ id: level.id }));
      }

      // * update old comments that updated on ui
      const newLevel = { ...level, ...newLevels[level.id] };
      delete newLevels[level.id];
      // * check if comment needs update
      if (!isEqual(level, newLevel)) {
        dispatch(actions.updateLevel({ roleId, ...newLevel }));
        return dispatch(levelsResource.operations.updateById(newLevel));
      }
    })
  ]);
  // * create new comments
  Object.values(newLevels).map(level => dispatch(createLevelFromUi({ ...level, roleId })));

  dispatch(actions.setLevelsRoleId(undefined));
};
