import React from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import { Col, Row, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { FileDoneOutlined } from '@ant-design/icons';
import { getChecklistDefinitionQuestionGroupsBeforeQuestions } from '../redux/selectors/checklistItems/checklistItems';
import {
  AddColumnIcon,
  ColumnPreview
} from '../pages/ClientInteractionsPage/ClientInteractionsSettingsDrawer/Components/styled';

const QuestionColumn = ({ checklistDefinition, addColumn, tableColumns }) => {
  const checklistQuestions = useSelector(
    state => getChecklistDefinitionQuestionGroupsBeforeQuestions(state, checklistDefinition),
    isEqual
  );
  const { Text } = Typography;
  const { t } = useTranslation();

  const Icon = FileDoneOutlined;

  return (
    <>
      {checklistQuestions &&
        checklistQuestions.map(
          question =>
            !tableColumns.includes(question.id) && (
              <ColumnPreview span={24} onClick={() => addColumn(question?.id)}>
                <Row
                  key={question?.id}
                  type="flex"
                  align="middle"
                  gutter={[4, 0]}
                  style={{ flexWrap: 'nowrap', padding: '8px' }}
                >
                  <Col>
                    <Icon style={{ marginTop: 4 }} />
                  </Col>
                  <Col>
                    <Tooltip title={t(question?.name)}>
                      <div
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '100%'
                        }}
                      >
                        <Text>{t(question?.name)}</Text>
                      </div>
                    </Tooltip>
                  </Col>
                </Row>
                <AddColumnIcon />
              </ColumnPreview>
            )
        )}
    </>
  );
};

export default QuestionColumn;
