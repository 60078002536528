import React from 'react';
import catWithSpinner from 'assets/video/cat_with_spinner.mp4';
import BackgroundJob from 'pages/BackgroundJob';
import { Row, Col, message } from 'antd';
import { useTranslation } from 'react-i18next';

const SyncBackgroundJob = ({ proceed }) => {
  const { t } = useTranslation();
  const onFailed = () => {
    message.error(t('messages.error.failedInstallIntegration'));
  };

  const onDone = () => {
    message.success(t('messages.success.integrationCompleted'));
    proceed();
  };

  return (
    <Row gutter={[16, 16]}>
      <Col>
        <BackgroundJob
          text={
            <video autoPlay loop muted playsinline>
              <source src={catWithSpinner} type="video/mp4" />
            </video>
          }
          description={
            <>
              <div>Это может занять несколько минут.</div>
              <div>Вы можете подождать или сразу начать пользоваться приложением</div>
            </>
          }
          spinning={false}
          onDone={onDone}
          onFailed={onFailed}
        />
      </Col>
    </Row>
  );
};

export default SyncBackgroundJob;
