import createListReducer from 'core/redux/lists/listReducer';
import { phoneCallsResource } from 'redux/resources/calls';

const defaultState = {
  callsByIds: {},
  editingCallId: null,
  filters: {
    direction: 'all',
    status: 'all'
  }
};

export const uploadPageReducer = {
  finishEditingCall: (state, { payload }) => ({
    ...state,
    callsByIds: { ...state.callsByIds, [payload.id]: payload },
    editingCallId: null
  }),
  updateEditingCall: (state, { payload }) => {
    const callId = state.editingCallId || payload.id;
    const call = { ...state.callsByIds[callId], ...payload };
    return { ...state, callsByIds: { ...state.callsByIds, [callId]: call } };
  },
  cancelEditingCall: state => ({
    ...state,
    editingCallId: null
  }),
  startEditingCall: (state, { payload }) => ({
    ...state,
    editingCallId: payload.callId
  })
};

const { actions, reducer, operations } = createListReducer({
  name: 'uploadedCalls',
  defaultState,
  additionalReducer: uploadPageReducer,
  rebuildQuery: true,
  loader: phoneCallsResource.operations.load
});

const uploadedCallsReducer = reducer;

export { actions, uploadedCallsReducer, operations };
