import ClientInteractionDrawer from 'components/ClientInteractionDrawer';
import SRow from 'components/Standard/SRow';
import { get } from 'lodash';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { calibrationSessionsResource } from 'redux/resources/calibrationSessions';
import { taskDefinitionsResource } from 'redux/resources/taskDefinitions';
import { getCurrentUser } from 'redux/selectors/users';
import CalibrationInfo from './components/CalibrationInfo';
import UserReviewStatusTable from './components/UserReviewStatusTable';

const CalibrationDetails = ({
  currentSession,
  currentSessionId,
  loadCurrentSession,
  loadTaskDefinitions,
  currentUserId,
  checklistsIds,
  reviewsIds
}) => {
  useEffect(() => {
    loadCurrentSession({
      id: currentSessionId,
      include:
        'author.unit,members.unit,folder_items.client_interaction.operator.unit,reviews,checklist_definition'
    });
    loadTaskDefinitions({ pagination: false });
  }, [checklistsIds.length, reviewsIds.length]);

  const { t } = useTranslation();
  const { membersIds = [], authorId } = currentSession;
  const membersToView = authorId === currentUserId ? membersIds : [currentUserId];

  return (
    <SRow margin="16px">
      <Helmet>
        <title>{t('pagesMeta.calibrationsSessionsPages.viewCalibrationSession.title')}</title>
      </Helmet>
      <ClientInteractionDrawer shouldLoad />
      <CalibrationInfo currentSession={currentSession} />
      {membersToView.map(memberId => (
        <UserReviewStatusTable key={memberId} memberId={memberId} currentSession={currentSession} />
      ))}
    </SRow>
  );
};

const mapStateToProps = (state, ownProps) => {
  const calibrationsSessionsByIds = state.calibrationSessionsResource.byIds;
  const currentSessionId = ownProps.match.params.id;
  const currentSession = get(calibrationsSessionsByIds, currentSessionId, {});
  const currentUserId = get(getCurrentUser(state), 'id', '');
  const checklistsIds = Object.keys(state.checklistsResource.byIds);
  const reviewsIds = Object.keys(state.reviewsResource.byIds);

  return {
    checklistsIds,
    reviewsIds,
    currentUserId,
    currentSession,
    currentSessionId
  };
};

const mapDispatchToProps = {
  loadCurrentSession: calibrationSessionsResource.operations.loadById,
  loadTaskDefinitions: taskDefinitionsResource.operations.load
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalibrationDetails));
