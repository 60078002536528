import { Button, Col, Form, Input, Row, Typography } from 'antd';
import SCard from 'components/Standard/SCard';
import { handleError } from 'core/services/errors';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { passwordResetRequest } from 'redux/entities/authPassword/operations';
import styled from 'styled-components';
import { AuthLogo, LinkToLandingPage } from '../styled';

const { Text, Title } = Typography;

const FormContainer = styled(Col)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;

    .ant-card {
      width: 80%;
      max-width: 560px;
    }
  }
`;

const RecoverPasswordPage = ({ loading, history, passwordResetRequest }) => {
  const { t } = useTranslation();

  const onResetPasswordSubmit = async values => {
    const { email } = values;
    try {
      const response = await passwordResetRequest({ email });
      if (response) history.push('/recover-password/success');
    } catch (err) {
      console.log(err);
      handleError(err?.response?.status, err?.response?.data);
    }
  };

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.recoverPasswordPage.title')}</title>
      </Helmet>
      <AuthLogo />
      <Row type="flex" align="middle" justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <FormContainer span={24}>
            <SCard shadowed>
              <Row justify="space-between" type="flex" align="middle">
                <Col>
                  <Title level={3}>{t('recoverPasswordPage.title')}</Title>
                </Col>
                <Col>
                  <Link to="/login">{t('recoverPasswordPage.buttons.login')}</Link>
                </Col>
              </Row>

              <Form hideRequiredMark name="login" onFinish={onResetPasswordSubmit}>
                <Row className="ant-form-item">
                  <Col>
                    <Text strong>{t('recoverPasswordPage.description')}</Text>
                  </Col>
                </Row>

                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: t('recoverPasswordPage.form.messages.enterEmail')
                    },
                    { type: 'email', message: t('recoverPasswordPage.form.messages.wrongEmail') }
                  ]}
                >
                  <Input
                    placeholder={t('recoverPasswordPage.form.fields.emailPlaceholder')}
                    type="email"
                  />
                </Form.Item>

                <Button type="primary" htmlType="submit" block loading={loading}>
                  {t('recoverPasswordPage.form.buttons.continue')}
                </Button>
              </Form>
            </SCard>
          </FormContainer>
        </Col>
      </Row>
      <LinkToLandingPage />
    </>
  );
};

const mapDispatchToProps = {
  passwordResetRequest
};

export default withRouter(connect(null, mapDispatchToProps)(RecoverPasswordPage));
