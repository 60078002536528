export default {
  calibrationResultPage: {
    messages: {
      loadSessionsFailed: 'Failed to upload the session',
      sessionSuccessfullyCompleted: 'Session successfully completed',
      emptyReviews: 'No evaluations'
    },
    commentPlaceholder: 'A place for your notes on the session ✍️',
    buttons: { completeSession: 'Complete the session' },
    table: {
      organizer: 'Organizer:',
      columns: {
        gradedPoints: 'Evaluated points',
        coincidence: 'Coincidence',
        result: 'Evaluation result'
      }
    }
  }
};
