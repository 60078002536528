import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SCard from 'components/Standard/SCard';
import { isEmpty } from 'lodash';
import { resetFiltersByUserId, setFiltersByUserId } from 'redux/ui/userAnalyticsPage/reducer';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import { parse, stringify } from 'core/utils/queryString';
import { getModeFromFilters } from 'components/DatePicker/utils';
import ChecklistDefinitionSelector from './ChecklistDefinitionSelector';
import CustomDatePicker from '../../../components/DatePicker/DatePicker';
import { DATE_PICKER_MODES, SYMBOLIC_TIME_RANGE } from '../../../core/utils/constants';

const MainControlsPanel = ({ additionalFilterComponent, additionalInfoComponent }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id: userId } = useParams();

  const query = parse(useLocation().search);

  const queryFilters = {
    ...query.filters,
    checklistDefinitionColorZones: query.filters?.checklistDefinitionColorZones?.split(','),
    commentsRatingFlags: query.filters?.commentsRatingFlags?.split(','),
    reviewTimeFrom: query.filters?.reviewTimeFrom
      ? decodeURIComponent(query.filters?.reviewTimeFrom)
      : undefined,
    reviewTimeTo: query.filters?.reviewTimeTo
      ? decodeURIComponent(query.filters?.reviewTimeTo)
      : undefined,
    clientInteractionTimeFrom: query.filters?.clientInteractionTimeFrom
      ? decodeURIComponent(query.filters?.clientInteractionTimeFrom)
      : undefined,
    clientInteractionTimeTo: query.filters?.clientInteractionTimeTo
      ? decodeURIComponent(query.filters?.clientInteractionTimeTo)
      : undefined
  };

  const setFiltersWithQuery = ({ userId, filters }) => {
    dispatch(
      setFiltersByUserId({
        userId,
        operatorsIds: userId,
        ...filters
      })
    );
    history.replace({
      pathname: location.pathname,
      search: stringify({
        filters
      })
    });
  };

  useEffect(() => {
    const isEmptyFilters = isEmpty(Object.values(queryFilters).filter(i => i));

    if (isEmptyFilters) {
      setFiltersWithQuery({
        userId,
        filters: {
          clientInteractionSymbolicTimeRange: SYMBOLIC_TIME_RANGE.THIS_MONTH,
          checklistDefinitionsIds: 'all'
        }
      });
    }
    if (!isEmptyFilters) {
      dispatch(resetFiltersByUserId({ userId }));
      dispatch(
        setFiltersByUserId({
          userId,
          operatorsIds: userId,
          ...queryFilters
        })
      );
    }
  }, []);

  return (
    <SCard rounded={false}>
      <SRow type="flex" align="middle" justify="space-between" gutter={[8, 0]}>
        <SCol span={6}>
          <ChecklistDefinitionSelector
            filters={queryFilters}
            userId={userId}
            onChangeFilters={setFiltersWithQuery}
          />
        </SCol>
        <SCol span={6}>{additionalFilterComponent}</SCol>
        <SCol span={6}>{additionalInfoComponent}</SCol>
        <SCol span={6}>
          <CustomDatePicker
            onChange={filters => {
              setFiltersWithQuery({
                userId,
                filters: {
                  ...queryFilters,
                  ...filters
                }
              });
            }}
            value={{
              symbolicTimeRange:
                queryFilters.clientInteractionSymbolicTimeRange ||
                queryFilters.reviewSymbolicTimeRange,
              timeFrom: queryFilters.clientInteractionTimeFrom || queryFilters.reviewTimeFrom,
              timeTo: queryFilters.clientInteractionTimeTo || queryFilters.reviewTimeTo
            }}
            position="left"
            mode={DATE_PICKER_MODES.default}
            filtersMode={getModeFromFilters({ filters: queryFilters })}
          />
        </SCol>
      </SRow>
    </SCard>
  );
};

export default MainControlsPanel;
