import api from 'core/api';
import { createReduxResource } from 'core/redux/resources';

export const checklistDefinitionsResource = createReduxResource({
  name: 'checklistDefinitions',
  customOperations: {
    copyById: {
      callEndpoint: async requestOptions => {
        const { id, name } = requestOptions;
        return api.copyChecklistDefinition({ id }, { name });
      },
      onSuccess: ({ result }) => {
        return result;
      }
    },
    loadWithInclude: {
      callEndpoint: api.getChecklistDefinitionsWithInclude,
      onSuccess: ({ result }) => {
        return result;
      }
    }
  },
  additionalReducer: {
    // LOAD
    loadWithIncludeStarted: state => ({ ...state, loading: true }),
    loadWithIncludeSucceed: (state, { payload }) => ({
      ...state,
      loading: false,
      byIds: { ...state.byIds, ...payload }
    }),
    loadWithIncludeFailed: (state, { payload }) => ({
      ...state,
      failed: true,
      errors: [...state.errors, payload]
    })
  }
});
