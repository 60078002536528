import React from 'react';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SCard from 'components/Standard/SCard';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { useParams } from 'react-router-dom';
import SText from 'components/Standard/SText';
import moment from 'moment';
import { flatMap, get, isEqual, uniq } from 'lodash';
import { getUserName } from 'components/UserPreview/getUserName';

const TasksInfo = () => {
  const { Title } = Typography;
  const { t } = useTranslation();

  const { id: currentConfigurationId } = useParams();

  const workPlanTaskConfigurationsByIds = useSelector(
    state => state.workPlanTaskConfigurationsResource.byIds,
    isEqual
  );

  const currentConfiguration = get(workPlanTaskConfigurationsByIds, currentConfigurationId, {});

  const currentConfigurationSchedule = useSelector(
    state => state.workPlanTaskSchedulesResource.byIds[currentConfiguration?.scheduleId],
    isEqual
  );

  const workPlanFiltersByIds = useSelector(state => state.workPlanFiltersResource.byIds);

  const currentConfigurationsFilters = get(currentConfiguration, 'filtersIds', []).map(
    id => workPlanFiltersByIds[id]
  );

  const fieldsByIds = useSelector(state => state.workPlanFilterFieldsResource.byIds, isEqual);

  const currentConfigurationsFields = flatMap(
    currentConfigurationsFilters.map(filter =>
      get(filter, 'fieldsIds', []).map(fieldId => fieldsByIds[fieldId])
    )
  );

  const usersByIds = useSelector(state => state.usersResource.byIds);

  const getOperatorsCount = () => {
    const operatorsFields = currentConfigurationsFields?.filter(
      field => field?.key === 'operators_ids'
    );

    return uniq(flatMap(operatorsFields.map(config => config.settings.operatorsIds)))?.length || 0;
  };

  return (
    <SCard rounded={false}>
      <SRow type="flex" align="middle" gutter={[16, 0]}>
        <SCol>
          <Title level={5}>{currentConfiguration?.name}</Title>
        </SCol>
        <SCol>
          <SText type="secondary">{`${t('general.created')}: `}</SText>
          {getUserName({ user: usersByIds[currentConfiguration?.createdById] })}
        </SCol>
        <SCol>
          <SText type="secondary">{`${t('workPlanTasks.tasksStartedAt')}: `}</SText>
          <SText>{moment(currentConfigurationSchedule?.periodTimeFrom).format('DD/MM/YYYY')}</SText>
        </SCol>
        <SCol>
          <SText type="secondary">{`${t('workPlanTasks.reviewersCount')}: `}</SText>
          <SText>{currentConfiguration?.reviewerBindingsIds?.length}</SText>
        </SCol>
        <SCol>
          <SText type="secondary">{`${t('workPlanTasks.operatorsCount')}: `}</SText>
          <SText>{getOperatorsCount()}</SText>
        </SCol>
      </SRow>
    </SCard>
  );
};

export default TasksInfo;
