import moment from 'moment';
import { valueToColorZoneColor } from 'pages/DashboardPage/utils';
import {
  VISIBLE_WIDGET_DATE_FORMAT,
  BACKEND_WIDGET_DATE_FORMAT
} from 'pages/DashboardPages/AutofailScoresPage/Components/Widgets/AutoFailCountsBy/utils';

export const prepareTableData = ({ data, datesArray, columns, checklistDefinition }) => {
  return datesArray.reduce((prevValue, current) => {
    const dateKey = moment(current).format(BACKEND_WIDGET_DATE_FORMAT);
    let rowData = {
      id: dateKey,
      key: dateKey,
      date: moment(current).format(VISIBLE_WIDGET_DATE_FORMAT),
      momentDate: current
    };

    columns.forEach(({ key: fieldName }) => {
      if (!rowData?.[fieldName]) {
        const percentOfClientInteractions = data?.percentOfClientInteractions?.[dateKey];

        const percentOfReviewsWithAnyTypeAutofailCount =
          data?.percentOfReviewsWithAnyTypeAutofailCount?.[dateKey];

        let fieldNameValue = data?.[fieldName]?.[dateKey]
          ? Math.ceil(parseFloat(data?.[fieldName]?.[dateKey] || 0) * 10) / 10
          : null;

        if (fieldName === 'checklistDefinitionAverageScoreHistory') {
          fieldNameValue = {
            value: fieldNameValue,
            color: fieldNameValue
              ? valueToColorZoneColor({
                  value: fieldNameValue,
                  colorZones: checklistDefinition.colorZones
                })
              : null
          };
        }

        rowData = {
          ...rowData,
          ...{
            [fieldName]: fieldNameValue
          },
          ...{
            percentOfClientInteractions: {
              value: percentOfClientInteractions,
              color: percentOfClientInteractions
                ? valueToColorZoneColor({
                    value: percentOfClientInteractions,
                    colorZones: checklistDefinition.colorZones
                  })
                : null
            },
            percentOfReviewsWithAnyTypeAutofailCount: {
              value: percentOfReviewsWithAnyTypeAutofailCount,
              color: percentOfReviewsWithAnyTypeAutofailCount
                ? valueToColorZoneColor({
                    value: percentOfReviewsWithAnyTypeAutofailCount,
                    colorZones: checklistDefinition.colorZones
                  })
                : null
            }
          }
        };
      }
    });

    return [...prevValue, ...[rowData]];
  }, []);
};

export const prepareTableColumns = ({ columns, t }) =>
  columns.reduce((prev, current) => {
    prev.push({
      ...current,
      ...{
        id: current.key,
        title: t(`dashboardPages.autoFailScores.columns.${current.key}`)
      }
    });
    return prev;
  }, []);
