import { Alert, Button, Col, Menu, Row, Select } from 'antd';
import styled from 'styled-components';

export const SettingsRow = styled(Row)`
  &.ant-row {
    margin: 8px 0;
  }
`;

export const WidgetInfoCol = styled(Col)`
  &.ant-col {
    min-height: 32px;
    border: 1px solid var(--gray-border);
    border-radius: 4px;
    margin-left: 4px;
    max-width: 506px;
  }

  &.ant-col > .ant-typography {
    margin-left: 4px;
  }
`;

export const StyledButton = styled(Button)`
  &.ant-btn {
    border: none;
    box-shadow: none;
    margin-top: 2px;
  }
`;

export const MoreDashboardInfoButton = styled(Button)`
  &.ant-btn {
    box-shadow: none;
    margin-top: 5px;
  }
`;

export const MoreRowInfoButton = styled(Button)`
  &.ant-btn {
    box-shadow: none;
    margin-top: 1px;
  }
`;

// export const StyledIcon = styled(Icon)`
//   &.anticon {
//     margin-right: 4px;
//   }
// `;

export const StyledSelect = styled(Select)`
  &.ant-select {
    width: 100%;
    margin-top: 4px;
  }
`;

export const CreateButton = styled(Button)`
  &.ant-btn {
    border-color: var(--primary);
    margin-top: 8px;
    color: var(--primary);
  }
`;

export const LimitCountDashboard = styled(Alert)`
  &.ant-alert {
    margin-top: 8px;
  }
`;

export const DeleteMenuItem = styled(Menu.Item)`
  &.ant-dropdown-menu-item {
    color: red;
  }
`;

export const MoveButtonsBlock = styled(Col)`
  &.ant-col {
    width: 32px;
    display: flex;
    flex-direction: column;
    font-size: 10px;
    border-left: 1px solid var(--gray-border);
    position: absolute;
    right: 0px;
    top: 0px;
  }
`;

export const StyleMoveIcon = Icon => styled(Icon)`
  & {
    /* padding: 3px 0px 2px 0px; */
    margin: 0 auto;
    cursor: pointer;
    height: 16px;
    width: 100%;
  }

  &:hover {
    color: var(--primary);
    background-color: var(--gray-background);
  }
`;
