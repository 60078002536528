export default {
  recoverPasswordPage: {
    title: 'Восстановление пароля',
    description:
      'Введите адрес электронной почты, которую вы используете для своей учетной записи.',
    buttons: {
      login: 'Войти'
    },
    form: {
      fields: {
        emailPlaceholder: 'Введите ваш e-mail'
      },
      messages: {
        enterEmail: 'Введите e-mail',
        wrongEmail: 'Введите корректный e-mail'
      },
      buttons: {
        continue: 'Продолжить'
      }
    },
    success: {
      title: 'Спасибо!',
      description:
        'Мы выслали инструкции по восстановлению пароля на указанный вами адрес электронной почты. Если письмо с инструкциями не пришло в течении 10 минут, проверьте правильность e-mail адреса, который вы ввели.',
      buttons: {
        login: 'Войти',
        register: 'Зарегистрироваться'
      }
    },
    setNewPassword: {
      title: {
        newUser: '👋 Добро пожаловать!',
        setPass: '🔐 Придумайте надёжный пароль'
      },
      form: {
        newPassMessage: 'Придумайте надёжный пароль для',
        fields: {
          passwordPlaceholder: 'Введите пароль'
        },
        messages: {
          enterPass: 'Введите пароль',
          minLengthPassword: 'Минимум 6 символов'
        },
        buttons: {
          continue: 'Продолжить'
        }
      }
    }
  }
};
