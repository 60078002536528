import {
  formatTableCellValue,
  prepareFooterData
} from 'pages/DashboardPages/AutofailScoresPage/Components/Widgets/utils';
import Row from 'pages/DashboardPages/AutofailScoresPage/Components/Widgets/AutoFailHistoryReport/Row';
import Cell from 'pages/DashboardPages/AutofailScoresPage/Components/Widgets/AutoFailHistoryReport/Cell';
import React from 'react';

const SummaryRow = ({ pageData, total, checklistDefinition }) => {
  if (pageData.length === 0) return null;

  const {
    date,
    reviewsCountHistory,
    clientInteractionsCountHistory,
    anyTypeAutofailsCountHistory,
    reviewsWithAnyTypeAutofailCount,
    percentOfClientInteractions,
    percentOfReviewsWithAnyTypeAutofailCount,
    checklistDefinitionAverageScoreHistory
  } = prepareFooterData({ data: total, checklistDefinition });

  return (
    <Row style={{ height: '56px' }}>
      <Cell align="left" index={0} background="var(--gray_4)">
        {date}
      </Cell>
      <Cell background={checklistDefinitionAverageScoreHistory.color}>
        {formatTableCellValue(
          checklistDefinitionAverageScoreHistory?.value,
          'checklistDefinitionAverageScoreHistory'
        )}
      </Cell>
      <Cell>{reviewsCountHistory?.value}</Cell>
      <Cell>{clientInteractionsCountHistory?.value}</Cell>
      <Cell background={percentOfClientInteractions?.color || 'var(--gray_4)'}>
        {formatTableCellValue(percentOfClientInteractions?.value, 'percentOfClientInteractions')}
      </Cell>
      <Cell>{anyTypeAutofailsCountHistory?.value}</Cell>
      <Cell>{reviewsWithAnyTypeAutofailCount?.value}</Cell>
      <Cell background={percentOfReviewsWithAnyTypeAutofailCount?.color || 'var(--gray_4)'}>
        {formatTableCellValue(
          percentOfReviewsWithAnyTypeAutofailCount?.value,
          'percentOfReviewsWithAnyTypeAutofailCount'
        )}
      </Cell>
    </Row>
  );
};

export default SummaryRow;
