const defaultTableColumns = [];
const defaultTableFilters = {};
const defaultCustomFieldFilters = {};

export const getUIClientInteractions = state => {
  const {
    tableLoading,
    tableRows,
    tableName,
    tableFilters = defaultTableFilters,
    tableCustomFieldFilters = defaultCustomFieldFilters,
    tableColumns = defaultTableColumns,
    tableId,
    meta
  } = state.uiClientInteractions;

  return {
    tableLoading,
    tableRows,
    tableName,
    tableFilters,
    tableCustomFieldFilters,
    tableColumns,
    tableId,
    meta
  };
};
