import { Table } from 'antd';
import styled from 'styled-components';

const RedesignWidgetTable = styled(Table)`
  &.ant-table-wrapper .ant-table {
    border: none !important;
  }
  &.ant-table-wrapper .ant-table .ant-table-container {
    border: none !important;
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-content .ant-table-cell {
    border-color: white;
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-header table {
    border: none !important;
  }
  &.ant-table-wrapper .ant-table th {
    border: none !important;
    background-color: white;
    outline: 1px solid white !important;
  }
  &.ant-table-wrapper .ant-table th:hover {
    border: none !important;
    background: none;
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-header .ant-table-cell-scrollbar {
    background-color: white;
    border: none;
    outline: 1px solid var(--gray_4);
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-body table {
    border: none !important;
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-body .ant-table-cell-fix-left {
    border-left: 1px solid var(--gray_4);
  }
  &.ant-table-wrapper .ant-table .ant-table-container .ant-table-header .ant-table-cell-fix-left {
    border-left: 1px solid var(--gray_4);
    border-radius: 0;
    background-color: white;
  }
  &.ant-table-wrapper .ant-table .ant-table-thead .ant-table-column-sorters > span:first-child {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export default RedesignWidgetTable;
