import { get, destroy, post, put } from 'redux-bees';

const calibrationSessionsEndpoints = apiPath => ({
  getCalibrationSessions: { method: get, path: `${apiPath}/calibration_sessions` },
  getCalibrationSession: { method: get, path: `${apiPath}/calibration_sessions/:id` },
  createCalibrationSession: { method: post, path: `${apiPath}/calibration_sessions` },
  deleteCalibrationSession: { method: destroy, path: `${apiPath}/calibration_sessions/:id` },
  updateCalibrationSession: { method: put, path: `${apiPath}/calibration_sessions/:id` }
});

export default calibrationSessionsEndpoints;
