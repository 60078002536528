import { Col, Dropdown, Form, Input, List, Menu, Row, Typography } from 'antd';
import Icon from 'components/Icon';
import SButton from 'components/Standard/SButton';
import SCol from 'components/Standard/SCol';
import UserPreview from 'components/UserPreview';
import React, { forwardRef, useImperativeHandle } from 'react';
import { MoreVertical, Trash2 } from 'react-feather';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const FormItem = styled(Form.Item)`
  && {
    margin-bottom: 0px;
    & .ant-form-item-explain {
      position: absolute;
      bottom: -22px;
      white-space: nowrap;
    }
  }
`;
const CustomUserAccountForm = forwardRef(
  ({ userAccount, editable, onChange, onDelete, showActive }, ref) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();

    useImperativeHandle(ref, () => ({
      validate: async () => {
        try {
          const values = await form.validateFields();
          return { values };
        } catch (error) {
          return { error };
        }
      },
      userAccount
    }));

    const handleChange = e => {
      onChange(userAccount.id, e.target.name, e.target.value);
    };

    const menu = ({ id }) => {
      return (
        <Menu onClick={e => onDelete(e, id)}>
          <Menu.Item key="delete" style={{ color: 'var(--red_primary)' }}>
            <Icon icon={Trash2} />
            <span>{t('general.delete')}</span>
          </Menu.Item>
        </Menu>
      );
    };

    return (
      <Form name={userAccount.id} initialValues={userAccount} form={form}>
        <List.Item style={{ padding: '6px 0' }}>
          <Col span={24} style={{ padding: '0' }}>
            <Row
              type="flex"
              justify="space-between"
              align="middle"
              gutter={[8, 0]}
              style={{ marginRight: 0 }}
            >
              <Col span={showActive ? 6 : 8}>
                {editable ? (
                  <FormItem name="name">
                    <Input.Group compact>
                      <Input
                        style={{ width: '60%' }}
                        name="lastName"
                        placeholder={t('general.lastName')}
                        onChange={handleChange}
                        defaultValue={userAccount?.lastName}
                      />
                      <Input
                        style={{ width: '40%' }}
                        name="firstName"
                        placeholder={t('general.name')}
                        onChange={handleChange}
                        defaultValue={userAccount?.firstName}
                      />
                    </Input.Group>
                  </FormItem>
                ) : (
                  <UserPreview user={userAccount} showAvatar disabled />
                )}
              </Col>
              <SCol
                span={showActive ? 6 : 8}
                display="flex"
                justifyContent="flex-start"
                flex="auto"
              >
                {editable ? (
                  <FormItem
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: t('integrationsSettingsPage.customUserAccountForm.enterEmail')
                      },
                      {
                        type: 'email',
                        message: t(
                          'integrationsSettingsPage.customUserAccountForm.enterCorrectEmail'
                        )
                      }
                    ]}
                  >
                    <Input
                      placeholder={t('general.email')}
                      type="email"
                      width="100%"
                      onChange={handleChange}
                      name="email"
                    />
                  </FormItem>
                ) : (
                  <Text className="truncated">{userAccount.email}</Text>
                )}
              </SCol>
              {showActive && (
                <Col span={6}>
                  <Text
                    style={{
                      color: userAccount?.userId ? 'var(--green_primary)' : 'var(--red_primary)'
                    }}
                  >
                    {userAccount?.userId
                      ? t('integrationsSettingsPage.customUserAccountForm.sync')
                      : t('integrationsSettingsPage.customUserAccountForm.notSync')}
                  </Text>
                </Col>
              )}
              <SCol
                span={showActive ? 5 : 7}
                display="flex"
                justifyContent="flex-start"
                flex="auto"
              >
                <Row type="flex" style={{ maxWidth: '100%' }}>
                  {editable ? (
                    <FormItem
                      name="integrationUid"
                      rules={[
                        {
                          required: true,
                          message: t('integrationsSettingsPage.customUserAccountForm.enterId')
                        }
                      ]}
                    >
                      <Input
                        placeholder="ID"
                        width="100%"
                        onChange={handleChange}
                        name="integrationUid"
                      />
                    </FormItem>
                  ) : (
                    <Text className="truncated">{userAccount.integrationUid}</Text>
                  )}
                </Row>
              </SCol>

              <Col style={{ minWidth: '32px' }}>
                {editable && (
                  <Dropdown
                    overlay={menu({ id: userAccount.id })}
                    trigger={['click']}
                    placement="bottomLeft"
                  >
                    <SButton
                      type="link"
                      icon={<Icon icon={MoreVertical} color="var(--blue_2)" />}
                    />
                  </Dropdown>
                )}
              </Col>
            </Row>
          </Col>
        </List.Item>
      </Form>
    );
  }
);

export default CustomUserAccountForm;
