import { createReduxResource } from 'core/redux/resources';

export const phoneCallsResource = createReduxResource({
  name: 'phoneCalls',
  reactors: {
    onLoadByIdSucceed: ({ resource, dispatch }) => {
      dispatch({
        type: `clientInteractions/loadByIdSucceed`,
        payload: { ...resource, type: 'client-interactions' }
      });
    }
  }
});
