import { Col, Row, Spin, Typography, List } from 'antd';
import { actions, operations } from 'redux/lists/unitsListReducer';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isEqual, throttle } from 'lodash';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getUnitsByIds } from 'redux/selectors/units';
import Search from 'components/Inputs/Search';
import SModal from 'components/Standard/SModal';
import { SListItem } from 'components/Standard/SList';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import { WidgetTitle } from 'components/WidgetTitle';
import SCol from './Standard/SCol';
import SRow from './Standard/SRow';

const { Text } = Typography;

const AddUnitsModal = ({ onAdd, onCancel, selectedUnitsIds = [], visible }) => {
  const { t } = useTranslation();
  const [selectedUnits, setSelectedUnits] = useState(selectedUnitsIds);
  const dispatch = useDispatch();

  const loading = useSelector(state => state.unitsList.loading);
  const { totalPages, page, filters, search } = useSelector(state => state.unitsList, isEqual);

  const unitsByIds = useSelector(getUnitsByIds, isEqual);

  const units = useSelector(
    state => state.unitsList.ids?.map(id => state.unitsResource.byIds[id]),
    isEqual
  );

  const onOk = () => {
    onAdd(selectedUnits);
  };

  const onSearch = search => {
    dispatch(actions.clearList());
    dispatch(actions.setSearch(search));
    dispatch(
      operations.load({
        search,
        page: { ...page, number: 1 },
        filters,
        sort: 'name'
      })
    );
  };

  useEffect(() => {
    if (visible) {
      setSelectedUnits(selectedUnitsIds);
      dispatch(
        operations.load({
          search,
          page: { ...page, number: 1 },
          filters,
          sort: 'name'
        })
      );
    } else {
      dispatch(actions.setSearch(null));
      dispatch(actions.clearList());
      dispatch(actions.setFilters({}));
      setSelectedUnits([]);
    }

    return () => {
      dispatch(actions.setSearch(null));
      dispatch(actions.clearList());
      dispatch(actions.setFilters({}));
      setSelectedUnits([]);
    };
  }, [visible]);

  const hasNextPage = totalPages > page.number;

  const renderUnit = unit => {
    const onSelect = selected => {
      setSelectedUnits(
        selected
          ? [...selectedUnits, unit.id]
          : selectedUnits.filter(selectedId => selectedId !== unit.id)
      );
    };
    return (
      <SListItem
        key={unit.id}
        padding="0"
        _hover="cursor:pointer"
        onClick={() => {
          onSelect(!selectedUnits.includes(unit.id));
        }}
      >
        <Col span={24}>
          <SRow gutter={[8, 0]} align="middle">
            <SCol padding="6px 0">
              <Checkbox
                checked={selectedUnits.includes(unit.id)}
                onChange={e => {
                  e.stopPropagation();
                  onSelect(e.target.checked);
                }}
              />
            </SCol>
            <Col padding="6px 0">
              <Text>{unitsByIds[unit.id]?.name}</Text>
            </Col>
          </SRow>
        </Col>
      </SListItem>
    );
  };

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      dispatch(actions.updatePage({ number: newPage }));
      dispatch(
        operations.load({
          search,
          page: { ...page, number: 1 },
          filters,
          sort: 'name'
        })
      );
    }
  }, 250);

  return (
    <SModal
      width="887px"
      size="big"
      destroyOnClose
      title={
        <SRow align="middle" justify="space-between" paddingRight="25px">
          <Col>
            <WidgetTitle>{t('general.units')}</WidgetTitle>
          </Col>
          <Col>
            <Row gutter={[20, 0]}>
              <Col>
                <Search
                  loading={loading}
                  placeholder={t(
                    'organizationStructure.tabs.employees.table.filters.employeesSearch'
                  )}
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </Col>
            </Row>
          </Col>
        </SRow>
      }
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={t('general.save')}
      cancelText={<></>}
    >
      <SListItem borderBottom="1px solid var(--gray_4)" padding="0px">
        <SCol span={24}>
          <SRow>
            <SCol width="24px" />
            <SCol padding="6px 0">
              <Text strong>{t('general.units')}</Text>
            </SCol>
          </SRow>
        </SCol>
      </SListItem>
      <PerfectScrollbar
        onScrollY={onScrollY}
        options={{ suppressScrollX: true }}
        style={{ maxHeight: '385px' }}
      >
        <List dataSource={units} renderItem={renderUnit} rowKey="id" />
        <Spin spinning={loading}>
          <List.Item />
        </Spin>
      </PerfectScrollbar>
    </SModal>
  );
};

export default AddUnitsModal;
