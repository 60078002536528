import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  totalCommentsCount: null,
  totalCommentsWithRedFlagsCount: null,
  totalCommentsWithYellowFlagCount: null,
  totalCommentsWithGreenFlagsCount: null,
  totalCommentsWithoutFlagCount: null,
  loading: false,
  errors: []
};

export const loadCommentsAnalyticsStarted = createAction('comments/loadCommentsAnalyticsStarted');

export const loadCommentsAnalyticsSuccseed = createAction('comments/loadCommentsAnalyticsSuccseed');

export const loadCommentsAnalyticsFailed = createAction('comments/loadCommentsAnalyticsFailed');

export const commentsAnalyticsReducer = createReducer(defaultState, {
  [loadCommentsAnalyticsStarted]: state => ({ ...state, loading: true }),
  [loadCommentsAnalyticsSuccseed]: (state, { payload }) => ({
    ...state,
    ...payload,
    loading: false
  }),
  [loadCommentsAnalyticsFailed]: (state, { payload }) => ({
    ...state,
    loading: false,
    errors: [...state.errors, payload]
  })
});
