/* eslint-disable jsx-a11y/accessible-emoji */
import SCol from 'components/Standard/SCol';
import SRow from 'components/Standard/SRow';
import React from 'react';
import MainControlsPanel from '../MainControlsPanel';
import AnalyticsChartsPanel from './AnalyticsChartsPanel';

const UserAnalyticsPage = () => {
  return (
    <SRow>
      <SCol span={24}>
        <MainControlsPanel />
      </SCol>
      <SCol span={24}>
        <AnalyticsChartsPanel />
      </SCol>
    </SRow>
  );
};

export default UserAnalyticsPage;
