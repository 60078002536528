export default {
  calibrationResultPage: {
    messages: {
      loadSessionsFailed: 'Impossibile caricare la sessione',
      sessionSuccessfullyCompleted: "La sessione e' terminata con successo",
      emptyReviews: 'Nessuna valutazione'
    },
    commentPlaceholder: 'Spazio per le note della sessione ✍️',
    buttons: { completeSession: 'Termina la sessione' },
    table: {
      organizer: 'Organizzatore:',
      columns: {
        gradedPoints: 'Punti da valutare',
        coincidence: 'Coincidenza',
        result: 'Risultato della valutazione'
      }
    }
  }
};
