import { Col, Row, Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const TableHead = styled(Row)`
  &.ant-row {
    padding: 8px 0 !important;
  }
`;

export const FilterCol = styled(Col)`
  &.ant-col {
    display: flex;
    align-items: center;
  }
`;

export const AddEmployeeButtonCol = styled(Col)`
  &.ant-col {
    display: flex;
    justify-content: flex-end;
  }
`;

export const StyledFieldLabel = styled(Text)`
  &.ant-typography {
    margin-top: 8px;
    margin-bottom: 4px;
    display: block;
  }
`;
