import { Breadcrumb, Button, Col, Row, Tooltip, Typography } from 'antd';
import CalibrationSessionStatusTag from 'components/Tags/CalibrationSessionStatusTag';
import { Info } from 'components/Info';
import SCard from 'components/Standard/SCard';
import UserPreview from 'components/UserPreview';
import { getUserName } from 'components/UserPreview/getUserName';
import truncateString from 'core/utils/truncateString';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getCurrentUser } from 'redux/selectors/users';

const CalibrationInfo = ({
  currentSession,
  usersByIds,
  checklistDefinitionsByIds,
  history,
  currentUserId
}) => {
  const { Text, Title } = Typography;
  const { t } = useTranslation();
  const {
    loading,
    id,
    name,
    authorId,
    scheduledTime,
    place,
    membersIds = [],
    description,
    status,
    reviewsIds = [],
    folderItemsIds = [],
    checklistDefinitionId
  } = currentSession;

  const membersNames = membersIds
    .map(memberId => getUserName({ user: usersByIds[memberId] }))
    .join(', ');

  const isVisibleResultButton = status === 'done' || authorId === currentUserId;

  const validURL = str => {
    const pattern = new RegExp(
      '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$',
      'i'
    ); // fragment locator
    return !!pattern.test(str);
  };

  return (
    <Col span={24}>
      <SCard bordered shadowed bodyPadding="8px" loading={loading}>
        <Row type="flex" align="middle" justify="space-between">
          <Col>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/calibrations/pending">
                  {t('calibrationsPage.sessionInfo.breadcrumbs.allSessions')}
                </Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <Link to={`/calibration/${id}`}>
                  {t('calibrationsPage.sessionInfo.breadcrumbs.currentSession')}
                </Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col>
            {isVisibleResultButton && (
              <Button type="primary" onClick={() => history.push(`/calibration-result/${id}`)}>
                {t('calibrationsPage.sessionInfo.buttons.viewSessionResult')}
              </Button>
            )}
          </Col>
        </Row>
        <Row type="flex" align="middle" justify="start" gutter={[8, 8]} style={{ margin: '-4px' }}>
          <Col>
            <Title level={4} style={{ margin: 0 }}>
              {name}
            </Title>
          </Col>
          <Col>
            <CalibrationSessionStatusTag status={status} />
          </Col>
        </Row>
        <Row type="flex" justify="start" gutter={[8, 8]} style={{ margin: '-4px' }}>
          <Col span={18}>
            <Col lg={12} xl={11} xxl={8}>
              <Row gutter={[4, 4]} type="flex" align="middle">
                <Col>
                  <Text strong>{t('calibrationsPage.sessionInfo.infoItems.organizer')}</Text>
                </Col>
                <Col>
                  <UserPreview userId={authorId} disabled margin="0px" />
                </Col>
              </Row>

              <Row gutter={[4, 4]} type="flex">
                <Col>
                  <Text strong>{t('calibrationsPage.sessionInfo.infoItems.scheduledTime')}</Text>
                </Col>
                <Col>{moment(scheduledTime).format('DD/MM/YYYY')}</Col>
              </Row>

              <Row gutter={[4, 4]} type="flex">
                <Col>
                  <Text strong>
                    {t('calibrationsPage.sessionInfo.infoItems.checklistDefinition')}
                  </Text>
                </Col>
                <Col>{get(checklistDefinitionsByIds, `${checklistDefinitionId}.name`)}</Col>
              </Row>
            </Col>

            <Col lg={12} xl={13} xxl={16}>
              <Row gutter={[4, 4]} type="flex">
                <Col>
                  <Text strong>{t('calibrationsPage.sessionInfo.infoItems.members')}</Text>
                </Col>
                <Col>
                  <Tooltip title={membersNames}>{truncateString(membersNames, 100)}</Tooltip>
                </Col>
              </Row>

              <Row gutter={[4, 4]} type="flex">
                <Col>
                  <Text strong>{t('calibrationsPage.sessionInfo.infoItems.description')}</Text>
                </Col>
                <Col>
                  <Tooltip title={description}>{truncateString(description, 100)}</Tooltip>
                </Col>
              </Row>

              <Row gutter={[4, 4]} type="flex">
                <Col>
                  <Text strong>{t('calibrationsPage.sessionInfo.infoItems.place')}</Text>
                </Col>
                <Col>
                  {validURL(place) ? (
                    <Button
                      type="link"
                      onClick={() => window.open(place)}
                      style={{ height: 19, padding: 0 }}
                    >
                      {truncateString(place, 100)}
                    </Button>
                  ) : (
                    <Text>{place}</Text>
                  )}
                </Col>
              </Row>
            </Col>
          </Col>
          <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Row gutter={[4, 4]} type="flex" align="bottom" justify="end">
              <Col>
                <Info
                  tooltip={{
                    title: t('calibrationsPage.sessionInfo.tooltip')
                  }}
                />
              </Col>
              <Col>
                <Text strong>{t('calibrationsPage.sessionInfo.infoItems.totalReviewedItems')}</Text>
                <Text>{`${reviewsIds.length}/${folderItemsIds.length * membersIds.length}`}</Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </SCard>
    </Col>
  );
};

const mapStateToProps = state => {
  const currentUserId = get(getCurrentUser(state), 'id', '');
  const usersByIds = state.usersResource.byIds;
  const checklistDefinitionsByIds = state.checklistDefinitionsResource.byIds;

  return {
    currentUserId,
    usersByIds,
    checklistDefinitionsByIds
  };
};

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CalibrationInfo));
