import { Col, Empty, List, message, Row, Typography } from 'antd';
import { isEmpty, throttle, isEqual } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions, operations } from 'redux/lists/userAccountBindingsList';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { userAccountBindingsResource } from 'redux/resources/userAccountBindings';
import { useTranslation } from 'react-i18next';
import UserPreview from 'components/UserPreview';
import styled from 'styled-components';
import SModal from 'components/Standard/SModal';
import Search from '../../../../components/Inputs/Search';

const { Text } = Typography;

const UserAccountListItem = styled(List.Item)`
  &&:hover {
    cursor: pointer;
  }
  && {
    padding: 12px 24px;
    width: 100%;
    background: ${props => (props.selected ? 'var(--gray-background)' : 'inherit')};
    && > .ant-row {
      width: 100%;
    }
  }
`;

const SelectUserAccountList = ({ visible, integrationId, userId, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedUserAccountId, setSelectedUserAccountId] = useState(null);
  const userAccountsByIds = useSelector(state => state.userAccountBindingsResource.byIds, isEqual);
  const { ids, loading, page, totalPages, search } = useSelector(
    state => state.userAccountBindingsList,
    isEqual
  );
  const userAccounts = ids.map(id => userAccountsByIds[id]);
  const hasNextPage = parseInt(totalPages) > parseInt(page.number);
  const [submiting, setSubmiting] = useState(false);

  const onSearch = search => {
    dispatch(actions.clearList());
    dispatch(actions.setSearch(search));
    dispatch(
      operations.load({
        search
      })
    );
  };

  const onSubmit = async () => {
    setSubmiting(true);
    await dispatch(
      userAccountBindingsResource.operations.updateById({ id: selectedUserAccountId, userId })
    );
    message.success(
      t('organizationStructure.tabs.connections.userAccounts.userConnectedToIntegration')
    );
    setSubmiting(false);
    onCancel();
  };
  useEffect(() => {
    dispatch(actions.clearList());
    return () => dispatch(actions.clearList());
  }, []);

  useEffect(() => {
    if (visible) {
      dispatch(
        operations.load({
          page,
          search,
          filters: { integrationId }
        })
      );
    } else {
      dispatch(actions.clearList());
      dispatch(actions.setSearch(null));
    }
  }, [visible, page.number]);

  const onScrollY = throttle(async el => {
    const isOnBottom = (element => {
      return element.clientHeight + element.scrollTop + 20 >= element.scrollHeight;
    })(el);

    if (hasNextPage && !loading && isOnBottom) {
      const newPage = `${parseInt(page.number) + 1}`;
      dispatch(
        actions.updatePage({
          number: newPage
        })
      );
    }
  }, 250);

  const renderUserAccount = userAccount => {
    return (
      <Row onClick={() => setSelectedUserAccountId(userAccount.id)}>
        <UserAccountListItem selected={selectedUserAccountId === userAccount.id}>
          <Row align="middle">
            <Col span={8}>
              <UserPreview showAvatar disabled user={userAccount} />
            </Col>
            <Col span={8} className="truncated">
              <Text>{userAccount?.email || ''}</Text>
            </Col>
            <Col span={8} className="truncated">
              <Text>{userAccount?.integrationUid}</Text>
            </Col>
          </Row>
        </UserAccountListItem>
      </Row>
    );
  };

  return (
    <SModal
      visible={visible}
      title={
        <Row align="middle" justify="space-between" style={{ paddingRight: '25px' }}>
          <Col>
            <Row>{t('organizationStructure.tabs.connections.userAccounts.selectUserAccount')}</Row>
          </Col>
          <Col>
            <Row gutter={[16, 0]}>
              <Col>
                <Search
                  loading={loading}
                  placeholder={t(
                    'organizationStructure.tabs.employees.table.filters.employeesSearch'
                  )}
                  onSearch={onSearch}
                  style={{ width: 200 }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      }
      width="930px"
      onCancel={onCancel}
      okText={t('general.add')}
      onOk={onSubmit}
      okButtonProps={{ loading: submiting, disabled: !selectedUserAccountId }}
      bodyStyle={{ padding: '0' }}
      size="big"
      destroyOnClose
    >
      <List.Item style={{ padding: '16px 24px' }}>
        <Row align="middle" style={{ width: '100%' }}>
          <Col span={8}>
            <Text strong>{t('general.employees')}</Text>
          </Col>
          <Col span={8}>
            <Text strong>{t('general.email')}</Text>
          </Col>
          <Col span={8}>
            <Text strong>ID</Text>
          </Col>
        </Row>
      </List.Item>
      <PerfectScrollbar
        onScrollY={onScrollY}
        options={{ suppressScrollX: true }}
        className="list-scroll-container"
        style={{ maxHeight: '330px' }}
      >
        <List dataSource={userAccounts} renderItem={renderUserAccount}>
          {loading && (
            <Col span={24}>
              <Row type="flex" justify="center">
                <Col>
                  <Text type="secondary"></Text>
                </Col>
              </Row>
            </Col>
          )}
          {!loading && !hasNextPage && isEmpty(userAccounts) && (
            <Col span={24}>
              <Row type="flex" justify="center">
                <Col>
                  <Empty
                    description={
                      <Text type="secondary">
                        {t(
                          'organizationStructure.tabs.connections.userAccounts.integrationHasNoAccounts'
                        )}
                      </Text>
                    }
                  />
                </Col>
              </Row>
            </Col>
          )}
          {!loading && !hasNextPage && !isEmpty(userAccounts) && (
            <Col span={24}>
              <Row type="flex" justify="center">
                <Col>
                  <Text type="secondary">{t('calibrationsPage.calibrationsList.item.all')}</Text>
                </Col>
              </Row>
            </Col>
          )}
        </List>
      </PerfectScrollbar>
    </SModal>
  );
};

export default SelectUserAccountList;
