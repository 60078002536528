import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  loading: false,
  byIds: {},
  historyByIds: {},
  errors: []
};

export const questionGroupAnalyticsLoadStart = createAction('loadStart/questionGroupAnalytics');
export const questionGroupAnalyticsLoadSuccess = createAction('loadSuccess/questionGroupAnalytics');
export const questionGroupAnalyticsLoadFailure = createAction('loadFailure/questionGroupAnalytics');
export const questionGroupAnalyticsHistoryLoadStart = createAction(
  'historyLoadStart/questionGroupAnalytics'
);
export const questionGroupAnalyticsHistoryLoadSuccess = createAction(
  'historyLoadSuccess/questionGroupAnalytics'
);
export const questionGroupAnalyticsHistoryLoadFailure = createAction(
  'historyLoadFailure/questionGroupAnalytics'
);

const questionGroupsAnalyticsReducer = createReducer(defaultState, {
  [questionGroupAnalyticsLoadStart]: state => ({ ...state, loading: true }),
  [questionGroupAnalyticsLoadSuccess]: (state, { payload }) => ({
    ...state,
    loading: false,
    byIds: { ...state.byIds, ...payload }
  }),
  [questionGroupAnalyticsLoadFailure]: (state, { payload }) => ({
    ...state,
    errors: [...state.errors, payload]
  }),

  [questionGroupAnalyticsHistoryLoadStart]: state => ({ ...state, loading: true }),
  [questionGroupAnalyticsHistoryLoadSuccess]: (state, { payload }) => ({
    ...state,
    loading: false,
    historyByIds: { ...state.historyByIds, ...payload }
  }),
  [questionGroupAnalyticsHistoryLoadFailure]: (state, { payload }) => ({
    ...state,
    errors: [...state.errors, payload]
  })
});

export default questionGroupsAnalyticsReducer;
