import { reduce } from 'lodash';
import moment from 'moment';

export const getInitialValues = (initialValues = {}) => {
  return reduce(
    initialValues,
    (prev, curr, key) => {
      if (
        (typeof curr === 'string' || curr instanceof String) &&
        moment(curr, moment.ISO_8601, true).isValid() &&
        curr.length === 24
      ) {
        return { ...prev, [key]: moment(curr) };
      }
      return { ...prev, [key]: curr };
    },
    {}
  );
};
