export default {
  crmEntity: {
    responsible: {
      title: 'Ответственный'
    },
    communications: {
      title: 'Коммуникации'
    },
    dateOfApplication: 'Дата заявки'
  }
};
