import { createReduxResource } from 'core/redux/resources';
import api from 'core/api';

export const usersResource = createReduxResource({
  name: 'users',
  customOperations: {
    inviteById: {
      callEndpoint: ({ id }) => api.inviteUser({ id }, {}),
      onSuccess: ({ result, dispatch }) => {
        dispatch({ type: 'users/updateByIdSucceed', payload: result });
        return result;
      }
    }
  }
});
