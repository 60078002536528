import React from 'react';
import { isEmpty, intersection, get } from 'lodash';
import { connect } from 'react-redux';
import { Spin } from 'antd';
import { getCurrentUser } from 'redux/selectors/users';

// hoc takes rendering config as first argument
export const hocComponentName = (
  { loading, hide, allowedPermissions = [], spinnerStyle = {} },
  WrappedComponent
) => {
  const hocComponent = ({ currentUser, ...props }) => {
    // show spinner for loading state
    if (loading) return <Spin spinning style={spinnerStyle} />;

    // show nothing for hidden state
    if (hide) return null;

    if (
      !isEmpty(allowedPermissions) &&
      isEmpty(intersection(get(currentUser, 'role.permissions', []), [...allowedPermissions]))
    )
      return null;

    return <WrappedComponent {...props} />;
  };

  return hocComponent;
};

export const mapStateToProps = (state, ownProps) => ({
  currentUser: getCurrentUser(state),
  ...ownProps
});

export const mapDispatchToProps = {};

export default (config, WrapperComponent) =>
  connect(mapStateToProps, mapDispatchToProps)(hocComponentName(config, WrapperComponent));
