import { LoadingOutlined } from '@ant-design/icons';
import { Search as SearchIcon } from 'react-feather';
import { Input } from 'antd';
import Icon from 'components/Icon';
import { debounce } from 'lodash';
import React from 'react';

const Search = ({ onSearch, loading, ...props }) => {
  const handleChange = debounce(e => {
    onSearch(e.target.value);
  }, 350);
  return (
    <Input
      onChange={handleChange}
      suffix={
        loading ? (
          <LoadingOutlined style={{ color: 'var(--black_4)', width: '16px', height: '16px' }} />
        ) : (
          <Icon icon={SearchIcon} size="16px" style={{ color: 'var(--black_4)' }} />
        )
      }
      {...props}
    />
  );
};

export default Search;
