export default {
  dashboardPages: {
    unitsAnalyticsWidgetTitle: 'Análisis por Departamento',
    reportSettings: 'Configuración del informe',
    reportName: 'Título del informe',
    changeUnit: 'Seleccione los departamentos',
    changeUser: 'Seleccione a los empleados',
    addReport: 'Añadir informe',
    selectAll: 'Seleccionar todo',
    deleteReport: 'Eliminar informe',
    deleteWidgetConfirmModalTitle: 'Eliminar el widget?',
    deleteWidgetConfirmModalContent: 'No podrá cancelar esta acción',
    noCreatedReports: 'No se ha creado ningún informe',
    clickAddReportButton: 'Haga clic en el botón "Agregar informe" para Mostrar los datos',
    conflicts: {
      unitsAnalyticsPage: {
        checklistsConflict: {
          title: 'No hay acceso al formulario de evaluación',
          description1:
            'Debido a un cambio en la configuración del perfil, ha perdido el acceso a su formulario de evaluación previamente seleccionado.',
          description2:
            'Para añadir o ver el informe, seleccione otro formulario de evaluación. Para recuperar el acceso al formulario de evaluación previamente establecido, póngase en contacto con el administrador.'
        }
      },
      appealsAnalyticsPage: {
        checklistsConflict: {
          title: 'No hay acceso a los formularios de evaluación',
          description1:
            'Debido a un cambio en la configuración del perfil, ha perdido el acceso a uno o más formularios de evaluación previamente seleccionados.',
          description2:
            'Seleccione otros formularios de evaluación para ver el informe. Para recuperar el acceso a sus formularios de evaluación previamente seleccionados, póngase en contacto con el administrador'
        }
      },
      unitsConflict: {
        title: 'Los ajustes establecidos no están disponibles',
        description1:
          'Debido a un cambio en la configuración del perfil, ha perdido el acceso a los datos de uno o más departamentos establecidos en la configuración del informe.',
        description2:
          'Para cambiar los filtros, seleccione los nuevos departamentos en la configuración del informe. Para recuperar el acceso a los ajustes previamente establecidos, póngase en contacto con el administrador.'
      },
      usersConflict: {
        title: 'Los ajustes establecidos no están disponibles',
        description1:
          'Debido a un cambio en la configuración del perfil, ha perdido el acceso a los datos de uno o varios empleados establecidos en la configuración del informe.',
        description2:
          'Para cambiar los filtros, seleccione otros empleados en la configuración del informe. Para recuperar el acceso a los ajustes previamente establecidos, póngase en contacto con el administrador.'
      }
    },

    autoFailScores: {
      unitsAnalyticsWidgetTitle: 'La dinámica de los errores críticos',
      columns: {
        date: 'Período',
        reviewsCountHistory: 'Número de inspecciones',
        percentOfClientInteractions: '% de inspecciones',
        anyTypeAutofailsCountHistory: 'Total de errores críticos',
        clientInteractionsCountHistory: 'Comunicaciones totales',
        reviewsWithAnyTypeAutofailCount: 'Número de inspecciones con errores críticos',
        checklistDefinitionAverageScoreHistory: 'Puntuación media',
        percentOfReviewsWithAnyTypeAutofailCount: '% de controles con errores críticos'
      },
      footer: {
        total: 'Total'
      }
    }
  }
};
