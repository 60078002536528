import { ymToken } from 'core/config';
import React, { useEffect } from 'react';
import ym, { YMInitializer } from 'react-yandex-metrika';

const YandexMetrika = ({ history }) => {
  useEffect(() => {
    if (!ymToken) return;

    history.listen(location => {
      ym('hit', location.pathname);
    });
  }, []);

  if (!ymToken) return null;

  return (
    <YMInitializer
      accounts={[parseInt(ymToken)]}
      options={{ webvisor: true, clickmap: true, trackLinks: true, accurateTrackBounce: true }}
    />
  );
};

export default YandexMetrika;
