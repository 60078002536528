import React from 'react';
import { useTranslation } from 'react-i18next';
import { selectSearch } from 'core/utils/selectSearch';
import { CLIENT_INTERACTION_TYPES } from 'core/utils/constants';
import { connect } from 'react-redux';
import { actions } from 'redux/lists/clientInteractionsList';
import { Select } from 'antd';
import { FilterContainer, StyledSelect } from '../../ClientInteractionsPage/components/styled';

const { Option } = Select;

const CommunicationTypes = ({ updateFilters, filters }) => {
  const { t } = useTranslation();
  return (
    <FilterContainer>
      <StyledSelect
        disabled={false}
        mode="multiple"
        allowClear
        optionLabelProp="label"
        placeholder={t(
          'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypes'
        )}
        value={filters?.communicationsTypes || []}
        maxTagCount={0}
        maxTagPlaceholder={selectedKeys =>
          `${t(
            'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypesShort'
          )}: ${selectedKeys.length}`
        }
        onChange={communicationsTypes => updateFilters({ communicationsTypes })}
        filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
      >
        {Object.values(CLIENT_INTERACTION_TYPES).map(item => (
          <Option
            value={item.value}
            key={item.value}
            label={`${t(
              'clientInteractionsPage.tableFilters.tableGeneralFilters.form.communicationsTypes'
            )}: ${t(item.title)}`}
          >
            {t(item.title)}
          </Option>
        ))}
      </StyledSelect>
    </FilterContainer>
  );
};

const mapStateToProps = state => {
  const { filters } = state.clientInteractionsList;
  return {
    filters
  };
};

const mapDispatchToProps = {
  updateFilters: actions.updateFilters
};

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationTypes);
