import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  newItemName: null,
  currentFoldersIds: [],
  showAddToLibraryModal: false
};

export const setNewItemName = createAction('uiAddToLibraryModal/setNewItemName');
export const setCurrentFoldersIds = createAction('uiAddToLibraryModal/setCurrentFoldersIds');
export const openAddToLibraryModal = createAction('uiRecordPlayer/openAddToLibraryModal');
export const closeAddToLibraryModal = createAction('uiRecordPlayer/closeAddToLibraryModal');
export const clearLibraryModal = createAction('uiAddToLibraryModal/clearModal');

export const addToLibraryModalReducer = createReducer(defaultState, {
  [setNewItemName]: (state, { payload }) => ({ ...state, newItemName: payload }),
  [setCurrentFoldersIds]: (state, { payload }) => ({ ...state, currentFoldersIds: payload }),
  [openAddToLibraryModal]: state => ({ ...state, showAddToLibraryModal: true }),
  [closeAddToLibraryModal]: state => ({ ...state, showAddToLibraryModal: false }),
  [clearLibraryModal]: () => ({ ...defaultState })
});

export default addToLibraryModalReducer;
