import { destroy, get, put, post } from 'redux-bees';

const dashboardRowsEndpoints = apiPath => ({
  createDashboardRow: { method: post, path: `${apiPath}/dashboard_rows` },
  moveUp: { method: put, path: `${apiPath}/dashboard_rows/:id/move_up` },
  moveDown: { method: put, path: `${apiPath}/dashboard_rows/:id/move_down` },
  deleteDashboardRow: { method: destroy, path: `${apiPath}/dashboard_rows/:id` }
});

export default dashboardRowsEndpoints;
