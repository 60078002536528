import React from 'react';
import { Row, Col, Result, Button } from 'antd';
import SCard from 'components/Standard/SCard';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LinkToLandingPage, AuthLogo } from '../styled';

const RecoverPasswordSuccessPage = ({ history }) => {
  const SuccessContainer = styled(Col)`
    && {
      display: flex;
      align-items: center;
      justify-content: center;

      .ant-card {
        width: 80%;
        max-width: 560px;
      }
    }
  `;

  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('pagesMeta.recoverPasswordPage.title')}</title>
      </Helmet>
      <AuthLogo />
      <Row type="flex" align="middle" justify="center" style={{ width: '100%' }}>
        <Col span={24}>
          <SuccessContainer>
            <SCard shadowed>
              <Result
                status="success"
                title={t('recoverPasswordPage.success.title')}
                subTitle={t('recoverPasswordPage.success.description')}
                extra={[
                  <Button type="primary" key="login" onClick={() => history.push('/login')}>
                    {t('recoverPasswordPage.success.buttons.login')}
                  </Button>,
                  <Button key="register" onClick={() => history.push('/register')}>
                    {t('recoverPasswordPage.success.buttons.register')}
                  </Button>
                ]}
                style={{ padding: 0 }}
              />
            </SCard>
          </SuccessContainer>
        </Col>
      </Row>
      <LinkToLandingPage />
    </>
  );
};

export default withRouter(RecoverPasswordSuccessPage);
