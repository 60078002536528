export default {
  errorPages: {
    forbidden: {
      title: "L'accesso alla paggina e' stato negato. Contatta l'amminstratore del portale."
    },
    internalServerError: { title: "Qualcosa e' andato storto. Prova a ricaricare la pagina." },
    loadingPage: { title: 'Caricamento dati...' },
    mobileBlocked: {
      title: 'Uff...',
      description: "La versione mobile e' ancora in fase di sviluppo."
    },
    crashApp: {
      title: 'Qualcosa è andato storto.',
      description: 'Si prega di contattare il supporto tecnico per risolvere il problema.'
    },
    notFound: { title: 'Non esiste un tale indirizzo.' },
    unauthorized: { title: 'Per continuare a lavorare devi effettuare il login' },
    buttons: {
      login: 'Entrare',
      goBack: 'Dietro',
      goToHomePage: 'Alla pagina principale'
    }
  }
};
