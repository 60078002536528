import { get, sum, orderBy, mean, isEmpty } from 'lodash';
import { ANALYTICS_WIDGETS_NAMES } from 'core/utils/constants';

const tableData = ({
  checklistColorZoneData,
  previousChecklistColorZoneData,
  reviewsCountData,
  previousReviewsCountData,
  averageScoreData,
  previousPeriodAverageScoreData,
  usersByIds,
  units,
  ratingFlagsData,
  filters
}) => {
  const unitsWithUsers = units.map(unit => ({
    ...unit,
    users: unit.usersIds.map(userId => usersByIds[userId] || {})
  }));

  return orderBy(
    unitsWithUsers.reduce((result, unit) => {
      const isVisibleUnit =
        sum(unit.users.map(user => get(reviewsCountData, `${user.id}.value`, 0))) === 0 ||
        (!isEmpty(filters.unitsIds) && !filters.unitsIds.includes(unit.id));

      if (isVisibleUnit) {
        return result;
      }

      const widgetData = {
        key: unit.id,
        unitName: unit.name,

        [ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS]: mean(
          unit.users.reduce((acc, user) => {
            const score = get(averageScoreData, `${user.id}.value`);

            if (!score) {
              return acc;
            }

            return [...acc, score];
          }, [])
        ),

        [`${ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_AVERAGE_SCORE_BY_OPERATORS}_previous`]: mean(
          unit.users.reduce((acc, user) => {
            const score = get(previousPeriodAverageScoreData, `${user.id}.value`);

            if (!score) {
              return acc;
            }

            return [...acc, score];
          }, [])
        ),

        [ANALYTICS_WIDGETS_NAMES.REVIEWS_COUNT_BY_OPERATORS]: sum(
          unit.users.map(user => get(reviewsCountData, `${user.id}.value`, 0))
        ),

        greenZonePercentage:
          (sum(unit.users.map(user => get(checklistColorZoneData, [user.id, 'high'], 0))) /
            sum(unit.users.map(user => get(reviewsCountData, `${user.id}.value`, 0)))) *
          100,

        greenZonePercentagePrevious:
          (sum(unit.users.map(user => get(previousChecklistColorZoneData, [user.id, 'high'], 0))) /
            sum(unit.users.map(user => get(previousReviewsCountData, `${user.id}.value`, 0)))) *
          100,

        [ANALYTICS_WIDGETS_NAMES.CHECKLIST_DEFINITION_COLOR_ZONES_BY_OPERATORS]: {
          low: sum(unit.users.map(user => get(checklistColorZoneData, [user.id, 'low'], 0))),
          medium: sum(unit.users.map(user => get(checklistColorZoneData, [user.id, 'medium'], 0))),
          high: sum(unit.users.map(user => get(checklistColorZoneData, [user.id, 'high'], 0)))
        },

        [ANALYTICS_WIDGETS_NAMES.RATING_FLAGS_COUNT_BY_OPERATORS]: {
          without_flag: sum(
            unit.users.map(user => get(ratingFlagsData, [user.id, 'without_flag'], 0))
          ),
          red: sum(unit.users.map(user => get(ratingFlagsData, [user.id, 'red'], 0))),
          yellow: sum(unit.users.map(user => get(ratingFlagsData, [user.id, 'yellow'], 0))),
          green: sum(unit.users.map(user => get(ratingFlagsData, [user.id, 'green'], 0)))
        }
      };

      return [...result, widgetData];
    }, []),
    'name'
  );
};

export default tableData;
