export default {
  triggersSettingsPage: {
    loading: 'Loading',
    card: {
      titles: {
        create: 'Create tag',
        edit: 'Edit tag'
      },
      buttons: {
        enable: 'Enable',
        disable: 'Disable',
        settings: 'Settings',
        delete: 'Delete tag',
        createTaskDefinitionCard: 'Add new tag'
      },
      confirmDelete: {
        title: 'Delete tag?',
        ok: 'Yes',
        cancel: 'No'
      },
      info: {
        type: 'Type: ',
        author: 'Author',
        status: {
          title: 'Status',
          enable: 'Enabled',
          disable: 'Disabled'
        },
        checklistDefinition: 'Scorecard definition',
        createdAt: 'Creation date'
      },
      loading: 'Loading tags...😤',
      listLoaded: 'Thats all! 😊'
    },
    editTaskDefinition: {
      messages: {
        tagSuccessfullyUpdated: 'Tag updated',
        tagSuccessfullySaved: 'Tag saved',
        tagSuccessfullyDeleted: 'Tag deleted',
        addSettingsToAutomaticTag: 'Add settings for your automatic tag:'
      },
      buttons: {
        cancel: 'Cancel',
        save: 'Save'
      },
      text: {
        form: 'from:',
        to: 'to:'
      },
      empty: {
        checklistDefinition: 'Score card not selected or no questions in a scorecard',
        checklistDefinitionItemName: 'No name given'
      },
      form: {
        name: 'Name',
        namePlaceholder: 'Enter name',
        checklistDefinition: 'Scorecard',
        type: {
          title: 'Type',
          automatic: {
            name: 'Automatic ',
            description: '(Is added to the call upon completion of the scorecard)'
          },
          manual: {
            name: 'Manual ',
            description: '(Can be added to the call personally)'
          }
        }
      },
      changeTagTypeConfirm: {
        title: 'Change tag type?',
        description: 'All unsaved data will be deleted',
        ok: 'Yes',
        cancel: 'No'
      },
      changeChecklistDefinitionConfirm: {
        title: 'Change the scorecard?',
        description: 'All unsaved data will be deleted',
        ok: 'Yes',
        cancel: 'No'
      },
      resetChecklistDefinitionConditionsConfirm: {
        title: 'Condition cannot include questions or groups of questions',
        description: 'Reset the conditions for the entire scorecard?',
        ok: 'Yes',
        cancel: 'No'
      },
      resetChecklistDefinitionQuestionsConditionsConfirm: {
        title: 'Condition cannot include questions',
        description: 'Reset conditions for a group of questions?',
        ok: 'Yes',
        cancel: 'No'
      }
    }
  }
};
