import { TimePicker } from 'antd';
import { isNil } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const { RangePicker } = TimePicker;

export default ({ from, to, onChange, disabled, size, style, allowClear = true }) => {
  const { t } = useTranslation();

  const changeMomentsToUnits = values => {
    if (!values) return onChange({ from: undefined, to: undefined });

    const fromMoment = values[0];
    const toMoment = values[1];

    onChange({
      from: fromMoment ? fromMoment.diff(fromMoment.clone().startOf('day'), 'seconds') : undefined,
      to: toMoment ? toMoment.diff(toMoment.clone().startOf('day'), 'seconds') : undefined
    });
  };

  return (
    <DurationFiltersContainer style={style}>
      <RangePicker
        size={size}
        disabled={disabled}
        style={{ width: '100%' }}
        placeholder={[t('general.from'), t('general.to')]}
        value={[
          isNil(from)
            ? undefined
            : moment()
                .startOf('day')
                .add(parseInt(from), 'seconds'),
          isNil(to)
            ? undefined
            : moment()
                .startOf('day')
                .add(parseInt(to), 'seconds')
        ]}
        allowEmpty={[true, true]}
        allowClear={allowClear}
        onChange={changeMomentsToUnits}
      />
    </DurationFiltersContainer>
  );
};

const DurationFiltersContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
