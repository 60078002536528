import styled from 'styled-components';
import { Tree } from 'antd';

const { TreeNode } = Tree;

export const CustomTree = styled(Tree)`
  .ant-tree li .ant-tree-node-content-wrapper {
    width: 100%;
  }
`;

export const CustomNode = styled(TreeNode)`
  & .ant-tree-node-content-wrapper {
    width: 100%;
  }
`;
