import React from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { SpinContainer } from './styled';

const LoadingPage = () => {
  const { t } = useTranslation();
  return (
    <SpinContainer>
      <Spin tip={t('errorPages.loadingPage.title')} />
    </SpinContainer>
  );
};

export default LoadingPage;
