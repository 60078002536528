import React from 'react';
import moment from 'moment';
import { beatifyFloat } from 'core/utils/convertFloat';
import { keyBy, orderBy } from 'lodash';
import { Tooltip } from 'antd';
import { getColorizedColumn } from 'pages/DashboardPages/AppealsAnalyticsPage/Components/Widgets/utils';
import SText from '../../../../../components/Standard/SText';
import { valueToColorZoneColor } from '../../../../DashboardPage/utils';

export const prepareTableColumns = ({ datesArray, t }) => {
  const datesColumns = datesArray.map(day => {
    const dateString = moment(day).format('YYYY-MM-DD');
    return {
      title: moment(day).format('DD.MM'),
      dataIndex: dateString,
      key: dateString,
      align: 'center',
      width: datesArray.length < 15 ? null : 90,
      render: (text, record) => {
        const value = beatifyFloat(text?.value) === 'N/A' ? 'N/A' : `${beatifyFloat(text?.value)}%`;
        return getColorizedColumn({
          color: text?.color === 'white' ? 'var(--gray_4)' : text?.color,
          component: <SText strong={record.key === 'footer'}>{value}</SText>
        });
      }
    };
  });

  return [
    {
      title: t('general.units'),
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
      ellipsis: true,
      width: 250,
      render: (text, record) => {
        return getColorizedColumn({
          color: 'var(--gray_4)',

          component: (
            <Tooltip title={record.key === 'footer' ? '' : record.title}>
              <div style={{ height: '42px', display: 'flex', alignItems: 'center' }}>
                <SText ellipsis strong={record.key === 'footer'}>
                  {record.title}
                </SText>
              </div>
            </Tooltip>
          )
        });
      }
    },
    ...datesColumns
  ];
};

export const prepareTableData = ({ datesArray, unitsByIds, checklistDefinition }) => {
  return orderBy(
    unitsByIds?.reduce((acc, item) => {
      const history = datesArray?.reduce((acc, day) => {
        const value = item.history[moment(day).format('YYYY-MM-DD')];

        return {
          ...acc,
          [moment(day).format('YYYY-MM-DD')]: {
            value: parseFloat(beatifyFloat(value)),
            color: valueToColorZoneColor({ value, colorZones: checklistDefinition.colorZones })
          }
        };
      }, {});
      const data = {
        title: item.name,
        dataIndex: item.id,
        key: item.id,
        id: item.name,
        align: 'center',
        ...history
      };
      return [...acc, data];
    }, []),
    ''
  );
};

export const prepareFooterRow = ({ datesArray, total, checklistDefinition, t }) => {
  const totalScoresByDates = keyBy(total, 'date');
  return {
    key: 'footer',
    title: t('general.total'),
    id: t('general.total'),
    ...datesArray.reduce((acc, day) => {
      const date = moment(day).format('YYYY-MM-DD');
      const value = totalScoresByDates[date]?.total;
      return {
        ...acc,
        [date]: {
          value: parseFloat(beatifyFloat(value)),
          color: valueToColorZoneColor({
            value,
            colorZones: checklistDefinition.colorZones
          })
        }
      };
    }, {})
  };
};
