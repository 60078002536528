import createListReducer from 'core/redux/lists/listReducer';
import { reviewsResource } from 'redux/resources/reviews';

export const userReviewsWithCommentsListParams = {
  name: 'userReviewsWithComments',
  defaultState: {
    filters: {
      hasComments: 'true',
      commentsRatingFlags: '',
      checklistDefinitionId: '',
      timeFrom: '',
      timeTo: '',
      symbolicTimeRange: ''
    },
    page: {
      number: '1',
      size: '10'
    }
  },
  rebuildQuery: true,
  loader: reviewsResource.operations.load,
  keepResults: true
};

const { actions, reducer, operations } = createListReducer(userReviewsWithCommentsListParams);

const userReviewsWithCommentsListReducer = reducer;

export { actions, userReviewsWithCommentsListReducer, operations };
