import React from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { useSelector } from 'react-redux';
import { StyledAvatarGroup } from 'components/CrmEntityContent/styled';
import UserPreview from 'components/UserPreview';

const Users = ({ operatorId, fontWeight = 'normal', fontSize = 18 }) => {
  const user = useSelector(state => state.usersResource.byIds[operatorId]);
  if (!user) return null;

  return (
    <SRow flexDirection="row" type="flex">
      <SCol margin="auto 0">
        <StyledAvatarGroup {...fontWeight}>
          <UserPreview
            avatarSize="default"
            user={user}
            userId={operatorId}
            showAvatar
            fontSize={fontSize}
          />
        </StyledAvatarGroup>
      </SCol>
    </SRow>
  );
};

export default Users;
