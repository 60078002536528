import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Input } from 'antd';
import SCard from './SCard';
import SCol from '../../components/Standard/SCol';
import SSelect from './SSelect';
import { HeaderItems } from './styled';
import SButton from './SButton';
import ModalTask from './ModalTask';
import { actions } from '../../redux/lists/aiSettingsList';

const { Option } = SSelect;

const SearchAiSettings = ({
  isModalOpenAddorEditTask,
  setIsModalOpenAddorEditTask,
  isEditingData,
  setIsEditingData,
  newTaskModal,
  setNewTaskModal
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const options = [
    { name: t('aiSettingsPage.processStatus.completed'), value: 'finish' },
    { name: t('aiSettingsPage.processStatus.process'), value: 'in_process' },
    { name: t('aiSettingsPage.processStatus.mistake'), value: 'validation_error' },
    { name: t('aiSettingsPage.processStatus.ready'), value: 'ready' },
    { name: t('aiSettingsPage.processStatus.all'), value: 'all_statuses' }
  ];
  const showModal = () => {
    setIsModalOpenAddorEditTask(true);
    setNewTaskModal(true);
  };
  const onSearch = value => dispatch(actions.setSearch(value));
  const handleChange = value => {
    dispatch(actions.setStatus(value));
  };

  return (
    <>
      <SCol span={24}>
        <SCard shadowed bordered>
          <HeaderItems>
            <div>
              <Input.Search
                style={{ width: '180px', marginRight: '24px' }}
                placeholder={t('aiSettingsPage.taskSettings.enterName')}
                onSearch={onSearch}
                enterButton
              />
              <SSelect
                size="middle"
                disabled={false}
                onChange={handleChange}
                placeholder={t('aiSettingsPage.taskSettings.byStatus')}
              >
                {Object.values(options).map(item => (
                  <Option value={item.value} key={item.value}>
                    {item.name}
                  </Option>
                ))}
              </SSelect>
              {/* <SSelect */}
              {/*  size="middle" */}
              {/*  disabled={false} */}
              {/*  // onChange={setValue} */}
              {/*  // value={value} */}
              {/*  placeholder="По созданию" */}
              {/* > */}
              {/*  {Object.values(options2).map(item => ( */}
              {/*    <Option value={item.id} key={item.id}> */}
              {/*      {item.name} */}
              {/*    </Option> */}
              {/*  ))} */}
              {/* </SSelect> */}
            </div>
            <div>
              <SButton type="primary" size="large" onClick={showModal}>
                {t('aiSettingsPage.taskSettings.newTask')}
              </SButton>
            </div>
          </HeaderItems>
        </SCard>
      </SCol>
      {newTaskModal && (
        <ModalTask
          isModalOpen={isModalOpenAddorEditTask}
          setIsModalOpen={setIsModalOpenAddorEditTask}
          setNewTaskModal={setNewTaskModal}
          isEditingData={isEditingData}
          setIsEditingData={setIsEditingData}
        />
      )}
    </>
  );
};

export default SearchAiSettings;
