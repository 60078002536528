import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { get } from 'lodash';
import { connect } from 'react-redux';
import en from 'locales/en/apexCharts';
import ru from 'locales/ru/apexCharts';
import es from 'locales/es/apexCharts';
import it from 'locales/it/apexCharts';
import { beatifyFloat } from 'core/utils/convertFloat';
import { useTranslation } from 'react-i18next';

const CombinedChart = ({ chartData, selectedUserId, preferedLocale }) => {
  const { t } = useTranslation();
  const series = [
    {
      name: t('dashboardPage.tableReviewsCountByReviewers.viewModes.chart.chart.series.reviewed'),
      type: 'column',
      data: Object.values(get(chartData, ['bar', selectedUserId, 'history'], {}))
    },

    {
      name: t(
        'dashboardPage.tableReviewsCountByReviewers.viewModes.chart.chart.series.totalReviewed'
      ),
      type: 'line',
      data: Object.values(chartData.line)
    }
  ];

  const options = {
    chart: {
      type: 'line',
      stacked: false,
      toolbar: {
        show: false
      },
      offsetY: 8,
      zoom: {
        enabled: false
      },
      defaultLocale: preferedLocale,
      locales: [ru, en, es, it]
    },
    colors: ['var(--primary)', 'var(--lilac_8)'],
    stroke: {
      width: [0, 2, 2],
      curve: 'smooth'
    },
    plotOptions: {
      bar: {
        columnWidth: '50%'
      }
    },

    fill: {
      gradient: {
        inverseColors: false,
        shade: 'light',
        type: 'vertical',
        opacityFrom: 0.85,
        opacityTo: 0.55,
        stops: [0, 100, 100, 100]
      }
    },
    labels: Object.keys(chartData.line),
    markers: {
      size: 0
    },
    xaxis: {
      type: 'datetime'
    },
    yaxis: {
      title: {
        text: t('dashboardPage.tableReviewsCountByReviewers.viewModes.chart.chart.yAxis')
      },
      labels: {
        formatter: value => {
          return beatifyFloat(value, 0);
        },
        offsetX: 17
      },
      min: 0
    },
    tooltip: {
      shared: true,
      intersect: false
    }
  };

  return <ReactApexChart options={options} series={series} type="line" height={485} />;
};

const mapStateToProps = state => {
  return {
    preferedLocale: state.reduxTokenAuth.currentUser.attributes.user['prefered-locale']
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(CombinedChart);
