import { destroy, get, post, put } from 'redux-bees';

const statusesEndpoints = apiPath => ({
  getStatuses: { method: get, path: `${apiPath}/client_interactions/statuses` },
  updateStatus: { method: put, path: `${apiPath}/client_interactions/statuses/:id` },
  createStatus: { method: post, path: `${apiPath}/client_interactions/statuses` },
  deleteStatus: { method: destroy, path: `${apiPath}/client_interactions/statuses/:id` }
});

export default statusesEndpoints;
