import React from 'react';
import { Avatar } from 'antd';
import en from 'assets/images/locales/english.svg';
import ru from 'assets/images/locales/russian.svg';
import es from 'assets/images/locales/spanish.svg';
import it from 'assets/images/locales/italian.svg';

// TODO: move to user settings
const LocaleFlag = ({ locale }) => {
  const localeToFlag = {
    en,
    ru,
    es,
    it
  };

  return <Avatar src={localeToFlag[locale]} style={{ width: 20, height: 20 }} />;
};

export default LocaleFlag;
