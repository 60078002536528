import { Layout, Menu } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const MenuItem = styled(Menu.Item)`
  height: 100%;
`;

const BitrixLayout = ({ children }) => {
  const { Header, Content } = Layout;

  const history = useHistory();

  const onSelectTab = ({ key }) => history.push(key);

  return (
    <Layout>
      <Header className="headerStyle" style={{ height: '56px' }}>
        <Menu
          onClick={onSelectTab}
          selectedKeys={[history.location.pathname]}
          mode="horizontal"
          style={{ lineHeight: '56px' }}
        >
          <MenuItem key="/bitrix/client-interactions">Список коммуникаций</MenuItem>
          <MenuItem key="/bitrix/reviews">Проверки</MenuItem>
        </Menu>
      </Header>
      <Content style={{ background: 'white' }}>{children}</Content>
    </Layout>
  );
};

export default BitrixLayout;
