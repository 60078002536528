import { isEmpty, find, reject } from 'lodash';
import { BITRIX_INTEGRATION_PLACEMENTS } from 'core/utils/constants';

export const clientInteractionPlacements = [
  BITRIX_INTEGRATION_PLACEMENTS.CRM_DEAL_DETAIL_TAB,
  BITRIX_INTEGRATION_PLACEMENTS.CRM_LEAD_DETAIL_TAB
];

export const getPlacementInfo = () => {
  try {
    const info = window.BX24.placement.info();
    return info;
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const finishInstall = (onInstall = () => {}, onFinish = () => {}) => {
  try {
    window.BX24.installFinish();
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getProfile = () => {
  try {
    return window.BX24.profile();
  } catch (error) {
    console.log(error);
  }
};

export const callApiMethod = async ({ methodName, params = {}, callback }) => {
  try {
    window.BX24.callMethod(methodName, params, result => {
      if (result.error()) return;

      callback(result.data());
      if (result.more()) result.next();
    });
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getCurrentUserData = callback => {
  callApiMethod({
    methodName: 'user.current',
    callback
  });
};

export const getOption = name => {
  try {
    return JSON.parse(window.BX24.appOption.get(name));
  } catch (error) {
    console.log(error);
  }
};

export const setOption = (name, value, callback = () => {}) => {
  try {
    return window.BX24.appOption.set(name, JSON.stringify(value), callback);
  } catch (error) {
    console.log(error);
  }
};

// FIXME: look for empty PARENT to find department
export const getUserOrganization = callback => {
  callApiMethod({
    methodName: 'department.get',
    callback: res => callback(find(res, ({ PARENT, ID }) => ID === '1' || isEmpty(PARENT)))
  });
};

export const resizeWindow = ({ width, height }) => {
  try {
    window.BX24.resizeWindow(width, height);
  } catch (error) {
    console.log(error);
    return undefined;
  }
};

export const getAuth = () => {
  try {
    return window.BX24.getAuth();
  } catch (error) {
    console.log(error);
  }
};

export const getAppInfo = () => {
  return new Promise((resolve, reject) => {
    try {
      callApiMethod({ methodName: 'app.info', callback: resolve });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const isIntegrated = !isEmpty(window.BX24);

export const isClientInteractionPlacement = () => {
  return clientInteractionPlacements.includes(getPlacementInfo().placement);
};

export const init = () => {
  // * returns promise which resolved on execution
  return new Promise((resolve, reject) => {
    try {
      window.BX24.init(resolve);
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

const BitrixService = {
  getUserOrganization,
  finishInstall,
  getPlacementInfo,
  callApiMethod,
  resizeWindow,
  isIntegrated,
  getCurrentUserData,
  getAppInfo,
  getAuth,
  getOption,
  init,
  setOption
};

export default BitrixService;
