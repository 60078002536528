import { exportWidgetData } from 'pages/DashboardPage/utils';
import { updateDashboardPageWidget } from 'redux/entities/dashboardPages/operations';
import { prepareFooterRow, prepareTableData } from './prepareTableData';

export const onTableChange = async ({
  pagination,
  sorter,
  widgetId,
  tableColumns,
  filters,
  dispatch,
  setInitialState
}) => {
  setInitialState({
    number: pagination?.current,
    size: pagination?.pageSize - 1
  });

  const columnsKeys = tableColumns.map(item => item.key);
  const { columnKey } = sorter;

  const isNewSort = columnsKeys.includes(columnKey);

  const getSorterString = () =>
    sorter?.order === 'ascend' ? sorter?.columnKey : `-${sorter?.columnKey}`;

  await dispatch(
    updateDashboardPageWidget({
      id: widgetId,
      filters,
      page: {
        number: pagination.current,
        size: pagination.pageSize - 1
      },
      sort: isNewSort ? getSorterString() : ''
    })
  );
};

export const disabledDayOption = ({
  diff,
  timeFrom,
  timeTo,
  symbolicTimeRange,
  currentOption,
  historyGroupingPeriod,
  onChange
}) => {
  const excludedSymbolicTimeRangePeriods = ['this_year', 'previous_year'];
  const isUnacceptableDaysCount = diff > 60 && currentOption === 'day';

  const isUnacceptableSymbolicTimeRangeValue =
    excludedSymbolicTimeRangePeriods.includes(symbolicTimeRange) && currentOption === 'day';

  if (isUnacceptableDaysCount || isUnacceptableSymbolicTimeRangeValue) {
    if (historyGroupingPeriod === 'day') {
      onChange({
        clientInteractionTimeFrom: timeFrom,
        clientInteractionTimeTo: timeTo,
        historyGroupingPeriod: 'week'
      });
    }
    return true;
  }
};

export const onAdd = ({
  name,
  id,
  updatedFilter,
  filters,
  setVisibleFilterModal,
  dispatch,
  widgetPageSize
}) => {
  dispatch(
    updateDashboardPageWidget({
      id,
      filters: {
        ...filters,
        ...updatedFilter
      },
      page: {
        number: 1,
        size: widgetPageSize
      },
      name
    })
  );
  setVisibleFilterModal(false);
};

export const startExport = async ({
  t,
  id,
  meta,
  filters,
  dispatch,
  initialState,
  total,
  setLoadingExport,
  columns,
  checklistDefinition,
  datesArray
}) => {
  const res = await dispatch(
    updateDashboardPageWidget({
      id,
      filters,
      page: {
        number: 1,
        size: meta.total_count
      }
    })
  );

  const rows = [
    ...prepareTableData({
      datesArray,
      unitsByIds: res[id].data,
      checklistDefinition
    }),
    prepareFooterRow({ t, datesArray, total, checklistDefinition })
  ];

  const body = {
    widgetData: {
      columns,
      rows
    }
  };

  await exportWidgetData({ body, setLoadingExport });

  await dispatch(
    updateDashboardPageWidget({
      id,
      filters,
      page: {
        ...initialState
      }
    })
  );
};
