export default {
  userAnalyticsPage: {
    analyticsTabs: {
      analytics: 'Analytics',
      rates: 'Evaluations',
      flagsAndComments: 'Flags and Comments'
    },
    widgets: {
      checklistTooltip: 'Scorecards analytics',
      questionTooltip: 'Question analytics',
      reviewsCount: {
        name: 'Total number of evaluations',
        description: 'Total number of evaluations on the scorecard for the selected period'
      },
      averageScore: {
        name: 'Average score',
        description:
          'Average scorecard rate calculated from all scores for a selected period. For "all score cards" is calculated as the arithmetic mean'
      },
      lowPercentage: {
        name: 'Critical',
        description: 'The number of red zone scores'
      },
      mediumPercentage: {
        name: 'Normal',
        description: 'The number of yellow zone scores'
      },
      highPercentage: {
        name: 'Excellent',
        description: 'The number of green zone scores'
      },
      flags: {
        name: 'Flags',
        description: 'Number of flags added to the score for the selected scorecard'
      }
    },
    userReviewsTable: {
      columns: {
        clientInteractionStartedAt: 'Date and time',
        clientPhoneNumber: 'Phone number',
        clientInteractionDirection: 'Direction',
        clientInteractionDuration: 'Duration',
        checklistScore: 'Score',
        comments: 'Flags and comments',
        checklistDefinition: 'Scorecard',
        tasks: 'Tags',
        createdAt: 'Evaluation date',
        reviewerLastName: 'Evaluation author'
      },
      reviewsCount: 'Reviews count:',
      colorZoneSelect: { placeholder: 'Evaluation color zone' }
    },
    reviewsWithComments: {
      flagsAndCommentsCount: 'Total number of flags and comments',
      flagsAndCommentsPlaceholder: 'Flags and comments',
      loading: {
        load: 'Loading comments...😤',
        empty: 'Sorry, but there were no comments 😟',
        all: 'All 😊'
      }
    },
    reviewPreview: {
      date: 'Date:',
      type: 'Type:',
      value: 'Score:'
    },
    analyticsChartsPanel: { empty: 'No scores found for these filters 😓' },
    mainControlsPanel: {
      checklistDefinitionsEmpty: 'Empty scorecards',
      questionValues: 'Scores for all questions'
    }
  }
};
