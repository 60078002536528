import styled from 'styled-components';
import { Button } from 'antd';

export const GreenButton = styled(Button)`
  background: #7ad051 !important;
  color: white !important;
  opacity: ${props => (props.disabled ? 0.5 : 1)};

  :hover {
    color: white !important;
    border-color: white !important;
  }
`;
