import React, { useState } from 'react';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import SCard from 'components/Standard/SCard';
import UserPreview from 'components/UserPreview';
import { useTranslation } from 'react-i18next';
import SText from 'components/Standard/SText';
import { beatifyFloat } from 'core/utils/convertFloat';
import { useSelector, useDispatch } from 'react-redux';
import RedesignWidgetTable from 'components/Dashboard/RedesignWidgetTable';
import { IconButton } from 'components/Buttons';
import AddUsersModal from 'components/AddUsersModal';
import { get, isEqual } from 'lodash';
import { hasConflictFilters, appellationsPeriodSetter } from 'pages/DashboardPage/utils';
import { Empty, Radio, Tooltip } from 'antd';
import { PERMISSIONS, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { updateDashboardPageWidget } from 'redux/entities/dashboardPages/operations';
import AddUnitsModal from 'components/AddUnitsModal';
import { WidgetTitle } from 'components/WidgetTitle';
import { LoadingOutlined, SettingOutlined } from '@ant-design/icons';
import ExcelIcon from 'images/ExcelIcon';
import Icon from 'components/Icon';
import { TimePeriod } from 'components/TimePeriod';
import { StyledEmpty, StyledErrorMessage } from '../../../../DashboardPage/styled';
import { getUnitsByIds } from '../../../../../redux/selectors/units';
import WarningIcon from '../../../../../assets/images/warning-icon.svg';
import { getColorizedColumn, getOrderString, onAdd, onTableChange, startExport } from './utils';

const AppellationsByAppellants = () => {
  const { t } = useTranslation();
  const [isVisibleFilterModal, setVisibleFilterModal] = useState(false);
  const [loadingExport, setLoadingExport] = useState();
  const dispatch = useDispatch();

  const appealDashboard = useSelector(
    state => state.dashboardPages.dashboardsByTypes?.appellations,
    isEqual
  );

  const id =
    appealDashboard?.widgetsByTypes?.appellationStatusesCountByAppellants?.id ||
    appealDashboard?.widgetsByTypes?.appellationStatusesCountByUnits?.id;

  const loading = useSelector(state => state.dashboardPages.loading);
  const loadingWidgetId = useSelector(state => state.dashboardPages.loadingWidgetId);
  const loadingTable = loadingWidgetId === id || loadingWidgetId === 'filters' || loadingExport;
  const filters = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.filters, isEqual);
  const type = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.type, isEqual);
  const users = useSelector(state => state.usersResource.byIds);
  const page = useSelector(state => state.dashboardWidgetsResource.byIds[id]?.page, isEqual);
  const [initialState, setInitialState] = useState(page);
  const widgetAnalytics = useSelector(
    state => get(state.dashboardAnalytics.byIds, id, {}),
    isEqual
  );
  const units = useSelector(state => getUnitsByIds(state));
  const meta = get(widgetAnalytics, 'meta', {});
  const tableData = [
    ...get(widgetAnalytics, 'data', []),
    { ...widgetAnalytics?.total_appellations_count, id: 'footer' }
  ];

  const typeToViewMode = {
    appellation_statuses_count_by_units: WIDGET_VIEW_MODES.UNITS.value,
    appellation_statuses_count_by_appellants: WIDGET_VIEW_MODES.OPERATORS.value
  };

  const viewModeToType = {
    [WIDGET_VIEW_MODES.UNITS.value]: 'appellation_statuses_count_by_units',
    [WIDGET_VIEW_MODES.OPERATORS.value]: 'appellation_statuses_count_by_appellants'
  };

  const onChange = e => {
    dispatch(
      updateDashboardPageWidget({
        id,
        filters: {},
        type: viewModeToType[e.target.value],
        page: {
          number: page?.number,
          size: page?.size
        }
      })
    );
  };

  const isUnitsMode = type === 'appellation_statuses_count_by_units';

  const tableColumns = [
    {
      title: isUnitsMode ? t('general.unit') : t('general.author'),
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <div style={{ height: '42px', display: 'flex', alignItems: 'center' }}>
              <SText>{`${t('general.total')}:`}</SText>
            </div>
          ) : (
            <>
              {isUnitsMode ? (
                <div style={{ height: '42px', display: 'flex', alignItems: 'center' }}>
                  <SText>{record?.name}</SText>
                </div>
              ) : (
                <UserPreview userId={text} showAvatar showEmail disabled />
              )}
            </>
          );

        return getColorizedColumn({
          color: 'var(--gray_4)',
          component
        });
      }
    },

    {
      title: t('appealsPage.analytics.pending'),
      dataIndex: 'open_appellations_count',
      key: 'open_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.open_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--gray_4)',
          component
        });
      }
    },

    {
      title: t('appealsPage.analytics.noAnswer'),
      dataIndex: 'without_answer_appellations_count',
      key: 'without_answer_appellations_count',
      defaultSortOrder: getOrderString({
        orderString: meta?.without_answer_appellations_count
      }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--yellow_4)',
          component
        });
      }
    },

    {
      title: t('general.accepted'),
      dataIndex: 'accepted_appellations_count',
      key: 'accepted_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.accepted_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--green_4)',
          component
        });
      }
    },

    {
      title: t('general.rejected'),
      dataIndex: 'rejected_appellations_count',
      key: 'rejected_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.rejected_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      render: (text, record) => {
        const component =
          record?.id === 'footer' ? (
            <SText>{text}</SText>
          ) : (
            <>
              <SText>{text}</SText>
              <SText strong>
                {` / ${beatifyFloat((text / record?.total_appellations_count) * 100)} %`}
              </SText>
            </>
          );

        return getColorizedColumn({
          color: 'var(--red_4)',
          component
        });
      }
    },

    {
      title: t('general.total'),
      dataIndex: 'total_appellations_count',
      key: 'total_appellations_count',
      defaultSortOrder: getOrderString({ orderString: meta?.total_appellations_count }),
      sorter: true,
      ellipsis: true,
      align: 'center',
      width: 170,
      render: text => {
        const component = <SText>{text}</SText>;

        return getColorizedColumn({
          color: 'var(--gray_4)',
          component
        });
      }
    }
  ];

  const arrayOfUsers = Object.keys(users) || [];
  const arrayOfUnits = Object.keys(units) || [];
  const hasConflicts =
    hasConflictFilters({
      currentFilters: get(filters, 'appellantsIds', []),
      availableReduxFilters: arrayOfUsers
    }) ||
    hasConflictFilters({
      currentFilters: get(filters, 'unitsIds', []),
      availableReduxFilters: arrayOfUnits
    });

  const renderDescription = () => {
    if (hasConflicts) {
      return isUnitsMode ? (
        <>
          <SText color="#333333" fontSize="21px" fontWeight="600" style={{ lineHeight: '25px' }}>
            {t('dashboardPages.conflicts.unitsConflict.title')}
          </SText>
          <SRow align="center" marginTop="10px">
            <SText maxWidth="604px" type="secondary" style={{ lineHeight: '20px' }}>
              {t('dashboardPages.conflicts.unitsConflict.description1')}
            </SText>
          </SRow>
          <SRow align="center" marginTop="10px">
            <SText maxWidth="604px" type="secondary" style={{ lineHeight: '20px' }}>
              {t('dashboardPages.conflicts.unitsConflict.description2')}
            </SText>
          </SRow>
        </>
      ) : (
        <>
          <SText color="#333333" fontSize="21px" fontWeight="600" style={{ lineHeight: '25px' }}>
            {t('dashboardPages.conflicts.usersConflict.title')}
          </SText>
          <SRow align="center" marginTop="10px">
            <SText maxWidth="604px" type="secondary" style={{ lineHeight: '20px' }}>
              {t('dashboardPages.conflicts.usersConflict.description1')}
            </SText>
          </SRow>
          <SRow align="center" marginTop="10px">
            <SText maxWidth="604px" type="secondary" style={{ lineHeight: '20px' }}>
              {t('dashboardPages.conflicts.usersConflict.description2')}
            </SText>
          </SRow>
        </>
      );
    }
    return (
      <>
        <StyledErrorMessage level={3} type="secondary">
          {t('dashboardPage.widget.checkFilters')}
        </StyledErrorMessage>
        <SText type="secondary">{t('dashboardPage.widget.emptyFiltersData')}</SText>
      </>
    );
  };

  const isEmptyWidget = get(widgetAnalytics, 'data', []).length === 0;
  const title = t('appealsPage.analytics.appellationsByAppellants.title');
  const dataSource = loading || loadingTable ? [] : tableData;

  return (
    <SCard bordered shadowed>
      <SRow type="flex" wrap={false} alignItems="center" justify="space-between">
        <SCol flex="auto" span={8}>
          <Tooltip title={title}>
            <WidgetTitle>{title}</WidgetTitle>
          </Tooltip>
        </SCol>
        <SCol span={8} display="flex" justifyContent="center">
          <Radio.Group
            onChange={onChange}
            value={typeToViewMode[type] || WIDGET_VIEW_MODES.OPERATORS.value}
          >
            <Radio.Button value={WIDGET_VIEW_MODES.OPERATORS.value}>
              {t(WIDGET_VIEW_MODES.OPERATORS.title)}
            </Radio.Button>
            <Radio.Button
              value={WIDGET_VIEW_MODES.UNITS.value}
              // disabled={!currentChecklist?.isGroupable}
            >
              {t(WIDGET_VIEW_MODES.UNITS.title)}
            </Radio.Button>
          </Radio.Group>
        </SCol>
        <SCol span={8} display="flex" justifyContent="flex-end" alignItems="center">
          {!hasConflicts && !isEmptyWidget && (
            <IconButton
              tooltip={{ title: t('dashboardPage.widget.buttons.export') }}
              button={{
                icon: loadingExport ? <LoadingOutlined spin /> : <ExcelIcon />,
                size: 'icon',
                onClick: () =>
                  startExport({
                    t,
                    id,
                    meta,
                    type,
                    users,
                    filters,
                    dispatch,
                    initialState,
                    setLoadingExport
                  })
              }}
            />
          )}
          <IconButton
            tooltip={{ title: t('dashboardPage.widget.modal.tooltip') }}
            button={{
              onClick: setVisibleFilterModal,
              size: 'icon',
              icon: <Icon icon={SettingOutlined} />
            }}
          />
        </SCol>
      </SRow>
      <SRow>
        <SCol>
          <TimePeriod type="secondary">
            {appellationsPeriodSetter(get(appealDashboard, 'filters', {}))}
          </TimePeriod>
        </SCol>
      </SRow>

      <SRow>
        <SCol marginTop="10px" span={24}>
          {isEmptyWidget && !hasConflicts && (
            <SCol marginBottom="40px">
              <StyledEmpty image={Empty.PRESENTED_IMAGE_SIMPLE} description={renderDescription()} />
            </SCol>
          )}
          {hasConflicts && (
            <SCol marginBottom="40px">
              <StyledEmpty
                image={<img src={WarningIcon} alt="warning" width="70px" />}
                description={renderDescription()}
              />
            </SCol>
          )}
          {!isEmptyWidget && !hasConflicts && (
            <RedesignWidgetTable
              dataSource={dataSource}
              columns={loading ? [] : tableColumns}
              loading={loading || loadingTable}
              size="small"
              showSorterTooltip={false}
              pagination={{
                position: 'bottomLeft',
                showSizeChanger: true,
                total: meta?.total_count,
                current: meta?.page,
                pageSize: page?.size + 1,
                pageSizeOptions: ['10', '25', '50']
              }}
              onChange={(pagination, _, sorter) =>
                onTableChange({
                  pagination,
                  sorter,
                  widgetId: id,
                  tableColumns,
                  filters,
                  dispatch,
                  setInitialState
                })
              }
            />
          )}

          {isUnitsMode ? (
            <AddUnitsModal
              selectedUnitsIds={hasConflicts ? [] : filters?.unitsIds}
              onCancel={() => setVisibleFilterModal(false)}
              visible={isVisibleFilterModal}
              onAdd={ids =>
                onAdd({
                  id,
                  updatedFilter: { unitsIds: ids },
                  filters,
                  page,
                  setVisibleFilterModal,
                  dispatch
                })
              }
            />
          ) : (
            <AddUsersModal
              selectedUsersIds={filters?.appellantsIds}
              onCancel={() => setVisibleFilterModal(false)}
              visible={isVisibleFilterModal}
              onAdd={ids =>
                onAdd({
                  id,
                  updatedFilter: { appellantsIds: ids },
                  filters,
                  page,
                  setVisibleFilterModal,
                  dispatch
                })
              }
              addedFilters={{ withRolePermissions: [PERMISSIONS.CAN_CREATE_APPELLATIONS] }}
            />
          )}
        </SCol>
      </SRow>
    </SCard>
  );
};

export default AppellationsByAppellants;
