import createListReducer from 'core/redux/lists/listReducer';
import { reviewsResource } from 'redux/resources/reviews';

export const reviewsListParams = {
  name: 'review',
  defaultState: {
    filters: { direction: 'all' },
    filteredUnits: []
  },
  additionalReducer: {
    setUnits: (state, { payload }) => ({
      ...state,
      filteredUnits: payload
    })
  },
  rebuildQuery: true,
  loader: reviewsResource.operations.load
};

const { actions, reducer, operations } = createListReducer(reviewsListParams);

const reviewsListReducer = reducer;

export { actions, reviewsListReducer, operations };
