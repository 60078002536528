import createListReducer from 'core/redux/lists/listReducer';
import { workPlanTaskAssignmentsResource } from 'redux/resources/workPlanTaskAssignments';

const workPlanTaskAssignmentsParams = {
  name: 'workPlanTaskAssignments',
  defaultState: {},
  rebuildQuery: true,
  loader: workPlanTaskAssignmentsResource.operations.load
};

const { actions, reducer, operations } = createListReducer(workPlanTaskAssignmentsParams);

const workPlanTaskAssignmentsListReducer = reducer;

export { actions, workPlanTaskAssignmentsListReducer, operations };
