import { Col, Input, Row, Select } from 'antd';
import { ACCESS_LEVELS, USER_STATUSES } from 'core/utils/constants';
import { selectSearch } from 'core/utils/selectSearch';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actions } from 'redux/lists/usersListReducer';
import { rolesResource } from 'redux/resources/roles';
import { unitsResource } from 'redux/resources/units';

const { Search } = Input;
const { Option } = Select;

const TableSettings = ({
  filters,
  units = [],
  updateFilters,
  roles = [],
  loadUnits,
  loadRoles,
  setSearch,
  loading,
  totalCount
}) => {
  const loadOrganizationData = () => Promise.all[(loadUnits(), loadRoles())];

  useEffect(() => {
    loadOrganizationData();
  }, []);

  const accessLevels = Object.values(ACCESS_LEVELS).reduce((acc, cur) => {
    if (cur.value === ACCESS_LEVELS.USER_BLOCKED.value) return acc;
    return [...acc, cur];
  }, []);

  const { t } = useTranslation();

  return (
    <Row type="flex" align="middle" gutter={[8, 8]} style={{ margin: '-4px' }}>
      <Col>
        <Search
          loading={loading}
          placeholder={t('organizationStructure.tabs.employees.table.filters.employeesSearch')}
          onSearch={setSearch}
          style={{ width: 200 }}
        />
      </Col>
      <Col>
        <Select
          loading={loading}
          mode="multiple"
          onChange={unitsIds => updateFilters({ unitsIds })}
          style={{ width: 200 }}
          allowClear
          maxTagCount={0}
          maxTagPlaceholder={selectedKeys =>
            `${t('organizationStructure.tabs.employees.table.filters.unitsIdsMaxPlaceholder')} ${
              selectedKeys.length
            }`
          }
          value={filters.unitsIds}
          placeholder={t('organizationStructure.tabs.employees.table.filters.unitsIdsPlaceholder')}
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        >
          {units.map(unit => (
            <Option key={unit.id} value={unit.id}>
              {unit.name}
            </Option>
          ))}
        </Select>
      </Col>

      <Col>
        <Select
          loading={loading}
          mode="multiple"
          onChange={rolesIds => updateFilters({ rolesIds })}
          style={{ width: 200 }}
          allowClear
          maxTagCount={0}
          maxTagPlaceholder={selectedKeys =>
            `${t('organizationStructure.tabs.employees.table.filters.rolesIdsMaxPlaceholder')} ${
              selectedKeys.length
            }`
          }
          value={filters.rolesIds}
          placeholder={t('organizationStructure.tabs.employees.table.filters.rolesIdsPlaceholder')}
          filterOption={(input, option) => selectSearch({ input, option, searchProp: 'children' })}
        >
          {roles.map(role => (
            <Option key={role.id} value={role.id}>
              {role.name}
            </Option>
          ))}
        </Select>
      </Col>

      <Col>
        <Select
          loading={loading}
          onChange={active =>
            active === 'false'
              ? updateFilters({ active, invitationStatus: undefined })
              : updateFilters({ active })
          }
          allowClear
          style={{ width: 200 }}
          value={filters.active}
          placeholder={t('organizationStructure.tabs.employees.table.filters.statusPlaceholder')}
        >
          <Option key="true" value="true">
            {t(USER_STATUSES.ACTIVE.name)}
          </Option>
          <Option key="false" value="false">
            {t(USER_STATUSES.DISABLED.name)}
          </Option>
        </Select>
      </Col>

      <Col>
        <Select
          loading={loading}
          disabled={filters.active === 'false'}
          onChange={invitationStatus => updateFilters({ invitationStatus })}
          allowClear
          style={{ width: 200 }}
          value={filters.invitationStatus}
          placeholder={t(
            'organizationStructure.tabs.employees.table.filters.invitationStatusPlaceholder'
          )}
        >
          {accessLevels.map(accessLevel => (
            <Option key={accessLevel.value} value={accessLevel.value}>
              {t(accessLevel.name)}
            </Option>
          ))}
        </Select>
      </Col>

      <Col>
        <span>
          {`${t(
            'organizationStructure.tabs.employees.table.filters.totalCountEmployees'
          )} ${totalCount || ''}`}
        </span>
      </Col>
    </Row>
  );
};

const mapStateToProps = state => {
  const { filteredUnits, filters, loading: listLoading } = state.usersList;

  const loading =
    listLoading ||
    state.usersResource.loading ||
    state.usersResource.updateByIdStarted ||
    state.usersResource.deleteByIdStarted;

  return {
    loading,
    units: Object.values(state.unitsResource.byIds),
    roles: Object.values(state.rolesResource.byIds),
    filters,
    filteredUnits
  };
};

const mapDispatchToProps = {
  loadUnits: unitsResource.operations.load,
  loadRoles: rolesResource.operations.load,
  updateFilters: actions.updateFilters,
  setSearch: actions.setSearch
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TableSettings));
