import { get, put, destroy, post } from 'redux-bees';

const foldersEndpoints = apiPath => ({
  getFolders: { method: get, path: `${apiPath}/folders` },
  createFolder: { method: post, path: `${apiPath}/folders` },
  deleteFolder: { method: destroy, path: `${apiPath}/folders/:id` },
  updateFolder: { method: put, path: `${apiPath}/folders/:id` }
});

export default foldersEndpoints;
