import React from 'react';
import { useSelector } from 'react-redux';
import { isEqual } from 'lodash';
import UserPreview from 'components/UserPreview';
import { Tooltip } from 'antd';

const GetUserPreview = ({ userId, altName = '', url }) => {
  // ! use only in XL widgets
  const user = useSelector(state => state.usersResource.byIds[userId], isEqual);

  if (user) return <UserPreview userId={userId} margin="0px" showAvatar showUnit link={url} />;
  return <Tooltip title="Пользователь перешел в другой отдел">{altName}</Tooltip>;
};

export default GetUserPreview;
