import styled from 'styled-components';
import { Select } from 'antd';
import { space, layout, flexbox, background, shadow, position, variant } from 'styled-system';

export default styled(Select)`
  &&.ant-select {
    ${variant({
      prop: 'size',
      variants: {
        large: {
          width: '304px',
          height: '40px'
        },
        middle: {
          width: '180px',
          height: '32px'
        }
      }
    })}

    ${space}
    ${layout} 
    ${flexbox} 
    ${background} 
    ${shadow} 
    ${position}
  }
`;
