import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import SRow from 'components/Standard/SRow';
import SCol from 'components/Standard/SCol';
import { Helmet } from 'react-helmet';
import { loadAppellationsDashboard } from 'redux/entities/dashboardPages/appellationsDashboardPage/operations';
import LoadingDashboard from 'pages/DashboardPage/Components/LoadingDashboard';
import { concat, get, isEqual, isString } from 'lodash';
import { getChecklistsDefinitionsByIds } from 'redux/selectors/checklists';
import SCard from 'components/Standard/SCard';
import WarningIcon from 'assets/images/warning-icon.svg';
import SText from 'components/Standard/SText';
import { unitsResource } from 'redux/resources/units';
import { usersResource } from 'redux/resources/users';
import { StyledEmpty } from '../../DashboardPage/styled';
import { hasConflictFilters } from '../../DashboardPage/utils';
import { loadUsers } from 'redux/ui/registerFromIntegration/operations';
import { Space } from 'antd';
import Filters from './Components/Filters';
import AppellationsByReviewers from './Components/Widgets/AppellationsByReviewers';
import AppellationsByAppellants from './Components/Widgets/AppellationsByAppellants';

const Analytics = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      unitsResource.operations.load({
        pagination: false
      })
    );
    dispatch(
      usersResource.operations.load({
        pagination: false
      })
    );
    dispatch(
      loadUsers({
        pagination: 'false',
        include: 'unit,role.levels',
        sort: 'last_name'
      })
    );
    dispatch(loadAppellationsDashboard());
  }, []);

  const dashboardPage = useSelector(
    state => get(state, 'dashboardPages.dashboardsByTypes.appellations', {}),
    isEqual
  );

  const checklists = useSelector(state => getChecklistsDefinitionsByIds(state));
  const arrayOfChecklists = Object.keys(checklists);
  const dashboardChecklistsFilters = get(dashboardPage?.filters, 'checklistDefinitionsIds', []);
  const widgetChecklistsIds = isString(dashboardChecklistsFilters)
    ? concat([], dashboardChecklistsFilters)
    : [...dashboardChecklistsFilters];

  const hasConflicts = hasConflictFilters({
    currentFilters: widgetChecklistsIds,
    availableReduxFilters: arrayOfChecklists
  });

  const loading = useSelector(state => state.dashboardPages.loading);

  const renderDescription = () => {
    return (
      <>
        <SText color="#333333" fontSize="21px" fontWeight="600" style={{ lineHeight: '25px' }}>
          {t('dashboardPages.conflicts.appealsAnalyticsPage.checklistsConflict.title')}
        </SText>
        <SRow align="center" marginTop="10px">
          <SText maxWidth="396px" type="secondary" style={{ lineHeight: '20px' }}>
            {t('dashboardPages.conflicts.appealsAnalyticsPage.checklistsConflict.description1')}
          </SText>
        </SRow>
        <SRow align="center" marginTop="10px">
          <SText maxWidth="396px" type="secondary" style={{ lineHeight: '20px' }}>
            {t('dashboardPages.conflicts.appealsAnalyticsPage.checklistsConflict.description2')}
          </SText>
        </SRow>
      </>
    );
  };

  return (
    <SRow>
      <Helmet>
        <title>{t('pagesMeta.appealsPage.title')}</title>
      </Helmet>
      <SCol span={24}>
        <SRow>
          <SCol span={24}>
            <Filters hasConflicts={hasConflicts} />
          </SCol>
          <SCol span={24} padding="16px">
            {loading && <LoadingDashboard />}
            {!loading && hasConflicts && (
              <SCard bordered shadowed bodyPadding="44px" headPadding="25px">
                <StyledEmpty
                  image={<img src={WarningIcon} alt="warning" width="70px" />}
                  description={renderDescription()}
                />
              </SCard>
            )}
            {!loading && !hasConflicts && (
              <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                <AppellationsByReviewers />
                <AppellationsByAppellants />
              </Space>
            )}
          </SCol>
        </SRow>
      </SCol>
    </SRow>
  );
};

export default Analytics;
