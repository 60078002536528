export default {
  userSettingsPage: {
    categories: {
      settings: 'Impostazioni',
      main: 'Il principale'
    },
    title: 'Impostazioni del profilo',
    tabs: {
      info: {
        tabName: 'Informazione',
        form: {
          registrationDate: 'Data di registrazione',
          companyName: "Nome dell'azienda",
          role: 'Ruolo',
          unit: 'Dipartimento'
        }
      },
      accountSettings: {
        tabName: "Impostazioni dell'accaunt",
        form: {
          lastName: 'Cognome',
          firstAndMiddleName: 'Nome e patronimico',
          email: 'E-mail',
          phoneNumber: 'Numero del telefono',
          locale: "Lingua dell'interfaccia"
        },
        avatar: {
          tabName: 'Avatar',
          updaloadAvatarlabel: 'Premi per scegliere una foto',
          avatarPreview: 'Anteprima: ',
          buttons: {
            loadAvatar: 'Carica una foto',
            deleteAvatar: 'Elimina foto'
          }
        }
      },
      securitySettings: {
        tabName: 'Impostazioni di accesso',
        form: {
          newPassword: 'Nuova password',
          newPasswordConfirmation: 'Conferma password'
        }
      }
    },
    buttons: { update: 'Aggiorna i dati' },
    messages: {
      dataSuccessfullyUpdated: 'Dati aggiornati',
      updateDataFailed: 'Impossibile aggiornare i dati',
      passwordSuccessfullyUpdated: 'Password aggiornata',
      editingAnotherUsersSettingsPT1: 'Attenzione! Stai modificando le impostazioni utente',
      editingAnotherUsersSettingsPT2:
        "per modificare la tua pagina, vai nel menu (nell'angolo in alto a destra) e seleziona il punto.",
      form: {
        validateMessages: {
          enterLastName: 'Per favore inserisci il cognome',
          enterFirstAndMiddleName: 'Per favore inserisci un nome',
          enterPassword: 'Per favore inserisci la password',
          enterPasswordConfirmation: 'Per favore conferma la password',
          passwordMismathing: 'Le password non corrispondono',
          passwordLenghtMismathing: 'La password deve contenere più di 6 caratteri'
        }
      }
    },
    organization: {
      title: "Informazioni sull'utilizzo",
      currentPlanSlug: 'Tariffa attuale',
      registrationDate: 'Data di registrazione',
      registeredUsers: 'Utenti registrati',
      monthReviewsCount: 'Numero di assegni per il mese in corso'
    }
  }
};
