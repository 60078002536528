import { createSelector } from '@reduxjs/toolkit';
import { intersection, isEmpty, orderBy, pickBy, reduce } from 'lodash';
import { getUnitsByIds } from './units';

export const getChecklistsDefinitionsByIds = state => state.checklistDefinitionsResource.byIds;
export const getChecklistsByIds = state => state.checklistsResource.byIds;
export const getAnswersByIds = state => state.checklistAnswersResource.byIds;

export const getChecklistsDefinitionsByStatuses = createSelector(
  getChecklistsDefinitionsByIds,
  (_, { statuses }) => statuses,
  (checklistDefinitionsByIds, statuses) =>
    isEmpty(statuses)
      ? Object.values(checklistDefinitionsByIds)
      : Object.values(
          pickBy(checklistDefinitionsByIds, checklistDefinition =>
            statuses.includes(checklistDefinition.status)
          )
        )
);

export const getChecklistsDefinitionsByParams = createSelector(
  getChecklistsDefinitionsByIds,
  getUnitsByIds,
  (_, { params }) => params,
  (checklistDefinitionsByIds, unitsByIds, params) =>
    orderBy(
      Object.values(checklistDefinitionsByIds).reduce((result, checklistDefinition) => {
        const { statuses, unitsIds, communicationTypes } = params;

        if (!isEmpty(statuses) && !statuses.includes(checklistDefinition.status)) return result;

        const checklistDefinitionUnitsIds = checklistDefinition.unitsIds;

        if (!isEmpty(unitsIds) && isEmpty(intersection(checklistDefinitionUnitsIds, unitsIds)))
          return result;

        if (
          !isEmpty(communicationTypes) &&
          isEmpty(intersection(checklistDefinition.communicationTypes, communicationTypes))
        )
          return result;

        return [...result, checklistDefinition];
      }, []),
      'status',
      'desc'
    )
);

export const getChecklistsWithAnswersByIds = createSelector(
  getChecklistsByIds,
  getAnswersByIds,
  state => state.questionsResource.byIds,
  (checklistsByIds, answersByIds, questionsByIds) => {
    const checklists = reduce(
      checklistsByIds,
      (acc, curr) => {
        const checklist = {
          ...curr,
          questionsWithAnswersByIds: reduce(
            pickBy(answersByIds, { checklistId: curr.id }),
            (result, answer) => {
              const question = questionsByIds[answer.questionId];

              return question ? { ...result, [question.id]: { ...answer, ...question } } : result;
            },
            {}
          )
        };

        return { ...acc, [checklist.id]: checklist };
      },
      {}
    );

    return checklists;
  }
);
