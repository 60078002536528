export default {
  workPlanTasks: {
    taskPlaceholder: 'Compito',
    colorizedNumbers: {
      completed: 'Numero di controlli eseguiti',
      incompleted: 'Numero di assegni scaduti',
      total: 'Numero di controlli rimanenti'
    },
    tableColumns: {
      reviewsCount: 'Valutare',
      reviewsCount2: 'Valutazioni',
      completed: 'Eseguita',
      incompleted: 'Sinistra',
      closeTask: 'Completa il compito',
      stopRepeating: 'Interrompi le ripetizioni',
      name: "Nome dell'attività",
      lastTaskTimeFrom: "La data dell'inizio",
      lastTaskTimeTo: 'Data di completamento',
      creator: 'Il creatore',
      schedule: 'Ripetizione',
      repeatedCount: 'Lanci',
      reviewCreatedAt: 'Data e ora della valutazione',
      progress: 'Progresso',
      repeat: 'Ripetizione'
    },
    assignments: {
      closeTask: 'Completa il compito',
      close: 'Finire',
      taskStartedAt: 'Ora di pranzo',
      assignmentsCount: 'Numero di ispettori',
      completedTaskItemsCount: 'Valutazioni assegnate'
    },
    filters: { createTask: 'Crea attività' },
    confirmStopRepeating: 'Interrompi le ripetizioni?',
    confirmClosing: 'Completa il compito?',
    confirmDelete: 'Elimina Configurazione?',
    tasksStartedAt: 'Prima data di lancio',
    reviewersCount: 'Numero di ispettori',
    operatorsCount: 'Numero di operatori',
    reviewedOperator: 'Impiegato controllato',
    communicationsNotFound: 'Nessuna comunicazione trovata',
    nextCommunicationDate:
      'Le comunicazioni saranno aggiunte nella prossima esecuzione che avrà luogo ',
    checkCommunication: "Controllo dell'impostazione del compito",
    howToBlock: {
      howToButton: 'Cómo utilizar',
      watchVideo: 'Ver el vídeo',
      readManual: 'Lea las instrucciones completas',
      startTour: 'Tour interactivo'
    },
    menu: {
      inProgress: 'Nel processo',
      pending: 'In attesa di',
      total: 'Esecuzione generale'
    },
    tooltips: {
      configurationProgress:
        "Il rapporto tra il numero di comunicazioni controllate e il numero totale nel campione. Le informazioni sullo stato di avanzamento indicano l'ultima attività.",
      progress:
        'La relación entre el número de comunicaciones verificadas y el número total de la muestra.',
      schedule:
        'Periodo de repetición de muestreo de comunicación. La muestra puede actualizarse automáticamente y ejecutarse diaria, semanal o mensualmente.',
      repeatedCount: 'El número de ejecuciones de tareas para comprobar la selección.'
    },
    repeat: 'ripetizione',
    confirmModal: {
      title: 'Stai per completare un compito. Continuare?',
      content: 'Anche tutte le sue ripetizioni in futuro saranno completate'
    }
  }
};
