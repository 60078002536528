import { get, post } from 'redux-bees';

const amoEndpoints = apiPath => ({
  amoRegisterOrganization: {
    method: post,
    path: `${apiPath}/integrations/amocrm/register_organization`
  },
  getEntityCustomFields: {
    method: get,
    path: `${apiPath}/integrations/amocrm/:id/entity_custom_fields`
  },
  connectEntityCustomFields: {
    method: post,
    path: `${apiPath}/integrations/amocrm/:id/connect_entity_custom_field/:fieldId`
  },
  disconnectEntityCustomFields: {
    method: post,
    path: `${apiPath}/integrations/amocrm/:id/disconnect_entity_custom_field/:fieldId`
  },
  //   bitrixConnectOrganization: { method: post, path: `${apiPath}/bitrix/connect_organization/:id` },
  amoSyncDataByPeriod: { method: post, path: `${apiPath}/integrations/amocrm/:id/fetch_data` }
});

export default amoEndpoints;
