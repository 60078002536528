export default {
  appealsPage: {
    actionsListName: 'Лента изменений',
    actionsList: {
      changed: 'изменил',
      accept: 'принял',
      reject: 'отклонил',
      create: 'подал',
      add: 'добавил',
      exclude: 'исключил',
      answered: 'ответил',
      createComment: 'оставил комментарий',
      statusBy: 'статус на',
      appealByCritery: 'апелляцию по критерию',
      appeal: 'апелляцию',
      wathcer: 'наблюдателем',
      viewer: 'наблюдателя'
    },
    appealInfo: {
      operator: 'Оператор',
      reviewCreate: 'Проверил'
    },
    appealStatus: {
      process: 'Обработать',
      needsInformation: 'Нужна информация',
      withoutAnswer: 'Нет ответа',
      appealInfo: 'Сведения по апелляции',
      notProcessed: 'Не обработано'
    },
    participantsCard: {
      appealed: 'Подал апелляцию',
      watchers: 'Наблюдатели',
      addWatcher: 'Добавить наблюдателя',
      addingWatcher: 'Наблюдатели'
    },
    appealsList: {
      appealBy: 'Проверяющий',
      changedBy: 'Изменил'
    },
    filters: {
      withCurrentUserRoles: 'Апелляции где я'
    },
    settings: {
      appealDeadline: 'Максимальный срок после проверки для подачи апелляции (дней)',
      addOperatorAsWatcher: 'Автоматически назначать оператора наблюдателем в апелляцию',
      addReviewerAsWatcher: 'Автоматически назначать проверяющего наблюдателем в апелляцию',
      selectAssignerFromUsersArray: 'Назначить поданную апелляцию на:',
      selectAssignerFromUsersArrayAdd: 'Выбрать конкретного сотрудника(ов)',
      appealed: 'Подал апелляцию',
      assignerMethods: {
        reviewer: {
          title: 'На автора проверки',
          description: 'Все поданные апелляции будут автоматически назначаться на автора оценки'
        },
        usersArray: {
          description:
            'Все поданные апелляции будут автоматически назначаться на выбранного сотрудника/ов',
          title: 'На выбранного сотрудника/ов'
        },
        assignerByApellantRules: {
          description:
            'Вы можете создать правило по которому апелляции поданные сотрудниками будут распределяться между ответственными/ным',
          title: 'Создать своё правило'
        }
      },
      addRule: 'Добавить правило',
      assignRule: {
        appellants: 'Если апелляцию подал',
        assigners: 'Назначить апелляцию на',
        note: 'Примечание'
      }
    },
    confirmDelete: 'Удалить апелляцию?',
    analytics: {
      noAnswer: 'Нет ответа',
      pending: 'Не обработано',
      tracker: 'Трекер',
      appellationsByReviewers: {
        title: 'Апелляции по проверяющему'
      },
      appellationsByAppellants: {
        title: 'Апелляции по автору'
      }
    },
    appealAccepted: 'Апелляция принята',
    appealDenied: 'Апелляция отклонена'
  }
};
