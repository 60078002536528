import { isArray, isEmpty } from 'lodash';

export const COLOR_PALETTE_7 = [
  'var(--black_7)',
  'var(--blue_7)',
  'var(--red_7)',
  'var(--green_7)',
  'var(--cyan_7)',
  'var(--yellow_7)',
  'var(--magenta_7)',
  'var(--gray_7)',
  'var(--violet_7)',
  'var(--lilac_7)',
  'var(--dust-red_7)',
  'var(--volcano_7)',
  'var(--sunset-orange_7)',
  'var(--gold_7)',
  'var(--lime_7)'
];

export const themeColors = {
  black: 'var(--black_primary)',
  lilac: 'var(--lilac_primary)',
  green: 'var(--green_primary)',
  red: 'var(--red_primary)',
  yellow: 'var(--yellow_primary)',
  orange: 'var(--orange_primary)',
  blue: 'var(--blue_primary)',
  transparent: 'transparent'
};

export const avatarColors = [
  'var(--orange_primary)',
  'var(--green_6)',
  'var(--blue_6)',
  'var(--yellow_primary)',
  'var(--magenta_primary)'
];

const mapColors = {
  // COLOR_PALETTE_8
  // '#595959': 'var(--black_8)',
  '#2f54eb': 'var(--blue_8: )',
  '#ff4d4f': 'var(--red_8)',
  '#52c41a': 'var(--green_8: )',
  '#ffd188': 'var(--orange_8)',
  '#ffe645': 'var(--yellow_8)',
  '#b53484': 'var(--magenta_8)',
  '#e7e7e7': 'var(--gray_8)',
  // '#c48ad1': 'var(--violet_8)',
  '#9671ff': 'var(--lilac_8: )',
  // '#f5222d': 'var(--dust-red_8)',
  // '#fa541c': 'var(--volcano_8)',
  // '#fa8c16': 'var(--sunset-orange_8)',
  // '#faad14': 'var(--gold_8)',
  '#a0d911': 'var(--lime_8)',

  // COLOR_PALETTE_6
  // '#8c8c8c': 'var(--black_6)',
  // '#597ef7': 'var(--blue_6)',
  // '#ff7875': 'var(--red_6)',
  // '#73d13d': 'var(--green_6)',
  '#ffdfad': 'var(--orange_6)',
  '#fbed92': 'var(--yellow_6)',
  // '#cd62a4': 'var(--magenta_6)',
  // '#f1f1f1': 'var(--gray_6)',
  // '#dfafeb': 'var(--violet_6)',
  // '#bca4ff': 'var(--lilac_6)',
  // '#f5222d': 'var(--dust-red_6)',
  // '#fa541c': 'var(--volcano_6)',
  // '#fa8c16': 'var(--sunset-orange_6)',
  // '#faad14': 'var(--gold_6)',
  // '#a0d911': 'var(--lime_6)',

  // COLOR_PALETTE_4
  '#c1c1c1': 'var(--black_4)',
  '#85a5ff': 'var(--blue_4)',
  '#ffa39e': 'var(--red_4)',
  '#95de64': 'var(--green_4)',
  '#ffeccf': 'var(--orange_4)',
  '#fcf2b1': 'var(--yellow_4)',
  '#e7a4cd': 'var(--magenta_4)',
  '#f5f5f5': 'var(--gray_4)',
  '#f8daff': 'var(--violet_4)',
  '#dacdff': 'var(--lilac_4)',

  // COLOR_PALETTE_2
  '#d9d9d9': 'var(--black_2)',
  '#ccdcff': 'var(--blue_2)',
  '#feeae8': 'var(--red_2)',
  '#bdec9e': 'var(--green_2)',
  '#ffe8c7': 'var(--orange_2)',
  '#fff7c7': 'var(--yellow_2)',
  '#ffe7f6': 'var(--magenta_2)',
  '#fcfcfc': 'var(--gray_2)',
  '#fdf4ff': 'var(--violet_2)',
  '#efe9ff': 'var(--lilac_2)',

  // COLOR_PALETTE_7
  '#8c8c8c': 'var(--black_7)',
  '#597ef7': 'var(--blue_7)',
  '#ff7875': 'var(--red_7)',
  '#73d13d': 'var(--green_7)',
  '#13c2c2': 'var(--cyan_7)',
  '#d4b106': 'var(--yellow_7)',
  '#cd62a4': 'var(--magenta_7)',
  '#595959': 'var(--gray_7)',
  '#c48ad1': 'var(--violet_7)',
  '#bca4ff': 'var(--lilac_7)',
  '#cf1322': 'var(--dust-red_7)',
  '#fa541c': 'var(--volcano_7)',
  '#fa8c16': 'var(--sunset-orange_7)',
  '#faad14': 'var(--gold_7)',
  '#7cb305': 'var(--lime_7)'
};

export const mapColorToId = id => {
  const number = id?.match(/\d/)[0] / 2;
  if (id) {
    return avatarColors[number] || avatarColors[2];
  }
};

export const transformToDarkerColor = color => {
  if (isEmpty(color)) return '';

  if (isArray(color)) {
    return color.reduce((p, c) => {
      p.push(transformToDarkerColor(c));
      return p;
    }, []);
  }
  // example: transform #e7e7e7 to var(--gray_8),
  if (color in mapColors) color = mapColors[color];

  [
    'blue_',
    'black_',
    'red_',
    'orange_',
    'green_',
    'yellow_',
    'violet_',
    'magenta_',
    'gold_',
    'sunset-orange_',
    'lilac_',
    'lime_',
    'volcano_',
    'dust-red',
    'gray_'
  ].forEach(item => {
    if (color?.includes('gray_') && !color?.includes('gray_7'))
      color = color.replace(/gray_([\d]+)/gi, 'black_6');
    color = color.replace('orange_6', 'cyan_7');
    if (color?.includes(item)) color = color.replace('_6', '_7');
  });

  return color;
};
