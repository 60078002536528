import { destroy, get, post, put } from 'redux-bees';

const appellationCommentsEndpoints = apiPath => ({
  createAppellationComment: {
    method: post,
    path: `${apiPath}/appellation_comments`
  }
});

export default appellationCommentsEndpoints;
