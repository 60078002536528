import styled from 'styled-components';
import SText from 'components/Standard/SText';

export const StyledRenderCell = styled.div`
  && {
    height: 42px;
    display: flex;
    align-items: center;
  }
`;

export const TruncatedSText = styled(SText)`
  && {
    width: 100%;
  }
`;
