import { message } from 'antd';
import React from 'react';
import IconButton from 'components/new/IconButton';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-feather';

const CopyLinkButton = ({ reviewId, destinationId, destinationType }) => {
  const { t } = useTranslation();
  const pageLink = reviewId
    ? `${window.location.origin}/reviews/${reviewId}`
    : `${window.location.origin}/${destinationType}/${destinationId}`;

  const onCopy = async () => {
    navigator.clipboard.writeText(pageLink).then(
      () => {
        message.success(t('components.recordPlayer.copyLinkSuccess'));
      },
      err => {
        console.error(err);
      }
    );
  };

  return (
    <IconButton
      icon={<Link />}
      tooltipProps={{
        title: t('components.recordPlayer.copyLink')
      }}
      buttonProps={{
        onClick: onCopy
      }}
    />
  );
};

export default CopyLinkButton;
