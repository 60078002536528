import { createAction, createReducer } from '@reduxjs/toolkit';

const defaultState = {
  form: {}
};

export const updateCalibrationSessionSettingsForm = createAction(
  'uiCalibrationSessionSettingsModal/updateCalibrationSessionSettingsForm'
);

export const resetCalibrationSessionSettingsForm = createAction(
  'uiCalibrationSessionSettingsModal/resetCalibrationSessionSettingsForm'
);

export const calibrationSessionSettingsModalReducer = createReducer(defaultState, {
  [resetCalibrationSessionSettingsForm]: state => ({
    ...state,
    form: {}
  }),
  [updateCalibrationSessionSettingsForm]: (state, { payload }) => ({
    ...state,
    form: { ...state.form, ...payload }
  })
});

export default calibrationSessionSettingsModalReducer;
