import { Col, Input, message, Row, Tooltip } from 'antd';
import axios from 'axios';
import { typeToRoute } from 'components/ClientInteractionLink';
import SButton from 'components/Standard/SButton';
import SCard from 'components/Standard/SCard';
import { processJsonApiObject } from 'core/jsonapi';
import { handleError } from 'core/services/errors';
import { INTEGRATIONS } from 'core/utils/constants';
import { find, isEqual, snakeCase } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { singular } from 'pluralize';
import uniqid from 'uniqid';

const { TextArea } = Input;

const integrationTypeToTestBody = {
  [INTEGRATIONS.custom_http_text.type]: {
    client_id: '375299933332', // Идентификатор клиента в системе клиента (номер телефона, id или хэш)
    communication_type: 'chat', // Тип текстового взаимодействия. Возможные варианты: chat / ticket / email
    title: 'Добрый вечер. Я хочу приобрести холодильник', // Заглавие беседы, если нету - берется из conversation_parts.first.body
    source: 'chat', // источник чата (telegram, viber и другие)
    nps: 8, // Net Promoter Score
    client_feedback: 1, // число, отображающее отзыв клиента
    status: 'closed', // String обозначающий статус (accepted / rejected)
    direction: 'incoming', // incoming / outcoming / local
    started_at: moment().toISOString(true),
    communication_parts: [
      {
        communication_part_id: `${uniqid()}2202737122`,
        author: {
          type: 'client' // при отсутствии id клиента мы используем client_id из
        },
        body: 'Добрый вечер. Я хочу приобрести холодильник',
        created_at: '2020-01-17T08:15:30+03:00'
      },
      {
        communication_part_id: `${uniqid()}2202737122`,
        author: {
          type: 'client'
        },
        body: 'Интересует модель X этого года',
        created_at: '2020-01-17T08:15:30+03:00',
        updated_at: '2020-01-17T09:30:28+03:00'
      },
      {
        communication_part_id: `${uniqid()}2202737122`,
        author: {
          id: '123', // id оператора (внутренний номер)
          type: 'operator' // возможные значения operator / client
        },
        body: 'Здравствуйте! Спасибо за сообщение, наш отдел продаж обязательно свяжется с вами',
        content_type: 'text/plain', // text/plain by default, возможные варианты text/html
        created_at: '2020-01-17T08:15:30+03:00',
        updated_at: '2020-01-17T08:15:30+03:00'
      }
    ]
  },
  [INTEGRATIONS.custom_http_telephony.type]: {
    operator_id: 'operator@email.com',
    started_at: moment().toISOString(true),
    uid: '7b2331ca-adcb-4f15-811b-6b6cfddb2baf',
    client_phone_number: '+375332222453',
    direction: 'incoming',
    duration: 94,
    media_url: 'https://demo-records.s3.eu-central-1.amazonaws.com/News_Room_News.mp3'
  }
};

const TestRequest = ({ integration }) => {
  const { t } = useTranslation();
  const userAccount = useSelector(
    state =>
      find(
        state.userAccountBindingsResource.byIds,
        ({ integrationId, userId }) => integrationId === integration.id && userId
      ),
    isEqual
  );
  const [testBody, setTestBody] = useState(
    JSON.stringify(
      integrationTypeToTestBody[
        integration?.integrationType || INTEGRATIONS.custom_http_telephony.type
      ],
      undefined,
      4
    )
  );

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTestBody(
      JSON.stringify(
        {
          ...integrationTypeToTestBody[
            integration?.integrationType || INTEGRATIONS.custom_http_telephony.type
          ],
          ...(integration?.integrationType === INTEGRATIONS.custom_http_text.type
            ? {
                communication_parts: [
                  {
                    communication_part_id: `${uniqid()}2202737122`,
                    author: {
                      type: 'client' // при отсутствии id клиента мы используем client_id из
                    },
                    body: 'Добрый вечер. Я хочу приобрести холодильник',
                    created_at: moment()
                      .add(1, 'hour')
                      .toISOString(true)
                  },
                  {
                    communication_part_id: `${uniqid()}2202737122`,
                    author: {
                      type: 'client'
                    },
                    body: 'Интересует модель X этого года',
                    created_at: moment()
                      .add(2, 'hour')
                      .toISOString(true)
                  },
                  {
                    communication_part_id: `${uniqid()}2202737122`,
                    author: {
                      id: userAccount?.integrationUid, // id оператора (внутренний номер)
                      type: 'operator' // возможные значения operator / client
                    },
                    body:
                      'Здравствуйте! Спасибо за сообщение, наш отдел продаж обязательно свяжется с вами',
                    content_type: 'text/plain', // text/plain by default, возможные варианты text/html
                    created_at: moment()
                      .add(3, 'hour')
                      .toISOString(true)
                  }
                ]
              }
            : {}),
          operator_id: userAccount?.integrationUid
        },
        undefined,
        4
      )
    );
  }, [userAccount?.userId]);

  const onMakeRequest = async () => {
    setLoading(true);
    try {
      const response = await axios.post(integration.url, JSON.parse(testBody), {
        headers: { Authorization: integration.authorizationToken }
      });
      const resource = processJsonApiObject(response.data.data);
      const text = t('integrationsSettingsPage.integrationPage.testRequest.success');
      const textSplitResult = text.split(' ');
      const linkWord = textSplitResult[textSplitResult.length - 1];
      textSplitResult.pop();
      message.success(
        <>
          <span>{textSplitResult.join(' ')}</span>{' '}
          <a
            href={`/${typeToRoute[snakeCase(singular(resource.type))]}/${resource.id}`}
          >{`${linkWord}`}</a>
        </>,
        10
      );
    } catch (error) {
      handleError(error?.response?.status, error?.response?.data);
      message.error(t('integrationsSettingsPage.integrationPage.testRequest.failure'));
    } finally {
      setLoading(false);
    }
  };

  const handleChange = e => setTestBody(e.target.value);

  return (
    <SCard bordered shadowed>
      <Row align="bottom" justify="space-between">
        <Col>
          <TextArea
            value={testBody}
            rows={10}
            cols={60}
            onChange={handleChange}
            style={{ fontSize: '14px' }}
          />
        </Col>
        <Col>
          <Row align="bottom">
            <Col>
              <Tooltip title={userAccount ? undefined : 'В интеграции нет пользователей'}>
                <SButton
                  type="primary"
                  width="230px"
                  size="big"
                  loading={loading}
                  onClick={onMakeRequest}
                  disabled={!userAccount}
                >
                  {t('integrationsSettingsPage.integrationPage.testRequest.makeRequest')}
                </SButton>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    </SCard>
  );
};

export default TestRequest;
