import { Tooltip, Typography } from 'antd';
import { getUserName } from 'components/UserPreview/getUserName';
import { RATING_CALCULATION, RATING_MODE, WIDGET_VIEW_MODES } from 'core/utils/constants';
import { beatifyFloat } from 'core/utils/convertFloat';
import { valueFormatter } from 'core/utils/valueFormatter';
import { Trans } from 'react-i18next';
import { find, get, mean, orderBy, keyBy } from 'lodash';
import React from 'react';
import { stringify } from 'core/utils/queryString';
import { getOrderedGroupQuestionsAndQuestions } from 'pages/DashboardPage/utils';
import GetUserPreview from 'components/UserPreview/GetUserPreview';
import { getFilters, valueToColorZoneColor } from '../../utils';

const { Text } = Typography;

export const prepareColumns = ({ checklistItems, filters, checklistDefinition }) => {
  const viewMode = get(filters, 'viewMode', WIDGET_VIEW_MODES.QUESTIONS.value);
  const targetItems = getOrderedGroupQuestionsAndQuestions({ checklistItems, viewMode });

  const mapChecklistItemsToColumns = ({ checklistItem }) => {
    return checklistItem.reduce((acc, item) => {
      const data = {
        title: (
          <Tooltip title={item.name} placement="topLeft">
            <Text>{item.name}</Text>
          </Tooltip>
        ),
        dataIndex: item.id,
        key: item.id,
        align: 'center',
        width: checklistItem.length < 8 ? null : 170,
        ellipsis: true,
        render: (text, { key }) => {
          return {
            props: {
              style: {
                background: text?.color
              }
            },
            children: (text?.value || text?.value === 0) && (
              <Text strong={key === 'footer'}>
                {valueFormatter({ value: text?.value, ratingMode: checklistDefinition.ratingMode })}
              </Text>
            )
          };
        }
      };
      return [...acc, data];
    }, []);
  };

  return [
    {
      title: (
        <Trans
          i18nKey={
            viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
              ? 'dashboardPage.tableChecklistItemsByOperators.table.columns.questions'
              : 'dashboardPage.tableChecklistItemsByOperators.table.columns.groups'
          }
        />
      ),
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: 270,
      render: (text, record) => {
        return {
          props: {
            style: {
              padding: '2px 5px'
            }
          },
          children:
            record.key === 'header' || record.key === 'footer' ? (
              <Text strong={record.key === 'footer'}>
                <Trans i18nKey={text} />
              </Text>
            ) : (
              <GetUserPreview
                userId={record.key}
                altName={record.name}
                url={`/user/${record.key}/charts${stringify({
                  filters: { ...getFilters(filters) }
                })}`}
              />
            )
        };
      }
    },

    {
      title: <Trans i18nKey="dashboardPage.widget.unitName" />,
      dataIndex: 'unitName',
      key: 'unitName',
      fixed: 'left',
      width: 80
    },

    {
      title: <Trans i18nKey="dashboardPage.dashboard.reviewsCountShort" />,
      dataIndex: 'reviewsCount',
      key: 'reviewsCount',
      fixed: 'left',
      width: 80,
      align: 'center',
      render: text => text
    },

    ...mapChecklistItemsToColumns({
      checklistItem: targetItems,
      viewMode
    }),

    {
      title: (
        <Trans i18nKey="dashboardPage.tableChecklistItemsByOperators.table.columns.totalScore" />
      ),
      dataIndex: 'totalScore',
      key: 'totalScore',
      align: 'center',
      fixed: 'right',
      width: 140,
      render: text => {
        return {
          props: {
            style: {
              background: text?.color
            }
          },
          children: (
            <Text strong>
              {valueFormatter({ value: text?.value, ratingMode: checklistDefinition.ratingMode })}
            </Text>
          )
        };
      }
    }
  ];
};

export const prepareTableData = ({
  filters,
  usersByIds,
  unitsByIds,
  checklistItems,
  checklistDefinition,
  averageScoreByQuestions,
  checklistDefinitionAverageScore,
  checklistDefinitionsAverageScoresByOperators,
  checklistQuestionsAverageScoresByOperators,
  checklistQuestionGroupsAverageScoresByOperators,
  reviewsCountByOperators
}) => {
  const viewMode = get(filters, 'viewMode', WIDGET_VIEW_MODES.QUESTIONS.value);

  const widgetItem =
    viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
      ? Object.values(checklistQuestionsAverageScoresByOperators)
      : Object.values(checklistQuestionGroupsAverageScoresByOperators);

  const checklistItem =
    viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
      ? checklistItems.filter(item => item.type === 'checklist-questions')
      : checklistItems.filter(item => item.type !== 'checklist-questions');

  const mappedChecklistItemsScore = orderBy(
    widgetItem.reduce((acc, item) => {
      const checklistItemsByIds = keyBy(checklistItems, 'id');
      const score = get(checklistDefinitionsAverageScoresByOperators, `${item.operator_id}.value`);
      const value = checklistDefinitionsAverageScoresByOperators[item.operator_id]?.value;
      if (!score && score !== 0) return acc;
      const data = {
        key: item.operator_id,
        name: usersByIds[item.operator_id]
          ? getUserName({ user: usersByIds[item.operator_id] })
          : checklistDefinitionsAverageScoresByOperators[item.operator_id]?.name,
        ...item.scores.reduce((acc, item) => {
          const widgetKey =
            viewMode === WIDGET_VIEW_MODES.QUESTIONS.value
              ? item.question_id
              : item.question_group_id;
          return {
            ...acc,
            [widgetKey]: {
              value: item.value,
              color:
                checklistDefinition.ratingMode === RATING_MODE.NUMBERS &&
                viewMode !== WIDGET_VIEW_MODES.QUESTIONS.value
                  ? undefined
                  : valueToColorZoneColor({
                      value: item.value,
                      colorZones:
                        checklistDefinition.ratingMode === RATING_MODE.NUMBERS
                          ? checklistItemsByIds[widgetKey]?.colorZones
                          : checklistDefinition.colorZones
                    })
            }
          };
        }, {}),
        unitName: unitsByIds[usersByIds[item.operator_id]?.unitId]?.name,
        lastName: usersByIds[item.operator_id]?.lastName,
        totalScore: {
          value,
          color: valueToColorZoneColor({
            value,
            colorZones: checklistDefinition.colorZones
          })
        },
        reviewsCount: reviewsCountByOperators[item.operator_id]?.value
      };
      return [...acc, data];
    }, []),
    'lastName'
  );

  const checklistItemsAverageScore = () => {
    const checklistItemsByIds = keyBy(checklistItems, 'id');
    const objectKey =
      viewMode === WIDGET_VIEW_MODES.QUESTIONS.value ? 'question_id' : 'question_group_id';
    const scores = checklistItem.reduce((acc, item) => {
      const value = mean(
        widgetItem.map(operator =>
          get(
            find(operator.scores, {
              [objectKey]: item.id
            }),
            'value'
          )
        )
      );
      return {
        ...acc,
        [item.id]: {
          value,
          color: valueToColorZoneColor({
            value,
            colorZones:
              checklistDefinition.ratingMode === RATING_MODE.NUMBERS &&
              checklistDefinition.ratingCalculation === RATING_CALCULATION.SUM
                ? undefined
                : checklistDefinition.colorZones
          })
        }
      };
    }, {});

    const questionsAverageScore = averageScoreByQuestions.reduce((acc, item) => {
      return {
        ...acc,
        [item.id]: {
          value: item.value,
          color: valueToColorZoneColor({
            value: item.value,
            colorZones:
              checklistDefinition.ratingMode === RATING_MODE.NUMBERS
                ? checklistItemsByIds[item.id]?.colorZones
                : checklistDefinition.colorZones
          })
        }
      };
    }, {});

    return viewMode === WIDGET_VIEW_MODES.QUESTIONS.value ? questionsAverageScore : scores;
  };

  return [
    ...mappedChecklistItemsScore,
    {
      key: 'footer',
      name: 'dashboardPage.tableChecklistItemsByOperators.table.columns.average',
      ...checklistItemsAverageScore(),
      totalScore: {
        value: parseFloat(beatifyFloat(checklistDefinitionAverageScore)),
        color: valueToColorZoneColor({
          value: beatifyFloat(checklistDefinitionAverageScore),
          colorZones: checklistDefinition.colorZones
        })
      },
      reviewsCount: beatifyFloat(
        mean(Object.values(reviewsCountByOperators).map(item => item.value))
      )
    }
  ];
};
