import { destroy, get, post, put } from 'redux-bees';

const workPlanTaskConfigurations = apiPath => ({
  getWorkPlanTaskConfigurations: { method: get, path: `${apiPath}/work_plan/task_configurations` },
  getWorkPlanTaskConfiguration: {
    method: get,
    path: `${apiPath}/work_plan/task_configurations/:id`
  },
  updateWorkPlanTaskConfiguration: {
    method: put,
    path: `${apiPath}/work_plan/task_configurations/:id`
  },

  getWorkPlanTaskConfigurationTasks: {
    method: get,
    path: `${apiPath}/work_plan/task_configurations/:id/tasks`
  },

  createWorkPlanTaskConfiguration: {
    method: post,
    path: `${apiPath}/work_plan/task_configurations`
  },

  runWorkPlanTaskConfiguration: {
    method: post,
    path: `${apiPath}/work_plan/task_configurations/:id/run`
  },

  stopRepeatingWorkPlanTaskConfigurations: {
    method: post,
    path: `${apiPath}/work_plan/task_configurations/:id/stop_repeating`
  },

  cancelWorkPlanTaskConfigurations: {
    method: post,
    path: `${apiPath}/work_plan/task_configurations/:id/cancel`
  },

  completeWorkPlanTask: {
    method: post,
    path: `${apiPath}/work_plan/task_configurations/:id/tasks/:task_id/complete`
  },

  deleteWorkPlanTaskConfiguration: {
    method: destroy,
    path: `${apiPath}/work_plan/task_configurations/:id`
  }
});

export default workPlanTaskConfigurations;
